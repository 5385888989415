import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { Container } from "react-bootstrap";
import { useNotification } from "../../../Notifications/Notifications"
import { useNavigate } from 'react-router';
import Messages from './Messages';
import DashboardNavbar from '../../DashboardNavbar';

const socket = io('http://localhost:8000'); 

function SellerCollaborations() {
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [username] = useState('User');
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("Messages");
  const tabs = ["Messages", "Meetings"]

  const handleGoBack = () => {
    navigate("/seller-dashboard/user-profile");
  };
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const { addNotification } = useNotification();

  useEffect(() => {
    const messageHandler = (data) => {
      setMessages((prevMessages) => [...prevMessages, data]);
      if (data.replyTo) {

        addNotification({title:`${username} replied to your message` });
      } else {

        addNotification({
          title:`New message received from ${username}`,
      
        })
      }
    };

    socket.on('message', messageHandler);

    return () => {
      socket.off('message', messageHandler);
    };
  }, [addNotification]);

  const sendMessage = () => {
    socket.emit('message', { username, message: messageInput });
    setMessageInput('');
  };

  
  return (
<Container fluid style={{background: "#f5f5f5"}}>
    <DashboardNavbar
        title={"Collaborations"}
        handleBack={handleGoBack}
        tabs={tabs}
        selectedTab={selectedTab}
        handleTabClick={handleTabClick}
        backClick="/seller-dashboard/user-profile"
        comingSoon={true}
      />
  <Messages/>
</Container>

  );
}
export default SellerCollaborations;
