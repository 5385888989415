import { OTP_VERIFIED_SUCCESS, OTP_VERIFIED_FAILURE } from '../constants/constants';

const initialState = {
  verificationResult: null,
  error: null
};

const verificationReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case OTP_VERIFIED_SUCCESS:
      return {
        ...state,
        verificationResult: action.status,
        error: null
      };
    case OTP_VERIFIED_FAILURE:
      return {
        ...state,
        verificationResult: null,
        error: action.status
      };
    default:
      return state;
  }
};

export default verificationReducer;


