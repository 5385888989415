import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import plusIcon from "../../../../assets/plusIcon.svg";
import CompanyTable from "./CompanyTable";
import CreateNewSupplierForm from "./CreateNewSupplierForm";
import profileCreatedCheck from "../../../../assets/profileCreaytedCheck.svg";
import Overlay from "../../../Overlay/Overlay";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { SupplierCompanyData } from "../../../../DummyData";

const Suppliers = () => {
  const [isNewSupplierClicked, setNewSupplierClicked] = useState(false);
  const popupContainerRef = useRef(null);
  const createSupplierRef = useRef(null);
  const editSupplierRef = useRef(null);
  const [editableCompany, setEditableCompany] = useState(null);
  const [isSupplierCreated, setSupplierCreated] = useState(false);
  const [isEditableSupplier, setEditableSupplier] = useState(false);
  const [isTierOneActive, setTierOneActive] = useState(false);
  const [isTierTwoActive, setTierTwoActive] = useState(false);
  const [isAllSuppliersActive, setAllSuppliersActive] = useState(false);
  const [isEditPopup, setEditPopup] = useState(false);
  const dispatch = useDispatch();

  const suppliers = SupplierCompanyData;
  // const suppliers = useSelector((state) => state.suppliers.suppliers);

  

  const tierOneSuppliers = useSelector(
    (state) => state.suppliers.tierOneSuppliers
  );
  const tierTwoSuppliers = useSelector(
    (state) => state.suppliers.tierTwoSuppliers
  );
  const pendingSuppliers = useSelector(
    (state) => state.suppliers.pendingSuppliers
  );
console.log("tier 1:", tierOneSuppliers, 'tier2:', tierTwoSuppliers)
  // const suppliers = useSelector((state) => state.suppliers.suppliers);

  const totalSuppliers = tierOneSuppliers.length + tierTwoSuppliers.length || suppliers.length;
const newSupplier = {
  id: uuidv4(),
  firstName: "",
  lastName: "",
  email: "",
  address: {
    country: "",
    state: "",
    city: ""
  },
  company: {
    name: "",
    size: "",
    industry: "",
    companyProfilePicture: null,
    companyWebsite: "",
    annualRevenue: "",
    registrationStatus: "",
    yearFounded: "",
  },
  password: "",
  confirmPassword: "",
  supplierProfile: {
    name: "",
    profilePicture: null,
    email: "",
    primaryRole: "",
    rating: 0,
    bio: "",
    yearFounded: 0,
    linkedInUrl: "",
    diversity: {
      gender: "",
      pronouns: "",
      race: "",
      diversityStatus: "",
    },
    certificates: [],
    reviews: [],
    businessLicenses: "",
    services: [
      {
        serviceImage: null,
        title: "",
        description: "",
        tag: ""
      },
      {
        serviceImage: null,
        title: "",
        description: "",
        tag: ""
      },
      {
        serviceImage: null,
        title: "",
        description: "",
        tag: ""
      },
      {
        serviceImage: null,
        title: "",
        description: "",
        tag: ""
      },
      {
        serviceImage: null,
        title: "",
        description: "",
        tag: ""
      }
    ],
    skills: {
      technical: [],
      industry: [],
      company: []
    },
    tier: "",
    mainContacts: [
      {
        id: uuidv4(),
        name: "",
        primaryrole: "",
        email: ""
      }
    ],
    otherContacts: [
      {
        id: uuidv4(),
        name: "",
        primaryrole: "",
        email: ""
      }
    ]
  }
};


  const handleCreateSupplier = () => {
    setNewSupplierClicked(true);
  };

  const handleTierOneSuppliers = () => {
    setTierOneActive(true);
  };
  const handleTierTwoSuppliers = () => {
    setTierTwoActive(true);
  };
  const handleAllSuppliers = () => {
    setAllSuppliersActive(true);
  };

  const handleEdit = (supplier) => {
    const editableSupplierData =  {
      id: supplier.id,
      firstName: "",
      lastName: "",
      email: "",
      address: {
        country: supplier.address.country || "",
        state: supplier.address.state || "",
        city: supplier.address.city || "",
      },
      company: {
        name: supplier.company.name,
        size: supplier.company.size,
        industry: supplier.company.industry,
        companyProfilePicture: supplier.company.profilePicture,
        registrationStatus: supplier.registrationStatus,
        yearFounded: supplier.company.yearFounded,
        companyWebsite: supplier.company.companyWebsite,
        annualRevenue: "",
      },
      password: "",
      confirmPassword: "",
      supplierProfile: {
        name: supplier.supplierProfile.name,
        profilePicture: null,
        email: supplier.supplierProfile.email,
        primaryRole: "",
        rating: 0,
        bio: "",
        yearFounded: 0,
        linkedInUrl: "",
        diversity: {
          gender: "",
          pronouns: "",
          race: "",
          diversityStatus: supplier.supplierProfile.diversity.diversityStatus,
        },
        certificates: [supplier.supplierProfile.certificates[0]],
        reviews: [],
        businessLicenses: "",
        services: [
          {
            serviceImage: null,
            title: "",
            description: "",
            tag: "",
          },
          {
            serviceImage: null,
            title: "",
            description: "",
            tag: "",
          },
          {
            serviceImage: null,
            title: "",
            description: "",
            tag: "",
          },
          {
            serviceImage: null,
            title: "",
            description: "",
            tag: "",
          },
          {
            serviceImage: null,
            title: "",
            description: "",
            tag: "",
          },
        ],
        skills: {
          technical: [],
          industry: [],
          company: [],
        },
        tier: supplier.supplierProfile.tier || "",
        mainContacts: [
          {
            id: uuidv4(),
            name: "",
            primaryrole: "",
            email: "",
          },
        ],
        otherContacts: [
          {
            id: uuidv4(),
            name: "",
            primaryrole: "",
            email: "",
          },
        ],
      },
    };

    setNewSupplierClicked(true);
    setEditableCompany(editableSupplierData);
  };

  const handleClickOutside = (event) => {
    if (
      (popupContainerRef.current &&
        !popupContainerRef.current.contains(event.target)) ||
      (createSupplierRef.current &&
        !createSupplierRef.current.contains(event.target)) ||
      (editSupplierRef.current &&
        !editSupplierRef.current.contains(event.target))
    ) {
      setNewSupplierClicked(false);
      setEditableCompany(null);
      setEditableSupplier(false);
      setEditPopup(false);
      setSupplierCreated(false);
    }
  };
  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Container fluid style={{ background: "#F5F5F5", minHeight: "100vh" }}>
      <Row>
        <Col>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
              <div
                className="supplier-number-box"
                onClick={() => handleAllSuppliers()}
              >
                <h3 style={{ color: "#484848", fontSize: "32px" }}>
                  {totalSuppliers || 0}
                </h3>
                <p style={{ color: "#7B7B7B", fontSize: "16px" }}>
                  Total Suppliers
                </p>
              </div>
              <div
                className="supplier-number-box"
                onClick={() => handleTierOneSuppliers()}
              >
                <h3 style={{ color: "#484848", fontSize: "32px" }}>
                  {tierOneSuppliers.length}
                </h3>
                <p style={{ color: "#7B7B7B", fontSize: "16px" }}>
                  Tier 1 Suppliers
                </p>
              </div>
              <div
                className="supplier-number-box"
                onClick={() => handleTierTwoSuppliers()}
              >
                <h3 style={{ color: "#484848", fontSize: "32px" }}>
                  {tierTwoSuppliers.length}
                </h3>
                <p style={{ color: "#7B7B7B", fontSize: "16px" }}>
                  Tier 2 Suppliers
                </p>
              </div>
            </div>
            <button
              type="submit"
              onClick={() => handleCreateSupplier()}
              className="create-supplier-btn"
              style={{
                backgroundImage: `url(${plusIcon})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "left 10px center",
              }}
            >
              Create Supplier
            </button>
          </div>
        </Col>
      </Row>
      <CompanyTable
        suppliers={suppliers}
        handleEdit={handleEdit}
        pendingSuppliers={pendingSuppliers}
        isTierOneActive={isTierOneActive}
        tierOneSuppliers={tierOneSuppliers}
        isTierTwoActive={isTierTwoActive}
        tierTwoSuppliers={tierTwoSuppliers}
        isAllSuppliersActive={isAllSuppliersActive}
        setTierOneActive={setTierOneActive}
        setTierTwoActive={setTierTwoActive}
        setAllSuppliersActive={setAllSuppliersActive}
        totalSuppliers={totalSuppliers}
      />

      {isNewSupplierClicked && (
        <div ref={popupContainerRef}>
          <CreateNewSupplierForm
            setNewSupplierClicked={setNewSupplierClicked}
            newSupplier={newSupplier}
            editableCompany={editableCompany}
            setSupplierCreated={setSupplierCreated}
            setEditableSupplier={setEditableSupplier}
            isEditableSupplier={isEditableSupplier}
            setEditPopup={setEditPopup}
            tierOneSuppliers={tierOneSuppliers}
            tierTwoSuppliers={tierTwoSuppliers}
            pendingSuppliers={pendingSuppliers}
          />
        </div>
      )}

      {isSupplierCreated && (
        <>
          <Overlay />
          <div ref={createSupplierRef} className="supplier-created-container">
            <Image
              src={profileCreatedCheck}
              alt=""
              style={{ marginBottom: "22px" }}
            />
            <span>Supplier Created</span>
          </div>
        </>
      )}
      {isEditPopup && (
        <>
          <Overlay />
          <div ref={editSupplierRef} className="supplier-created-container">
            <Image
              src={profileCreatedCheck}
              alt=""
              style={{ marginBottom: "22px" }}
            />
            <span>Supplier details edited</span>
          </div>
        </>
      )}
    </Container>
  );
};
export default Suppliers;
