import React, { useState } from "react";
import { Image, Modal, Button } from "react-bootstrap";
import importDocument from "../../assets/importDocument.svg";
import pdfIcon from "../../assets/pdfIcon.svg";
import xlsIcon from "../../assets/xlsIcon.svg";
import csvIcon from "../../assets/csvIcon.svg";
import eyeIcon from "../../assets/eyeIcon.svg";
import deleteIcon from "../../assets/deleteIcon.svg";
import "./DragAndDropComponent.css";
import Overlay from "../Overlay/Overlay";
import scanAndAutoFill from "./ScanAndAutofill";

const DragAndDropComponent = ({
  onPreview,
  onDelete,
  importedFiles,
  setImportedFiles,
  setPdfFile,
  onFileChange,
  setAutoFilledData,
  subtitle,
  width,
  margin,
  height,
  color,
  documentIcon,
  background,
  eyeIconWhite,
  trashIcon

}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  console.log('IMPORTED FILES', importedFiles)

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleFileChange = async (event) => {
    event.preventDefault();
    const selectedFiles = event.target.files || event.dataTransfer.files;
    console.log('Selected files', selectedFiles, event.target.value)
  
    if (selectedFiles) {
      onFileChange(selectedFiles);
      const newFiles = Array.from(selectedFiles);
      setImportedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  
      const file = selectedFiles[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileData = e.target.result;
        setPdfFile(fileData);
      };
      try {
        const autoFillData = await scanAndAutoFill(file);
        console.log('Auto-filled data:', autoFillData);
        setAutoFilledData(autoFillData)
      } catch (error) {
        console.error('Error scanning and auto-filling:', error);
      }

      reader.readAsDataURL(file);
    }
  };
  
  const handleDeleteClick = (file) => {
    setFileToDelete(file);
    setShowConfirmation(true);
  };

  const handleConfirmDelete = () => {
    if (fileToDelete) {
      onDelete(fileToDelete.name);
      setFileToDelete(null);
      setShowConfirmation(false);
    }
  };

  const handleCancelDelete = () => {
    setFileToDelete(null);
    setShowConfirmation(false);
  };
  const getFileIcon = (fileName) => {
    const fileExtension = fileName.split('.').pop().toLowerCase();

    const iconMappings = {
      pdf: pdfIcon,
      xls: xlsIcon,
      csv: csvIcon,
    };

    return iconMappings[fileExtension]
  };

  return (
    <>
      <div
        className="drop-zone"
        onDragOver={handleDragOver}
        onDrop={handleFileChange}
      >
        <input
          type="file"
          id="fileInput"
          style={{ display: "none" }}
          onChange={handleFileChange}
          multiple
        />
        <label htmlFor="fileInput" className="drag-drop-container" style={{width: width ? width : '', marginTop: margin ? margin : '', height: height ? height : "", background: background ? background : ''}}>
          <div className="drag-drop-icon">
            <Image
              src={documentIcon || importDocument}
              alt="import document"
              style={{ marginBottom: "22px" }}
            />
          </div>
          <p style={{ fontSize: "14px", color: color || "#8F8F8F" }}>
            Drag and drop files here or{" "}
            <strong style={{ fontWeight: "bold", color: color || "#0E8080" }}>browse</strong>
          </p>
          <p style={{ fontSize: "10px", color: color || "#8F8F8F" }}>
            File must be in {subtitle || "PDF"} and less than 20mb
          </p>
        </label>
      </div>
      <div className="imported-files-list">
       
        {importedFiles.length > 0 && importedFiles.map((file, index) => {
          console.log(file)
          const fileSize = Math.round(file.size / 1024);
          const fileIcon = getFileIcon(file.name);
          const fileType = file.name.split('.').pop().toLowerCase();
          const filePreviewUrl = URL.createObjectURL(file);

          return (
            <div key={index} className="imported-file-item">
              <div style={{ display: "flex" }}>

              {fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' || fileType === 'gif' ? (
          <Image
            src={filePreviewUrl}
            alt={file.name}
            style={{
              display: "flex",
              padding: "12px",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              background: "#EDEDED",
              marginRight: "8px",
              width: "50px",
              height: "50px",
              objectFit: "cover",
            }}
          />
        ) : (
    <Image
                  src={fileIcon}
                  alt=""
                  style={{
                    display: "flex",
                    padding: "12px",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "8px",
                    background: "#EDEDED",
                    marginRight: "8px",
                  }}
                />
              )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <span style={{color: color ? color : "#000000"}}>{file.name}</span>
                  <span style={{ fontSize: "12px", color: color ? color : "#8F8F8F" }}>
                    {fileSize}kb
                  </span>
                </div>
              </div>
              <div className="file-icons">
                {fileType === 'pdf' && (
                <Image
                  src={eyeIconWhite ? eyeIconWhite : eyeIcon}
                  alt="Review icon"
                  onClick={() => onPreview(file)}
                />
                )}
                <Image
                  src={trashIcon ? trashIcon : deleteIcon}
                  alt="Delete icon"
                  onClick={() => handleDeleteClick(file)}
                />
              </div>
            </div>
          );
        })}
      </div>

      {showConfirmation && (
       
        <>
         <Overlay/>
        <div className="delete-confirm-popup">
          <div className="delete-preview-content">
          <Image
                  src={deleteIcon}
                  alt="Delete icon"
                  width="27px"
                  height="27px"
                  style={{marginBottom:"28px", marginLeft:"10px", borderRadius: "36px",padding:"5px",
                  background: "#F6F6F6"}}
                />
            <p>Are you sure you want to delete this document?</p>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <button
                className="close-preview-btn"
                onClick={handleCancelDelete}
                style={{marginRight:"10px", border:"0.949px solid #CDCDCD", background:"#FFF", color:"#7B7B7B"}}
              >
                Cancel
              </button>
              <button
                className="close-preview-btn"
                onClick={handleConfirmDelete}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
        </>
      )}
      
    </>
  );
};

export default DragAndDropComponent;
