import React, { useState, useRef, useEffect } from "react";
import SignUpContainer from "../SignUpContainer";
import { Col, Form } from "react-bootstrap";
import "./styling.css";
import axios from "axios";

const CompanyLocation = ({ formDataStep, setFormDataStep, handleNextStep }) => {
  const [isCountryDropdownOpen, setCountryDropdownOpen] = useState(false);
  const [isStateDropdownOpen, setStateDropdownOpen] = useState(false);
  const [countryLocationError, setCountryLocationError] = useState(false);
  const [stateLocationError, setStateLocationError] = useState(false);
  const [cityLocationError, setCityLocationError] = useState(false);
  const stateDropdownRef = useRef();
  const countryDropdownRef = useRef();
  const cityDropdownRef = useRef();
  const [query, setQuery] = useState("");



  const handleSubmit = (event) => {
    if(event) event.preventDefault();

    if (!formDataStep.address.country) {
      setCountryLocationError(true);
    } else {
      setCountryLocationError(false);
    }

    if (!formDataStep.address.state) {
      setStateLocationError(true);
    } else {
      setStateLocationError(false);
    }
    if (!formDataStep.address.city) {
      setCityLocationError(true);
    } else {
      setCityLocationError(false);
    }
    if (
      formDataStep.address.country &&
      formDataStep.address.state &&
      formDataStep.address.city
    ) {
      handleNextStep();
    }
  };

  const handleToggleCountry = () => {
    setCountryDropdownOpen(!isCountryDropdownOpen);
  };
  const handleToggleState = () => {
    setStateDropdownOpen(!isStateDropdownOpen);
  };


  const handleSelectCountryOption = (value) => {
    setFormDataStep({
      ...formDataStep,
      address: {
        ...formDataStep.address,
        country: value
      }
    });
    setCountryDropdownOpen(false);
    setCountryLocationError(false);
  };

  const handleSelectStateOption = (value) => {
    setFormDataStep({
      ...formDataStep,
      address: {
        ...formDataStep.address,
        state: value,
      },
    });
    setStateDropdownOpen(false);
    setStateLocationError(false);
  };
  const handleSelectCityOption = (value) => {
    setFormDataStep({
      ...formDataStep,
     address: {
        ...formDataStep.address,
        city: value,
      },
    });
    setCityLocationError(false);
  };
  const handleClickOutside = (event) => {
    if (
      (countryDropdownRef.current &&
        !countryDropdownRef.current.contains(event.target)) ||
      (stateDropdownRef.current &&
        !stateDropdownRef.current.contains(event.target)) ||
      (cityDropdownRef.current &&
        !cityDropdownRef.current.contains(event.target))
    ) {
      setCountryDropdownOpen(false);
      setStateDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const companyLocations = [
    "Alabama",
    "Alaska",
    "American Samoa",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District Of Columbia",
    "Florida",
    "Georgia",
    "Guam",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Marshall Islands",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Northern Marianas",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Palau",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];
  const countries = [
    "United States",
    "Canada",
    "United Kingdom",
    "Australia",
    "Germany",
    "France",
    "Japan",
    "China",
    "India",
    "Brazil",
    "Mexico",
    "Italy",
    "Spain",
    "South Korea",
    "Russia",
    "Indonesia",
    "Netherlands",
    "Switzerland",
    "Sweden",
    "Belgium",
  ];

  return (
    <SignUpContainer
      title="Company Location"
      subtitle={<>Where is your company located</>}
      onContinueClick={handleSubmit}
      isValid="true"
    >
      <Form onSubmit={handleSubmit}>
        <Form.Group
          as={Col}
          controlId="companyCountry"
          className="single-input"
        >
          <Form.Label className="input-title">Country</Form.Label>
          <div
            ref={countryDropdownRef}
            className={`custom-input custom-dropdown-container ${
              isCountryDropdownOpen ? "open" : ""
            }
            ${countryLocationError ? "error" : ""}`}
          >
            <div className="custom-dropdown" onClick={handleToggleCountry}>
              <span className="selected-option">
                {formDataStep.address.country || "Select Country"}
              </span>
              <ul
                className={`custom-dropdown-list ${
                  isCountryDropdownOpen ? "show" : ""
                }`}
              >
                <li
                  className={`custom-dropdown-item ${
                    formDataStep.address.country ? "" : "disabled"
                  }`}
                  onClick={() => handleSelectCountryOption("")}
                >
                  Select Location
                </li>
                {countries.map((country, index) => (
                  <li
                    key={index}
                    className="custom-dropdown-item"
                    onMouseDown={(e) => {
                      e.preventDefault();
                      handleSelectCountryOption(country);
                      setCountryLocationError(false);
                    }}
                  >
                    {country}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {countryLocationError && (
            <div className="error-message">Country is required</div>
          )}
        </Form.Group>

        <Form.Group as={Col} controlId="companyState" className="single-input">
          <Form.Label className="input-title">State</Form.Label>
          <div
            ref={stateDropdownRef}
            className={`custom-input custom-dropdown-container ${
              isStateDropdownOpen ? "open" : ""
            }
            ${stateLocationError ? "error" : ""}`}
          >
            <div className="custom-dropdown" onClick={handleToggleState}>
              <span className="selected-option">
                {formDataStep.address.state || "Select State"}
              </span>
              <ul
                className={`custom-dropdown-list ${
                  isStateDropdownOpen ? "show" : ""
                }`}
              >
                <li
                  className={`custom-dropdown-item ${
                    formDataStep.address.state ? "" : "disabled"
                  }`}
                  onClick={() => handleSelectStateOption("")}
                >
                  Select State
                </li>
                {companyLocations.map((location, index) => (
                  <li
                    key={index}
                    className="custom-dropdown-item"
                    onMouseDown={(e) => {
                      e.preventDefault();
                      handleSelectStateOption(location);
                      setStateLocationError(false);
                    }}
                  >
                    {location}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {stateLocationError && (
            <div className="error-message">State is required</div>
          )}
        </Form.Group>
        <Form.Group as={Col} controlId="cityLocation" className="single-input">
          <Form.Label className="input-title">City</Form.Label>
          <Form.Control
            type="text"
            placeholder="City"
            required
            className={`custom-input ${cityLocationError ? "error" : ""} `}
            value={formDataStep.address.city}
            onChange={(e) => {
              e.preventDefault();
              handleSelectCityOption(e.target.value);
              setCityLocationError(false);
            }}
          />
           {cityLocationError && (
            <div className="error-message">City is required</div>
          )}
        </Form.Group>
      </Form>
    </SignUpContainer>
  );
};

export default CompanyLocation;
