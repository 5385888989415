
import {
  OTP_SENT_SUCCESS,
  OTP_SENT_FAILURE,

} from '../constants/constants';

const initialState = {
  isOtpSent: false,
  otpId: null,
  error: null
};

const otpReducer = (state = initialState, action) => {
  console.log('ACTION otp', action.payload)
  switch (action.type) {
    case OTP_SENT_SUCCESS:
      return {
        ...state,
        otpId: action.payload,
        error: null
      };
      
    case OTP_SENT_FAILURE:
      return {
        ...state,
        otpId: null,
        error: action.payload
      };
    default:
      return state;
  }
};

export default otpReducer;



