import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {Chart, ArcElement} from 'chart.js'
Chart.register(ArcElement);

const DiversityDonutChart = ({title}) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const data = {
      labels: ['Minority owned business', 'Women owned business', 'Veteran owned business', 'SMBs', 'Others'],
      datasets: [
        {
          label: 'Diversity Metrics',
          data: [30, 20, 15, 25, 10],
          backgroundColor: [
            '#FFD399',
            '#D37CCF',
            '#B3F6FA',
            '#7045AF',
            '#3EA77B',
          ],
          borderWidth: 5,
          borderRadius:10
        },
      ],
    };

    setChartData(data);

    return () => {
      setChartData(null);
    };
  }, []);

  return (
    <div style={{display:"flex", flexDirection:"column"}}>
    <h3 style={{ margin: '0', marginBottom: '30px', display:"flex", marginTop:"20px", padding:"20px", fontSize:"15px" }}>{title || "Diversity Metric"}</h3>
    <div style={{ display: 'flex', alignItems: 'center', flexDirection:"row"}}>
      <div className='chart-position'>
        {chartData && (
          <Doughnut
            data={chartData}
            options={{
              // maintainAspectRatio: false,
              // responsive: true,
              cutout: '50%',
              plugins: {
                legend: {
                  display: false,
                },
                datalabels: {
                  color: '#000000', 
                  font: {
                    size: 16,
                  },
                  formatter: (value, context) => {
                    return `${value}%`;
                  },
                },
              },
              layout: {
                padding: {
                  left: 10,
                  right: 10,
                  top: 10,
                  bottom: 10,
                },
              },
            }}
            plugins={[ChartDataLabels]} 
          />
        )}
      </div>
      <div className='chart-labels' style={{ marginRight: '10px' }}>
        {chartData &&
          chartData.labels.map((label, index) => (
            <div key={index}  style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              <div
                style={{
                  width: '14px',
                  height: '14px',
                  backgroundColor: chartData.datasets[0].backgroundColor[index],
                  marginRight: '5px',
                  borderRadius: '3px',
                }}
              ></div>
              <span>{label}</span>
            </div>
          ))}
      </div>
    </div>
    </div>  
  );
  
};


export default DiversityDonutChart;

