import React from "react";
import "./PreviewPopup.css";

const PreviewPopup = ({ file, onClose }) => {
  console.log(file)
  return (
    <div className="preview-popup">
      <div className="preview-content">
        <div>
          <iframe
            className="preview"
            style={{
              width: "100%",
              height: "500px",
              border: "none",
              background: "none",
            }}
            src={file}
          ></iframe>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button className="close-preview-btn" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default PreviewPopup;
