import React, { useState, useEffect } from "react";
import "./landingPage.css";

const DynamicTextComponent = ({ words, isHovering, isMobile }) => {
  const [index, setIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const color = isHovering && isMobile ? "transparent" : isHovering ? "#fff" : "transparent";

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % words.length);
        setIsAnimating(false);
      }, 500);
    }, 1500);

    return () => clearInterval(interval);
  }, [index]);

  return (
    <div>
      <span className="dynamic-container-span">Connecting Enterprises</span>
      <br />
      <div className="word-slider">
        <div className="word-container" style={{ display: "flex" }}>
          <div className="animated-word">
            <span>and</span>
          </div>
          <div style={{ display: "flex" }}>
            <span
              key={index}
              className={`word ${isAnimating ? "active" : ""}`}
              style={{
                backgroundImage:
                  "linear-gradient(270deg, #CC9866 13.35%, #654198 82.21%)",
                WebkitBackgroundClip: "text",
                color: color,
                fontWeight: "700",
                display: "inline-block",
              }}
            >
              {words[index]}
            </span>
          </div>
          <div className="animated-word">
            <span> Suppliers</span>
          </div>
        </div>
      </div>
      <br />
      <span className="dynamic-container-span">with the Power of AI</span>
    </div>
  );
};

export default DynamicTextComponent;
