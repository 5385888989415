import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BasicInformation from "../SharedComponents/BasicInformation";
import CompanyDetails from "../SharedComponents/CompanyDetails";
import CompanyLocation from "../SharedComponents/CompanyLocation";
import CreatePassword from "../SharedComponents/CreatePassword";
import OTPConfirmation from "../SharedComponents/OTPConfirmation";
import ProfileConfirmation from "../SharedComponents/ProfileConfirmation";
import InvitationPage from "../SharedComponents/InvitationPage";
import { SellerSignup } from "../../../actions/authActions";
import { getSellerOTP, verifySellerOTP } from "../../../actions/OTPAction";
import { useDispatch, useSelector } from "react-redux";

const SellerOnboarding = () => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const [invitedMembers, setInvitedMembers] = useState([]);
  const currentSupplier = useSelector((state) => state.suppliers.currentSupplier);
  const [formDataStep, setFormDataStep] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: {
      country: "",
      state: "",
      city: "",
    },
    company: {
      name: "",
      size: "",
      industry: "",
      companyProfilePicture: "",
      companyWebsite: "",
      annualRevenue: "",
      registrationStatus: "",
      yearFounded: 0,
    },
    password: "",
    confirmPassword: "",

    supplierProfile: {
      name: "",
      profilePicture: "",
      email: "",
      primaryRole: "",
      rating: 0,
      bio: "",
      yearFounded: 0,
      linkedInUrl: "",

      diversity: {
        gender: "",
        pronouns: "",
        race: "",
        diversityStatus: "",
      },

      certificates: [""],
      businessLicenses: "",
      services: [
        {
          serviceImage: "",
          title: "",
          description: "",
          tag: "",
        },
      ],

      skills: {
        technical: [""],

        industry: [""],

        company: [""],
      },

      tier: "",
      mainContacts: [{ name: "", primaryRole: "", email: "" }],
      otherContacts: [
        {
          name: "",
          primaryRole: "",
          email: "",
        },
      ],
    },
  });

  const navigate = useNavigate();

  const handleSignUp = () => {
  dispatch(SellerSignup(formDataStep));
  };

  const getOtpCode = () => {
    dispatch(getSellerOTP(formDataStep.email, 5));
  };
  const handleOTPVerification = (email, otpId, otp) => {
    dispatch(verifySellerOTP(email, otpId, otp));
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    handleNextStep();
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
    window.history.pushState(null, null, `#${currentStep + 1}`);
  };
  useEffect(() => {
    if (currentStep === 1)
      window.history.pushState(null, null, `#${currentStep}`);
  }, []);
  useEffect(() => {
    const handleHashChange = () => {
      const hashStep = parseInt(window.location.hash.slice(1), 10);

      if (!isNaN(hashStep) && hashStep !== currentStep) {
        setCurrentStep(hashStep);
      }
    };

    const handleBackClick = () => {
      if (currentStep === 1) {
        navigate("/sign-up");
      } else {
        setCurrentStep((prevStep) => prevStep - 1);
      }
    };

    window.addEventListener("hashchange", handleHashChange);
    window.addEventListener("popstate", handleBackClick);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
      window.removeEventListener("popstate", handleBackClick);
    };
  }, [currentStep, navigate, setCurrentStep]);

  useEffect(() => {
    const hashStep = parseInt(window.location.hash.slice(1), 10);
    if (!isNaN(hashStep) && hashStep !== currentStep) {
      setCurrentStep(hashStep);
    }
  }, [currentStep]);

  const renderStep = () => {
    switch (currentStep) {
      // Step 1
      case 1:
        return (
          <BasicInformation
            formDataStep={formDataStep}
            setFormDataStep={setFormDataStep}
            handleNextStep={handleNextStep}
            handleSubmit={handleSubmit}
            accountType="seller"
            step={currentStep}
          />
        );
      // Step 2
      case 2:
        return (
          <CompanyDetails
            formDataStep={formDataStep}
            setFormDataStep={setFormDataStep}
            handleNextStep={handleNextStep}
            step={currentStep}
            dispalyQuestionMark="true"
            accountType="seller"
            skip="true"
          />
        );
      // Step 3
      case 3:
        return (
          <CompanyLocation
            formDataStep={formDataStep}
            setFormDataStep={setFormDataStep}
            handleNextStep={handleNextStep}
          />
        );
      // Step 4
      case 4:
        return (
          <InvitationPage
            formDataStep={formDataStep}
            invitedMembers={invitedMembers}
            setInvitedMembers={setInvitedMembers}
            setFormDataStep={setFormDataStep}
            handleNextStep={handleNextStep}
            step={currentStep}
            accountType="seller"
            skip="true"
          />
        );
      case 5:
        return (
          <CreatePassword
            formDataStep={formDataStep}
            setFormDataStep={setFormDataStep}
            handleNextStep={handleNextStep}
            step={currentStep}
            getOtpCode={getOtpCode}
          />
        );
      // Step 5
      case 6:
        return (
          <OTPConfirmation
            formDataStep={formDataStep}
            setFormDataStep={setFormDataStep}
            handleNextStep={handleNextStep}
            handleOTPVerification={handleOTPVerification}
            handleSignUp={handleSignUp}
          />
        );
      // Step 6
      case 7:
        return (
          <ProfileConfirmation
            formDataStep={formDataStep}
            setFormDataStep={setFormDataStep}
            handleNextStep={handleNextStep}
            handleSubmit={handleSubmit}
            profileSetup="Continue  with Diversity Data"
            title="Email confirmed"
            accountType="seller"
            currentSupplier={currentSupplier}
          />
        );
      default:
        return null;
    }
  };
  return <>{renderStep()}</>;
};

export default SellerOnboarding;
