import "./App.css";
import React, {useEffect} from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RoutesConfig from "./routes";
import { NotificationsProvider } from "./components/Notifications/Notifications"
import { SavedSuppliersProvider } from "./components/Dashboard/BuyerDasboard/Management/SavedSuppliersContext";
  import { setSuppliers, setTierOneSuppliers, setTierTwoSuppliers, fetchAllSuppliers, fetchSupplierById } from './actions/suppliersActions';
  import { SupplierCompanyData } from './DummyData';
  import axios from "axios";


const App = () => {
  const dispatch = useDispatch();
  const suppliers = useSelector((state) => state.suppliers?.suppliers);



  useEffect(() => {
    dispatch(fetchAllSuppliers());
  }, [dispatch]);



//   useEffect(() => {
//     // Once suppliers are fetched, categorize them
//     if (suppliers.length > 0) {
//       console.log("SUPPLIERS", suppliers)
//       const tierOneSuppliers = suppliers.filter(supplier => supplier.supplierProfile.tier === 'Tier 1');
//       const tierTwoSuppliers = suppliers.filter(supplier => supplier.supplierProfile.tier === 'Tier 2');
// console.log('from app tiers', tierOneSuppliers, tierTwoSuppliers)
//       dispatch(setTierOneSuppliers(tierOneSuppliers));
//       dispatch(setTierTwoSuppliers(tierTwoSuppliers));
//     }
//   }, [suppliers, dispatch]);



 

  return (
    <Router>
      <NotificationsProvider>
        <SavedSuppliersProvider>
          <div className="App">
            <RoutesConfig />
          </div>
        </SavedSuppliersProvider>
      </NotificationsProvider>
    </Router>
  );
}



export default App;
