import React, { useState, useEffect, useRef } from "react";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router";
import supplierIcon from "../../assets/LandingPage/character image.png";
import EnterpriceIcon from "../../assets/LandingPage/Buildings (1).json";
import supplierProfile from "../../assets/LandingPage/supplierSample.png";
import Lottie from "react-lottie";

const SuppliersDiscoverySection = ({ isMobile }) => {
  const navigate = useNavigate();
  const [isAnimating, setIsAnimating] = useState(false);

  const [isSmallLaptop, setSmallLaptop] = useState(window.innerWidth > 768 && window.innerWidth <= 1280 );


  useEffect(() => {
    const handleResize = () => {
      setSmallLaptop(window.innerWidth > 768 && window.innerWidth <= 1280 );
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: EnterpriceIcon,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    const container = document.querySelector(".suppliers-container");
    const textElements = container.querySelectorAll(".text-top");
    const bottomTextElements = container.querySelectorAll(".text-bottom");

    const handleScroll = () => {
      const containerTop = container.offsetTop;
      const containerHeight = container.clientHeight;
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;

      const isContainerTopInView =
        scrollPosition <= containerTop + containerHeight;
      const isContainerBottomInView =
        scrollPosition + windowHeight >= containerTop;

      if (isContainerTopInView && isContainerBottomInView && textElements) {
        textElements.forEach((textElement) => {
          textElement.classList.add("animate");
        });
      } else {
        setIsAnimating(false);
      }

      if (scrollPosition >= containerTop - 200 && textElements) {
        bottomTextElements.forEach((textElement) => {
          setIsAnimating(true);
          textElement.classList.add("animate");
        });
      } else {
        setIsAnimating(false);
      }
    };

    if (container) {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [isAnimating]);

  const handleSignUp = () => {
    navigate("/sign-up");
  };

  const handleAnimationComplete = () => {
    setIsAnimating(false);
  };

  return (
    <div
      className="suppliers-container"
      style={{ display: "flex", flexDirection: "column", height: "1382px" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          <span className="text-top">
            Increase Your Visibility as a Diverse Supplier with TalkDEI
          </span>
          <p className="text-top">
            Establish new opportunities for your business by joining our
            inclusive supplier marketplace. Showcase your company's specific
            skill sets, experiences, and unique offerings.   By creating a
            profile, you not only increase your visibility in the TalkDEI
            supplier network but also create a competitive edge for your
            business. Gain visibility and connect with enterprises actively
            seeking to diversify their supply chains
          </p>
        </div>
        <div style={{ position: "relative" }}>
          <Image
          className="supplier-icon"
            src={supplierIcon}
            alt=""
            width={isMobile ? 278 : ""}
            height={isMobile ? 259 : ""}
          />
          <Image
            src={supplierProfile}
            alt=""
            width={isMobile ? 239 : ""}
            height={isMobile ? 76 : ""}
            style={{
              position: "absolute",
              top: isMobile ? "150px" : "200px",
              right: isMobile ? "150px" : "200px",
            }}
          />
        </div>
      </div>

      {isMobile ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginTop: "107px" }}>
            <span className="text-bottom">
              Communicate with Enterprises Effortlessly
            </span>
            <p className="text-bottom">
              Experience seamless communication with enterprises using TalkDEI
              intuitive messaging system. Connect instantly, share updates, and
              discuss project details in a secure and centralized platform. Stay
              in sync with your enterprise partners, ensuring smooth
              collaboration and successful outcomes
            </p>
            <p>Join TalkDEI and Showcase Your Business to Enterprises</p>
            <button className="join-btn" onClick={handleSignUp}>
              Join TalkDEI
            </button>
          </div>
          <div style={{ marginBottom: "50px", marginTop:"50px" }}>
            <Lottie
              options={defaultOptions}
              height={314}
              width={341}
              speed={0.7}
              isPaused={!isAnimating}
              eventListeners={[
                {
                  eventName: "complete",
                  callback: handleAnimationComplete,
                },
              ]}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginBottom: "50px" }}>
            <Lottie
              options={defaultOptions}
              // height={isMobile ? 314 : 600}
              // width={isMobile ? 341 : 600}
              width={isMobile ? 341 : (isSmallLaptop ? 456 : 600)}
              height={isMobile ? 314 : (isSmallLaptop ? 421 : 600)}
              speed={0.7}
              isPaused={!isAnimating}
              eventListeners={[
                {
                  eventName: "complete",
                  callback: handleAnimationComplete,
                },
              ]}
            />
          </div>
          <div style={{ marginTop: "107px" }}>
            <span className="text-bottom">
              Communicate with Enterprises Effortlessly
            </span>
            <p className="text-bottom">
              Experience seamless communication with enterprises using TalkDEI
              intuitive messaging system. Connect instantly, share updates, and
              discuss project details in a secure and centralized platform. Stay
              in sync with your enterprise partners, ensuring smooth
              collaboration and successful outcomes
            </p>
            <p>Join TalkDEI and Showcase Your Business to Enterprises</p>
            <button className="join-btn" onClick={handleSignUp}>
              Join TalkDEI
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SuppliersDiscoverySection;
