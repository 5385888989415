import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
Chart.register(ArcElement);

const PieChart = ({ title }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const data = {
      labels: ["Contract Awards", "Modifications"],
      datasets: [
        {
          label: "Diversity Metrics",
          data: [60, 40],
          backgroundColor: ["#0E8080", "#CC9866"],
          borderWidth: 0,
        },
      ],
    };

    setChartData(data);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "15.341px",
        width: "413px",
        border: "0.959px solid #EBEBEB"
      }}
    >
      <p
        style={{
          display: "flex",
          margin: "10px",
          padding: "9.356px 10.025px",
          fontSize: "15px",
          color: "#1D2739",
          borderRadius: "4.01px",
          border: "0.668px solid #EBEBEB",
          background: "#FFF",
          width: "90px",
          height:"36px",
          alignSelf: "flex-end",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Quarterly
      </p>
      <div
        style={{ display: "flex", alignItems: "center", flexDirection: "row" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {chartData && (
            <div
              style={{
                position: "relative",
                width: "205px",
                height: "205px",
                // marginRight: "20px",
              }}
            >
              <Doughnut
                data={chartData}
                options={{
                  cutout: "85%",
                  plugins: {
                    legend: { display: false },
                    tooltips: {
                      enabled: true,
                      callbacks: {
                        label: function (tooltipItem, data) {
                          var dataset = data.datasets[tooltipItem.datasetIndex];
                          var currentValue = dataset.data[tooltipItem.index];
                          // var label = data.labels[tooltipItem.index] || "";
                          return `${currentValue}%`;
                        },
                      },
                    },
                    datalabels: {
                      display: true,
                      color: "#FFFFFF",
                      formatter: (value, ctx) => {
                        return `${
                          ctx.chart.data.labels[ctx.dataIndex]
                        }: ${value}%`;
                      },
                    },
                  },
                  layout: { padding: 20 },
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  textAlign: "center",
                }}
              >
                <span style={{ color: "#7B7B7B", fontSize: "15px" }}>
                  {title}
                </span>
                <span
                  style={{
                    color: "#1D2739",
                    fontSize: "16px",
                    fontWeight: "600",
                    display: "block",
                  }}
                >
                  1245
                </span>
              </div>
            </div>
          )}
          <div className='chart-labels'>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <span
                style={{
                  display: "inline-block",
                  width: "12px",
                  height: "12px",
                  backgroundColor: "#0E8080",
                  marginRight: "5px",
                }}
              ></span>
              <span
                style={{
                  color: "#7B7B7B",
                  // fontSize: "15px",
                  marginRight: "10px",
                }}
              >
                Contract Awards
              </span>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  display: "inline-block",
                  width: "12px",
                  height: "12px",
                  backgroundColor: "#CC9866",
                  marginRight: "5px",
                }}
              ></span>
              <span style={{ color: "#7B7B7B"}}>
                Modifications
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PieChart;
