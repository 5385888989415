import React, { useState } from "react";
import "./PdfImportButton.css";
import PreviewPopup from "../../../FilePreviewPopup/PreviewPopup";
import Overlay from "../../../Overlay/Overlay";
import axios from "axios";
import DragAndDropComponent from "../../../DragAndDrop/DragAndDropComponent";

const PdfImportButton = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [importedFiles, setImportedFiles] = useState([]);
  const [diverseGroups, setDiverseGroups] = useState([
    "Women-owned",
    "Veteran-owned",
    "Minority-owned",
    "LGBTQ-owned",
    "Disabled-owned",
    "Small business",
    "Native American-owned",
    "Black-owned",
    "Non-profit",
    "Asian-owned",
    "Hispanic-owned",
    "Alaskan Native-owned",
    "Socially Disadvantaged",
    "Pacific Islander-owned",
    "Middle Eastern-owned",
    "Native Hawaiian-owned",
    "Historically Underutilized-Owned",
    "Disadvantaged-owned"    
  ]);
  const [showPreviewPopup, setShowPreviewPopup] = useState(false);
  const handleFileChange = (files) => {
console.log(files)
  };
  

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const cancelImport = () => {
    togglePopup();
  };

  const completeImport = () => {
    togglePopup();
    handlePdf()
  };

  const handlePreview = (file) => {
    console.log(file);
    setShowPreviewPopup(true);
  };

  const closePreview = () => {
    setShowPreviewPopup(false);
  };
  const handlePdf = async () => {
    try {
      const apiEndpoint = 'YOUR_API';
      const response = await axios.get(apiEndpoint, {
        params: { file: pdfFile },
      });
      const data = response.data;

      console.log('Search results:', data);
  
    } catch (error) {
      console.error('Error during search:', error);
    }
  };
  const handleDelete = (fileName) => {
    setImportedFiles((prevImportedFiles) =>
      prevImportedFiles.filter((file) => file.name !== fileName)
    );

  }
  return (
    <div className="pdf-import-container">
      <button className="pdf-import-btn" onClick={togglePopup}>
        Upload RFP/RFQ
      </button>
      {showPopup && (
        <>
          <Overlay />
          <div
            className="popup-import-container"
          >
            <div className="popup-import-content">
              <div style={{ alignItems: "flex-start" }}>
                <p
                  style={{
                    marginTop: "53px",
                    display: "flex",
                    justifyContent: "flex-start",
                    color: "#000",
                  }}
                >
                  Upload RFP/RFQ document
                </p>
                <p
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    color: "#7B7B7B",
                  }}
                >
                  Uploading this document will help narrow down your supplier
                  search
                </p>
              </div>
              <DragAndDropComponent
            onFileChange={handleFileChange}
            onPreview={handlePreview}
            onDelete={handleDelete}
            importedFiles={importedFiles}
            setImportedFiles={setImportedFiles} 
            setPdfFile={setPdfFile}
          />
              <div className="diverse-group-selection">
                <p
                  style={{
                    fontSize: "16px",
                    display: "flex",
                    marginBottom: "23px",
                  }}
                >
                  Select Diverse Group
                </p>
                <div className="checkboxes">
                  {diverseGroups.map((group, index) => (
                    <div key={index}>
                      <input
                        type="checkbox"
                        id={`group-${index}`}
                        style={{ marginRight: "8px" }}
                      />
                      <label
                        htmlFor={`group-${index}`}
                        style={{ color: "#7B7B7B", gap: "45px" }}
                      >
                        {group}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div className="bottom-buttons">
                <button onClick={cancelImport} className="cancel-button">
                  Cancel
                </button>
                <button onClick={completeImport} className="done-button">
                  Done
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {showPreviewPopup && (
        <>
          <Overlay />
          <PreviewPopup file={pdfFile} onClose={closePreview} />
        </>
      )}
    </div>
  );
};
export default PdfImportButton;
