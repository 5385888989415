import React, { useState, useRef, useEffect } from "react";
import SignUpContainer from "../SignUpContainer";
import { Col, Form, Image } from "react-bootstrap";
import "./styling.css";


const CompanyDetailsPartTwo = ({ handleNextStep }) => {
  const [isDepartmentsDropdownOpen, setDepartmentsDropdownOpen] =
    useState(false);
  const [isUsersDropdownOpen, setUsersDropdownOpen] = useState(false);
  const departmentsDropdownRef = useRef();
  const usersDropdownRef = useRef();
  const [isMultiple, setisMultiple] = useState(false);
  const [companyFormData, setCompanyFormData] = useState({
    isManyDepartments: "",
    isMultipleUsers: "",
    numberOfUsersUsingPlatform: "",
  });

  // useEffect(() => {
  //   if (companyFormData.isMultipleUsers === "Yes") setisMultiple(true);
  // }, [companyFormData.isMultipleUsers]);

  const departmentsOptions = [" ", "Yes", "No"];
  const usersOptions = [" ", "Yes, there will be additional users", "No"];

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    handleNextStep();
  };

  const handleToggleDepartmentsDropdown = () => {
    setDepartmentsDropdownOpen(!isDepartmentsDropdownOpen);
  };

  const handleToggleUsersDropdown = () => {
    setUsersDropdownOpen(!isUsersDropdownOpen);
  };

  const handleSelectDepartmentsOption = (value) => {
    setCompanyFormData({
      ...companyFormData,
      isManyDepartments: value,
    });
    setDepartmentsDropdownOpen(false);
  };

  const handleSelectUsersOption = (value) => {
    if (value === "Yes, there will be additional users") setisMultiple(true);
    setCompanyFormData({
      ...companyFormData,
      isMultipleUsers: value,
    });
    setUsersDropdownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (
      (departmentsDropdownRef.current &&
        !departmentsDropdownRef.current.contains(event.target)) ||
      (usersDropdownRef.current &&
        !usersDropdownRef.current.contains(event.target))
    ) {
      setDepartmentsDropdownOpen(false);
      setUsersDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <SignUpContainer
      title="Company Details"
      height="790px"
      onContinueClick={handleSubmit}
    >
      <Form onSubmit={handleSubmit}>
        <Form.Group as={Col} controlId="companySize" className="single-input">
          <Form.Label className="input-title">
            Will your company be using this platform for more than one
            department
          </Form.Label>
          <div
            ref={departmentsDropdownRef}
            className={`custom-input custom-dropdown-container ${
              isDepartmentsDropdownOpen ? "open" : ""
            }`}
          >
            <div
              className="custom-dropdown"
              onClick={handleToggleDepartmentsDropdown}
            >
              <span className="selected-option">
                {companyFormData.isManyDepartments || "No"}
              </span>
              <ul
                className={`custom-dropdown-list ${
                  isDepartmentsDropdownOpen ? "show" : ""
                }`}
              >
                {departmentsOptions.map((option, index) => (
                  <li
                    key={option}
                    className="custom-dropdown-item"
                    onMouseDown={(e) => {
                      e.preventDefault();
                      handleSelectDepartmentsOption(option);
                    }}
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Form.Group>

        <Form.Group as={Col} controlId="companySize" className="single-input">
          <Form.Label className="input-title">
            Will there be multiple users accessing this platform under your
            company account?
          </Form.Label>
          <div
            ref={usersDropdownRef}
            className={`custom-input custom-dropdown-container ${
              isUsersDropdownOpen ? "open" : ""
            }`}
          >
            <div
              className="custom-dropdown"
              onClick={handleToggleUsersDropdown}
            >
              <span className="selected-option">
                {companyFormData.isMultipleUsers || "No"}
              </span>
              <ul
                className={`custom-dropdown-list ${
                  isUsersDropdownOpen ? "show" : ""
                }`}
              >
                {usersOptions.map((option, index) => (
                  <li
                    key={option}
                    className="custom-dropdown-item"
                    onMouseDown={(e) => {
                      e.preventDefault();
                      handleSelectUsersOption(option);
                    }}
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Form.Group>

        {isMultiple && (
          <Form.Group as={Col} controlId="lastName" className="single-input">
            <Form.Label className="input-title">How many users?</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              required
              className="custom-input"
              value={companyFormData.numberOfUsersUsingPlatform}
              onChange={(e) => {
                setCompanyFormData({
                  ...companyFormData,
                  numberOfUsersUsingPlatform: e.target.value,
                });
              }}
            />
          </Form.Group>
        )}
      </Form>
    </SignUpContainer>
  );
};

export default CompanyDetailsPartTwo;
