import React, { useEffect, useState } from "react";
import { Image, Card, Form, Col} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import star from "../../assets/star.svg";
import message from "../../assets/message.svg";
import menudots from "../../assets/saveIcon.svg";
import certificate from "../../assets/certificate.svg";
import "../Dashboard/Dashboard.css";
import DashboardNavbar from "../Dashboard/DashboardNavbar";
import arrowBackGrey from "../../assets/arrowBackGrey.svg";
import saveIcon from "../../assets/saveIcon.svg";
import certifiedCheck from "../../assets/certifiedCheck.svg";
import { useSavedSuppliers } from "../Dashboard/BuyerDasboard/Management/SavedSuppliersContext";
import CompanyAvatar from "../CompanyAvatar";
import Reviews from "./Reviews";
import Services from "./Services";
import PreviewPopup from "../FilePreviewPopup/PreviewPopup";
import Overlay from "../Overlay/Overlay";
import moneyBagIcon from "../../assets/moneyBagIcon.svg";

const FullUserProfileComponent = () => {
  const [selectedTab, setSelectedTab] = useState("about");
  const [isSaveToManagementTab, setSaveToManagementTab] = useState(false);
  const { savedSuppliers, updateSavedSuppliers } = useSavedSuppliers();
  const location = useLocation();
  const [isMouseOver, setMouseOver] = useState(false);
  const [showCertificates, setShowCertificates] = useState(true);
  const [isPreview, setPreview] = useState(false);
  const [isQuoteRequested, setQuoteRequsted] = useState(false);

  const supplier = location.state?.supplier || dummySupplier;
  const backUrl = location.state?.backUrl;

  const [projectDetails, setProjectDetails] = useState({
    name: "",
    description: "",
    budget: 0,
    time: "",
  });
  const navigate = useNavigate();
  const handleTabClick = (tab) => {
    if (tab !== "about") setShowCertificates(false);
    else {
      setShowCertificates(true);
    }
    setSelectedTab(tab);
  };

  const handleOpenCertificate = () => {
    setPreview(true);
  };

  const closePreview = () => {
    setPreview(false);
  };
  const handleUpdateSuppliers = (supplier) => {
    setSaveToManagementTab(!isSaveToManagementTab);
    const isSupplierExist = savedSuppliers.some((s) => s.id === supplier.id);

    if (!isSupplierExist) {
      updateSavedSuppliers([...savedSuppliers, supplier]);
    } else {
      console.log("Supplier already exists in the array");
    }
  };

  console.log("backUrl", backUrl);
  const handleGoBack = () => {
    navigate(backUrl);
  };
  const handleMessage = () => {
    navigate("/buyer-dashboard/collaborations");
  }
  const handleRequestQuote = () => {
    setQuoteRequsted(true);
  };

  const handleQuoteRequestedCompleted = () => {
    setQuoteRequsted(false);
    setProjectDetails({
      name: "",
      description: "",
      budget: 0,
      time: "",
    })
  };

  const handleCancel = () => {
    setQuoteRequsted(false);
    setProjectDetails({
      name: "",
      description: "",
      budget: 0,
      time: "",
    })
  }

  const handleMouseOver = () => {
    setMouseOver(true);
  };
  const handleMouseOut = () => {
    setMouseOver(false);
  };

  const divFlexRow = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };
  const divFlexColumn = {
    display: "flex",
    flexDirection: "column",
  };
  const tabStyle = {
    padding: "10px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const activeTabStyle = {
    color: "#1D2739",
    // borderBottom: "4px solid #9a9a9a",
    borderRadius: "8px",
    background: "#F0F2F4",
    height: "43px",
    width: "102px",
  };

  const inactiveTabStyle = {
    color: "#7B7B7B",
  };

  const generateInfoBox = (label, value) => {
    return (
      <div className="info-box">
        <p style={{ color: "grey", display: "flex", flexDirection: "column" }}>
          {label} <span style={{ color: "black" }}>{value}</span>
        </p>
      </div>
    );
  };

  return (
    <>
      <DashboardNavbar arrow={false} title="Profile" />
      <Card.Body className="full-user-profile-card-body">
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, marginRight: "38px", marginTop: "50px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "70px",
                marginLeft: "20px",
                gap: "10px",
              }}
            >
              <Image src={arrowBackGrey} alt="" />
              <span
                onClick={handleGoBack}
                style={{
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  color: "#9A9A9A",
                }}
              >
                Go back
              </span>
            </div>

            <div style={divFlexColumn}>
              <div
                style={{
                  ...divFlexRow,
                  justifyContent: "space-between",
                }}
              >
                <div style={divFlexRow}>
                  {supplier.company.companyProfilePicture ? (
                    <Image
                      src={supplier.company.companyProfilePicture}
                      alt=""
                      width="106px"
                      height="106px"
                      style={{ borderRadius: "9px", marginRight: "10px" }}
                    />
                  ) : (
                    <div style={{ marginRight: "10px" }}>
                      <CompanyAvatar
                        companyName={supplier.company.name}
                        size="106px"
                      />
                    </div>
                  )}
                  <div style={{ ...divFlexColumn, gap: "8px" }}>
                    <Card.Subtitle className="user-profile-subtitle">
                      {supplier.company.name}
                    </Card.Subtitle>
                    <Card.Subtitle
                      className="user-profile-subtitle"
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#7B7B7B",
                      }}
                    >
                      {supplier.company.industry}
                    </Card.Subtitle>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "column",
                        }}
                      >
                        <p style={{ color: "#7B7B7B", marginRight: "10px" }}>
                          {supplier.address.city},&nbsp;
                          {supplier.address.state},&nbsp;
                          {supplier.address.country}
                        </p>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <p style={{ color: "#7B7B7B", marginRight: "10px" }}>
                            Rating:
                          </p>
                          <span className="supplier-rating">
                            {/* <Image src={star} alt="star" /> */}
                            <p>{supplier.supplierProfile.rating}</p>
                          </span>
                          {supplier.supplierProfile.certificates.length > 0 && (
                            <span style={{ display: "flex" }}>
                              <img
                                src={certifiedCheck}
                                alt=""
                                style={{
                                  marginLeft: "16px",
                                  marginRight: "5px",
                                }}
                              />
                              <p
                                style={{
                                  fontSize: "14px",
                                  background:
                                    "linear-gradient(180deg, #CC9866 9.29%, #792E8E 98.58%)",
                                  WebkitBackgroundClip: "text",
                                  color: "transparent",
                                }}
                              >
                                Certified
                              </p>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={divFlexColumn}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <button
                      type="submit"
                      className="hire-btn"
                      onClick={handleRequestQuote}
                    >
                      Request a Quote
                    </button>
                    <div
                      style={{
                        borderRadius: "8px",
                        border: "1px solid #C9C9C9",
                        padding: "10px",
                      }}
                    >
                      <Image
                        src={message}
                        alt=""
                        width="16px"
                        height="16px"
                        onClick={handleMessage}
                      />
                    </div>
                    <div
                      style={{
                        borderRadius: "8px",
                        border: "1px solid #C9C9C9",
                        padding: "6px",
                      }}
                    >
                      <Image
                        src={menudots}
                        alt=""
                        width="24px"
                        height="24px"
                        onClick={() => handleUpdateSuppliers(supplier)}
                      />
                    </div>
                  </div>
                </div>

                {isQuoteRequested && (
                  <>
                    <Overlay />
                    <div className="edit-budget-container">
                      <Form>
                        <div style={divFlexRow}>
                          <Image
                            src={moneyBagIcon}
                            alt=""
                            style={{ marginRight: "9px" }}
                          />
                          <span
                            style={{
                              fontWeight: 600,
                              textAlign: "left",
                              fontSize: "20px",
                            }}
                          >
                            Request a quote
                          </span>
                        </div>
                        <Form.Group
                          as={Col}
                          controlId="amount"
                          className="single-input"
                          style={{ margin: "40px 0 20px 0" }}
                        >
                          <Form.Label className="input-title-edit-profile">
                            Project name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Project name"
                            className="custom-edit-profile-input"
                            value={projectDetails.name}
                            onChange={(e) =>
                              setProjectDetails({
                                ...projectDetails,
                                name: e.target.value,
                              })
                            }
                          />
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          controlId="amount"
                          className="single-input"
                          style={{ margin: "40px 0 20px 0" }}
                        >
                          <Form.Label className="input-title-edit-profile">
                          Give a detailed description of the project
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Description"
                            className="custom-edit-profile-input"
                            value={projectDetails.description}
                            onChange={(e) =>
                              setProjectDetails({
                                ...projectDetails,
                                description: e.target.value,
                              })
                            }
                          />
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          controlId="amount"
                          className="single-input"
                          style={{ margin: "40px 0 20px 0" }}
                        >
                          <Form.Label className="input-title-edit-profile">
                            Budget
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Project name"
                            className="custom-edit-profile-input"
                            value={projectDetails.budget}
                            onChange={(e) =>
                              setProjectDetails({
                                ...projectDetails,
                                budget: e.target.value,
                              })
                            }
                          />
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          controlId="amount"
                          className="single-input"
                          style={{ margin: "40px 0 20px 0" }}
                        >
                          <Form.Label className="input-title-edit-profile">
                            Timeline
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Weeks"
                            className="custom-edit-profile-input"
                            value={projectDetails.time}
                            onChange={(e) =>
                              setProjectDetails({
                                ...projectDetails,
                                time: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            gap: "20px",
                            position: "absolute",
                            bottom: "20px",
                            left: "50%",
                            transform: "translateX(-50%)",
                          }}
                        >
                          <button
                            variant="primary"
                            type="submit"
                            className="custom-edit-profile-button"
                            onClick={() => handleQuoteRequestedCompleted()}
                          >
                            Save
                          </button>
                          <button
                            onClick={() => handleCancel()}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              color: "#7E7E7E",
                              border: "none",
                              background: "none",
                              marginTop: "10px",
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </Form>
                    </div>
                  </>
                )}
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {isSaveToManagementTab && (
                  <div className="save-to-mng-tab-container">
                    <p>
                      You can find the saved supplier in the managements tab
                    </p>
                  </div>
                )}
              </div>
              <div
                className="tabs"
                style={{ ...divFlexRow, marginTop: "87px", gap: "44px" }}
              >
                <div
                  className="tab"
                  style={{
                    ...tabStyle,
                    ...(selectedTab === "about"
                      ? activeTabStyle
                      : inactiveTabStyle),
                  }}
                  onClick={() => handleTabClick("about")}
                >
                  About
                </div>
                <div
                  className="tab"
                  style={{
                    ...tabStyle,
                    ...(selectedTab === "skills"
                      ? activeTabStyle
                      : inactiveTabStyle),
                  }}
                  onClick={() => handleTabClick("skills")}
                >
                  Skills
                </div>
                <div
                  className="tab"
                  style={{
                    ...tabStyle,
                    ...(selectedTab === "services"
                      ? activeTabStyle
                      : inactiveTabStyle),
                  }}
                  onClick={() => handleTabClick("services")}
                >
                  Services
                </div>
                <div
                  className="tab"
                  style={{
                    ...tabStyle,
                    ...(selectedTab === "reviews"
                      ? activeTabStyle
                      : inactiveTabStyle),
                  }}
                  onClick={() => handleTabClick("reviews")}
                >
                  Reviews
                </div>
              </div>

              <div className="tab-content" style={{ marginBottom: "76px" }}>
                {selectedTab === "about" && (
                  <p>{supplier.supplierProfile.bio}</p>
                )}
                {selectedTab === "skills" && (
                  <div className="list-of-skills-pers-profile">
                    {Object.keys(supplier.supplierProfile.skills).map(
                      (skillType, index) => (
                        <div key={index}>
                          <h3>
                            {skillType === "technical"
                              ? "Technical Skills"
                              : skillType === "industry"
                              ? "Industry Skills"
                              : skillType === "company"
                              ? "Company Skills"
                              : skillType}
                          </h3>
                          <div className="skills-row">
                            {supplier.supplierProfile.skills[skillType].map(
                              (skill, skillIndex) => (
                                <span key={skillIndex}>{skill}</span>
                              )
                            )}
                          </div>
                        </div>
                      )
                    )}

                    <div>
                      <h3>Certificates</h3>
                      <div className="skills-row">
                        {supplier.supplierProfile.certificates.map(
                          (skill, skillIndex) => (
                            <span key={skillIndex}>{skill}</span>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {selectedTab === "services" && (
                  <Services services={supplier.supplierProfile.services} />
                )}
                {selectedTab === "reviews" && <Reviews />}
              </div>
              {showCertificates && (
                <>
                  <Card.Subtitle
                    className="integration-list-subtitle"
                    style={{ marginBottom: "8px" }}
                  >
                    Certificates
                  </Card.Subtitle>
                  {supplier.supplierProfile.certificates.length > 0 && (
                    <div
                      style={{
                        ...divFlexColumn,
                        border: "1px solid #ddd",
                        padding: "15px 14px",
                        gap: "34px",
                        borderRadius: "8px",
                        marginBottom: "40px",
                      }}
                    >
                      {supplier.supplierProfile.certificates.map((cert, i) => (
                        <div key={`${i} + ${cert}`} style={divFlexRow}>
                          <Image
                            src={certificate}
                            alt=""
                            width="18px"
                            height="18px"
                            style={{ borderRadius: "9px", marginRight: "10px" }}
                          />
                          <span onClick={() => handleOpenCertificate()}>
                            {cert}
                          </span>
                          {isPreview && (
                            <>
                              <Overlay />
                              <PreviewPopup
                                file={cert}
                                onClose={closePreview}
                              />
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          <div
            style={{
              maxWidth: "40%",
              background: "#F9F9F9",
              padding: "20px",
            }}
          >
            <div>
              <Card.Subtitle
                className="integration-list-subtitle"
                style={{
                  marginTop: "24px",
                  marginBottom: "15px",
                  color: "#000",
                }}
              >
                Company info
              </Card.Subtitle>
              <div
                style={{
                  background: "#fff",
                  padding: "20px",
                  border: "1px solid #EBEBEB",
                  borderRadius: "10px",
                }}
              >
                <div style={{ ...divFlexRow, marginTop: "16px" }}>
                  {supplier.company.companyProfilePicture ? (
                    <Image
                      src={supplier.company.companyProfilePicture}
                      alt=""
                      width="46px"
                      height="46px"
                      style={{
                        borderRadius: "9px",
                        marginRight: "10px",
                        marginBottom: "42px",
                      }}
                    />
                  ) : (
                    <div style={{ marginRight: "10px", marginBottom: "42px" }}>
                      <CompanyAvatar
                        companyName={supplier.company.name}
                        size="46px"
                      />
                    </div>
                  )}

                  <div
                    style={{
                      ...divFlexColumn,
                      alignItems: "flex-start",
                      marginBottom: "42px",
                    }}
                  >
                    <span>{supplier.company.name}</span>
                    <span style={{ color: "#7B7B7B" }}>
                      {supplier.company.companyWebsite}
                    </span>
                  </div>
                </div>

                <div className="company-full-info-container">
                  <div className="info-boxes">
                    {generateInfoBox("Industry", supplier.company.industry)}
                    {generateInfoBox(
                      "Diversity Group",
                      supplier.supplierProfile.diversity.diversityStatus
                    )}
                    {generateInfoBox("Employees", supplier.company.size)}
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                background: "#fff",
                padding: "20px",
                border: "1px solid #EBEBEB",
                borderRadius: "10px",
                marginTop: "50px",
              }}
            >
              <Card.Subtitle
                className="integration-list-subtitle"
                style={{
                  marginTop: "30px",
                  marginBottom: "15px",
                  color: "#7B7B7B",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Main Contact
              </Card.Subtitle>
              <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                <div style={{ ...divFlexRow, marginTop: "16px" }}>
                  {supplier.supplierProfile.profilePicture ? (
                    <Image
                      src={supplier.supplierProfile.profilePicture}
                      alt=""
                      width="46px"
                      height="46px"
                      style={{
                        borderRadius: "9px",
                        marginRight: "10px",
                        marginBottom: "42px",
                      }}
                    />
                  ) : (
                    <div style={{ marginRight: "10px", marginBottom: "42px" }}>
                      <CompanyAvatar
                        companyName={supplier.supplierProfile.name}
                        size="46px"
                      />
                    </div>
                  )}
                  <div
                    style={{
                      ...divFlexRow,
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        ...divFlexColumn,
                        alignItems: "flex-start",
                        marginBottom: "42px",
                        marginRight: "50px",
                      }}
                    >
                      <p
                        style={{
                          display: "flex",
                          color: "#7B7B7B",
                          fontSize: "16px",
                        }}
                      ></p>
                      <span style={{ color: "#313131" }}>
                        {supplier.supplierProfile.name}
                      </span>
                      <span style={{ color: "#313131" }}>
                        {supplier.supplierProfile.primaryRole}
                      </span>
                    </div>
                    {isMouseOver && (
                      <div className="contact-hover" onClick={handleMessage}>
                        <Image
                          src={message}
                          alt=""
                          width="16px"
                          height="16px"
                          style={{ marginRight: "9px" }}
                        />
                        Message
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <Card.Subtitle
                className="integration-list-subtitle"
                style={{
                  marginTop: "30px",
                  marginBottom: "15px",
                  color: "#7B7B7B",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Other Contacts
              </Card.Subtitle>
              <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                <div style={{ ...divFlexRow, marginTop: "16px" }}>
                  {supplier.supplierProfile.profilePicture ? (
                    <Image
                      src={supplier.supplierProfile.profilePicture}
                      alt=""
                      width="46px"
                      height="46px"
                      style={{
                        borderRadius: "9px",
                        marginRight: "10px",
                        marginBottom: "42px",
                      }}
                    />
                  ) : (
                    <div style={{ marginRight: "10px", marginBottom: "42px" }}>
                      <CompanyAvatar
                        companyName={supplier.supplierProfile.name}
                        size="46px"
                      />
                    </div>
                  )}
                  <div
                    style={{
                      ...divFlexRow,
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        ...divFlexColumn,
                        alignItems: "flex-start",
                        marginBottom: "42px",
                        marginRight: "50px",
                      }}
                    >
                      <p
                        style={{
                          display: "flex",
                          color: "#7B7B7B",
                          fontSize: "16px",
                        }}
                      ></p>
                      <span style={{ color: "#313131" }}>
                        {supplier.supplierProfile.name}
                      </span>
                      <span style={{ color: "#313131" }}>
                        {supplier.supplierProfile.primaryRole}
                      </span>
                    </div>
                    {/* {isMouseOver && (
                      <div className="contact-hover" onClick={handleMessage}>
                        <Image
                          src={message}
                          alt=""
                          width="16px"
                          height="16px"
                          style={{ marginRight: "9px" }}
                        />
                        Message
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card.Body>
    </>
  );
};

export default FullUserProfileComponent;
