import React from "react";
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import "../../Dashboard.css";
import certifiedCheck from "../../../../assets/certifiedCheck.svg";
import CompanyAvatar from "../../../CompanyAvatar";

const GalleryOfSuppliers = ({ suppliers, onCardClick }) => {
  const suppliersGallery = suppliers.map((supplier, index) => (
    <Col
      key={index}
      md={4}
      className="mb-4"
      onClick={() => onCardClick(supplier)}
    >
      <Card className="gallery-supplier-card">
        <Card.Body
          className="gallery-card-body"
          style={{ textAlign: "center" }}
        >
          {supplier.company.companyProfilePicture ? (
            <Image
              src={supplier.company.companyProfilePicture}
              alt=""
              width="58px"
              height="58px"
              style={{
                borderRadius: "11px",
                marginBottom: "10px",
                marginTop: "20px",
              }}
            />
          ) : (
            <div style={{marginTop:"20px", marginBottom: "10px"}}>
            <CompanyAvatar companyName={supplier.company.name} size="58px" />
            </div>
          )}

          <Card.Title
            style={{
              color: "#1D2739",
              fontSize: "19px",
              fontWeight: 500,
              marginBottom: "11px",
            }}
          >
            {supplier.company.name}
          </Card.Title>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "26px",
            }}
          >
            <p
              style={{ marginBottom: "5px" }}
              className="integration-list-desc"
            >
              {supplier.company.industry}
            </p>
            <p className="integration-list-desc">
              {" "}
              {supplier.address.city},&nbsp;
              {supplier.address.state},&nbsp;
              {supplier.address.country}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p className="mr-2" style={{ color: "#7B7B7B" }}>
              Rating:
            </p>
            {/* <img src={star} alt="star" /> */}
            <p
              className="ml-2"
              style={{
                borderRadius: "4.501px",
                background: "rgba(216, 216, 216, 0.63)",
                width: "37px",
                marginLeft: "9px",
              }}
            >
              {supplier.supplierProfile.rating}
            </p>
            {supplier.supplierProfile.certificates.length > 0 && (
              <span style={{ display: "flex" }}>
                <img
                  src={certifiedCheck}
                  alt=""
                  style={{ marginLeft: "16px", marginRight: "5px" }}
                />
               <p
                  style={{
                    fontSize: "14px",
                    background:
                      "linear-gradient(180deg, #CC9866 9.29%, #792E8E 98.58%)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                  }}
                >
                  Certified
                </p>
              </span>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              padding: " 30px 20px 20px 20px",
            }}
          >
            <Card.Subtitle>About</Card.Subtitle>
            <p style={{ color: "#7B7B7B" }}>{supplier.supplierProfile.bio}</p>
          </div>
        </Card.Body>
      </Card>
    </Col>
  ));
  const groupedSuppliers = [];
  for (let i = 0; i < suppliersGallery.length; i += 3) {
    groupedSuppliers.push(
      <Row key={i} className="gallery-suppliers-list">
        {suppliersGallery.slice(i, i + 3)}
      </Row>
    );
  }

  return (
    <Container fluid className="gallery-suppliers-main-container mt-4">
      {groupedSuppliers}
    </Container>
  );
};

export default GalleryOfSuppliers;
