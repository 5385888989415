// import React from "react";
// import "./ProgressBarComponent.css";
// import {Image} from "react-bootstrap";
// import userIcon from "../../../assets/userIconProgressBar.svg";
// import diversityIcon from "../../../assets/diversityProgressBarIcon.svg";
// import diversityIconBlack from "../../../assets/diversityIconBlack.svg";
// import certIcon from "../../../assets/certificateLineIcon.svg";
// import certIconBlack from "../../../assets/certIconBlack.svg";

// const ProgressBarComponent = ({ selectedStep }) => {
//   const steps = [
//     {
//       step:"Personal Details",
//       img: userIcon
//     },
//     {
//       step:"Diversity Group",
//       img: diversityIcon
//     },
//     {
//       step: "Certificates",
//       img: certIcon
//     }
//   ];

//   return (
//     <div className="progress-bar-container">
//       <div
//         className="progress-bar"
//       ></div>
//       <div className="progress-steps">
//         {steps.map((step, index) => (
//           <div
//             key={index}
//             className={`progress-step ${selectedStep >= index + 1 ? "active" : ""}`}
//           >
//             <Image
//              src={step.img}
//              alt=""
//              style={{marginLeft:"10px"}}
//             />
//              <span style={{ color: selectedStep >= index + 1 ? "#fff" : "#000000" }}>
//             {step.step}
//             </span>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ProgressBarComponent;

import React from "react";
import "./ProgressBarComponent.css";
import { Image } from "react-bootstrap";
import userIcon from "../../../assets/userIconProgressBar.svg";
import userIconBlack from "../../../assets/userIconBlack.svg"
import diversityIcon from "../../../assets/diversityProgressBarIcon.svg";
import diversityIconBlack from "../../../assets/diversityIconBlack.svg";
import certIcon from "../../../assets/certificateLineIcon.svg";
import certIconBlack from "../../../assets/certIconBlack.svg";

const ProgressBarComponent = ({ selectedStep }) => {
  // Define icons and their corresponding active and inactive versions
  const icons = [
    { step: "Personal Details", icon: userIcon, iconBlack: userIconBlack },
    { step: "Diversity Group", icon: diversityIcon, iconBlack: diversityIconBlack },
    { step: "Certificates", icon: certIcon, iconBlack: certIconBlack },
  ];

  return (
    <div className="progress-bar-container">
      <div className="progress-bar"></div>
      <div className="progress-steps">
        {icons.map((icon, index) => (
          <div key={index} className={`progress-step ${selectedStep >= index + 1 ? "active" : ""}`}>
            <Image src={selectedStep >= index + 1 ? icon.icon : icon.iconBlack} alt="" style={{ marginLeft: "10px" }} />
            <span style={{ color: selectedStep >= index + 1 ? "#fff" : "#000000" }}>
              {icon.step}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBarComponent;
