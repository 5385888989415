
import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import DashboardNavbar from "../../DashboardNavbar";
import { useLocation, useNavigate } from "react-router";
import PaginationBar from "../../../PaginationBar/PaginationBar";
import successCheck from "../../../../assets/successCheck.svg";
import { useNotification } from "../../../Notifications/Notifications"

const MappingTable = () => {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [suppliersData, setSuppliersData] = useState(location.state?.suppliers);
  const [unmappedColumns, setUnmappedColumns] = useState(0);
  const [isUnmapped, setIsUnmapped] = useState(false);
  const navigate = useNavigate();
  const itemsPerPage = 1;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleListSuppliers = suppliersData.slice(startIndex, endIndex);
  const totalItems = suppliersData.length;
  const { addNotification } = useNotification();

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const fieldValues = [
    { fieldName: "Company name", value: "companyname" },
    { fieldName: "Company type", value: "companytype" },
    { fieldName: "URL", value: "url" },
    { fieldName: "Contact Email", value: "contactemail" },
    { fieldName: "Ownership", value: "ownership" },
    { fieldName: "Certification", value: "certification" }
  ];

  useEffect(() => {
    const checkUnmappedColumns = () => {
      let count = 0;
      suppliersData.forEach((supplier) => {
        fieldValues.forEach((field) => {
          if (!(field.value in supplier)) {
            count++;
          }
        });
      });
      setUnmappedColumns(count);
      setIsUnmapped(count > 0);
    };

    checkUnmappedColumns();
  }, [suppliersData]);

  const getValueForField = (supplier, fieldName) => {
    if (fieldName in supplier) {
      return supplier[fieldName];
    } else {
      return "";
    }
  };

  const getMappedValue = (value) => {
    return value !== "" ? "Success" : "Mapping Error";
  };

  const getTypeForField = (index) => {
    const typeValues = [
      "Text",
      "Long text",
      "Text",
      "Text",
      "Text",
      "Text"
    ];
    return typeValues[index];
  };

  const paginationBarStyle = {
    position: "absolute",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    padding: "10px",
    background: "transparent",
    bottom: "120px",
    boxShadow: "none",
    gap: "20px",
  };

  const handleDone = () => {
    addNotification({ title: 'Supplier imported.', subtitle: 'Your supplier database will be updated in a few hours.' });
    navigate("/buyer-dashboard/management");
  };

  return (
    <>
      <DashboardNavbar title={"Mapping"} arrow={false} />
      <Container
        fluid
        style={{
          margin: "10px 20px 0 10px",
          background: "#fff",
          borderRadius: "13px",
          position: "relative",
          borderRadius: "13.558px",
          border: "1.043px solid #EBEBEB",
        }}
      >
        <div className="suppliers-table" style={{height:"350px"}}>
          <div className="table-header">
            <div className="column">TalkDEI Fields</div>
            <div className="column">Preview</div>
            <div className="column">Type</div>
            <div className="column">Mapped</div>
          </div>
          <div className="table-body">
            {visibleListSuppliers.map((supplier, supplierIndex) => (
              <React.Fragment key={supplierIndex}>
                {fieldValues.map((field, index) => (
                  <div
                    key={index}
                    className={`table-row ${
                      index % 2 === 1 ? "row-even" : ""
                    }`}
                    style={{
                      borderBottom: "1px solid #F6F6F6",
                      height: "49px",
                      paddingLeft: "10px",
                    }}
                  >
                    <div className="column">{field.fieldName}</div>
                    <div className="column">
                      {getValueForField(supplier, field.value)}
                    </div>
                    <div className="column">{getTypeForField(index)}</div>
                    <div
                      className="column"
                      style={getMappedValue(
                        getValueForField(supplier, field.value)
                      ).style}
                    >
                      {getValueForField(supplier, field.value) !== "" ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={successCheck}
                            alt="Success"
                            style={{ marginRight: "5px" }}
                          />
                          <p style={{ color: "#0E8080", margin: 0 }}>
                            Success
                          </p>
                        </div>
                      ) : (
                        <p style={{ color: "#FF0000" }}>Mapping Error</p>
                      )}
                    </div>
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
          <PaginationBar
            currentPage={currentPage}
            totalPages={Math.ceil(totalItems / itemsPerPage)}
            handlePageChange={handlePageChange}
            paginationBarStyle={paginationBarStyle}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "30px",
            gap: "10px",
          }}
        >
          {isUnmapped ? (
            <button className="unmapped-btn">
              You have {unmappedColumns} columns unmapped
            </button>
          ) : (
            <button
              className="mapped-btn"
              style={{
                backgroundImage: `url(${successCheck})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "left 20px center",
              }}
            >
              Successfully Mapped
            </button>
          )}
          <button
            className="done-btn-mapping-table"
            onClick={() => handleDone()}
          >
            Done
          </button>
        </div>
      </Container>
    </>
  );
};

export default MappingTable;
