import React, { useState } from "react";
import "../../../Dashboard/BuyerDasboard/Marketplace/PdfImportButton.css";
import { Image } from "react-bootstrap";
import PreviewPopup from "../../../FilePreviewPopup/PreviewPopup";
import Overlay from "../../../Overlay/Overlay";
import SignUpContainer from "../../SignUpContainer";
import certificateIcon from "../../../../assets/diversityCertificate.svg";
import licenseIcon from "../../../../assets/licenseIcon.svg";
import licenseIconInactive from "../../../../assets/licenseIconInactive.svg";
import certificateIconInactive from "../../../../assets/certIconInactive.svg"
import DragAndDropComponent from "../../../DragAndDrop/DragAndDropComponent";
import documentIcon from "../../../../assets/documentIconWhite.svg"
import trashWhite from "../../../../assets/trashWhite.svg"
import eyeWhite from "../../../../assets/eyeWhite.svg"


const UserCertificates = ({ handleNextStep, handleUpdateSupplier, handleSkip,setFormDataStep,step,skip, accountType, sellerProfile}) => {
  const [pdfFile, setPdfFile] = useState(null);
  const [importedCertificates, setImportedCertificates] = useState([]);
  const [importedLicences, setImportedLicenses] = useState([]);
  const [selectedTab, setSelectedTab] = useState("certificates");

  const [showPreviewPopup, setShowPreviewPopup] = useState(false);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };
  const handleSubmit = (event) => {
    event.preventDefault();

      handleNextStep();
      handleUpdateSupplier()
  };

  const handleFileChange = (files) => {
    console.log("Files", files, selectedTab);
    const newFiles = Array.from(files);
    console.log(newFiles)
  
    if (selectedTab === "certificates") {
      setFormDataStep((prevFormDataStep) => ({
        ...prevFormDataStep,
        certificates: [
          ...(prevFormDataStep.certificates || []),
          ...newFiles.map((file) => file.name),
        ],
      }));
    }
  

  if (selectedTab === "licenses") {
    setFormDataStep((prevFormDataStep) => ({
      ...prevFormDataStep,
      licenses: [
        ...(prevFormDataStep.licenses || []),
        ...newFiles.map((file) => file.name),
      ],
    }));
  }
};

  const divFlexRow = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };
  const divFlexColumn = {
    display: "flex",
    flexDirection: "column",
  };
  const tabStyle = {
    padding: "10px",
    cursor: "pointer",
  };

  const activeTabStyle = {
    color: "white",
    borderBottom: "4px solid #782D8F",
  };

  const inactiveTabStyle = {
    color: "#8F8F8F",
  };
  const handlePreview = (file) => {
    console.log(file);
    setShowPreviewPopup(true);
  };

  const closePreview = () => {
    setShowPreviewPopup(false);
  };
  const handleDelete = (fileName) => {
    if (selectedTab === "certificates") {
      setImportedCertificates((prevImportedFiles) =>
        prevImportedFiles.filter((file) => file.name !== fileName)
      );
    }

    if (selectedTab === "licenses") {
      setImportedLicenses((prevImportedFiles) =>
        prevImportedFiles.filter((file) => file.name !== fileName)
      );
    }
  };

  return (
    <SignUpContainer
      title="Upload Certificates"
      subtitle={<>Upload your supplier certificates</>}
      height="955px"
      step={step}
      skip={skip}
      // containerHeight="100%"
      onContinueClick={handleSubmit}
      handleSkip={handleSkip}
      accountType={accountType}
      sellerProfile={sellerProfile}
    >
      <div style={{ padding: "0 44px 32px 44px" }}>
        <div className="cert-tabs" style={divFlexRow}>
          <div
            className="tab"
            style={{
              ...tabStyle,
              ...(selectedTab === "certificates"
                ? activeTabStyle
                : inactiveTabStyle),
            }}
            onClick={() => handleTabClick("certificates")}
          >
            <Image
              src={selectedTab === "certificates" ? certificateIcon : certificateIconInactive}
              alt="Diversity Certificate Icon"
              className="icon"
              style={{marginRight:"3px"}}
            />
            Diversity Certificate
          </div>
          <div
            className="cert-tab"
            style={{
              ...tabStyle,
              ...(selectedTab === "licenses"
                ? activeTabStyle
                : inactiveTabStyle),
            }}
            onClick={() => handleTabClick("licenses")}
          >
            <Image
                src={selectedTab === "licenses" ? licenseIcon : licenseIconInactive}
              alt="Diversity Certificate Icon"
              className="icon"
              style={{marginRight:"3px"}}
            />
            Business Licenses
          </div>
        </div>
        {selectedTab === "certificates" ? (
          <DragAndDropComponent
            onFileChange={handleFileChange}
            onPreview={handlePreview}
            onDelete={handleDelete}
            importedFiles={importedCertificates}
            setImportedFiles={setImportedCertificates}
            setPdfFile={setPdfFile}
            color= "#fff"
            documentIcon={documentIcon}
            background="rgba(0, 0, 0, 0.10)"
            eyeIconWhite={eyeWhite}
            trashIcon={trashWhite}

          />
        ) : (
          <DragAndDropComponent
            onFileChange={handleFileChange}
            onPreview={handlePreview}
            onDelete={handleDelete}
            importedFiles={importedLicences}
            setImportedFiles={setImportedLicenses}
            setPdfFile={setPdfFile}
            color= "#fff"
            documentIcon={documentIcon}
            background="rgba(0, 0, 0, 0.10)"
            eyeIconWhite={eyeWhite}
            trashIcon={trashWhite}
            

          />
        )}
      </div>
      {showPreviewPopup && (
        <>
          <Overlay />
          <PreviewPopup file={pdfFile} onClose={closePreview} />
        </>
      )}
    </SignUpContainer>
  );
};
export default UserCertificates;
