// import React, { createContext, useContext, useReducer, useCallback } from 'react';
// import { v4 as uuidv4 } from 'uuid';

// const NotificationContext = createContext();

// const initialState = {
//   notifications: [],
// };

// const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
// const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

// const notificationReducer = (state, action) => {
//   switch (action.type) {
//     case ADD_NOTIFICATION:
//       return {
//         ...state,
//         notifications: [...state.notifications, action.payload],
//       };
//     case REMOVE_NOTIFICATION:
//       return {
//         ...state,
//         notifications: state.notifications.filter((notification) => notification.id !== action.payload),
//       };
//     default:
//       return state;
//   }
// };

// const NotificationsProvider = ({ children }) => {
//   const [state, dispatch] = useReducer(notificationReducer, initialState);

//   const addNotification = useCallback((title, subtitle) => {
//     const newNotification = {
//       id: uuidv4(),
//       title,
//       subtitle,
//     };
//     dispatch({ type: ADD_NOTIFICATION, payload: newNotification });
//   }, []);

//   const removeNotification = useCallback((id) => {
//     dispatch({ type: REMOVE_NOTIFICATION, payload: id });
//   }, []);

//   return (
//     <NotificationContext.Provider value={{ notifications: state.notifications, addNotification, removeNotification }}>
//       {children}
//     </NotificationContext.Provider>
//   );
// };

// const useNotification = () => {
//   const context = useContext(NotificationContext);
//   if (!context) {
//     throw new Error('useNotification must be used within a NotificationsProvider');
//   }
//   return context;
// };

// export { NotificationsProvider, useNotification };


import React, { createContext, useContext, useReducer, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

const NotificationContext = createContext();

const initialState = {
  notifications: [],
};

const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

const notificationReducer = (state, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter((notification) => notification.id !== action.payload),
      };
    default:
      return state;
  }
};

const NotificationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(notificationReducer, initialState);

  const addNotification = useCallback((notification) => {
    const newNotification = {
      id: uuidv4(),
      ...notification,
    };
    dispatch({ type: ADD_NOTIFICATION, payload: newNotification });
  }, []);

  const removeNotification = useCallback((id) => {
    dispatch({ type: REMOVE_NOTIFICATION, payload: id });
  }, []);

  return (
    <NotificationContext.Provider value={{ notifications: state.notifications, addNotification, removeNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};

const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationsProvider');
  }
  return context;
};

export { NotificationsProvider, useNotification };
