import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);
import moneySpend from "../../../../assets/moneySpendGreenIcon.svg";

const LineChart = ({monthlySpends}) => {
  const [chartData, setChartData] = useState(null);
  


  useEffect(() => {
    let dummyData;
  
    if (monthlySpends && Object.keys(monthlySpends).length > 0) {
      dummyData = [];
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
  
      months.forEach(month => {
        if (monthlySpends[month]) {
          dummyData.push(parseFloat(monthlySpends[month]) * 1000);
        } else {
          dummyData.push(0);
        }
      });
    }

    const data = {
      labels: [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ],
      datasets: [
        {
          data: dummyData,
          fill: true,
          borderColor: "#CC9866",
          backgroundColor: "rgba(14, 128, 128, 0.04)",
          tension: 0.7,
        },
      ],
    };
  
    setChartData(data);
  }, [monthlySpends]);
  

  const chartOptions = {
    scales: {
      yAxes: [
        {
          gridLines: {
            color: "black",
            borderDash: [2, 5],
          },
        },
      ],
      y: {
        beginAtZero: true,
        title: {
          display: true,
        },
        ticks: {
          stepSize: 20000,
          callback: function (value, index, values) {
            return value.toLocaleString();
          },
        },
        grid: {
          display: false,
          color: "rgba(0, 0, 0, 0.1)",
          borderColor: "#D7D7D9",
          borderWidth: 1,
          borderDash: [5, 5],
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "index",
        intersect: false,
      },
      legend: { display: false },
    },
    elements: {
      line: {
        borderWidth: 5,
      },
      point: {
        radius: 0,
      },
    },
    layout: {
      padding: {
        left: 1,
        right: 20,
        top: 20,
        bottom: 20,
      },
    },
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", padding: "10px" }}>
          <img src={moneySpend} />
          <span
            style={{
              display: "flex",
              marginLeft: "5px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Spend Overview
          </span>
        </div>
        <div>
          <p
            style={{
              display: "flex",
              margin: "10px",
              fontSize: "15px",
              color: "#7B7B7B",
              borderRadius: "4.01px",
              border: "0.668px solid #EBEBEB",
              background: "#FFF",
              width: "78px",
              alignSelf: "flex-end",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Monthly
          </p>
        </div>
      </div>
      {chartData && <Line data={chartData} options={chartOptions} />}
    </div>
  );
};

export default LineChart;
