import React, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import { Container, Row, Col, Image, Card, Form } from "react-bootstrap";
import { useNotification } from "../../../Notifications/Notifications";
import sendmsg from "../../../../assets/sendMessage.svg";
import search from "../../../../assets/search.svg";
import attachIcon from "../../../../assets/attachment.svg";
import eyeIcon from "../../../../assets/eyeIcon.svg";
import Overlay from "../../../Overlay/Overlay";
import PreviewPopup from "../../../FilePreviewPopup/PreviewPopup";
import CompanyAvatar from "../../../CompanyAvatar";
import { useSelector } from "react-redux";
import { SupplierCompanyData } from "../../../../DummyData";
import moneyBagIcon from "../../../../assets/moneyBagIcon.svg";

const socket = io("http://localhost:8000");
const Messages = () => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [messageInput, setMessageInput] = useState("");
  const [importedFile, setImportedFile] = useState(null);
  const [isImported, setIsImported] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [showPreviewPopup, setShowPreviewPopup] = useState(false);
  const currentTime = new Date();
  const formattedTime = currentTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  const [projectDetails, setProjectDetails] = useState({
    name: "",
    description: "",
    budget: 0,
    time: "",
  });

  const handleQuoteRequestedCompleted = () => {
    setQuoteRequsted(false);
    setProjectDetails({
      name: "",
      description: "",
      budget: 0,
      time: "",
    })
  };

  const handleCancel = () => {
    setQuoteRequsted(false);
    setProjectDetails({
      name: "",
      description: "",
      budget: 0,
      time: "",
    })
  }

  const [messages, setMessages] = useState([
    {
      username: "",
      type: "sent",
      message: "Hello there. This is Joe",
      date: "November 23 2023 02:00 PM ",
    },
    {
      username: "Sarah Jenkins",
      type: "received",
      message: "Hey. How are you?",
      date: "November 23 2023 02:00 PM ",
    },
    {
      username: "Joe",
      type: "sent",
      message: "Hello there. This is Joe",
      date: "December 25 2023 02:00 PM ",
    },
    {
      username: "Sarah Jenkins",
      type: "received",
      message: "Hey. How are you?",
      date: "December 26 2023 02:00 PM ",
    },
  ]);


  const divFlexRow = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };
  const divFlexColumn = {
    display: "flex",
    flexDirection: "column",
  };
  const [username] = useState("User");
  // const suppliers = useSelector(state => state.suppliers.suppliers);
  const suppliers = SupplierCompanyData;
  const fileInputRef = useRef(null);
  const [isQuoteRequested, setQuoteRequsted] = useState(false);
  const handleAttachImgClick = () => {
    fileInputRef.current.click();
  };

  const handleRequestQuote = () => {
    setQuoteRequsted(true);
  };
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setImportedFile(file);
    setIsImported(true);
  };
  const { addNotification } = useNotification();
  useEffect(() => {
    const messageHandler = (data) => {
      setMessages((prevMessages) => [...prevMessages, data]);
      if (data.replyTo) {
        addNotification({title:`${username} replied to your message`});
      } else {
        addNotification({title:`New message received from ${username}`});
      }
    };

    socket.on("message", messageHandler);

    return () => {
      socket.off("message", messageHandler);
    };
  }, [addNotification]);

  const sendMessage = () => {
    if (importedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const fileContent = e.target.result;
        console.log("Sending file:", importedFile);

        socket.emit("message", {
          username,
          file: {
            name: importedFile.name,
            type: importedFile.type,
            size: importedFile.size,
            content: fileContent,
          },
          date: new Date(),
        });
      };
      reader.readAsDataURL(importedFile);
    } else {
      socket.emit("message", {
        username,
        message: messageInput,
        date: new Date(),
      });
      console.log("Sending message:", messageInput);
    }
    setMessageInput("");
    setImportedFile(null);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      sendMessage();
    }
  };

  const handleBackgroundClick = () => {
    sendMessage();
  };
  const handlePreview = (file) => {
    setShowPreviewPopup(true);
    setPreviewFile(file);
  };
  const closePreview = () => {
    setShowPreviewPopup(false);
  };

  const suppliersList = suppliers.map((supplier, i) => {
    const isUserActive = supplier.status === "Active";
    const handleClick = (supplier) => {
      setSelectedUser(supplier);
    };

    return (
      <Col
        key={`${supplier} + ${i}`}
        className="users-list"
        onClick={() => handleClick(supplier)}
      >
        <Card.Body>
          <div className="integration-list-content">
            <div className="integration-list-info">
              <div style={{ position: "relative" }}>
              {supplier.supplierProfile.profilePicture ? (
                <Image
                src={supplier.supplierProfile.profilePicture}
                alt=""
                width="41px"
                height="41px"
                style={{ borderRadius: "9px", marginRight: "10px" }}
              />
          ) : (
            <div style={{ marginRight: "10px" }}>
              <CompanyAvatar companyName={supplier.supplierProfile.name} size="41px" />
            </div>
          )}

                {isUserActive && <span className="status-dot"></span>}
              </div>
              <div className="talent-overview">
                <Card.Subtitle className="messages-list-subtitle">
                  {supplier.supplierProfile.name}
                </Card.Subtitle>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <p style={{ color: "#7B7B7B", fontSize: "12px" }}>
                    {supplier.supplierProfile.primaryRole}
                  </p>
                </div>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <p style={{ color: "#7B7B7B", fontSize: "12px" }}>2h ago</p>
            </div>
          </div>
        </Card.Body>
        <div className="messages-container">
          <p
            style={{
              fontSize: "12px",
              marginLeft: "45px",
              color: "#7B7B7B",
              marginRight: "45px",
              textAlign: "left",
            }}
          >
            Thank you for taking the time to meet with me. Further arrange...
          </p>
          {supplier.unreadMessages > 0 && (
            <p className="unread-messages">{supplier.unreadMessages}</p>
          )}
        </div>
      </Col>
    );
  });

  const userDetails = selectedUser && (
    <Container fluid className="user-details-main-container">
      <Col>
        <Card.Body>
          <div
            className="user-details-header"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex" }}>
            {selectedUser.supplierProfile.profilePicture ? (
                <Image
                src={selectedUser.supplierProfile.profilePicture}
                alt=""
                width="41px"
                height="41px"
                style={{ borderRadius: "9px", marginRight: "10px" }}
              />
          ) : (
            <div style={{ marginRight: "10px" }}>
              <CompanyAvatar companyName={selectedUser.supplierProfile.name} size="41px" />
            </div>
          )}

              <div className="user-details-info">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Card.Subtitle className="supplier-profile-title">
                    {selectedUser.supplierProfile.name}
                  </Card.Subtitle>
                  {selectedUser.supplierProfile.status === "Active" && (
                    <Card.Subtitle className="supplier-profile-title-active">
                      {selectedUser.status}
                    </Card.Subtitle>
                  )}
                </div>

                <Card.Subtitle className="supplier-profile-subtitle">
                  {selectedUser.supplierProfile.primaryRole}
                </Card.Subtitle>
                <Card.Subtitle
                  className="supplier-profile-subtitle"
                  style={{ fontSize: "16px", marginTop: "8px" }}
                >
                  {selectedUser.supplierProfile.email}
                </Card.Subtitle>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="hire-btn"
                style={{ marginRight: "12px", background: "#0E8080" }}
                onClick={handleRequestQuote}
              >
               Request a quote
              </button>
              {/* <button className="hire-btn" style={{ background: "#CC9866" }}>
                Schedule Meeting
              </button> */}
            </div>
          </div>

          <div className="user-details-container">
            <Card.Body>
              {messages.map((msg, index) => {
                const isFileMessage =
                  typeof msg.message === "object" && msg.message.name;
                const dateObject = new Date(msg.date);

                const time = dateObject.toLocaleTimeString("en-US", {
                  hour: "numeric",
                  minute: "2-digit",
                  hour12: true,
                });

                const date = dateObject.toLocaleDateString("en-US", {
                  weekday: "short",
                  day: "2-digit",
                  month: "short",
                });

                const showDateDivider =
                  index === 0 ||
                  new Date(messages[index - 1].date).getDate() !==
                    dateObject.getDate();
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems:
                        msg.type === "sent" ? "flex-end" : "flex-start",
                      marginRight: "23px",
                      marginBottom: "58px",
                      marginTop: showDateDivider ? "30px" : "0",
                    }}
                  >
                    {showDateDivider && (
                      <p
                        style={{
                          textAlign: "center",
                          fontSize:"12px",
                          display: "flex",
                          width:"100px",
                          padding: "10px 16px",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "15px",
                          background: "#F0F0F0",
                          margin: "0 auto 10px auto",
                        }}
                      >
                        {date}
                      </p>
                    )}
                    <div
                      className={
                        msg.type === "sent"
                          ? "user-message"
                          : "receiver-message"
                      }
                    >
                      {msg.type !== "sent" && (
                        <p
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            fontSize: "12px",
                            color: "#1C1C1C",
                            marginBottom: "16px",
                          }}
                        >
                          {`${msg.username}`}
                        </p>
                      )}
                      <p
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: "18px",
                          textAlign: "left",
                        }}
                      >{`${isFileMessage ? msg.message.name : msg.message}`}</p>
                      {isFileMessage && (
                        <div className="file-icons">
                          <Image
                            src={eyeIcon}
                            alt="Review icon"
                            onClick={() => handlePreview(msg.message.content)}
                          />
                        </div>
                      )}
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          fontSize: "12px",
                        }}
                      >{`${time}`}</p>
                    </div>
                  </div>
                );
              })}
            </Card.Body>
          </div>

          <div className="send-message-container">
            <button
              type="submit"
              onClick={handleAttachImgClick}
              style={{
                backgroundImage: `url(${attachIcon})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "48px",
                width: "48px",
                border: "none",
                borderRadius: "59px 0 0 59px",
                position: "absolute",
                bottom: "0",
                left: "10px",
                marginBottom: "30px",
              }}
            />
            <textarea
              type="text"
              placeholder="Type message"
              className="chat-search-input"
              value={
                isImported && importedFile ? importedFile.name : messageInput
              }
              rows="2"
              onChange={(e) => setMessageInput(e.target.value)}
            />
            <input
              ref={fileInputRef}
              type="file"
              style={{ display: "none" }}
              onChange={handleFileInputChange}
            />
            <button
              type="submit"
              className="send-message-button"
              onClick={handleBackgroundClick}
              onKeyDown={handleKeyDown}
              style={{
                backgroundImage: `url(${sendmsg})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            />
          </div>
        </Card.Body>
      </Col>


      {isQuoteRequested && (
                  <>
                    <Overlay />
                    <div className="request-quote-container">
                      <Form>
                        <div style={divFlexRow}>
                          <Image
                            src={moneyBagIcon}
                            alt=""
                            style={{ marginRight: "9px" }}
                          />
                          <span
                            style={{
                              fontWeight: 600,
                              textAlign: "left",
                              fontSize: "20px",
                            }}
                          >
                            Request a quote
                          </span>
                        </div>
                        <Form.Group
                          as={Col}
                          controlId="amount"
                          className="single-input"
                          style={{ margin: "40px 0 20px 0" }}
                        >
                          <Form.Label className="input-title-edit-profile">
                            Project name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Project name"
                            className="custom-edit-profile-input"
                            value={projectDetails.name}
                            onChange={(e) =>
                              setProjectDetails({
                                ...projectDetails,
                                name: e.target.value,
                              })
                            }
                          />
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          controlId="amount"
                          className="single-input"
                          style={{ margin: "40px 0 20px 0" }}
                        >
                          <Form.Label className="input-title-edit-profile">
                          Give a detailed description of the project
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Description"
                            className="custom-edit-profile-input"
                            value={projectDetails.description}
                            onChange={(e) =>
                              setProjectDetails({
                                ...projectDetails,
                                description: e.target.value,
                              })
                            }
                          />
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          controlId="amount"
                          className="single-input"
                          style={{ margin: "40px 0 20px 0" }}
                        >
                          <Form.Label className="input-title-edit-profile">
                            Budget
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Project name"
                            className="custom-edit-profile-input"
                            value={projectDetails.budget}
                            onChange={(e) =>
                              setProjectDetails({
                                ...projectDetails,
                                budget: e.target.value,
                              })
                            }
                          />
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          controlId="amount"
                          className="single-input"
                          style={{ margin: "40px 0 20px 0" }}
                        >
                          <Form.Label className="input-title-edit-profile">
                            Timeline
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Weeks"
                            className="custom-edit-profile-input"
                            value={projectDetails.time}
                            onChange={(e) =>
                              setProjectDetails({
                                ...projectDetails,
                                time: e.target.value,
                              })
                            }
                          />
                        </Form.Group>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            gap: "20px",
                            position: "absolute",
                            bottom: "20px",
                            left: "50%",
                            transform: "translateX(-50%)",
                          }}
                        >
                          <button
                            variant="primary"
                            type="submit"
                            className="custom-edit-profile-button"
                            onClick={() => handleQuoteRequestedCompleted()}
                          >
                            Save
                          </button>
                          <button
                            onClick={() => handleCancel()}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              color: "#7E7E7E",
                              border: "none",
                              background: "none",
                              marginTop: "10px",
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </Form>
                    </div>
                  </>
                )}

      {showPreviewPopup && (
        <>
          <Overlay />
          <PreviewPopup file={previewFile} onClose={closePreview} />
        </>
      )}
    </Container>
  );

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Container className="messages-main-container">
        <div>
          <input
            type="text"
            placeholder="Search"
            className="search-user-input"
            aria-label="Search"
            style={{
              backgroundImage: `url(${search})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "left 10px center",
              color: "#A8A8A8",
              fontSize: "16px",
              margin: "20px 10px",
              width: "285px",
              height: "37px",
              fontWeight: "400",
            }}
          />
        </div>
        <div className="supplier-list-container">{suppliersList}</div>
      </Container>
      {userDetails}
    </div>
  );
};

export default Messages;
