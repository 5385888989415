
import React from "react";
import { Route, Routes } from "react-router-dom";
import BuyerOnboarding from "./components/SignUpComponent/BuyerOnboardingForm/BuyerOnboarding";
import SignUpComponent from "./components/SignUpComponent/SignUpComponent";
import SignInComponent from "./components/SignInComponent/SignInComponent";
import BuyerDashboard from "./components/Dashboard/BuyerDasboard/BuyerDashboard";
import SellerDashboard from "./components/Dashboard/SellerDashboard/SellerDashboard";
import FullUserProfileComponent from "./components/SellerProfile/FullUserProfileComponent";
import Profile from "./components/Dashboard/SellerDashboard/Profile";
import BuyerCollaborations from "./components/Dashboard/BuyerDasboard/Collaborations/BuyerCollaborations";
import SellerCollaborations from "./components/Dashboard/SellerDashboard/Collaborations/SellerCollaborations";
import Marketplace from "./components/Dashboard/BuyerDasboard/Marketplace/Marketplace";
import Integration from "./components/Dashboard/BuyerDasboard/Integrations/Integrations";
import Settings from "./components/Dashboard/BuyerDasboard/Settings/Settings";
import Management from "./components/Dashboard/BuyerDasboard/Management/Management";
import Overview from "./components/Dashboard/BuyerDasboard/Overview/Overview";
import SellerOnboarding from "./components/SignUpComponent/SellerOnboardingForm/SellerOnboarding";
import SellerProfile from "./components/SignUpComponent/SellerOnboardingForm/SellerPersonalDetails/SellerProfile";
import CreateNewSupplierForm from "./components/Dashboard/BuyerDasboard/Management/CreateNewSupplierForm";
import JobsAndOffers from "./components/Dashboard/SellerDashboard/JobsAndOffers";
import ProductAndServices from "./components/Dashboard/SellerDashboard/ProductAndServices";
import Orders from "./components/Dashboard/SellerDashboard/Orders";
import MappingTable from "./components/Dashboard/BuyerDasboard/Management/MappingTable";
import LandingPage from "./components/LandingPage/LandingPage";
import TermsOfUse from "./Pages/TermsOfUse";
import Policy from "./Pages/Policy";
import CCPAPolicy from "./Pages/CCPAPolicy";
import SuppliersPricing from "./Pages/Pricing/Enterprises";
import EnterprisesPricing from "./Pages/Pricing/Suppliers";
import Pricing from "./Pages/Pricing/Pricing";
import Company from "./Pages/Company";

const BuyerDashboardRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<BuyerDashboard />}>
      <Route path="overview" element={<Overview />} />
        <Route path="marketplace" element={<Marketplace />} />
        <Route path="integrations" element={<Integration />} />
        <Route path="collaborations" element={<BuyerCollaborations />} />
        <Route path="integrations" element={<Integration />} />
        <Route path="settings" element={<Settings />} />
        <Route path="management/*" element={<Management />} />
        <Route path="marketplace/user-profile" element={<FullUserProfileComponent />} />
        <Route path="management/create-new-supplier" element={<CreateNewSupplierForm />} />
        <Route path="management/create-new-supplier/mapping-table" element={<MappingTable />} />

      </Route>
    </Routes>
  );
};

const SellerDashboardRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<SellerDashboard />}>
      <Route path="user-profile" element={<Profile />} />
        {/* <Route path="jobs-and-offers" element={<JobsAndOffers />} /> */}
        <Route path="product-and-services" element={<ProductAndServices />} />
        {/* <Route path="orders" element={<Orders />} /> */}
        <Route path="collaborations" element={<SellerCollaborations />} />
        <Route path="settings" element={<Settings />} />
      </Route>
    </Routes>
  );
};

function RoutesConfig() {

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} /> 
      <Route path="/terms-of-use" element={<TermsOfUse />} />
      <Route path="/suppliers-pricing" element={<SuppliersPricing />} />
      <Route path="/enterprises-pricing" element={<EnterprisesPricing />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/about" element={<Company />} />
      <Route path="/privacy-policy" element={<Policy />} />
      <Route path="/ccpa-policy" element={<CCPAPolicy />} />
      <Route path="/sign-up" element={<SignUpComponent />} />
      <Route path="/sign-in" element={<SignInComponent />} />
      <Route path="/buyer-onboarding" element={<BuyerOnboarding />} />
      <Route path="/seller-onboarding" element={<SellerOnboarding />} />
      <Route path="/seller-onboarding/personal-profile" element={<SellerProfile/>} />
      <Route path="/buyer-dashboard/*" element={<BuyerDashboardRoutes />} />
      <Route path="/seller-dashboard/*" element={<SellerDashboardRoutes />} />
    </Routes>
  );
}

export default RoutesConfig;
