import React, { useState, useRef, useEffect } from "react";
import { Image, Card, Form, Col, Badge } from "react-bootstrap";
import "../../SellerProfile/SellerProfile.css";
import "../../Dashboard/Dashboard.css";
import CompanyAvatar from "../../CompanyAvatar";
import penIcon from "../../../assets/penIcon.svg";
import Compressor from 'compressorjs';


const EditProfile = ({
  formDataStep,
  setFormDataStep,
  supplier,
  industryOptions,
  handleSaveProfileInfo,
}) => {
  const [profilePicture, setProfilePicture] = useState(
    supplier.supplierProfile.profilePicture
  );

  const [newTechnicalSkill, setNewTechnicalSkill] = useState("");
  const [newIndustrySkill, setNewIndustrySkill] = useState("");
  const [newCertificate, setNewCertificate] = useState("");
  const [newCompanySkill, setNewCompanySkill] = useState("");
  const [isIndustrySkillsDropdownOpen, setIndustrySkillsDropdownOpen] =
    useState(false);
  const industrySkillsDropdownRef = useRef();

  const handleToggleIndustryDropdown = () => {
    setIndustrySkillsDropdownOpen(!isIndustrySkillsDropdownOpen);
  };

  console.log('FORM', formDataStep)
  const handleSelectIndustryOption = (value) => {
    setFormDataStep({
      ...formDataStep,
      supplierProfile: {
        ...formDataStep.supplierProfile,
        skills: {
          ...formDataStep.supplierProfile.skills,
          industry: [...formDataStep.supplierProfile.skills.industry, value],
        },
      },
    });
    setIndustrySkillsDropdownOpen(false);
  };
  const handleAddSkill = () => {
    if (newTechnicalSkill.trim() !== "") {
      setFormDataStep({
        ...formDataStep,
        supplierProfile: {
          ...formDataStep.supplierProfile,
          skills: {
            ...formDataStep.supplierProfile.skills,
            technical: [
              ...formDataStep.supplierProfile.skills.technical,
              newTechnicalSkill.trim(),
            ],
          },
        },
      });
      setNewTechnicalSkill("");
    }
    if (newIndustrySkill.trim() !== "") {
      setFormDataStep({
        ...formDataStep,
        supplierProfile: {
          ...formDataStep.supplierProfile,
          skills: {
            ...formDataStep.supplierProfile.skills,
            industry: [
              ...formDataStep.supplierProfile.skills.industry,
              newIndustrySkill.trim(),
            ],
          },
        },
      });
      setNewIndustrySkill("");
    }
    if (newCompanySkill.trim() !== "") {
      setFormDataStep({
        ...formDataStep,
        supplierProfile: {
          ...formDataStep.supplierProfile,
          skills: {
            ...formDataStep.supplierProfile.skills,
            company: [
              ...formDataStep.supplierProfile.skills.company,
              newCompanySkill.trim(),
            ],
          },
        },
      });
      setNewCompanySkill("");
    }
    if (newCertificate.trim() !== "") {
      setFormDataStep({
        ...formDataStep,
        supplierProfile: {
          ...formDataStep.supplierProfile,
          certificates: [
            ...formDataStep.supplierProfile.certificates,
            newCertificate.trim(),
          ],
        },
      });
      setNewCertificate("");
    }
  };

  const handleRemoveSkill = (skillType, indexToRemove) => {
    setFormDataStep((prevFormDataStep) => ({
      ...prevFormDataStep,
      supplierProfile: {
        ...prevFormDataStep.supplierProfile,
        skills: {
          ...prevFormDataStep.supplierProfile.skills,
          [skillType]: prevFormDataStep.supplierProfile.skills[
            skillType
          ].filter((_, index) => index !== indexToRemove),
        },
      },
    }));
  };

  const handleRemoveCertificate = (indexToRemove) => {
    setFormDataStep((prevFormDataStep) => ({
      ...prevFormDataStep,
      supplierProfile: {
        ...prevFormDataStep.supplierProfile,
        certificates: prevFormDataStep.supplierProfile.certificates.filter(
          (_, index) => index !== indexToRemove
        ),
      },
    }));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      handleAddSkill();
    }
  };


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log(file)
      new Compressor(file, {
        quality: 0.1, // Adjust quality as necessary
        success(result) {
          console.log(result, 'result')
          const reader = new FileReader();
          reader.onload = () => {
            setProfilePicture(reader.result);
            setFormDataStep((prevFormDataStep) => ({
              ...prevFormDataStep,
              supplierProfile: {
                ...prevFormDataStep.supplierProfile,
                profilePicture: reader.result,
              },
            }));
          };
          reader.onerror = () => {
            console.error("Failed to read file");
          };
          reader.readAsDataURL(result);
        },
        error(err) {
          console.error("Error compressing file:", err);
        },
      });
    }
  };



  const handleAvatarClick = () => {
    document.getElementById("file-input").click();
  };

  return (
    <>
      <div className="edit-supplier-info-container">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            {profilePicture ? (
              <img
                src={profilePicture}
                alt=""
                width="106px"
                height="106px"
                style={{
                  borderRadius: "9px",
                  marginRight: "10px",
                  position: "relative",
                }}
                onClick={() => document.getElementById("file-input").click()}
              />
            ) : (
              <div
              style={{
                width: "106px",
                height: "106px",
                marginRight: "10px",
                borderRadius: "9px",
                backgroundColor: "#D1D1D1",
              }}
            />
            )}
            <input
              id="file-input"
              type="file"
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleImageChange}
            />
            <img
              src={penIcon}
              alt="Edit"
              style={{
                position: "absolute",
                bottom: "-5px",
                right: "0",
                cursor: "pointer",
              }}
              onClick={() => document.getElementById("file-input").click()}
            />
          </div>

          <button
            style={{
              height: "36px",
              display: "flex",
              alignSelf: "flex-end",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              width: "85px",
              border: "none",
              background: "#0E8080",
              color: "#fff",
            }}
            onClick={() => handleSaveProfileInfo()}
          >
            Save Edit
          </button>
        </div>

        <Form.Group as={Col} className="single-input-edit">
          <Form.Label className="edit-profile-input-title">
            Company name
          </Form.Label>
          <Form.Control
            type="text"
            placeholder=""
            required
            className="custom-edit-profile-input"
            value={formDataStep.company.name || ""}
            onChange={(e) => {
              setFormDataStep({
                ...formDataStep,
                company: {
                  ...formDataStep.company,
                  name: e.target.value,
                },
              });
            }}
          />
        </Form.Group>

        <Form.Group as={Col} style={{ width: "100%" }}>
          <Form.Label
            className="edit-profile-input-title"
            style={{ marginBottom: "7px" }}
          >
            Your bio
          </Form.Label>
          <Form.Control
            as="textarea"
            style={{
              height: "196px",
              // background: "#f9f9f9",
              padding: "20px",
              resize: "none",
            }}
            required
            className="custom-edit-profile-input"
            value={formDataStep.supplierProfile.bio || ""}
            onChange={(e) => {
              setFormDataStep({
                ...formDataStep,
                supplierProfile: {
                  ...formDataStep.supplierProfile,
                  bio: e.target.value,
                },
              });
            }}
          />
        </Form.Group>

        <span className="skills-span">Skills</span>
        <div className="skills-container">
          <Form.Group
            as={Col}
            style={{ width: "100%", marginBlock: "48px" }}
            className="single-input-edit"
          >
            <Form.Label
              className="edit-profile-input-title"
              style={{ marginBottom: "7px" }}
            >
              Technical Skills
            </Form.Label>
            <div style={{ marginBottom: "20px", marginTop: "10px" }}>
              {formDataStep.supplierProfile.skills.technical.length > 0 &&
                formDataStep.supplierProfile.skills.technical.map(
                  (skill, index) => (
                    skill !== "" && (
                    <Badge
                      key={index}
                      variant="primary"
                      style={{
                        marginRight: "10px",
                        background: "red",
                        height: "35.74px",
                        padding: "12.838px 9.489px",
                        borderRadius: "46.009px",
                        background: "#F5F5F5",
                        color: "#545454",
                        fontSize: "11px",
                      }}
                    >
                      {skill}{" "}
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemoveSkill("technical", index)}
                      >
                        x
                      </span>
                    </Badge>
                  )
                  )
                )}
            </div>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <Form.Control
                type="text"
                placeholder="Technical skills"
                className="custom-edit-profile-input"
                value={newTechnicalSkill}
                onChange={(e) => setNewTechnicalSkill(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            </div>
          </Form.Group>

          <Form.Group
            as={Col}
            style={{ width: "100%", marginBlock: "48px" }}
            className="single-input-edit"
          >
            <Form.Label
              className="edit-profile-input-title"
              style={{ marginBottom: "7px" }}
            >
              Industry Skills
            </Form.Label>
            <div style={{ marginBottom: "20px", marginTop: "10px" }}>
              {formDataStep.supplierProfile.skills.industry.length > 0 &&
                formDataStep.supplierProfile.skills.industry.map(
                  (skill, index) => (
                    skill !== "" && (
                    <Badge
                      key={index}
                      variant="primary"
                      style={{
                        marginRight: "10px",
                        background: "red",
                        height: "35.74px",
                        padding: "12.838px 9.489px",
                        borderRadius: "46.009px",
                        background: "#F5F5F5",
                        color: "#545454",
                        fontSize: "11px",
                      }}
                    >
                      {skill}{" "}
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemoveSkill("industry", index)}
                      >
                        x
                      </span>
                    </Badge>
                  )
                  )
                )}
            </div>
            <div
              ref={industrySkillsDropdownRef}
              className={`custom-edit-profile-input custom-dropdown-container ${
                isIndustrySkillsDropdownOpen ? "open" : ""
              }`}
              style={{
                background: "#f9f9f9",
                border: "border: 2px solid #EBEBEB",
              }}
            >
              <div
                className="custom-dropdown-edit-profile"
                onClick={handleToggleIndustryDropdown}
              >
                <span className="selected-option">
                  {newIndustrySkill || "Industry Skills"}
                  {/* {formDataStep.skills.industry || ""} */}
                </span>
                <ul
                  className={`custom-dropdown-industry-list ${
                    isIndustrySkillsDropdownOpen ? "show" : ""
                  }`}
                >
                  {industryOptions.map((option, index) => (
                    <li
                      key={option}
                      className={`custom-dropdown-item ${
                        index === 0 ? "disabled" : ""
                      }`}
                      onMouseDown={(e) => {
                        e.preventDefault();
                        setNewIndustrySkill(option);
                        handleSelectIndustryOption(option);
                      }}
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Form.Group>

          <Form.Group
            as={Col}
            style={{ width: "100%", marginBlock: "48px" }}
            className="single-input-edit"
          >
            <Form.Label
              className="edit-profile-input-title"
              style={{ marginBottom: "7px" }}
            >
              Certificates
            </Form.Label>
            <div style={{ marginBottom: "20px", marginTop: "10px" }}>
              {formDataStep.supplierProfile.certificates.length > 0 &&
                formDataStep.supplierProfile.certificates.map(
                  (skill, index) => (
                    skill !== "" && (
                    <Badge
                      key={index}
                      variant="primary"
                      style={{
                        marginRight: "10px",
                        background: "red",
                        height: "35.74px",
                        padding: "12.838px 9.489px",
                        borderRadius: "46.009px",
                        background: "#F5F5F5",
                        color: "#545454",
                        fontSize: "11px",
                      }}
                    >
                      {skill}{" "}
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemoveCertificate(index)}
                      >
                        x
                      </span>
                    </Badge>
                  )
                  )
                )}
            </div>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <Form.Control
                type="text"
                placeholder="Other Certificates"
                className="custom-edit-profile-input"
                value={newCertificate}
                onChange={(e) => setNewCertificate(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            </div>
          </Form.Group>

          <Form.Group
            as={Col}
            style={{ width: "100%", marginBlock: "48px" }}
            className="single-input-edit"
          >
            <Form.Label
              className="edit-profile-input-title"
              style={{ marginBottom: "7px" }}
            >
              Company Skills
            </Form.Label>
            <div style={{ marginBottom: "20px", marginTop: "10px" }}>
              {formDataStep.supplierProfile.skills.company.length > 0 &&
                formDataStep.supplierProfile.skills.company.map(
                  (skill, index) => (
                    skill !== "" && (
                    <Badge
                      key={index}
                      variant="primary"
                      style={{
                        marginRight: "10px",
                        background: "red",
                        height: "35.74px",
                        padding: "12.838px 9.489px",
                        borderRadius: "46.009px",
                        background: "#F5F5F5",
                        color: "#545454",
                        fontSize: "11px",
                      }}
                    >
                      {skill}{" "}
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRemoveSkill("company", index)}
                      >
                        x
                      </span>
                    </Badge>
                  )
                  )
                )}
            </div>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <Form.Control
                type="text"
                placeholder="Company skills"
                className="custom-edit-profile-input"
                value={newCompanySkill}
                onChange={(e) => setNewCompanySkill(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            </div>
          </Form.Group>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
