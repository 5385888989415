import React from 'react';
import PropTypes from 'prop-types';
import './Overlay.css'; 

const Overlay = ({ onClose, overlayStyle }) => {
  const handleClick = (e) => {
    if (e.target.classList.contains('overlay')) {
      onClose();
    }
  };

  return <div className="overlay" onClick={handleClick} style={overlayStyle}></div>;
};

Overlay.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Overlay;