import Papa from "papaparse";


const scanAndAutoFill = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const fileData = e.target.result;
        Papa.parse(fileData, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            const records = result.data;
          let columnNamesRow = -1;
          for (let i = 0; i < records.length; i++) {
            const row = records[i];
            let foundColumnName = false;
            for (const key in row) {
              if (row.hasOwnProperty(key)) {
                const columnName = row[key];
                if (columnName.replace(/\s+/g, "").toLowerCase() === "companyname") {
                  foundColumnName = true;
                  break;
                }
              }
            }
            if (foundColumnName) {
              columnNamesRow = i;
              break;
            }
          }
          
          if (columnNamesRow !== -1) {
            const firstDataRow = records[columnNamesRow];
            const keyToColumnNameMap = {};
            for (const key in firstDataRow) {
              if (firstDataRow.hasOwnProperty(key)) {
                const columnValue = firstDataRow[key].replace(/\s+/g, "").toLowerCase()
                keyToColumnNameMap[key] = columnValue;
              }
            }
        
            const suppliers = records
              .slice(columnNamesRow + 1)
              .map((record) => {
                const supplier = {};
                for (const key in record) {
                  if (record.hasOwnProperty(key) && keyToColumnNameMap.hasOwnProperty(key)) {
                    supplier[keyToColumnNameMap[key]] = record[key].trim();
                  }
                }
                return supplier;
              });

            resolve(suppliers);
          } else {
  
            resolve([]);
          }
          

          },
        });
      } catch (error) {
        reject(error);
      }
    };

    reader.readAsText(file);
  });
};

export default scanAndAutoFill;
