import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useNavigate } from "react-router";
import notificationsImg from "../../assets/notifications.svg";
import userpicture from "../../assets/userpicture.png";
import arrowdropdown from "../../assets/arrowdropdown.svg";
import arrowBack from "../../assets/arrowBack.svg";
import logoutIcon from "../../assets/logoutIcon.svg";
import "./Dashboard.css";
import { useNotification } from "../Notifications/Notifications";
import notificationIcon from "../../assets/notificationIcon.svg";

const DashboardNavbar = ({
  title,
  subtitle,
  handleBack,
  tabs,
  selectedTab,
  handleTabClick,
  arrow,
  comingSoon,
}) => {
  const [showNotification, setShowNotification] = useState(false);
  const [newNotification, setNewNotification] = useState(false);
  const [showLogOut, setShowLogOut] = useState(false);
  const { notifications, removeNotification } = useNotification();

  const navigate = useNavigate();

  const [isLogOut, setLogOut] = useState(false);

  const notificationRef = useRef();
  useEffect(() => {
    if (!showNotification && notifications.length > 0) {
      setNewNotification(true);
    }
  }, [showNotification, notifications]);

  const handleNotificationClick = () => {
    setShowNotification(true);
    setNewNotification(false);
  };

  const handleDeleteNotifications = (id) => {
    removeNotification(id);
  };
  const handleShowLogout = () => {
    setShowLogOut(!showLogOut);
  };
  const handleLogOut = () => {
    setLogOut(true);
    navigate("/sign-in");
  };
  const handleClickOutsideNotification = (event) => {
    if (
      notificationRef.current &&
      !notificationRef.current.contains(event.target)
    ) {
      setShowNotification(false);
    }
  };
  const divFlexRow = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };
  const divFlexColumn = {
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
  };

  const tabStyle = {
    padding: "10px",
    cursor: "pointer",
  };

  const activeTabStyle = {
    color: "#fff",
    borderBottom: "4px solid #fff",
  };

  const inactiveTabStyle = {
    color: "#fff",
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideNotification);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideNotification);
    };
  }, []);

  return (
    <Container>
      <>
        <Row>
          <Col>
            <div className="dashboard-nav-container-main">
              <div style={{ marginTop: "27px", marginLeft: "17px" }}>
                <Col>
                  <div style={divFlexRow} onClick={handleBack}>
                    {arrow !== false ? (
                      <>
                        <Image
                          src={arrowBack}
                          alt=""
                          height="35px"
                          width="24px"
                        />
                        <span
                          className="collaborations-container-label"
                          style={{
                            display: "flex",
                            textDecoration: "none",
                            marginLeft: "8px",
                          }}
                        >
                          {title}
                        </span>
                      </>
                    ) : (
                      <span
                        className="collaborations-container-label"
                        style={{
                          display: "flex",
                          textDecoration: "none",
                          marginLeft: "0",
                        }}
                      >
                        {title}
                      </span>
                    )}
                  </div>
                  <p className="dashboard-nav-container-subtitle">
                    {subtitle || ""}
                  </p>

                  <div style={divFlexRow}>
                    {tabs &&
                      tabs.map((tab) =>
                        tab === "Meetings" || tab === "Billing" ? (
                          <div
                            key={tab}
                            className="tab"
                            style={{
                              ...tabStyle,
                              ...(selectedTab === tab
                                ? inactiveTabStyle
                                : inactiveTabStyle),
                            }}
                            // onClick={() => handleTabClick(tab)}
                          >
                            {comingSoon ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap:"10px"
                                }}
                              >
                                {tab}
                                <span
                                  style={{
                                    borderRadius: "4px",
                                    background: "rgba(255, 255, 255, 0.30)",
                                    padding: " 0 5px",
                                  }}
                                >
                                  coming soon
                                </span>
                              </div>
                            ) : (
                              tab
                            )}
                          </div>
                        ) : (
                          <div
                            key={tab}
                            className="tab"
                            style={{
                              ...tabStyle,
                              ...(selectedTab === tab
                                ? activeTabStyle
                                : inactiveTabStyle),
                            }}
                            onClick={() => handleTabClick(tab)}
                          >
                            {tab}
                          </div>
                        )
                      )}
                  </div>
                </Col>
              </div>
              <div style={divFlexColumn}>
                <div style={{ marginTop: "27px", marginRight: "32px" }}>
                  <Col style={divFlexRow}>
                    <div style={{ position: "relative" }}>
                      <Image
                        src={notificationsImg}
                        alt="notifications"
                        style={{ marginRight: "31px", cursor: "pointer" }}
                        onClick={handleNotificationClick}
                      />
                      {showNotification && (
                        <div
                          className="custom-notification-container"
                          ref={notificationRef}
                        >
                          {notifications.map((notification) => (
                            <div
                              key={notification.id}
                              className={`notification ${notification.type}`}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                padding: "20px 0",
                              }}
                            >
                              <div style={divFlexRow}>
                                <Image src={notificationIcon} alt="" />
                                <div style={divFlexColumn}>
                                  <p
                                    style={{
                                      textAlign: "left",
                                      fontSize: "11px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {notification.title}
                                  </p>
                                  <p
                                    style={{
                                      textAlign: "left",
                                      fontSize: "11px",
                                      fontWeight: "400",
                                      color: "#7B7B7B",
                                    }}
                                  >
                                    {notification.subtitle}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <button
                                  className="close-button"
                                  onClick={() =>
                                    handleDeleteNotifications(notification.id)
                                  }
                                >
                                  <span>&times;</span>
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                      {newNotification && !showNotification && (
                        <div className="red-dot"></div>
                      )}
                    </div>
                    <div
                      style={{
                        width: "32px",
                        height: "32px",
                        borderRadius: "32px",
                        border: "4px solid #FFF",
                        background: "#D9D9D9",
                        marginRight: "16px",
                      }}
                    ></div>

                    <Image
                      onClick={() => handleShowLogout()}
                      src={arrowdropdown}
                      alt="arrowdropdown"
                      height="32px"
                      width="32px"
                      style={{ marginLeft: "8px" }}
                    />
                  </Col>
                </div>

                {showLogOut && (
                  <button
                    onClick={() => handleLogOut()}
                    className="logout-btn"
                    style={{
                      backgroundImage: `url(${logoutIcon})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "left 10px center",
                      position: "absolute",
                      top: "72px",
                      right: "32px",
                    }}
                  >
                    Logout
                  </button>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </>
    </Container>
  );
};

export default DashboardNavbar;
