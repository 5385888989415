import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Container, Row, Col } from "react-bootstrap";
import UserProfileComponent from "../../../SellerProfile/UserProfileComponent";
import Overlay from "../../../Overlay/Overlay";
import CompanyAvatar from "../../../CompanyAvatar";
import { useSelector } from "react-redux";
import { SupplierCompanyData } from "../../../../DummyData";

const EngagementTable = () => {
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [isDotsMenuOpen, setDotsMenuOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Top engaged");
  const tabs = ["Top engaged", "Active"];
  const navigate = useNavigate();

  const handleTabClick = (tab) => {
    if (tab === "Top engaged") {
    }
    setSelectedTab(tab);
  };
  // const suppliers = useSelector(state => state.suppliers.suppliers);
  const suppliers = SupplierCompanyData;

  const [suppliersData, setSuppliersData] = useState(suppliers.slice(0, 6));
  const handleChange = (event) => {
    setMessage(event.target.value);
  };
  const extractTextBetweenParentheses = (text) => {
    const startIndex = text.indexOf("(") + 1;
    const endIndex = text.indexOf(")");

    const lastSpaceIndex = text.lastIndexOf(" ", endIndex);
    return text.substring(startIndex, lastSpaceIndex);
  };
  const handleSeeAllClick = () => {
    navigate("/buyer-dashboard/management");
  };

  const handleCloseUserProfile = () => {
    setSelectedCard(false);
    setIsUserProfileOpen(false);
    setIsClosed(true);
  };
  const handleCardSelection = (supplier) => {
    if (isClosed) {
      setSelectedCard(false);
      setIsUserProfileOpen(false);
      setIsClosed(false);
      return;
    }
    setSelectedCard(true);
    setSelectedSupplier(supplier);
    setIsUserProfileOpen(true);
  };
  const handleSelectUserProfile = (supplier) => {
    handleCardSelection(supplier);
  };

  const toggleMenu = (supplierId) => {
    setDotsMenuOpen(!isDotsMenuOpen);

    setMenuVisibility((prevState) => ({
      ...prevState,
      [supplierId]: !prevState[supplierId],
    }));
  };

  const overlayStyle = {
    background: "rgba(0, 0, 0, 0.1)",
  };

  const divFlexRow = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };
  const divFlexColumn = {
    display: "flex",
    flexDirection: "column",
  };
  const tabStyle = {
    padding: "10px",
    cursor: "pointer",
  };

  const activeTabStyle = {
    color: "#7B7B7B",
    borderBottom: "4px solid #0E8080",
  };

  const inactiveTabStyle = {
    color: "#7B7B7B",
  };
  return (
    <Container
      fluid
      style={{
        margin: "10px 20px 0 10px",
        background: "#fff",
        borderRadius: "13px",
        position: "relative",
        borderRadius: "13.558px",
        border: "1.043px solid #EBEBEB",
      }}
    >
      <div style={divFlexColumn}>
        <Row>
          <Col>
            <div style={{ ...divFlexRow, padding: "23px", gap: "45px" }}>
              <span
                style={{ color: "#1D2739", fontWeight: 600, fontSize: "16px" }}
              >
                Top Engaged Suppliers
              </span>
              <span
                onClick={() => handleSeeAllClick()}
                style={{ fontWeight: 700, fontSize: "16px" }}
              >
                {" "}
                See all
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ ...divFlexRow, paddingLeft: "23px" }}>
              {tabs &&
                tabs.map((tab) => (
                  <div
                    key={tab}
                    className="tab"
                    style={{
                      ...tabStyle,
                      ...(selectedTab === tab
                        ? activeTabStyle
                        : inactiveTabStyle),
                    }}
                    onClick={() => handleTabClick(tab)}
                  >
                    {tab}
                  </div>
                ))}
            </div>
          </Col>
        </Row>
      </div>

      <div className="suppliers-table" style={{height:"570px"}}>
        <div className="table-header">
          <div className="column">Company Name</div>
          <div className="column">Description</div>
          <div className="column">Employees</div>
          <div className="column">Registration Status</div>
          <div className="column">Year founded</div>
          <div className="column">Diversity Certification</div>
          <div className="column">Diversity Status</div>
        </div>
        <div className="table-body">
          {suppliersData.map((company, index) => (
            <div
              key={company.id}
              className="table-row"
              style={{ borderBottom: "1px solid #F6F6F6" }}
            >
              <>
                <div
                  className="column"
                  onClick={(e) => {
                      handleSelectUserProfile(company);
                  }}
                  style={{ padding: "20px 5px 8px 20px" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                  {company.company.companyProfilePicture ? (
                    <img
                    src={company.company.companyProfilePicture}
                    alt={company.company.name}
                    style={{
                      width: "38px",
                      height: "38px",
                      marginRight: "10px",
                    }}
                  />
                  ) : (
                    <div style={{ marginRight: "10px"}}>
                      <CompanyAvatar
                        companyName={company.company.name}
                        size="38px"
                      />
                    </div>
                  )}

                    <div>
                      <div style={{ fontWeight: 600 }}>{company.company.name}</div>
                      <div>
                        <p style={{ textDecoration: "none", color: "#7B7B7B" }}>
                          {company.company.companyWebsite}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">{company.company.industry}</div>
                <div className="column">{ extractTextBetweenParentheses(company.company.size)}</div>
                <div className="column">{company.company.registrationStatus}</div>
                <div className="column">{company.company.yearFounded}</div>
                <div className="column">{company.supplierProfile.certificates[0]}</div>
                <div className="column">{company.supplierProfile.diversity.diversityStatus}</div>
              </>
            </div>
          ))}
        </div>

        {isUserProfileOpen && <Overlay />}
        {selectedCard && (
          <div
            className="additional-bar"
            style={{
              width: "500px",
              position: "fixed",
              right: 0,
              top: 0,
              height: "100vh",
              background: "white",
              zIndex: 999,
            }}
          >
            <UserProfileComponent
              supplier={selectedSupplier}
              onClose={handleCloseUserProfile}
              backUrl="/buyer-dashboard/overview"
            />
          </div>
        )}
      </div>
    </Container>
  );
};

export default EngagementTable;
