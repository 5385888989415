import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import DashboardNavbar from "../DashboardNavbar";
import { useSelector, useDispatch } from "react-redux";
import plusIcon from "../../../assets/plusIcon.svg";
import Overlay from "../../Overlay/Overlay";
import DragAndDropComponent from "../../DragAndDrop/DragAndDropComponent";
import pictureSymbol from "../../../assets/pictureSymbol.svg";
import {
  updateSupplierByIdAsync,
} from "../../../actions/suppliersActions";
import Compressor from 'compressorjs';

const Services = () => {
  const currentSupplierId = useSelector((state) => state.suppliers.currentSupplierID);
  const supplier = useSelector((state) => state.suppliers.currentSupplier);
  // const supplier = useSelector((state) => state.suppliers.suppliers.find(s => s._id === currentSupplierId));
  // const supplier = useSelector((state) => state.suppliers.suppliers[0]);
  const [addNewService, setAddNewService] = useState(false);
  // const services = supplier.supplierProfile.services;
  const [pdfFile, setPdfFile] = useState(null);
  const [importedFiles, setImportedFiles] = useState([]);
  const dispatch = useDispatch();
  const [updated, setUpdated] = useState(false);

  console.log(supplier, 'supplierFromService')
  const [newServiceFormData, setNewServiceFormData] = useState({
    serviceImage: null,
    title: "",
    description: "",
    tag: "",
  });

  const [formDataStep, setFormDataStep] = useState({
    firstName: '',
    lastName: '',
    email: '',
    address: {
      country: '',
      state: '',
      city: '',
    },
    company: {
      name: '',
      size: '',
      industry: '',
      companyProfilePicture: '',
      companyWebsite: '',
      annualRevenue: '',
      registrationStatus: '',
      yearFounded: '',
    },
    supplierProfile: {
      name: '',
      profilePicture: '',
      email: '',
      primaryRole: '',
      rating: '',
      reviews: [],
      bio: '',
      yearFounded: '',
      linkedInUrl: '',
      diversity: {
        gender: '',
        pronouns: '',
        race: '',
        diversityStatus: '',
      },
      certificates: [],
      businessLicenses: [],
      services: [],
      skills: {
        technical: [],
        industry: [],
        company: [],
      },
      tier: '',
      mainContacts: [],
      otherContacts: [],
    },
  });



  useEffect(() => {
    if (supplier) {
      setFormDataStep({
        firstName: supplier.firstName || '',
        lastName: supplier.lastName || '',
        email: supplier.email || '',
        address: {
          country: supplier.address?.country || '',
          state: supplier.address?.state || '',
          city: supplier.address?.city || '',
        },
        company: {
          name: supplier.company?.name || '',
          size: supplier.company?.size || '',
          industry: supplier.company?.industry || '',
          companyProfilePicture: supplier.company?.companyProfilePicture || '',
          companyWebsite: supplier.company?.companyWebsite || '',
          annualRevenue: supplier.company?.annualRevenue || '',
          registrationStatus: supplier.company?.registrationStatus || '',
          yearFounded: supplier.company?.yearFounded || '',
        },
        supplierProfile: {
          name: supplier.supplierProfile?.name || '',
          profilePicture: supplier.supplierProfile?.profilePicture || '',
          email: supplier.supplierProfile?.email || '',
          primaryRole: supplier.supplierProfile?.primaryRole || '',
          rating: supplier.supplierProfile?.rating || '',
          reviews: supplier.supplierProfile?.reviews || [],
          bio: supplier.supplierProfile?.bio || '',
          yearFounded: supplier.supplierProfile?.yearFounded || '',
          linkedInUrl: supplier.supplierProfile?.linkedInUrl || '',
          diversity: {
            gender: supplier.supplierProfile?.diversity?.gender || '',
            pronouns: supplier.supplierProfile?.diversity?.pronouns || '',
            race: supplier.supplierProfile?.diversity?.race || '',
            diversityStatus: supplier.supplierProfile?.diversity?.diversityStatus || '',
          },
          certificates: supplier.supplierProfile?.certificates || [],
          businessLicenses: supplier.supplierProfile?.businessLicenses || [],
          services: supplier.supplierProfile?.services || [],
          skills: {
            technical: supplier.supplierProfile?.skills?.technical || [],
            industry: supplier.supplierProfile?.skills?.industry || [],
            company: supplier.supplierProfile?.skills?.company || [],
          },
          tier: supplier.supplierProfile?.tier || '',
          mainContacts: supplier.supplierProfile?.mainContacts || [],
          otherContacts: supplier.supplierProfile?.otherContacts || [],
        },
      });
    }
  }, [supplier]);

  useEffect(() => {
    if (updated) {
      const supplierProfile = formDataStep.supplierProfile;
      const supplierId = supplier._id;
      console.log(supplierProfile, 'supplier profile in update');
      dispatch(updateSupplierByIdAsync({ supplierId, supplierProfile }));
      setUpdated(false);
    }
  }, [updated]);

  const handleAddService = () => {
    setAddNewService(true);
  };
  const handleSubmit = () => {
    setAddNewService(false);

    setFormDataStep((prevFormDataStep) => ({
      ...prevFormDataStep,
      supplierProfile: {
        ...prevFormDataStep.supplierProfile,
        services: [
          ...prevFormDataStep.supplierProfile.services,
          newServiceFormData,
        ],
      },
    }));
    const supplierProfile = formDataStep.supplierProfile;
    console.log(supplierProfile, 'supplier profile in update')
    dispatch(updateSupplierByIdAsync({ currentSupplierId, supplierProfile }));
    setUpdated(true)
    setNewServiceFormData({
      serviceImage: null,
      title: "",
      description: "",
      tag: "",
    });
    setImportedFiles([]);
  };



  


  const handleCancel = () => {
    setAddNewService(false);
    setNewServiceFormData({
      serviceImage: null,
      title: "",
      description: "",
      tag: "",
    });
    setImportedFiles([]);
  };
  const handleImageChange = (files) => {
    const file = files[0];
    if (file) {
      console.log(file)
      new Compressor(file, {
        quality: 0.1, // Adjust quality as necessary
        success(result) {
          console.log(result, 'result')
          const reader = new FileReader();
          reader.onload = () => {
            setNewServiceFormData({
                      ...newServiceFormData,
                      serviceImage: reader.result,
                    });
          };
          reader.onerror = () => {
            console.error("Failed to read file");
          };
          reader.readAsDataURL(result);
        },
        error(err) {
          console.error("Error compressing file:", err);
        },
      });
    }
  };
  // const handleImageChange = (files) => {
  //   const file = files[0];
  //   console.log(file);
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setNewServiceFormData({
  //         ...newServiceFormData,
  //         serviceImage: reader.result,
  //       });
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  // const handleImageChange = (e) => {
  //   const file = files[0];
  //   if (file) {
  //     console.log(file)
  //     new Compressor(file, {
  //       quality: 0.1, // Adjust quality as necessary
  //       success(result) {
  //         console.log(result, 'result')
  //         const reader = new FileReader();
  //         reader.onload = () => {
  //           setProfilePicture(reader.result);
  //           setFormDataStep((prevFormDataStep) => ({
  //             ...prevFormDataStep,
  //             supplierProfile: {
  //               ...prevFormDataStep.supplierProfile,
  //               profilePicture: reader.result,
  //             },
  //           }));
  //         };
  //         reader.onerror = () => {
  //           console.error("Failed to read file");
  //         };
  //         reader.readAsDataURL(result);
  //       },
  //       error(err) {
  //         console.error("Error compressing file:", err);
  //       },
  //     });
  //   }
  // };
  const handleFileChange = (files) => {
    console.log("files", files);
    handleImageChange(files);
  };

  const handleDelete = (fileName) => {
    setImportedFiles((prevImportedFiles) =>
      prevImportedFiles.filter((file) => file.name !== fileName)
    );
  };

  if (!supplier) {
    return <div></div>;
  }

  return (
    <>
      <DashboardNavbar arrow={false} title="Products and Services" />
      <div
        style={{
          marginTop: "32px",
          display: "flex",
          justifyContent: "flex-end",
          width: "97%",
        }}
      >
        <button
          type="submit"
          onClick={() => handleAddService()}
          className="create-supplier-btn"
          style={{
            backgroundImage: `url(${plusIcon})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "left 10px center",
          }}
        >
          Add new service
        </button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
        }}
      >
        {formDataStep.supplierProfile.services.slice(1).map((service, index) => (
          <div
            key={index}
            style={{ flex: "1 1 auto", maxWidth: "267px", margin: "8px" }}
          >
            <div className="service-card">
              <div className="service-image">
                {service.serviceImage === null ? (
                  <img
                    src={pictureSymbol}
                    alt=""
                    style={{ maxWidth: "90%", height: "auto" }}
                  />
                ) : (
                  <img
                    src={service.serviceImage}
                    alt=""
                    style={{
                      width: "100%",
                      height: "156px",
                      borderRadius: "16px 16px 0 0",
                      objectFit: "cover", // Ensure the image covers the area without stretching
                      boxSizing: "border-box",
                    }}
                  />
                )}
              </div>
              <div className="service-content">
              <span style={{color: "#1D2739", fontSize:"16px", fontWeight:"600"}}>{service.title}</span>
                <span>{service.description}</span>
                <p
                  style={{
                    alignSelf: "flex-start",
                    background: service.tag ? "#F5F5F5" : "none",
                  }}
                >
                  {service.tag}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {addNewService && (
        <>
          <Overlay />

          <div className="popup-import-container">
            <div
              className="popup-import-content"
              style={{ position: "relative", height: "920px" }}
            >
              <p
                style={{
                  marginTop: "53px",
                  display: "flex",
                  justifyContent: "center",
                  color: "#1D2739",
                  fontSize: "24px",
                  fontWeight: 600,
                  marginBottom: "10px",
                  alignSelf: "flex-start",
                }}
              >
                Add new service
              </p>

              <Form.Group as={Col} className="single-input-edit">
                <Form.Label className="input-title-edit-profile">Service name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  required
                  className="custom-edit-profile-input"
                  value={newServiceFormData.title || ""}
                  onChange={(e) => {
                    setNewServiceFormData({
                      ...newServiceFormData,
                      title: e.target.value,
                    });
                  }}
                />
              </Form.Group>
              <Form.Group>
  <Form.Label
    className="input-title-edit-profile"
    style={{ marginBottom: "7px", display:"flex", textAlign:"left" }}
  >
    Description
  </Form.Label>
  <Form.Control
    as="textarea"
    style={{
      height: "150px",
      padding: "20px",
      overflow: "hidden",
      resize: "none",
    }}
    required
    placeholder="Enter the description of the service you’re offering"
    className="custom-edit-profile-input"
    value={newServiceFormData.description || ""}
    onChange={(e) => {
      const value = e.target.value;
      if (value.length <= 110) {
        setNewServiceFormData({
          ...newServiceFormData,
          description: value,
        });
      }
    }}
  />
  <div
    style={{
      textAlign: "right",
      fontSize: "12px",
      color: "#888",
      marginTop: "5px",
    }}
  >
    {newServiceFormData.description?.length || 0}/110
  </div>
</Form.Group>


              {/* <Form.Group as={Col} style={{ width: "100%" }}> */}
                {/* <Form.Label
                  className="input-title-edit-profile"
                  style={{ marginBottom: "7px", display:"flex", textAlign:"left" }}
                >
                  Description
                </Form.Label>
                <Form.Control
                  as="textarea"
                  style={{
                    height: "150px",
                    // background: "#f9f9f9",
                    padding: "20px",
                    overflow: "hidden",
                    resize: "none",
                  }}
                  required
                  placeholder="Enter the description of the service you’re offering"
                  className="custom-edit-profile-input"
                  value={newServiceFormData.description || ""}
                  onChange={(e) => {
                    setNewServiceFormData({
                      ...newServiceFormData,
                      description: e.target.value,
                    });
                  }}
                />
              </Form.Group> */}

              

              <div>
                <p
                  style={{
                    display: "flex",
                    color: "#1D2739",
                    fontSize: "16px",
                    fontWeight: 500,
                    alignSelf: "flex-start",
                  }}
                >
                  Service
                </p>
                <DragAndDropComponent
                  onFileChange={handleFileChange}
                  onDelete={handleDelete}
                  importedFiles={importedFiles}
                  setImportedFiles={setImportedFiles}
                  setPdfFile={setPdfFile}
                  subtitle="jpg, png"
                  width="100%"
                  margin="0"
                  height="153px"
                />
              </div>

              <Form.Group as={Col} className="single-input-edit">
                <Form.Label className="input-title-edit-profile">Service Tag</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Tag"
                  required
                  className="custom-edit-profile-input"
                  value={newServiceFormData.tag || ""}
                  onChange={(e) => {
                    setNewServiceFormData({
                      ...newServiceFormData,
                      tag: e.target.value,
                    });
                  }}
                />
              </Form.Group>

              <div>
                <button
                  className="custom-continue-button"
                  onClick={() => handleSubmit()}
                  style={{
                    position: "absolute",
                    bottom: "35px",
                    left: "50%",
                    height: "50px",
                    background: "linear-gradient(270deg, #CC9866 0%, #722592 102.7%)",
                    color:"#fff",
                    transform: "translateX(-50%)",
                  }}
                >
                  Publish
                </button>
                <span
                  onClick={() => handleCancel()}
                  style={{
                    color: "#7E7E7E",
                    display: "flex",
                    justifyContent: "center",
                    position: "absolute",
                    bottom: 0,
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                >
                  Cancel
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Services;
