
import React, { useState } from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import logo from "../../assets/navbar-assets/logo.svg";
import logoWhite from "../../assets/navbar-assets/logoWhite.svg";
import { useNavigate } from "react-router";
import "./landingPage.css";
import hamburgerMenu from "../../assets/LandingPage/hamburderMenu.svg";
import hamburgerMenuWhite from "../../assets/LandingPage/hamburgerMenuWhite.svg";

const NavigationBar = ({ outsideOfLandingPage, company, isMobile }) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [mobileLinksVisible, setMobileLinksVisible] = useState(false);

  const toggleNavbar = () => {
    setExpanded(!expanded);
    if (isMobile) {
      setMobileLinksVisible(!mobileLinksVisible);
    }
  };

  const handleRedirectToPage = (page) => {
    switch (page) {
      case 'pricing':
        navigate('/pricing');
        setMobileLinksVisible(false);
        break;
      case 'company':
        navigate('/about');
        setMobileLinksVisible(false);
        break;
      case 'sign-up':
        navigate('/sign-up');
        break;
      case 'product':
        if (outsideOfLandingPage) {
          navigate('/');
          setTimeout(() => {
            scrollToSection('product');
          }, 500);
        } else {
          scrollToSection('product');
          setMobileLinksVisible(false);
        }
        break;
      case 'marketplace':
        if (outsideOfLandingPage) {
          navigate('/');
          setTimeout(() => {
            scrollToSection('marketplace');
          }, 500);
        } else {
          scrollToSection('marketplace');
          setMobileLinksVisible(false);
        }
        break;
      default:
        break;
    }
  };

  const scrollToSection = (section) => {
    if (section === 'product') {
      const suppliersContainers = document.getElementsByClassName('suppliers-container');
      if (suppliersContainers.length > 0) {
        const topOffset = suppliersContainers[0].offsetTop - 180;
        window.scrollTo({ top: topOffset, behavior: 'smooth' });
      }
    } else if (section === 'marketplace') {
      const enterpriseContainers = document.getElementsByClassName('enterprise-container');
      if (enterpriseContainers.length > 0) {
        enterpriseContainers[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  return (
    <Navbar bg="light" expand="lg" className={isMobile ? 'mobile-basic-navbar' : 'basic-navbar-nav'}>
      {isMobile && (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <Navbar.Brand href="/">
            <img
              src={company ? logoWhite : logo}
              alt="logo for TalkDEI"
              className="navbar-mobile-logo"
              height="24px"
              style={{ marginLeft: "auto", marginRight: "auto" }}
            />
          </Navbar.Brand>
          <div>
            <img src={company ? hamburgerMenuWhite : hamburgerMenu} onClick={toggleNavbar} />
          </div>
        </div>
      )}

      {mobileLinksVisible && (
        <div className="mobile-links-menu">
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            <Navbar.Brand href="/">
              <img
                src={logo}
                alt="logo for TalkDEI"
                className="navbar-logo"
                height="24px"
              />
            </Navbar.Brand>
            <div className="close-menu" onClick={() => setMobileLinksVisible(false)}>X</div> {/* Close button */}
          </div>
          <Nav className={company ? "mobile-nav-links" : "mobile-nav-links"}>
            
            <ScrollLink
              to="product"
              spy={true}
              smooth={true}
              duration={500}
              className="nav-link"
              onClick={() => handleRedirectToPage('product')}
            >
              Product
            </ScrollLink>
            <ScrollLink
              to="marketplace"
              spy={true}
              smooth={true}
              duration={500}
              className="nav-link"
              onClick={() => handleRedirectToPage('marketplace')}
            >
              Marketplace
            </ScrollLink>
            <ScrollLink
              to="company"
              spy={true}
              smooth={true}
              duration={500}
              className="nav-link"
              onClick={() => handleRedirectToPage('company')}
            >
              Company
            </ScrollLink>
            <ScrollLink
              to="pricing"
              spy={true}
              smooth={true}
              duration={500}
              className="nav-link"
              onClick={() => handleRedirectToPage('pricing')}
            >
              Pricing
            </ScrollLink>
          </Nav>
        </div>
      )}

      {!isMobile && (
        <>
          <Navbar.Brand href="/">
            <img
              src={company ? logoWhite : logo}
              alt="logo for TalkDEI"
              className="navbar-logo"
              height="24px"
            />
          </Navbar.Brand>
          <Nav className={company ? "company-nav-links" : "nav-links"}>
            <Nav.Link onClick={() => handleRedirectToPage('product')}>Product</Nav.Link>
            <Nav.Link onClick={() => handleRedirectToPage('marketplace')}>Marketplace</Nav.Link>
            <Nav.Link onClick={() => handleRedirectToPage('company')}>Company</Nav.Link>
            <Nav.Link onClick={() => handleRedirectToPage('pricing')}>Pricing</Nav.Link>
          </Nav>
          <Button className={company  ? "company-sign-up-btn" : "sign-up-btn"} onClick={() => handleRedirectToPage('sign-up')}>Sign Up</Button>
        </>
      )}
    </Navbar>
  );
};

export default NavigationBar;
