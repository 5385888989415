import React, { useState, useEffect } from "react";
import CreateAccountContainer from "../SignUpComponent/SignUpContainer"
import { Link } from "react-router-dom";
import { Col, Form } from "react-bootstrap";
import "../SignUpComponent/SharedComponents/styling.css"
import { useNavigate } from "react-router-dom";

const SignInComponent = () => {
  const [showError, setShowError] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isCredentialsValid, setIsCredentialsValid] = useState(false)

const navigate = useNavigate()

useEffect(() => {
  if(username === 'olena' && password === '12345')
  setIsCredentialsValid(true)
}, [username, password])

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!isCredentialsValid) {
      setShowError(true);
    } else {
      setShowError(false);
      navigate("/dashboard");
    }
  };

  return (
    <CreateAccountContainer
      title="Sign in to your account"
      buttonBackground={isCredentialsValid ? "#782D8F" : "rgba(35, 35, 35, 0.30)"}
      onContinueClick={handleSubmit}
      subtitle={
        <>
          Don't have an account?{" "}
          <Link
            to="/sign-up"
            style={{
              color: "#fff",
              fontFamily: "Lato",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "144.523%",
              textDecorationLine: "underline",
            }}
          >
            Create one
          </Link>
        </>
      }
    >
      <Form onSubmit={handleSubmit}>
        {showError && (
          <div className="error-message">Password or Username incorrect</div>
        )}
        <Form.Group as={Col} controlId="companyEmail" className="single-input">
          <Form.Label className="input-title">Email</Form.Label>
          <div className="password-input-wrapper">
            <Form.Control
              type="email"
              placeholder=""
              required
              className={`custom-input ${showError ? "error" : ""}`}
              value={username}
              onChange={(e) => {
                setUsername(e.target.value); 
                setShowError(false);
              }}
            />
          </div>
        </Form.Group>

        <Form.Group as={Col} controlId="password" className="password-input">
          <Form.Label className="input-title">Password</Form.Label>
          <div className="password-input-wrapper">
            <Form.Control
              type="password"
              placeholder=""
              required
              className={`custom-input ${showError ? "error" : ""}`}
              style={{ width: "100%" }}
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);  
                setShowError(false);
              }}
            />
          </div>
        </Form.Group>

        <Form.Group
          controlId="rememberMe"
          className="sign-in-checkbox d-flex justify-content-between"
        >
          <Form.Check
            type="checkbox"
            label="Remember me"
          />
          <span>Forgot Password?</span>
        </Form.Group>
      </Form>
    </CreateAccountContainer>
  );
};

export default SignInComponent;
