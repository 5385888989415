export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const SIGNUP_START = 'SIGNUP_START';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const GET_BUYERS_START = 'GET_BUYERS_START';
export const GET_BUYERS_SUCCESS = 'GET_BUYERS_SUCCESS';
export const GET_BUYERS_FAILURE = 'GET_BUYERS_FAILURE';
export const GET_SELLERS_START = 'GET_SELLERS_START';
export const GET_SELLERS_SUCCESS = 'GET_SELLERS_SUCCESS';
export const GET_SELLERS_FAILURE = 'GET_SELLERS_FAILURE';
// ActionTypes.js
export const SET_TIER_ONE_SUPPLIERS = 'SET_TIER_ONE_SUPPLIERS';
export const SET_TIER_TWO_SUPPLIERS = 'SET_TIER_TWO_SUPPLIERS';
export const SET_PENDING_SUPPLIERS = 'SET_PENDING_SUPPLIERS';
export const SET_SUPPLIERS = 'SET_SUPPLIERS';
export const SET_BUYERS = 'SET_BUYERS';
export const UPDATE_SUPPLIER_BY_ID = 'UPDATE_SUPPLIER_BY_ID';
export const UPDATE_SUPPLIER_PROFILE_PROPERTY = 'UPDATE_SUPPLIER_PROFILE_PROPERTY'
export const UPDATE_SUPPLIER_TIER_ONE_BY_ID = 'UPDATE_SUPPLIER_TIER_ONE_BY_ID'
export const UPDATE_SUPPLIER_TIER_TWO_BY_ID = 'UPDATE_SUPPLIER_TIER_TWO_BY_ID'
export const SEND_OTP = 'SEND_OTP'
export const VERIFY_OTP = 'VERIFY_OTP'
export const OTP_SENT_SUCCESS = 'OTP_SENT_SUCCESS';
export const OTP_SENT_FAILURE = 'OTP_SENT_FAILURE';
export const OTP_VERIFIED_SUCCESS = 'OTP_VERIFIED_SUCCESS';
export const OTP_VERIFIED_FAILURE = 'OTP_VERIFIED_FAILURE';
export const UPDATE_COMPANY_BY_ID = 'UPDATE_COMPANY_BY_SUPPLIER_ID';
export const FETCH_ALL_SUPPLIERS = 'FETCH_ALL_SUPPLIERS';
export const FETCH_SUPPLIER_BY_ID = 'FETCH_SUPPLIER_BY_ID'

