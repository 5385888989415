import React, { useState, useRef, useEffect } from "react";
import SignUpContainer from "../SignUpContainer";
import { Col, Form, Image } from "react-bootstrap";
import "./styling.css";
import questionMark from "../../../assets/questionMark.svg"

const CompanyDetails = ({ formDataStep, setFormDataStep, handleNextStep, step,skip, dispalyQuestionMark, accountType }) => {
  const [isSizeDropdownOpen, setSizeDropdownOpen] = useState(false);
  const [isIndustryDropdownOpen, setIndustryDropdownOpen] = useState(false);
  const sizeDropdownRef = useRef();
  const industryDropdownRef = useRef();
  const [companyNameError, setCompanyNameError] = useState(false);
  const [companySizeError, setCompanySizeError] = useState(false);
  const [companyIndustryError, setCompanyIndustryError] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const handleSubmit = (event) => {
    if(event) event.preventDefault();

    if (!formDataStep.company.name) {
      setCompanyNameError(true);
    } else {
      setCompanyNameError(false);
    }
    if (!formDataStep.company.size) {
      setCompanySizeError(true);
    } else {
      setCompanyNameError(false);
    }
    if (!formDataStep.company.industry) {
      setCompanyIndustryError(true);
    } else {
      setCompanyIndustryError(false);
    }

    if (
      formDataStep.company.name &&
      formDataStep.company.size &&
      formDataStep.company.industry
    ) {
      handleNextStep();
    }
  };
  const handleSkip = () => {
    handleNextStep();
  };

  const sizeOptions = [
    "",
    "Micro (1-10 employees)",
    "Small (11-50 employees)",
    "Medium (51-250 employees)",
    "Large (251-500 employees)",
    "Enterprise (501-1000 employees)",
    "Corporate (1001-5000 employees)",
    "Global (5001+ employees)",
  ];

  const industryOptions = [
    "",
    "Technology",
    "Healthcare",
    "Finance",
    "Education",
    "Retail",
    "Manufacturing",
    "Non-profit",
    "Energy",
    "Transportation",
    "Real Estate",
    "Agriculture",
    "Hospitality",
    "Entertainment",
    "Telecommunications",
    "Government",
    "Consulting",
    "Consumer Goods",
    "Environmental Services",
    "Marketing/Advertising",
    "Legal",
    "Other",
  ];

  const handleToggleSizeDropdown = () => {
    setSizeDropdownOpen(!isSizeDropdownOpen);
  };

  const handleToggleIndustryDropdown = () => {
    setIndustryDropdownOpen(!isIndustryDropdownOpen);
  };

  const handleSelectSizeOption = (value) => {
    setFormDataStep({
      ...formDataStep,
      company: {
        ...formDataStep.company,
        size: value
      }
    })
    setSizeDropdownOpen(false);
  };

  const handleSelectIndustryOption = (value) => {
      setFormDataStep({
        ...formDataStep,
        company: {
          ...formDataStep.company,
          industry: value
        }
      })
    setIndustryDropdownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (
      (sizeDropdownRef.current &&
        !sizeDropdownRef.current.contains(event.target)) ||
      (industryDropdownRef.current &&
        !industryDropdownRef.current.contains(event.target))
    ) {
      setSizeDropdownOpen(false);
      setIndustryDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <SignUpContainer
      title="Company Details"
      subtitle={<>Tell us about your organization</>}
      skip={skip}
      height="790px"
      step={step}
      onContinueClick={handleSubmit}
      handleSkip={handleSkip}
      accountType={accountType}
    >
      <Form onSubmit={handleSubmit}>
        <Form.Group as={Col} controlId="companyName" className="single-input">
          <Form.Label className="input-title">Company Name
          {dispalyQuestionMark && (
          <span className="question-mark-container">
          <Image
            src={questionMark}
            alt=""
            className="question-mark-icon"
            onClick={() => setShowMessage(!showMessage)}
          />
          {showMessage && (
            <div className="info-message">
              If you’re representing a company, providing the company name is preferred.
            </div>
          )}
        </span>
        )}
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="S/R Legals"
            required
            className={`custom-input ${companyNameError ? "error" : ""}`}
            value={formDataStep.company.name}
            onChange={(e) => {
              setFormDataStep({
                ...formDataStep,
                company: {
                  ...formDataStep.company,
                  name: e.target.value
                }
              })
              setCompanyNameError(false)
            }}
          />
            {companyNameError && (
            <div className="error-message">Company Name is required</div>
          )}
        </Form.Group>

        <Form.Group as={Col} controlId="companySize" className="single-input">
          <Form.Label className="input-title">Company Size</Form.Label>
          <div
            ref={sizeDropdownRef}
            className={`custom-input custom-dropdown-container ${
              isSizeDropdownOpen ? "open" : ""
            }
            ${companySizeError ? "error" : ""}`}
          >
            <div className="custom-dropdown" onClick={handleToggleSizeDropdown}>
              <span className="selected-option">
                {formDataStep.company.size || "50-100"}
              </span>
              <ul
                className={`custom-dropdown-list ${
                  isSizeDropdownOpen ? "show" : ""
                }`}
              >
                {sizeOptions.map((option, index) => (
                  <li
                    key={option}
                    className={`custom-dropdown-item ${
                      index === 0 
                        ? "disabled"
                        : ""
                    }`}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    handleSelectSizeOption(option);
                    setCompanySizeError(false)
                  }}
                  
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {companySizeError && (
            <div className="error-message">Company Size is required</div>
          )}
        </Form.Group>

        <Form.Group controlId="companyIndustry" className="single-input">
          <Form.Label className="input-title">Industry</Form.Label>
          <div
            ref={industryDropdownRef}
            className={`custom-input custom-dropdown-container ${
              isIndustryDropdownOpen ? "open" : ""
            }
            ${companyIndustryError ? "error" : ""}`}
          >
            <div
              className="custom-dropdown"
              onClick={handleToggleIndustryDropdown}
            >
              <span className="selected-option">
                {formDataStep.company.industry || "Select Industry"}
              </span>
              <ul
                className={`custom-dropdown-industry-list ${
                  isIndustryDropdownOpen ? "show" : ""
                }`}
              >
                {industryOptions.map((option, index) => (
                  <li
                    key={option}
                    className={`custom-dropdown-item ${
                      index === 0 
                        ? "disabled"
                        : ""
                    }`}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    handleSelectIndustryOption(option);
                    setCompanyIndustryError(false)
                  }}
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {companyIndustryError && (
            <div className="error-message">Company Industry is required</div>
          )}
        </Form.Group>
      </Form>
    </SignUpContainer>
  );
};

export default CompanyDetails;

