import React from "react";
import pictureSymbol from "../../assets/pictureSymbol.svg";

const Services = ({ services }) => {

  const truncateText = (text, limit) => {
    if (text.length <= limit) {
      return text;
    }
    return text.slice(0, limit) + '...';
  };
  

  console.log(services)
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
      }}
    >
      {services.map((service, index) => (
        <div
          key={index}
          style={{ flex: "1 1 auto", maxWidth: "267px", margin: "8px" }}
        >
          <div className="service-card">
            <div className="service-image">
              {service.serviceImage === null ? (
                <img
                  src={pictureSymbol}
                  alt=""
                  style={{ width: "74px", height: "74px" }}
                />
              ) : (
                <img
                  src={service.serviceImage}
                  alt=""
                  style={{
                    width: "100%",
                    height: "156px",
                    borderRadius: "16px 16px 0 0",
                  }}
                />
              )}
            </div>
            <div className="service-content">
              <span>{truncateText(service.description, 100)}</span>

              {/* <span>{service.description}</span> */}
              <p
                style={{
                  alignSelf: "flex-start",
                  background: service.tag ? "#F5F5F5" : "none",
                }}
              >
                {service.tag}
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Services;
