import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { Container, Row, Col, Image, Form } from "react-bootstrap";
import DashboardNavbar from "../../DashboardNavbar";
import toggleIcon from "../../../../assets/toggleOffIcon.svg";

const Settings = () => {
  const [selectedTab, setSelectedTab] = useState("General");
  const tabs = ["General", "Notifications", "Billing"];

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/buyer-dashboard/overview");
  };

  const emailNotifications = [
    "News and updates",
    "Marketing emails",
    "Contract approvals",
    "Supplier updates",
    "Messages",
  ];
  const desktopNotifications = [
    "Contract approvals",
    "Supplier updates",
    "Messages",
  ];
  const pushNotifications = [
    "Contract approvals",
    "Supplier updates",
    "Messages",
  ];
  return (
    <div style={{ background: "#fff", minHeight: "100vh" }}>
      <Container fluid>
        <DashboardNavbar
          title={"Settings"}
          handleBack={handleGoBack}
          tabs={tabs}
          selectedTab={selectedTab}
          handleTabClick={handleTabClick}
          comingSoon={true}
        />

        {selectedTab === "General" ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "124px 30px 30px 30px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "20%",
                marginTop: "20px",
                color: "#000",
                fontSize: "24px",
                textAlign: "left",
              }}
            >
              <span>General</span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <Form.Group
                as={Col}
                controlId="companyName"
                className="single-input-edit"
              >
                <Form.Label className="input-title-edit">
                  Company Name
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="This is the details about the company"
                  required
                  className="custom-edit-profile-input"
                  // value={formDataStep.company.name}
                  // onChange={(e) => {
                  // setFormDataStep({
                  //   ...formDataStep,
                  //   company: {
                  //     ...formDataStep.company,
                  //     name: e.target.value
                  //   }
                  // })

                  // }}
                />
              </Form.Group>

              <Form.Group as={Col} style={{ width: "100%" }}>
                <Form.Label
                  className="edit-profile-input-title"
                  style={{ marginBottom: "7px" }}
                >
                  About
                </Form.Label>
                <Form.Control
                  as="textarea"
                  style={{
                    height: "196px",
                    // background: "#f9f9f9",
                    padding: "20px",
                    resize: "none",
                  }}
                  required
                  className="custom-edit-profile-input"
                  placeholder="This is the details about the buyer"
                  // value={formDataStep.supplierProfile.bio || ""}
                  // onChange={(e) => {
                  //   setFormDataStep({
                  //     ...formDataStep,
                  //     supplierProfile: {
                  //       ...formDataStep.supplierProfile,
                  //       bio: e.target.value,
                  //     },
                  //   });
                  // }}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                controlId="companyEmail"
                className="single-input-edit"
              >
                <Form.Label className="input-title-edit">Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your company email"
                  required
                  className="custom-edit-profile-input"
                  // value={formDataStep.email}
                  // onChange={(e) => {
                  //   const enteredEmail = e.target.value;
                  //   setFormDataStep({
                  //     ...formDataStep,
                  //     email: enteredEmail,
                  //   });

                  // }}
                />
              </Form.Group>
              <span
                style={{
                  color: "#1D2739",
                  fontSize: "16px",
                  marginTop: "60px",
                  marginBottom: "28px",
                }}
              >
                To save your changes, please enter your password
              </span>

              <Form.Group controlId="password" className="single-input-edit">
                <Form.Label className="input-title-edit">
                  Current password
                </Form.Label>
                <div className="password-input-wrapper">
                  <Form.Control
                    type="password"
                    placeholder=""
                    required
                    className="custom-edit-profile-input"
                    // value={formDataStep.password}
                    // onChange={(e) =>
                    //   setFormDataStep({
                    //     ...formDataStep,
                    //     password: e.target.value,
                    //   })
                    // }
                  />
                </div>
              </Form.Group>

              {/* <button
                 style={{
                  height: "36px",
                  display: "inline-flex",
                  padding: "10px",
                  
                  alignItems: "center",
                  borderRadius: "8px",
                  border: "none",
                  background: "#0E8080",
                  color: "#fff",
                }}
            >Save changes</button> */}
            </div>
          </div>
        ) : (
          selectedTab === "Notifications" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "124px 30px 30px 30px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "20%",
                  color: "#000",
                  fontSize: "24px",
                  textAlign: "left",
                }}
              >
                <span>Notifications</span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                }}
              >
                <div style={{marginBottom:"50px"}}>
                  <span
                    style={{
                      marginBottom: "32.5px",
                      display: "flex",
                      color: "#1D2739",
                      fontSize: "16px",
                      fontWeight: 700,
                    }}
                  >
                    Email Notifications
                  </span>
                  {emailNotifications.map((notification) => {
                    return (
                      <div
                        key={notification}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginBottom: "29px",
                        }}
                      >
                        <span style={{color: "#1D2739", fontSize: "16px"}}>{notification}</span>
                        <Image src={toggleIcon} alt="" />
                      </div>
                    );
                  })}
                </div>

                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                  }}
                > */}
                {/* <span style={{marginBottom:"32.5px"}}>Desktop notifications</span> */}

                <div style={{marginBottom:"50px"}}>
                  <span style={{
                      marginBottom: "32.5px",
                      display: "flex",
                      color: "#1D2739",
                      fontSize: "16px",
                      fontWeight: 700,
                    }}>
                    Desktop notifications
                  </span>
                  {desktopNotifications.map((notification) => {
                    return (
                      <div
                        key={notification}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginBottom: "29px",
                        }}
                      >
                        <span style={{color: "#1D2739", fontSize: "16px"}}>{notification}</span>
                        <Image src={toggleIcon} alt="" />
                      </div>
                    );
                  })}
                  {/* </div> */}
                </div>

                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                  }}
                > */}

                <div style={{marginBottom:"50px"}}>
                  <span style={{
                      marginBottom: "32.5px",
                      display: "flex",
                      color: "#1D2739",
                      fontSize: "16px",
                      fontWeight: 700,
                    }}>
                    Push notifications
                  </span>
                  {pushNotifications.map((notification) => {
                    return (
                      <div
                        key={notification}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginBottom: "29px",
                        }}
                      >
                        <span style={{color: "#1D2739", fontSize: "16px"}}>{notification}</span>
                        <Image src={toggleIcon} alt="" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            // </div>
          )
        )}

        <div>
          <button
            style={{
              height: "36px",
              display: "inline-flex",
              padding: "10px",
              alignItems: "center",
              borderRadius: "8px",
              border: "none",
              background: "#0E8080",
              color: "#fff",
              marginBottom: "124px",
            }}
          >
            Save changes
          </button>
        </div>
      </Container>
    </div>
  );
};
export default Settings;
