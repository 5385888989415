import React, { useState, useEffect } from "react";
import Navbar from "../../components/LandingPage/Navbar";
import Footer from "../../components/LandingPage/Footer";
import "../Pages.css";
import background from "../../assets/LandingPage/Background (1).png";
import { Container, Col, Image } from "react-bootstrap";
import ownershipContainerImg from "../../assets/LandingPage/pricingPageOwnership.png";
import diversityImg from "../../assets/LandingPage/supplierDiversityPricing.png";
import vectorIcon from "../../assets/LandingPage/Vector.svg";
import checkmark from "../../assets/LandingPage/checkmarkWhite.svg";
import checkmarkBlack from "../../assets/LandingPage/checkMarkBlack.svg";
import { useNavigate } from "react-router";

const EnterprisesPricing = ({ renderfromPricing, isMobile }) => {
  const [activeColumn, setActiveColumn] = useState(2);
  const [hubSpotCodeAdded, setHubSpotCodeAdded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (hubSpotCodeAdded) {
      const script = document.createElement("script");
      script.setAttribute("charset", "utf-8");
      script.setAttribute("type", "text/javascript");
      script.setAttribute("src", "//js.hsforms.net/forms/embed/v2.js");
      document.body.appendChild(script);

      script.onload = () => {
        console.log("on load");
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "45694015",
            formId: "e3a0f2fb-e33a-4af1-90a8-d5305a3c7d82",
            target: "#hubspot-form",
            onFormSubmit: () => {
              // HubSpot form submitted successfully, trigger download
              const downloadLink = document.createElement("a");
              downloadLink.href = "/SalesDeck.pdf";
              downloadLink.download = "SalesDeck.pdf";
              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
            },
          });
        }
      };
    }
  }, [hubSpotCodeAdded]);

  const handlegetStarted = () => {
    navigate("/sign-up", { state: { selected: "buyer" } });
  };

  const handleClick = (column) => {
    setActiveColumn(column);
  };

  const addHubSpotCode = () => {
    if (!hubSpotCodeAdded) {
      setHubSpotCodeAdded(true);
    }
  };
  const featuresList = [
    "  ",
    "Supplier DIversity Budgeting Dashboard",
    "Action, Bid & Contract Supplier Diversity metrics",
    "Messaging Supplier Management",
    "AI-Powered RFP Supplier matching",
    "Unified Marketplace",
    "Supplier invitations",
    "Maximum Diverse Suppliers stored",
    "Monthly RFP matching",
    "Monthly Supplier invitations",
    " ",
  ];

  const features = [
    { title: "Basic", price: "$15,000" },
    { title: "Standard", price: "$20,000" },
    { title: "Premium", price: "$42,000" },
  ];

  const featuresItems = [
    ["Up to 1000", "Up to 2000", "Unlimited"],
    ["Up to 15", "Up to 30", "Unlimited"],
    ["Up to 50", "Up to 100", "Unlimited"],
  ];

  return (
    <div>
      <div
        className="pricing-gradient-container"
        style={{ height: "1350px", padding: "0" }}
      >
        {isMobile ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              color: "#fff",
              marginBottom: "150px",
              marginTop: "100px",
              gap: "52px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "50px",
                justifyContent: "center",
              }}
            >
              {features.map((item, index) => (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ fontSize: "14px", fontWeight: "700" }}>
                    {item.title}
                  </div>
                  <div style={{ fontSize: "17px", fontWeight: "500" }}>
                    {item.price}
                  </div>
                </div>
              ))}
            </div>

            <div
              style={{ display: "flex", flexDirection: "column" }}
              className={activeColumn === 1 ? "active-colomn" : ""}
              onClick={() => handleClick(1)}
            >
              {featuresList.slice(1).map((feature, index) => (
                <div
                  key={feature}
                  className={index === 0 ? "fetures-list-of-items-bottom-0" : "fetures-list-of-items-bottom"}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    // marginBottom: index === 0 ? "34px" : "44px",
                  }}
                >
                  <span style={{ marginBottom: "20px", fontSize: "13px" }}>
                    {feature}
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap:
                        index === 9
                          ? "20px"
                          : index === 6 || index === 7 || index === 8
                          ? "55px"
                          : "116px",
                    }}
                  >
                    {index === 6 || index === 7 || index === 8
                      ? featuresItems[index - 6].map((item, i) => (
                          <span key={i} style={{ fontSize: "13px" }}>
                            {item}
                          </span>
                        ))
                      : index !== 9 &&
                        featuresItems.map((item, i) => (
                          <img src={checkmark} alt="Checkmark" key={i} />
                        ))}

                    {index === 9 &&
                      featuresItems.map((item, i) => (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: "40px",
                          }}
                        >
                          <button
                            className="pricelist-get-started-btn"
                            style={{
                              background: activeColumn === 1 ? "#000" : "#fff",
                              color: activeColumn === 1 ? "#fff" : "#000",
                            }}
                            onClick={handlegetStarted}
                          >
                            Get started
                          </button>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div
          className="pricing-table"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              width: "100%",
              color: "#fff",
              marginBottom: "150px",
              marginTop: "138px",
              // gap: "150px",
            }}
          >
            {!isMobile && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                {featuresList.map((item, index) => (
                  <div
                  className={index === 0 ? "fetures-list-of-items-0" : "fetures-list-of-items"}
                    style={{
                      textAlign: "left",
                    }}
                  >
                    {item}
                  </div>
                ))}
              </div>
            )}

            <div
              style={{ display: "flex", flexDirection: "column" }}
              className={activeColumn === 1 ? "active-colomn" : ""}
              onClick={() => handleClick(1)}
            >
              {featuresList
                .slice(0, featuresList.length - 1)
                .map((item, index) => (
                  <div
                  className={index === 0 ? "fetures-list-of-items-bottom-0" : "fetures-list-of-items-bottom"}
                    key={item}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                      // marginBottom: index === 0 ? "34px" : "44px",
                    }}
                  >
                    {index === 0 ? (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div className="price-feature-title">
                          Basic
                        </div>
                        <div className="price-feature-price">
                          $15,000/y
                        </div>
                      </div>
                    ) : item === "Maximum Diverse Suppliers stored" ? (
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "400",
                          color: activeColumn === 1 ? "#000" : "#fff",
                          width: "100%",
                        }}
                      >
                        Up to 1000
                      </p>
                    ) : item === "Monthly RFP matching" ? (
                      // Render another feature
                      <p
                      className="feature-item-price"
                        style={{
                          fontSize: "18px",
                          fontWeight: "400",
                          color: activeColumn === 1 ? "#000" : "#fff",
                          width: "100%",
                        }}
                      >
                        Up to 15
                      </p>
                    ) : item === "Monthly Supplier invitations" ? (
                      // Render another feature
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "400",
                          color: activeColumn === 1 ? "#000" : "#fff",
                          width: "100%",
                        }}
                      >
                        Up to 50
                      </p>
                    ) : activeColumn === 1 ? (
                      <img src={checkmarkBlack} alt="Checkmark" />
                    ) : (
                      <img src={checkmark} alt="Checkmark" />
                    )}
                  </div>
                ))}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "25px",
                }}
              >
                <button
                  className="pricelist-get-started-btn"
                  style={{
                    background: activeColumn === 1 ? "#000" : "#fff",
                    color: activeColumn === 1 ? "#fff" : "#000",
                  }}
                  onClick={handlegetStarted}
                >
                  Get started
                </button>
              </div>
            </div>

            <div
              style={{ display: "flex", flexDirection: "column" }}
              className={activeColumn === 2 ? "active-colomn" : ""}
              onClick={() => handleClick(2)}
            >
              {featuresList
                .slice(0, featuresList.length - 1)
                .map((item, index) => (
                  <div
                    key={item}
                    className={index === 0 ? "fetures-list-of-items-bottom-0" : "fetures-list-of-items-bottom"}
                    style={{
                      // flex: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      // marginBottom: index === 0 ? "34px" : "44px",
                    }}
                  >
                    {index === 0 ? (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div  className="price-feature-title">
                          Standard
                        </div>
                        <div  className="price-feature-price">
                          $20,000/y
                        </div>
                      </div>
                    ) : item === "Maximum Diverse Suppliers stored" ? (
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "400",
                          color: activeColumn === 2 ? "#000" : "#fff",
                          width: "100%",
                        }}
                      >
                        Up to 2000
                      </p>
                    ) : item === "Monthly RFP matching" ? (
                      // Render another feature
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "400",
                          color: activeColumn === 2 ? "#000" : "#fff",
                          width: "100%",
                        }}
                      >
                        Up to 30
                      </p>
                    ) : item === "Monthly Supplier invitations" ? (
                      // Render another feature
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "400",
                          color: activeColumn === 2 ? "#000" : "#fff",
                          width: "100%",
                        }}
                      >
                        Up to 100
                      </p>
                    ) : activeColumn === 2 ? (
                      <img src={checkmarkBlack} alt="Checkmark" />
                    ) : (
                      <img src={checkmark} alt="Checkmark" />
                    )}
                  </div>
                ))}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "25px",
                }}
              >
                <button
                  className="pricelist-get-started-btn"
                  style={{
                    background: activeColumn === 2 ? "#000" : "#fff",
                    color: activeColumn === 2 ? "#fff" : "#000",
                  }}
                  onClick={handlegetStarted}
                >
                  Get started
                </button>
              </div>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column" }}
              className={activeColumn === 3 ? "active-colomn" : ""}
              onClick={() => handleClick(3)}
            >
              {featuresList
                .slice(0, featuresList.length - 1)
                .map((item, index) => (
                  <div
                    key={item}
                    className={index === 0 ? "fetures-list-of-items-bottom-0" : "fetures-list-of-items-bottom"}
                    style={{
                      // flex: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      // marginBottom: index === 0 ? "34px" : "44px",
                    }}
                  >
                    {index === 0 ? (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div  className="price-feature-title">
                          Premium
                        </div>
                        <div  className="price-feature-price">
                          $42,000/y
                        </div>
                      </div>
                    ) : item === "Maximum Diverse Suppliers stored" ? (
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "400",
                          color: activeColumn === 3 ? "#000" : "#fff",
                          width: "100%",
                        }}
                      >
                        Unlimited
                      </p>
                    ) : item === "Monthly RFP matching" ? (
                      // Render another feature
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "400",
                          color: activeColumn === 3 ? "#000" : "#fff",
                          width: "100%",
                        }}
                      >
                        Unlimited
                      </p>
                    ) : item === "Monthly Supplier invitations" ? (
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "400",
                          color: activeColumn === 3 ? "#000" : "#fff",
                          width: "100%",
                        }}
                      >
                        Unlimited
                      </p>
                    ) : activeColumn === 3 ? (
                      <img src={checkmarkBlack} alt="Checkmark" />
                    ) : (
                      <img src={checkmark} alt="Checkmark" />
                    )}
                  </div>
                ))}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "25px",
                }}
                // onClick={() => handleClick(3)}
              >
                <button
                  className="pricelist-get-started-btn"
                  style={{
                    background: activeColumn === 3 ? "#000" : "#fff",
                    color: activeColumn === 3 ? "#fff" : "#000",
                  }}
                  onClick={handlegetStarted}
                >
                  Get started
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        className="main-features-container-pricing"
        style={{
          background: "#fff",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="suppliers-pricing-container"
        >
          <p>Pilot Program</p>
          <button onClick={handlegetStarted}>Get started</button>
          <div className="features-container-pricing-suppliers">
            <div className="feature-column">
              <div className="feature-category-suppliers">
                <h4 style={{ marginBottom: isMobile ? "33px" : "27px" }}>
                  Pilot program package includes all features and services from
                  the premium package
                </h4>
              </div>
              <div className="feature-category-suppliers">
                <h4>Custom metrics</h4>
              </div>
              <div className="feature-category-suppliers">
                <h4>Contract signature API like DocuSign</h4>
              </div>
              <div className="feature-category-suppliers">
                <h4>File storage</h4>
              </div>
              <div className="feature-category-suppliers">
                <h4>Project calendar</h4>
              </div>
              <div className="feature-category-suppliers">
                <h4>In-app video messaging</h4>
              </div>
              <div className="feature-category-suppliers">
                <h4>Custom registration folder with Open AI templates</h4>
              </div>
              <div className="feature-category-suppliers">
                <h4>Custom registration folder with Open AI templates</h4>
              </div>
            </div>
            <div className="feature-column">
              <div className="feature-category-suppliers">
                <span
                  style={{ marginBottom: isMobile ? "52px" : "42.8px" }}
                  className="category-item"
                >
                  Included
                </span>
              </div>
              <div className="feature-category-suppliers">
                <span className="category-item">
                  Upto 4 choices from the list provided
                </span>
              </div>
              <div className="feature-category-suppliers">
                <span className="category-item">
                  Upto 4 choices from the list provided
                </span>
              </div>
              <div className="feature-category-suppliers">
                <span className="category-item">
                  Upto 4 choices from the list provided
                </span>
              </div>
              <div className="feature-category-suppliers">
                <span className="category-item">
                  Upto 4 choices from the list provided
                </span>
              </div>
              <div className="feature-category-suppliers">
                <span className="category-item">
                  Upto 4 choices from the list provided
                </span>
              </div>
              <div className="feature-category-suppliers">
                <span className="category-item">
                  Upto 4 choices from the list provided{" "}
                </span>
              </div>
              <div className="feature-category-suppliers">
                <span className="category-item">
                  Upto 4 choices from the list provided{" "}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              src={diversityImg}
              width={isMobile ? "377px" : "657px"}
              height={isMobile ? "212pxs" : "359px"}
              style={{ borderRadius: "20px", marginBottom: "30px" }}
              fluid
            />
          </div>
          <button
            className="download-beta-pdf"
            style={{
              borderRadius: "50px",
              padding: "10px 5px 10px 40px",
              position: "relative",
              pointerEvents: hubSpotCodeAdded ? "none" : "auto",
            }}
            onClick={addHubSpotCode}
          >
            <div
              style={{
                position: "absolute",
                left: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                background: "#0E8080",
                borderRadius: "50px",
                width: isMobile ? "26px" : "44px",
                height: isMobile ? "26px" : "44px",
              }}
            >
              <img
                src={vectorIcon}
                alt="Vector Icon"
                style={{
                  padding: isMobile ? "5px" : "15px",
                }}
              />
            </div>

            <span style={{ marginLeft: "10px" }}>
              Download the beta program pdf
            </span>
          </button>
          <div
            id="hubspot-form"
            style={{
              display: "flex",
              marginBottom: "50px",
              marginTop: "20px",
              height:'200px'
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default EnterprisesPricing;
