import React, { useRef, useState, useEffect, useLayoutEffect } from "react";
import Navbar from "../components/LandingPage/Navbar";
import Footer from "../components/LandingPage/Footer";
import "./Pages.css";
import CompanyBackgroundImg from "../assets/LandingPage/ComapnyPageBackground.png";
import background from "../assets/LandingPage/Background (1).png";
import arrowRight from "../assets/LandingPage/boldarrowRight.svg";
import arrowLeft from "../assets/LandingPage/boldarrowLeft.svg";
import { Container, Col, Image } from "react-bootstrap";
import AlanImg from "../assets/LandingPage/teamPictures/Alan.png";
import BosunImg from "../assets/LandingPage/teamPictures/Bosun.png";
import NinaImg from "../assets/LandingPage/teamPictures/Nina.png";
import MarshawnImg from "../assets/LandingPage/teamPictures/Marshawn.png";
import RashadImg from "../assets/LandingPage/teamPictures/Rashad.png";
import OlenaImg from "../assets/LandingPage/teamPictures/Olena.png";
import GaneshImg from "../assets/LandingPage/teamPictures/Ganesh.jpg";
import linkedInIcon from "../assets/LandingPage/linkedinIcon.svg";
import sponsor1 from "../assets/LandingPage/sponsors/image 12.png";
import sponsor2 from "../assets/LandingPage/sponsors/image 14.png";
import sponsor3 from "../assets/LandingPage/sponsors/image 15.png";
import sponsor4 from "../assets/LandingPage/sponsors/image 16.png";
import sponsor5 from "../assets/LandingPage/sponsors/image 33.png";
import sponsor6 from "../assets/LandingPage/sponsors/image 34.png";
import pattern1 from "../assets/LandingPage/Pattern 1.png";
import pattern2 from "../assets/LandingPage/Pattern 2.png";
import pattern3 from "../assets/LandingPage/Pattern 3.png";
import pattern4 from "../assets/LandingPage/Pattern 4.png";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function animateText() {
  const text = document.querySelector(".animated");
  const rows = text.innerText.split("\n");

  const wrappedRows = rows.map((row) => `<div>${row}</div>`);
  text.innerHTML = wrappedRows.join("");

  const divs = text.querySelectorAll("div");

  gsap.set(divs, { color: "#8E8E8E" });

  divs.forEach((div, index) => {
    ScrollTrigger.create({
      trigger: div,
      start: "top +150px",
      onEnter: () => {
        gsap.to(div, {
          duration: 0.5,
          backgroundImage:
            "linear-gradient(270deg, #CC9866 13.35%, #654198 82.21%)",
          WebkitBackgroundClip: "text",
          color: "transparent",
          scrub: true,
          from: { color: "#8E8E8E" },
        });
      },
    });
  });
}

const Company = () => {
  const [hoveredFounderIndex, setHoveredFounderIndex] = useState(null);
  const [hoveredAdvisorIndex, setHoveredAdvisorIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isSmallLaptop, setSmallLaptop] = useState(
    window.innerWidth > 768 && window.innerWidth <= 1280
  );
  const sliderRef = useRef(null);
  const containerBlueRef = useRef(null);
  const foundersListRef = useRef(null);
  const [firstIndex, setFirstIndex] = useState(0);
  const [firstAdvIndex, setFirstAdvIndex] = useState(0);
  // const imageWidth = isMobile ? 208 : 451;
  const imageWidth = isMobile ? 208 : isSmallLaptop ? 367 : 451;
  console.log(imageWidth);
  const gap = isMobile ? 35 : isSmallLaptop ? 30 : 50;

  // const gap = isMobile ? 35 : 50;

  const patterns = [pattern1, pattern2, pattern3, pattern4];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setSmallLaptop(window.innerWidth > 768 && window.innerWidth <= 1280);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const container = containerBlueRef.current;

    if (!container) return;

    const containerId = `#${container.id}-scrollTrigger`;
    container.style.backgroundImage = `url(${patterns[0]})`;
    container.style.backgroundSize = `100% 100%`;

    gsap.from(container, {
      opacity: 1,
      duration: 10,
      scrollTrigger: {
        trigger: container,
        start: "top +150px",
        end: "+=100",
        ease: "power4.in",
        scrub: true,

        stagger: {
          each: 10,
        },

        id: containerId,
        once: true,
        onUpdate: (self) => {
          const progress = self.progress;
          const newIndex = Math.floor(progress * (patterns.length - 1));
          setIndex(newIndex);
        },
        onComplete: () => {
          setIndex(3);
        },
      },
    });
    // return () => {
    //   ScrollTrigger.getAll().forEach((trigger) => {
    //     trigger.kill();
    //   });
    // };
  }, [patterns]);

  const founders = [
    {
      name: "Alan Bray",
      img: AlanImg,
      position: "CEO/Founder",
      linkedin: "https://www.linkedin.com/in/alanbray/",
      bio: "Welcome to TalkDEI! This journey has been 5 years in the making, but we've finally arrived at a solution that I believe will make a real impact in the world. It all started back in 2019 when I made the decision to leave my job as a tech sales rep. Despite being good at what I did, I often felt isolated and disconnected in a predominantly homogenous environment. I began to immerse myself in diversity initiatives within various organizations and discovered a passion for it. However, I also noticed significant gaps that technology and unity could address, leading me to create Bray Media, an ed-tech platform focused on closing the career awareness gap for underserved communities. Through conversations, I unearthed disparities in contracting opportunities and their potential impact on our communities, inspiring the creation of TalkDEI. I invite you to explore our platform and connect with me on LinkedIn. ",
    },
    {
      name: "Ganesh Kumar",
      img: GaneshImg,
      position: "VP of Technology Architecture",
      linkedin: "https://www.linkedin.com/in/ganeshkumarb-talkdei/",
      bio: `Ganesh’ is a forward-thinking, dynamic, aggressive & result-oriented “Visionary engineering leader”. With over 20 years of experience in building enterprise SaaS products and platforms from ideation to market, brings extensive expertise in microservice architecture and data-first approaches with AI/ML. His impressive background includes co-founding and leading several tech startups and companies.
            He is having diversified solution and delivery expertise in Automotive, Logistics, Travel, Energy & Utilities, Telecom, R&D, Health-care, FinTech, Stock Exchange, Banking and Insurance verticals
            He mentored and nurtured distributed, diversified young talents, experts globally to deliver multimillion projects
            Worked with various product and services companies like Bosch, Wipro, Samsung and served all the global customers in various roles like Director of Engineering, Director of digital delivery, Enterprise / Solution architect.
            He also certified TOGAF enterprise architect`,
    },
    {
      name: "Bosun Awodiya",
      img: BosunImg,
      position: "Founding Designer",
      linkedin: "https://www.linkedin.com/in/bosun-awodiya-3b5b77207/",
      bio: "As a seasoned product designer with 4+ years of experience building startups and digital products, Bosun brings a unique blend of creativity and strategic thinking to the table. His journey in design has seen him work at leading tech startups including Edukoya that raised the largest pre-seed round for an African EdTech platform to date .  This diverse background has empowered Bosun to play pivotal roles in various companies, equipping him with a unique skill set that bridges aesthetics and functionality, driving the creation of user-centered products that not only attract investors but also generate substantial revenue. His expertise lies in crafting visually appealing and engaging interfaces and experiences that are carefully tailored to meet user needs, ensuring that every product designed is not only beautiful but also functional and impactful. Having built the product, He's dedicated to building and contributing to the success of TalkDEI. His commitment to excellence and user satisfaction is at the core of everything he does, making him a valuable asset.",
    },
    {
      name: "Olena Danykh",
      img: OlenaImg,
      position: "Founding Engineer (Frontend)",
      linkedin: "https://www.linkedin.com/in/olena-danykh-a83016173/",
      bio: "Olena is an experienced front-end developer with five years of expertise in creating user-friendly applications. Originally from Ukraine, she earned a Master's degree in Management and Administration, which enhances her technical management skills and commitment to excellence. Now based in New York City, Olena's passion for problem-solving sparked her journey into technology. She began her career as a Quality Assurance Engineer but quickly transitioned to development, driven by a desire to contribute more directly to product innovation. Throughout her career, Olena has taken on a variety of roles, from freelance projects to full-time positions, which has helped her develop a versatile skill set and deep industry knowledge. Her dedication goes beyond her professional duties, as seen in her active participation in open-source projects, underscoring her commitment to advancing software development.",
    },
  ];

  const advisors = [
    {
      name: "Rashaad Lambert",
      img: RashadImg,
      position: "Board Advisor",
      linkedin: "",
      bio: "In an industry where diversity and racism have become part of the narrative among high-profile pro athletes, Rashaad has been immersed in creating pathways for addressing racism, equity and inclusion for their success during and outside of competition. Today, the former Strategic Partner of Social Impact at TD Bank is leading diversity initiatives targeting entrepreneurs for media titan Forbes as its Director of Culture and Community. Through his Forbes' #CultureTalks platform on Instagram, Rashaad has galvanized a collaborative online thinktank committed to improving access to more revenue and impact opportunities for communities that are marginalized when confronted with uphill climb against bias or discrimination. The multicultural engagement inroads made by this proud Philadelphia native and Temple University alumnus have been recognized by the NAACP, Forbes, the US Congress. He holds the distinguishing Lean Six Sigma Black Belt certification for coaching and organizational change leadership. ",
    },
    {
      name: "Nina Gortinski",
      img: NinaImg,
      position: "General Counsel and Attorney",
      linkedin: "https://www.linkedin.com/in/nina-gortinski-116b6014/",
      bio: "Nina Gortinski has over 16 years of proven success growing and developing businesses and books of business. She started her career in entertainment before shifting her focus to become a technical project manager, where she oversaw hundreds of technical and creative projects. She has hands-on experience with all aspects of digital marketing, is a California state-licensed attorney, and has worked with many Fortune 500 companies. Nina was responsible for driving overall Global Partner success at Yahoo, where she consistently grew an $80+ million book of business. She then served as Director of Partnerships and Programs Success and Corporate Counsel for a cutting-edge Silicon Valley tuition reimbursement start-up. Since 2017, she had been running legal affairs for a production company (2017-2024) and serving as corporate or commercial counsel for several startups and publicly traded companies (2021-Present).",
    },
  ];

  const [containerWidth, setContainerWidth] = useState(0);
  const [numImagesToShow, setNumImagesToShow] = useState(0);

  useEffect(() => {
    animateText();
  }, []);
  useEffect(() => {
    const calculateNumImages = () => {
      const container = document.getElementById("foundersList");
      if (container) {
        const width = container.clientWidth;
        const numImages = Math.floor(width / (imageWidth + gap));
        setContainerWidth(width);
        setNumImagesToShow(numImages);
      }
    };

    calculateNumImages();

    window.addEventListener("resize", calculateNumImages);

    return () => {
      window.removeEventListener("resize", calculateNumImages);
    };
  }, [imageWidth, gap]);

  const scrollToNext = () => {
    setFirstIndex((prevFirstIndex) =>
      Math.min(prevFirstIndex + 1, founders.length - numImagesToShow)
    );
  };

  const scrollToPrevious = () => {
    setFirstIndex((prevFirstIndex) => Math.max(prevFirstIndex - 1, 0));
  };

  const sponsors = [sponsor1, sponsor2, sponsor3, sponsor4, sponsor5, sponsor6];

  const duplicateSponsors = [...sponsors, ...sponsors];

  const handleredirectToCareers = () => {
    window.open("https://wellfound.com/company/talkdei/jobs", "_blank");
  };

  const scrollToNextAdvisor = () => {
    setFirstAdvIndex((prevFirstIndex) =>
      Math.min(prevFirstIndex + 1, advisors.length - numImagesToShow)
    );
  };

  const scrollToPreviousAdvisor = () => {
    setFirstAdvIndex((prevFirstIndex) => Math.max(prevFirstIndex - 1, 0));
  };

  const handleRedirectToLinkedIn = (url) => {
    console.log(url, "here");
    window.open(url, "_blank");
  };

  const handleMouseEnter = (index) => {
    console.log(index);
    setHoveredFounderIndex(index);
  };

  const handleMouseOut = () => {
    setHoveredFounderIndex(null);
  };

  const handleMouseEnterAdvisors = (index) => {
    console.log("INDEX", index);
    setHoveredAdvisorIndex(index);
  };

  const handleMouseOutAdvisors = () => {
    setHoveredAdvisorIndex(null);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Navbar company={true} outsideOfLandingPage={true} isMobile={isMobile} />

      <div className="company-main-container">
        <div className="company-container">
          <div style={{ position: "relative" }}></div>
          <Image
            src={CompanyBackgroundImg}
            className="company-background-image"
            style={{
              position: "absolute",
            }}
            fluid
          />
          <h1 className="company-page-title">
            Revolutionizing Supplier Diversity and Inclusion 
          </h1>
        </div>

        <div
          className="company-about-container"
          style={{
            display: "flex",
            flexDirection: "column",
            background: "#fff",
            height: isMobile ? "783px" : "1868px",
          }}
        >
          <p
            style={{
              color: "#0E8080",
              fontSize: "16px",
              textAlign: "center",
              fontFamily: "lato",
            }}
          >
            About TalkDEI
          </p>

          <div
            className="animated"
            style={{
              color: "#8E8E8E",
              fontSize: isMobile ? "25px" : "64px",
              textAlign: "center",
              fontFamily: "Hanken Grotesk",
              fontWeight: "700",
              lineHeight: isMobile ? "28px" : "72px",
              width: "932px",
              marginTop: "35px",
            }}
          >
            <div>TalkDEI is dedicated to driving</div>
            <div>social and economic impact </div>
            <div>through streamlined supplier</div>
            <div> sourcing, enhancing diversity,</div>
            <div> equity, and inclusion (DEI) </div>
            <div>efforts in procurement. Our </div>
            <div> platform provides a haven for</div>
            <div> diverse suppliers, </div>
            <div>empowering them to</div>
            <div> showcase their businesses to </div>
            <div>enterprises, adopt best</div>
            <div> practices, and foster</div>
            <div>collaborative partnerships for</div>
            <div> a better future.</div>
          </div>

          <p
            className="company-fouded"
            style={{
              color: "#515151",
              fontSize: isMobile ? "12px" : "16px",
              textAlign: "center",
              fontFamily: "Hanken Grotesk",
              width: isMobile ? "386px" : "794px",
              fontWeight: "400",
              marginTop: "74px",
              lineHeight: isMobile ? "16px" : "normal",
            }}
          >
            Founded in 2019, TalkDEI emerged at the forefront of the DEI
            movement, anticipating the transformative power of diversity long
            before it became a mainstream topic. In a landscape where DEI
            initiatives have become essential, we remain committed to supporting
            underrepresented businesses and diverse vendors. For companies,
            TalkDEI eliminates the complexities of data management and vendor
            discovery, serving as the ultimate one-stop solution. We aspire to
            be your strategic partner, enhancing efficiency and impact in
            supplier diversity strategy, making it clear that DEI in procurement
            is not just a desire but a necessity for driving bottom-line
            results. 
          </p>
          <Image
            src={background}
            className="background-image-terms"
            style={{ opacity: "0.3" }}
            fluid
          />
          <div className="text-content-terms"></div>
        </div>
      </div>
      <div
        ref={containerBlueRef}
        id="blue-animated-container"
        className="blue-animated-container"
        style={{
          position: "relative",
          width: "100%",
          height: isMobile ? "272px" : "794px",
        }}
      >
        <Image
          className="background-cubes-img"
          src={patterns[index]}
          alt=""
          height={isMobile ? "272px" : "794px"}
          width="100%"
          style={{
            position: "absolute",
            zIndex: 1,
            opacity: 0.7,
            objectFit: "cover",
            transformOrigin: "center center",
          }}
        />
        ;<span style={{ zIndex: "2" }}>We are a diverse owned business</span>
      </div>
      {/* </div> */}
      <div className="founders-container">
        <div className="arrows-container">
          <span>Founders</span>
          <div style={{ display: "flex" }}>
            <Image
              src={arrowLeft}
              width={isMobile ? "32px" : ""}
              height={isMobile ? "32px" : ""}
              onClick={scrollToPrevious}
            />
            <Image
              src={arrowRight}
              width={isMobile ? "32px" : ""}
              height={isMobile ? "32px" : ""}
              onClick={scrollToNext}
            />
          </div>
        </div>
        <div
          className="founders-list"
          ref={foundersListRef}
          id="foundersList"
          style={{ display: "flex", gap: "50px" }}
        >
          {founders
            .slice(firstIndex, firstIndex + numImagesToShow + 1)
            .map((founder, index) => {
              if (index === numImagesToShow) {
                const fractionalWidth =
                  (numImagesToShow + 1 - numImagesToShow) * imageWidth;
                return (
                  <div
                    key={index}
                    className="founder-info-container"
                    style={{ minWidth: `${fractionalWidth}px` }}
                  >
                    <Image
                      src={founder.img}
                      width={isMobile ? 197 : isSmallLaptop ? 367 : 451}
                      height={isMobile ? 221 : isSmallLaptop ? 412 : 506}
                      // width={isMobile ? "197px" : "451px"}
                      // height={isMobile ? "221px" : "506px"}
                    />
                    <div className="founder-info-container-details">
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <h2>{founder.name}</h2>
                        <h4>{founder.position}</h4>
                      </div>
                      <Image
                        src={linkedInIcon}
                        width={isMobile ? "14px" : "32px"}
                        height={isMobile ? "14px" : "32px"}
                        onClick={() =>
                          handleRedirectToLinkedIn(founder.linkedin)
                        }
                        alt="LinkedIn Icon"
                      />
                    </div>
                  </div>
                );
              }
              return (
                <div
                  key={index}
                  className="founder-info-container"
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseOut}
                  style={{
                    minWidth: isMobile ? 208 : isSmallLaptop ? 367 : 451,
                    marginBottom: isMobile ? "30px" : "",
                  }}
                >
                  <div
                  // onMouseEnter={() => handleMouseEnter(index)}
                  // onMouseLeave={handleMouseOut}
                  >
                    <Image
                      src={founder.img}
                      width={isMobile ? 197 : isSmallLaptop ? 367 : 451}
                      height={isMobile ? 221 : isSmallLaptop ? 412 : 506}
                      // width={isMobile ? "197px" : "451px"}
                      // height={isMobile ? "221px" : "506px"}
                    />
                    <div className="founder-info-container-details">
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <h2>{founder.name}</h2>
                        <h4>{founder.position}</h4>
                      </div>
                      <Image
                        src={linkedInIcon}
                        width={isMobile ? "14px" : "32px"}
                        height={isMobile ? "14px" : "32px"}
                        onClick={() =>
                          handleRedirectToLinkedIn(founder.linkedin)
                        }
                        alt="LinkedIn Icon"
                      />
                    </div>
                  </div>
                  <div
                    className="founder-bio"
                    style={{
                      transform:
                        hoveredFounderIndex === index
                          ? "translateY(50%)"
                          : "translateY(100%)",
                      opacity: hoveredFounderIndex === index ? 1 : 0,
                      transition: "transform 0.5s ease, opacity 0.5s ease",
                    }}
                  >
                    <div className="founder-info-container-details">
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <h2>{founder.name}</h2>
                        <h4>{founder.position}</h4>
                      </div>
                      <Image
                        src={linkedInIcon}
                        width={isMobile ? "14px" : "32px"}
                        height={isMobile ? "14px" : "32px"}
                        onClick={() =>
                          handleRedirectToLinkedIn(founder.linkedin)
                        }
                      />
                    </div>
                    <div style={{ marginTop: isMobile ? "10px" : "50px" }}>
                      {founder.bio}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <div className="founders-container">
        {isMobile ? (
          <div className="arrows-container">
            <span>Advisors</span>
            <div style={{ display: "flex" }}>
              <Image
                src={arrowLeft}
                width={isMobile ? "32px" : ""}
                height={isMobile ? "32px" : ""}
                onClick={scrollToPreviousAdvisor}
              />
              <Image
                src={arrowRight}
                width={isMobile ? "32px" : ""}
                height={isMobile ? "32px" : ""}
                onClick={scrollToNextAdvisor}
              />
            </div>
          </div>
        ) : (
          <div className="arrows-container">
            <span>Advisors</span>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "84px",
            marginTop: isMobile ? "20px" : "97px",
          }}
        >
          <div
            className="founders-list"
            ref={foundersListRef}
            id="foundersList"
            style={{ display: "flex", gap: "50px" }}
          >
            {advisors
              .slice(firstAdvIndex, firstAdvIndex + numImagesToShow + 1)

              .map((advisor, index) => (
                <div
                  key={index}
                  className="founder-info-container"
                  onMouseEnter={() => handleMouseEnterAdvisors(index)}
                  onMouseLeave={handleMouseOutAdvisors}
                  style={{
                    minWidth: isMobile ? 208 : isSmallLaptop ? 367 : 451,
                  }}
                >
                  <div
                  // onMouseEnter={() => handleMouseEnterAdvisors(index)}
                  // onMouseLeave={handleMouseOutAdvisors}
                  >
                    <Image
                      src={advisor.img}
                      width={isMobile ? 197 : isSmallLaptop ? 367 : 451}
                      height={isMobile ? 221 : isSmallLaptop ? 412 : 506}
                      // width={isMobile ? "197px" : "451px"}
                      // height={isMobile ? "221px" : "506px"}
                    />
                    <div className="founder-info-container-details">
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <h2>{advisor.name}</h2>
                        <h4
                          style={{
                            width:
                              advisor.name === "Nina Gortinski" ? "274px" : "",
                            whiteSpace:
                              advisor.name === "Nina Gortinski" ? "nowrap" : "",
                          }}
                        >
                          {advisor.position}
                        </h4>
                      </div>
                      <Image
                        src={linkedInIcon}
                        width={isMobile ? "14px" : "32px"}
                        height={isMobile ? "14px" : "32px"}
                        onClick={() =>
                          handleRedirectToLinkedIn(advisor.linkedin)
                        }
                        alt="LinkedIn Icon"
                      />
                    </div>
                  </div>
                  <div
                    className="founder-bio"
                    style={{
                      transform:
                        hoveredAdvisorIndex === index
                          ? "translateY(50%)"
                          : "translateY(100%)",
                      opacity: hoveredAdvisorIndex === index ? 1 : 0,
                      transition: "transform 0.5s ease, opacity 0.5s ease",
                    }}
                  >
                    <div className="founder-info-container-details">
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <h2>{advisor.name}</h2>
                        <h4
                          style={{
                            width:
                              advisor.name === "Nina Gortinski" ? "274px" : "",
                            whiteSpace:
                              advisor.name === "Nina Gortinski" ? "nowrap" : "",
                          }}
                        >
                          {advisor.position}
                        </h4>
                      </div>
                      <Image
                        src={linkedInIcon}
                        width={isMobile ? "14px" : "32px"}
                        height={isMobile ? "14px" : "32px"}
                        onClick={() =>
                          handleRedirectToLinkedIn(advisor.linkedin)
                        }
                      />
                    </div>
                    <div style={{ marginTop: isMobile ? "10px" : "50px" }}>
                      {advisor.bio}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: isMobile ? "150px" : "300px",
        }}
      >
        <span style={{ color: "#565656", fontSize: "16px", fontWeight: "700" }}>
          With help from
        </span>
        <div className="sponsor-container">
          <div className="sponsor-slider" ref={sliderRef}>
            {duplicateSponsors.map((sponsor, index) => (
              <img
                key={index}
                src={sponsor}
                alt={`Sponsor ${index + 1}`}
                className="sponsor-image"
              />
            ))}
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          marginBottom: isMobile ? "100px" : "300px",
        }}
      >
        <span
          style={{
            color: "#000",
            fontFamily: "Hanken Grotesk",
            fontSize: isMobile ? 30 : isSmallLaptop ? 52 : 64,
            fontWeight: "700",
          }}
        >
          Want to join TalkDEI?
        </span>
        <button
          onClick={handleredirectToCareers}
          style={{
            display: "flex",
            width: "149.866px",
            height: isMobile ? "44px" : "55.764px",
            padding: isMobile ? "6px" : "8.713px",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            borderRadius: isMobile ? "27px" : "37.466px",
            background: "#0E8080",
            border: "none",
          }}
        >
          View Careers
        </button>
      </div>

      <Footer button={true} height={true} isMobile={isMobile} />
    </div>
  );
};

export default Company;
