import React, { useState, useRef, useEffect } from "react";
import { Container, Image, Row, Col } from "react-bootstrap";
import dotsMenu from "../../../../assets/verticalDotsIcon.svg";
import PaginationBar from "../../../PaginationBar/PaginationBar";
import search from "../../../../assets/searchWhiteIcon.svg";
import searchGrey from "../../../../assets/search.svg";
import PdfImportButton from "../Marketplace/PdfImportButton";
import deleteIcon from "../../../../assets/deleteIcon.svg";
import deleteIconRed from "../../../../assets/trashIconRed.svg";
import requestInfo from "../../../../assets/requestInfo.svg";
import editIcon from "../../../../assets/editIcon.svg";
import UserProfileComponent from "../../../SellerProfile/UserProfileComponent";
import Overlay from "../../../Overlay/Overlay";
import profileCreatedCheck from "../../../../assets/profileCreaytedCheck.svg";
import eyeIcon from "../../../../assets/eyeIcon.svg";
import CompanyAvatar from "../../../CompanyAvatar";
import { v4 as uuidv4 } from "uuid";

const CompanyTable = ({
  suppliers,
  handleEdit,
  isSavedSuppliers,
  pendingSuppliers,
  isTierOneActive,
  tierOneSuppliers,
  tierTwoSuppliers,
  isTierTwoActive,
  isAllSuppliersActive,
  setTierOneActive,
  setTierTwoActive,
  setAllSuppliersActive,
  totalSuppliers
}) => {
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const dotsMenuRef = useRef();
  const deleteWindowRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [visibleListOfSuppliers, setVisibleListOfSuppliers] = useState([]);
  const [menuVisibility, setMenuVisibility] = useState({});
  const [isDotsMenuOpen, setDotsMenuOpen] = useState(false);
  const [supplierToDelete, setSupplierToDelete] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(null);
  const [message, setMessage] = useState("");
  const [showRequestConfirmation, setShowRequestConfirmation] = useState(false);
  const [suppliersData, setSuppliersData] = useState(suppliers);
  const [isDeletedSupplier, setDeletedSupplier] = useState(false);
  const [isMultipleMenuClicked, setMultipleMenuClicked] = useState(false);
  const [isPendingOpen, setPendingOpen] = useState(false);
  const [isCheckboxVisible, setCheckboxVisible] = useState(false);
  const [isSelect, setSelect] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [isSelectionMenuClicked, setSelectionMenuClicked] = useState(false);

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handleDone = () => {
    setDeletedSupplier(false);
  }

  const extractTextBetweenParentheses = (text) => {
    const startIndex = text.indexOf("(") + 1;
    const endIndex = text.indexOf(")");

    const lastSpaceIndex = text.lastIndexOf(" ", endIndex);
    return text.substring(startIndex, lastSpaceIndex);
  };

  const handleMultipleMenu = () => {
    setMultipleMenuClicked(!isMultipleMenuClicked);
    setCheckboxVisible(!isCheckboxVisible);

    if ((isSelect || selectAll) && selectedSuppliers.length > 0) {
      setCheckboxVisible(true);
      setMultipleMenuClicked(false);
      setSelectionMenuClicked(!isSelectionMenuClicked);
    }
  };
  const handleSelect = () => {
    setSelect(true);
  };

  const handleClearSelection = () => {
    setSelectedSuppliers([]);
    setSelectionMenuClicked(false);
    setCheckboxVisible(false);
  };

  const handleDeleteSelection = () => {
    const updatedVisibleListOfSuppliers = suppliersData.filter(
      (supplier) =>
        !selectedSuppliers.some(
          (selectedSupplier) => selectedSupplier.id === supplier.id
        )
    );

    setSelectedSuppliers([]);
    setSelectionMenuClicked(false);
    setCheckboxVisible(false);

    setSuppliersData(updatedVisibleListOfSuppliers);
  };

  const handleSendInvite = () => {
    console.log("Invite sent");
    setSelectionMenuClicked(false);
    setCheckboxVisible(false);
  };

  const handleRequestInfo = () => {
    console.log("Redirects to collaboration tool");
    setSelectionMenuClicked(false);
    setCheckboxVisible(false);
    setShowRequestConfirmation(true);
  };

  useEffect(() => {
    if ((isSelect || selectAll) && selectedSuppliers.length > 0) {
      setSelectionMenuClicked(true);
      setMultipleMenuClicked(false);
    }
    setSelectionMenuClicked(false);
  }, [isSelect, selectAll, selectedSuppliers]);

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      const allSuppliers = visibleListOfSuppliers;
      setSelectedSuppliers(allSuppliers);
    } else {
      setSelectedSuppliers([]);
    }
  };

  const handleSelectMenuClick = (company) => {
    if (isSelect) {
      const existingIndex = selectedSuppliers.findIndex(
        (supplier) => supplier.id === company.id
      );

      if (existingIndex !== -1) {
        // Item is already selected, remove it
        const updatedSuppliers = [...selectedSuppliers];
        updatedSuppliers.splice(existingIndex, 1);
        setSelectedSuppliers(updatedSuppliers);
      } else {
        // Item is not selected, add it
        const newSupplier = {
          id: company.id,
          name: company.name,
          email: company.email,
        };
        setSelectedSuppliers([...selectedSuppliers, newSupplier]);
      }
    }
  };

  const handleRequestSubmission = (company) => {
    setShowRequestConfirmation(true);
    toggleMenu(company.id);
    console.log("Submitting message:", message);

    setMessage("");
  };

  const handleRequestConfirmation = () => {
    setShowRequestConfirmation(false);
    console.log("Submitting message:", message);
  };

  const handleDeleteClick = (supplier) => {
    setShowConfirmation(true);
    setSupplierToDelete(supplier);
    toggleMenu(supplier.id);
  };

  const handleConfirmDelete = () => {
    setVisibleListOfSuppliers((prevSuppliers) =>
      prevSuppliers.filter((supplier) => supplier.id !== supplierToDelete.id)
    );
    setSupplierToDelete(null);
    setShowConfirmation(false);
    setDeletedSupplier(true);
  };

  const handleCancelDelete = () => {
    setSupplierToDelete(null);
    setShowConfirmation(false);
  };

  const handleCancelRequest = () => {
    setShowRequestConfirmation(false);
  };

  const handleCloseUserProfile = () => {
    setSelectedCard(false);
    setIsUserProfileOpen(false);
    setIsClosed(true);
  };
  const handleCardSelection = (supplier) => {
    if (isClosed) {
      setSelectedCard(false);
      setIsUserProfileOpen(false);
      setIsClosed(false);
      return;
    }
    setSelectedCard(true);
    setSelectedSupplier(supplier);
    setIsUserProfileOpen(true);
  };
  const handleSelectUserProfile = (event, supplier) => {
    handleCardSelection(supplier);
  };

  const toggleMenu = (supplierId) => {
    setDotsMenuOpen(!isDotsMenuOpen);

    setMenuVisibility((prevState) => ({
      ...prevState,
      [supplierId]: !prevState[supplierId],
    }));
  };

  const handleEditClick = (company) => {
    handleEdit(company);
    toggleMenu(company.id);
  };

  const handleViewPending = () => {
    setCheckboxVisible(false);
    setMultipleMenuClicked(!isMultipleMenuClicked);
    setPendingOpen(true);
    const mappedPendingSuppliers = pendingSuppliers.flat();
    setSuppliersData([...mappedPendingSuppliers]);
  };


  useEffect(() => {
    if (isTierOneActive) {

      setSuppliersData([...tierOneSuppliers]);
      setTierTwoActive(false);
      setAllSuppliersActive(false);
      setPendingOpen(false);
      
    }
  }, [isTierOneActive, tierOneSuppliers]);

  useEffect(() => {
    if (isTierTwoActive) {
      setSuppliersData([...tierTwoSuppliers]);
      setTierOneActive(false);
      setAllSuppliersActive(false);
      setPendingOpen(false);
    }
  }, [isTierTwoActive, tierTwoSuppliers]);

  useEffect(() => {
    if (isAllSuppliersActive) {
      setSuppliersData(suppliers);
      setTierOneActive(false);
      setTierTwoActive(false);
      setPendingOpen(false);
    }
  }, [isAllSuppliersActive]);

  const handleViewAll = () => {
    setSuppliersData(suppliers);
    setCheckboxVisible(false);
    setMultipleMenuClicked(false);
    setPendingOpen(false);
  };

  const itemsPerPage = 6;
  const totalItems = suppliersData.length;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const visibleList = suppliersData.slice(startIndex, endIndex);
    setVisibleListOfSuppliers(visibleList);
  }, [suppliersData, currentPage, itemsPerPage]);

  const handleClickOutside = (event) => {
    if (dotsMenuRef.current && !dotsMenuRef.current.contains(event.target)) {
      setDotsMenuOpen(false);
    }
    if (
      deleteWindowRef.current &&
      !deleteWindowRef.current.contains(event.target)
    ) {
      setDeletedSupplier(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dotsMenuRef]);

  const overlayStyle = {
    background: "rgba(0, 0, 0, 0.1)",
  };

  const divFlexRow = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };

  const paginationBarStyle = {
    position: "absolute",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    marginBottom: "20px",
    marginTop: "40px",
    padding: "10px",
    background: "transparent",
    bottom: 0,
    boxShadow: "none",
  };


  return (
    <Container
      fluid
      style={{
        margin: "10px 20px 0 10px",
        background: "#fff",
        borderRadius: "13px",
      }}
    >
      <div>
        <Row>
          <Col>
            <div className="filter-container">
              <div style={{ marginLeft: "31px", display: "flex" }}>
                <input
                  type="text"
                  placeholder="Input your prompt to match your search"
                  className="supplier-search-input"
                  aria-label="Search"
                  style={{
                    backgroundImage: `url(${searchGrey})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "left 10px center",
                    color: "#A8A8A8",
                    marginRight:"10px",
                    fontStyle: "italic",
                    fontWeight: "400",
                  }}
                />

                <button
                  type="submit"
                  className="filter-search-button"
                  style={{
                    backgroundImage: `url(${search})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "left 5px center",
                    paddingRight:0,
                    color: "#fff",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                  // onClick={handleSearch}
                >
                  Search
                </button>

                <PdfImportButton />
              </div>
              <div>
                <Row className=" justify-content-end">
                  <Col className="view-icons">
                    <Image
                      src={dotsMenu}
                      alt=""
                      style={{ marginRight: "55px", position: "relative" }}
                      onClick={() => handleMultipleMenu()}
                    />
                  </Col>
                </Row>
              </div>

              {isMultipleMenuClicked && (
                <div
                  className="dots-menu"
                  style={{
                    position: "absolute",
                    height: "173px",
                    marginTop: "35px",
                  }}
                >
                  <span onClick={() => handleSelect()} style={divFlexRow}>
                    <Image
                      src={editIcon}
                      alt=""
                      style={{ paddingRight: "10px" }}
                    ></Image>
                    Select
                  </span>
                  <span
                    onClick={() => handleSelectAllChange()}
                    style={divFlexRow}
                  >
                    <Image
                      src={editIcon}
                      alt=""
                      style={{ paddingRight: "10px" }}
                    ></Image>
                    Select all
                  </span>
                  {isPendingOpen ? (
                    <span onClick={() => handleViewAll()} style={divFlexRow}>
                      <Image
                        src={eyeIcon}
                        alt=""
                        style={{ paddingRight: "10px" }}
                      ></Image>
                      View All
                    </span>
                  ) : (
                    <span
                      onClick={() => handleViewPending()}
                      style={divFlexRow}
                    >
                      <Image
                        src={eyeIcon}
                        alt=""
                        style={{ paddingRight: "10px" }}
                      ></Image>
                      View Pending
                    </span>
                  )}
                </div>
              )}

              {isSelectionMenuClicked && (
                <div
                  className="dots-menu"
                  style={{
                    position: "absolute",
                    height: "220px",
                    marginTop: "35px",
                  }}
                >
                  <span
                    onClick={() => handleClearSelection()}
                    style={divFlexRow}
                  >
                    <Image
                      src={editIcon}
                      alt=""
                      style={{ paddingRight: "10px" }}
                    ></Image>
                    Clear Selection
                  </span>
                  <span onClick={() => handleSendInvite()} style={divFlexRow}>
                    <Image
                      src={editIcon}
                      alt=""
                      style={{ paddingRight: "10px" }}
                    ></Image>
                    Send invite
                  </span>
                  <span onClick={() => handleRequestInfo(selectedSuppliers)} style={divFlexRow}>
                    <Image
                      src={editIcon}
                      alt=""
                      style={{ paddingRight: "10px" }}
                    ></Image>
                    Request info
                  </span>
                  <span
                    onClick={() => handleDeleteSelection()}
                    style={divFlexRow}
                  >
                    <Image
                      src={editIcon}
                      alt=""
                      style={{ paddingRight: "10px" }}
                    ></Image>
                    Delete selection
                  </span>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>

      <div className="suppliers-table">
        <div className="table-header">
          <div className="column">Company Name</div>
          <div className="column">Description</div>
          <div className="column">Employees</div>
          <div className="column">Registration Status</div>
          <div className="column">Year founded</div>
          <div className="column">Diversity Certification</div>
          <div className="column">Diversity Status</div>
          <div className="column">Action</div>
        </div>
        <div className="table-body">
          {visibleListOfSuppliers.map((company, index) => (
            <div
              key={company.id}
              className="table-row"
              style={{ borderBottom: "1px solid #F6F6F6" }}
            >
              <>
                <div
                  className="column"
                  onClick={(e) => {
                    if (!isCheckboxVisible) {
                      handleSelectUserProfile(e, company);
                    }
                  }}
                  style={{ padding: "20px 5px 8px 20px" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {isCheckboxVisible && (
                      <input
                        type="checkbox"
                        onChange={() => {
                          if (isSelect) {
                            handleSelectMenuClick(company);
                          } else if (selectAll) {
                            handleSelectAllChange();
                          }
                        }}
                        checked={selectedSuppliers.some(
                          (supplier) => supplier.id === company.id
                        )}
                        style={{
                          marginRight: "10px",
                          accentColor: "#7B7B7B",
                          boxShadow:
                            "0px 0px 9.1px 0px rgba(0, 0, 0, 0.11) #DADADA",
                        }}
                      />
                    )}
                    {company.company.companyProfilePicture ? (
                      <img
                        src={company.company.companyProfilePicture}
                        alt=""
                        style={{
                          width: "38px",
                          height: "38px",
                          marginRight: "10px",
                        }}
                      />
                    ) : (
                      <div style={{ marginRight: "10px" }}>
                        <CompanyAvatar
                          companyName={company.company.name}
                          size="38px"
                        />
                      </div>
                    )}

                    <div>
                      <div style={{ fontWeight: 600 }}>{company.company.name}</div>
                      <div>
                        <p style={{ textDecoration: "none", color: "#7B7B7B" }}>
                          {company.company.companyWebsite}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column">
                  {company.company.industry || <span className="pending">Pending</span>}
                </div>
                <div className="column">
                  {extractTextBetweenParentheses(company.company.size) || (
                    <span className="pending">Pending</span>
                  )}
                </div>
                <div className="column">
                  {company.company.registrationStatus || (
                    <span className="pending">Pending</span>
                  )}
                </div>
                <div className="column">
                  {company.company.yearFounded || (
                    <span className="pending">Pending</span>
                  )}
                </div>
                <div className="column">
                  {company.supplierProfile.certificates[0] || (
                    <span className="pending">Pending</span>
                  )}
                </div>
                <div className="column">
                  {company.supplierProfile.diversity.diversityStatus || (
                    <span className="pending">Pending</span>
                  )}
                </div>

                <div className="column">
                  <Image
                    src={dotsMenu}
                    alt=""
                    style={{ paddingLeft: "20px", position: "relative" }}
                    onClick={() => toggleMenu(company.id)}
                  ></Image>
                  {menuVisibility[company.id] && isDotsMenuOpen && (
                    <div
                      className="dots-menu"
                      style={{
                        position: "absolute",
                        height: isSavedSuppliers ? "118px" : "163px",
                      }}
                    >
                      {isSavedSuppliers ? null : (
                        <span
                          onClick={() => handleEditClick(company)}
                          style={divFlexRow}
                        >
                          <Image
                            src={editIcon}
                            alt=""
                            style={{ paddingRight: "10px" }}
                          ></Image>
                          Edit Profile
                        </span>
                      )}
                      <span
                        style={divFlexRow}
                        onClick={() => handleRequestSubmission(company)}
                      >
                        <Image
                          src={requestInfo}
                          alt=""
                          style={{ paddingRight: "10px" }}
                        ></Image>
                        Request Info
                      </span>
                      <span
                        style={divFlexRow}
                        onClick={() => handleDeleteClick(company)}
                      >
                        <Image
                          src={deleteIcon}
                          alt=""
                          style={{ paddingRight: "10px" }}
                        ></Image>
                        Delete
                      </span>
                    </div>
                  )}
                </div>
              </>

              {showConfirmation && (
                <>
                  <Overlay overlayStyle={overlayStyle} />
                  <div className="delete-confirm-popup">
                    <div className="delete-preview-content">
                      <Image
                        src={deleteIconRed}
                        alt="Delete icon"
                        width="27px"
                        height="27px"
                        style={{
                          marginBottom: "20px",
                          marginLeft: "10px",
                          borderRadius: "36px",
                          padding: "5px",
                          background: "#F6F6F6",
                        }}
                      />
                      <p style={{ fontWeight: "600", textAlign: "left" }}>
                        Are you sure you want to delete this supplier profile?
                      </p>
                      <p style={{ color: "#7B7B7B", textAlign: "left" }}>
                        Deleted profiles can not be recovered and you will have
                        to create them again
                      </p>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <button
                          className="close-preview-btn"
                          onClick={handleCancelDelete}
                          style={{
                            marginRight: "10px",
                            border: "0.949px solid #CDCDCD",
                            background: "#FFF",
                            color: "#7B7B7B",
                            marginTop: "20px",
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="close-preview-btn"
                          style={{ marginTop: "20px", background:"#FF0000", color:"#fff", border:"none" }}
                          onClick={() => handleConfirmDelete(company.id)}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {showRequestConfirmation && (
                <>
                  <Overlay overlayStyle={overlayStyle} />
                  <div className="delete-confirm-popup">
                    <div className="request-preview-content">
                      <Image
                        src={requestInfo}
                        alt="Delete icon"
                        width="27px"
                        height="27px"
                        style={{
                          marginBottom: "20px",
                          marginLeft: "10px",
                          borderRadius: "36px",
                          padding: "5px",
                          background: "#F6F6F6",
                        }}
                      />
                      <p style={{ fontWeight: "600", textAlign: "left" }}>
                        What info do you want to request?
                      </p>
                      <textarea
                        className="request-info-textarea"
                        value={message}
                        onChange={handleChange}
                        placeholder="Type your message here..."
                      ></textarea>
                      <div
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <button
                          className="close-preview-btn"
                          onClick={handleCancelRequest}
                          style={{
                            marginRight: "10px",
                            border: "0.949px solid #CDCDCD",
                            background: "#FFF",
                            color: "#7B7B7B",
                            marginTop: "20px",
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          className="close-preview-btn"
                          style={{ marginTop: "20px" }}
                          onClick={() => handleRequestConfirmation()}
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
        </div>

        {isDeletedSupplier && (
          <>
            <Overlay />
            <div
              ref={deleteWindowRef}
              className="supplier-created-container"
              style={{ top: "30%" }}
            >
              <Image
                src={profileCreatedCheck}
                alt=""
                style={{ marginBottom: "22px" }}
              />
              <span>Supplier profile deleted</span>
              <button className="done-btn" onClick={() => handleDone()}>
                Done
              </button>
            </div>
          </>
        )}

        {isUserProfileOpen && <Overlay />}
        {selectedCard && (
          <div
            className="additional-bar"
            style={{
              width: "500px",
              position: "fixed",
              right: 0,
              top: 0,
              height: "100vh",
              background: "white",
              zIndex: 999,
            }}
          >
            <UserProfileComponent
              supplier={selectedSupplier}
              onClose={handleCloseUserProfile}
              backUrl="/buyer-dashboard/management"
              management={true}
            />
          </div>
        )}

        {suppliersData.length > 0 && (
          <PaginationBar
            currentPage={currentPage}
            totalPages={Math.ceil(totalItems / itemsPerPage)}
            handlePageChange={handlePageChange}
            paginationBarStyle={paginationBarStyle}
          />
        )}
      </div>
    </Container>
  );
};

export default CompanyTable;
