import React from "react";
import arrowBack from "../../assets/arrowBackBlack.svg";
import arrowNext from "../../assets/arrowNext.svg";

const PaginationBar = ({ currentPage, totalPages, handlePageChange, paginationBarStyle }) => {
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 10; // Maximum number of visible page numbers
    const maxPagesToShow = Math.min(maxVisiblePages, totalPages);

    // Helper function to render ellipses
    const renderEllipses = () => {
      return <span key="ellipsis">...</span>;
    };

    // Function to render page numbers between ellipses
    const renderPageRange = (start, end) => {
      const range = [];
      for (let i = start; i <= end; i++) {
        range.push(
          <span
            key={i}
            className={`pagination-number ${i === currentPage ? "disabled" : "active"}`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </span>
        );
      }
      return range;
    };

    if (totalPages <= maxVisiblePages) {
      pageNumbers.push(...renderPageRange(1, totalPages));
    } else {
      if (currentPage <= 4) {
        pageNumbers.push(...renderPageRange(1, maxPagesToShow));
        pageNumbers.push(renderEllipses());
      } else if (currentPage >= totalPages - 3) {
        pageNumbers.push(renderEllipses());
        pageNumbers.push(...renderPageRange(totalPages - maxPagesToShow + 1, totalPages));
      } else {
        const start = currentPage - Math.floor(maxVisiblePages / 2);
        const end = currentPage + Math.floor(maxVisiblePages / 2);
        pageNumbers.push(renderEllipses());
        pageNumbers.push(...renderPageRange(start, end));
        pageNumbers.push(renderEllipses());
      }
    }

    return pageNumbers;
  };

  return (
    <div className="pagination-bar" style={paginationBarStyle}>
      {currentPage > 1 && (
        <>
          <span
            className="pagination-arrow"
            onClick={() => handlePageChange(currentPage - 1)}
          >
            <img
              src={arrowBack}
              alt="Arrow Back"
              style={{ marginRight: "10px" }}
            />
            Previous
          </span>
        </>
      )}

      {currentPage === 1 && (
        <span
          className="pagination-arrow"
          disabled
          style={{ color: "#7B7B7B" }}
        >
          <img
            src={arrowBack}
            alt="Arrow Back"
            style={{ marginRight: "10px" }}
          />
          Previous
        </span>
      )}

      {renderPageNumbers()}

      {currentPage < totalPages && (
        <>
          <span
            className="pagination-arrow"
            onClick={() => handlePageChange(currentPage + 1)}
          >
            Next
            <img
              src={arrowNext}
              alt="Arrow Next"
              style={{ marginLeft: "10px" }}
            />
          </span>
        </>
      )}

      {currentPage === totalPages && (
        <span
          className="pagination-arrow"
          disabled
          style={{ color: "#7B7B7B" }}
        >
          Next
          <img
            src={arrowNext}
            alt="Arrow Next"
            style={{ marginLeft: "10px" }}
          />
        </span>
      )}
    </div>
  );
};

export default PaginationBar;
