import DiversityPieChart from "./DiversityPieChart";
import React, { useState, useEffect, useRef } from "react";
import arrowBack from "../../../../assets/arrowBack.svg";
import { useNavigate } from "react-router";
import { Container, Row, Col, Image } from "react-bootstrap";
import BarChart from "./BarChart";
import Suppliers from "./Suppliers";
import SavedSuppliers from "./SavedSuppliers";
import DashboardNavbar from "../../DashboardNavbar";

const Management = () => {
  const [selectedTab, setSelectedTab] = useState("No. of Suppliers");
  const tabs = ["No. of Suppliers", "Saved Suppliers", "Diversity Metrics"];
  const [isSavedSuppliers, setSavedSuppliers] = useState(false);

  const handleTabClick = (tab) => {
    if(tab === "Saved Suppliers" ) {
      setSavedSuppliers(true);
    }
    setSelectedTab(tab);
    
  };

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/buyer-dashboard/overview");
  };
  

  return (
    <div style={{ background: "#f5f5f5", minHeight: "100vh" }}>
      <Container fluid style={{position:"relative"}}>
        <DashboardNavbar
          title={"Supplier Management"}
          handleBack={handleGoBack}
          tabs={tabs}
          selectedTab={selectedTab}
          handleTabClick={handleTabClick}
        />
        {selectedTab === "Saved Suppliers" ? (
          <SavedSuppliers isSavedSuppliers={isSavedSuppliers}/>
        ) : selectedTab === "Diversity Metrics" ? (
          <div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div className="donut-chart">
                <DiversityPieChart />
              </div>
              <div className="donut-chart"></div>
            </div>
            <div className="bar-chart">
              <BarChart />
            </div>
          </div>
        ) : (
          <Suppliers />
        )}
      </Container>
    </div>
  );
};
export default Management;
