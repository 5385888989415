import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Image } from "react-bootstrap";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Draggable } from "gsap/Draggable";
import usersIcon from "../../assets/LandingPage/users 1.png";
import folderIcon from "../../assets/LandingPage/folder  1.png";
import rfpIcon from "../../assets/LandingPage/RFP 3d.png";
import pcIcon from "../../assets/LandingPage/pc 1.png";
import cube3D from "../../assets/LandingPage/Cube mark (1).png";

gsap.registerPlugin(ScrollTrigger, Draggable);
const GradientSection = ({ handleSignUp, isMobile }) => {
  const enterpriseRef = useRef(null);
  const cardsRef = useRef(null);

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    const container = enterpriseRef.current;
    if (!container) return;

    const cube = container.querySelector(".cube");
    const span = container.querySelectorAll("span");
    const button = container.querySelectorAll("button");
    const h1 = container.querySelectorAll("h1");
    const cardWidth = 314;
  const gap = 20;
  const cardTotalWidth = cardWidth + gap;

    const initialGradient = "linear-gradient(0deg, #cc9866 0%, #654198 0%)";
    const finalGradient =
      "linear-gradient(0deg, #cc9866 23.21%, #654198 62.33%)";
      const finalMobile = "linear-gradient(0deg, #CC9866 -31.55%, #651498 94.56%)"

    gsap.fromTo(
      cube,
      { scale: 0.5, opacity: 1 },
      { scale: 1.3, opacity: 0, duration: 1, ease: "power2.out" }
    );

    if(!isMobile) {
    gsap.fromTo(
      container,
      {
        backgroundImage: initialGradient,
        duration: 0.8,
        ease: "power2.out",
      },
      {
        backgroundImage: finalGradient,
        duration: 0.8,
        ease: "power2.out",
      }
    );
  }
    
    if (isMobile) {
      gsap.fromTo(
        container,
        {
          backgroundImage: finalMobile,
          duration: 0.4,
          ease: "power2.out",
        },
        {
          backgroundImage: finalMobile,
          duration: 0.4,
          ease: "power2.out",
        }
      );
      gsap.set(cardsRef.current.children, {
        visibility: "visible",
        opacity: 1,
      });
    
      const numberOfCards = cardsRef.current.children.length + 1.6;
      const containerWidth = numberOfCards * cardTotalWidth;
    
      Draggable.create(cardsRef.current, {
        type: "x",
        bounds: { minX: -(containerWidth - cardWidth), maxX: 0 },
        inertia: true,
        dragResistance: 0.01, 
        ease: "expo.out", 
        snap: {
          x: gsap.utils.snap(cardTotalWidth)
        },
        onDrag: function () {
          console.log("Swiping!", this.x);
        },
        onThrowUpdate: function () {
          console.log("Updating!", this.x);
          gsap.to(cardsRef.current, { x: this.x });
        }
      });
    
      ScrollTrigger.create({
        trigger: container,
        start: "top 50px",
        end: "bottom bottom",
        pin: container,
        scrub: true,
      });
    
    
    } else {



      gsap.from(cardsRef.current.children, {
              x: "100%",
              duration: 0.7,
              ease: "power1.inOut",
            });
            gsap.set(cardsRef.current.children, {
              visibility: "visible",
              opacity: 1,
            });
            gsap.to(".horizontal", {
              xPercent: -100,
              stagger: {
                each: 0.5,
              },
              ease: "power1.inOut",
              scrollTrigger: {
                trigger: container,
                start: isMobile ? "top 50px" : "top -60px",
                end: isMobile ? "+=1000" : "+=2000",
                pin: container,
                scrub:true,
                
              },
            });
    }

    gsap.set(span, { color: "#fff" });
    gsap.set(button, { visibility: "hidden" });
    gsap.set(h1, { visibility: "hidden" });
  }, [isMobile]);
  
  return (
    <div className="enterprise-container" ref={enterpriseRef}>
      <img src={cube3D} className="cube" alt="3D Cube" />
      <div style={{ width: isMobile ? "378px" : "888px", display:"flex", justifyContent:"center", marginTop: isMobile ? "70px" : '' }}>
        <span>
          Empower Your Enterprise with Automated Supplier Diversity Solutions,
          Revolutionizing Sourcing Efforts for a Future of Inclusive Procurement
        </span>
      </div>
      <h1>Upgrade Your Supplier Diversity: Join TalkDEI's Revolution Now!</h1>
      <button onClick={handleSignUp}>Join now</button>

      <div className="horizontal-scoll-wrapper" ref={enterpriseRef}>
        <div className="horizontal" ref={cardsRef}>
          <div className="rolling-card" style={{ width: isMobile ? "314px" : "449px", height: isMobile ? "390px" : '' }}>
            <Image src={rfpIcon} width="212px" height="212px" />
            <span>RFP AI/ML Recommendation Matching</span>
            <p className="card-content">
              {" "}
              Simply upload your RFP document, and our advanced algorithms scan
              the requirements, find the closest matches from our vetted
              supplier diversity profiles, and present you with a curated
              selection of qualified suppliers, saving you time and effort in
              sourcing
            </p>
          </div>
          <div className="rolling-card" style={{ width: isMobile ? "314px" : "449px", height: isMobile ? "390px" : ''}}>
            <Image src={usersIcon} width="212px" height="212px" />
            <span>Marketplace of Diverse Vendors  (Certified/Uncertified)</span>
            <p className="card-content">
              {" "}
              Gain access to TalkDEI's extensive marketplace of diverse vendors,
              comprising both certified and uncertified vetted suppliers. Easily
              browse through a wide range of vendors across various industries
              and specialties, enabling you to diversify your supply chain while
              ensuring compliance and inclusivity
            </p>
          </div>
          <div className="rolling-card" style={{ width: isMobile ? "314px" : "449px", height: isMobile ? "390px" : ''}}>
            <Image src={folderIcon} width="212px" height="212px" />
            <span>Manage Existing Vendors</span>
            <p className="card-content">
              {" "}
              Easily upload and manage existing suppliers, granting them key
              access to the platform to source and manage their services.
            </p>
          </div>
          <div className="rolling-card" style={{ width: isMobile ? "314px" : "449px", height: isMobile ? "390px" : ''}}>
            <Image src={pcIcon} width="212px" height="212px" />
            <span>Communicate Through the Platform</span>
            <p className="card-content">
              {" "}
              No more wasting time on email chains; communicate directly through
              the platform, ensuring instant and efficient communication. Foster
              stronger relationships, address inquiries promptly, and drive
              successful outcomes with streamlined communication.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GradientSection;
