import React, { useState, useRef, useEffect } from "react";
import SignUpContainer from "../SignUpContainer";
import { Col, Form, Image } from "react-bootstrap";
import "./styling.css";
import arrowDown from "../../../assets/arrowdown2.svg";

const InvitationPage = ({
  formDataStep,
  setFormDataStep,
  handleNextStep,
  step,
  skip,
  accountType,
}) => {
  const [isPermissionDropdownOpen, setPermissionDropdownOpen] = useState(false);
  const permissionDropdownRef = useRef();
  const [isEditPermissionDropdownOpen, setEditPermissionDropdownOpen] = useState(false);
  const [invitationEmail, setInvitationEmail] = useState("");
  const [currentPermission, setCurrentPermission] = useState("can view");
  const [menuVisibility, setMenuVisibility] = useState({});
  const [invitedMembers, setInvitedMembers] = useState([]);

  const handleSubmit = (event) => {
    if(event) event.preventDefault();
    handleNextStep();
  };

  const handleSkip = () => {
    handleNextStep();
  };

  const divFlexRow = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };

  const handlePermissionDropdown = () => {
    setPermissionDropdownOpen(!isPermissionDropdownOpen);
  };
  const handleEditPermissionDropdown = (memberId) => {
    setEditPermissionDropdownOpen(!isEditPermissionDropdownOpen);

    setMenuVisibility((prevVisibility) => ({
      ...prevVisibility,
      [memberId]: !prevVisibility[memberId],
    }));
  };
  console.log(menuVisibility, isEditPermissionDropdownOpen);

  const handleSelectPermissionOption = (email, permissionType) => {
    setCurrentPermission(permissionType);
    setPermissionDropdownOpen(false);
  };
  const handleEditPermissionOption = (email, permissionType, memberId) => {
    setEditPermissionDropdownOpen(false);
    setMenuVisibility((prevVisibility) => ({
      ...prevVisibility,
      [memberId]: !prevVisibility[memberId],
    }));
    const updatedInvitedMembers = invitedMembers.map((member) => {
      if (member.emailAddress === email) {
        return { ...member, permission: permissionType };
      }
      return member;
    });

    setFormDataStep({
      ...formDataStep,
      invitedMembers: updatedInvitedMembers,
    });
  };

  const handleSendInvite = () => {
    console.log(
      "Send invitation to email:",
      invitationEmail,
      "with permission:",
      currentPermission
    );
    setInvitedMembers([...invitedMembers, { emailAddress: invitationEmail, permission: currentPermission }, ])
    // setFormDataStep({
    //   ...formDataStep,
    //   invitedMembers: [
    //     ...formDataStep.invitedMembers,
    //     { emailAddress: invitationEmail, permission: currentPermission },
    //   ],
    // });
    setCurrentPermission("");
    setInvitationEmail("");
  };


  const handleClickOutside = (event) => {
    if (
      permissionDropdownRef.current &&
      !permissionDropdownRef.current.contains(event.target)
    ) {
      setPermissionDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <SignUpContainer
      title="Invite team members"
      subtitle={<>Add your team members to the platform</>}
      skip={skip}
      height="995px"
      step={step}
      onContinueClick={handleSubmit}
      handleSkip={handleSkip}
      accountType={accountType}
      buttonBackground={
        invitedMembers.length > 0 ? "#5C0E80" : "rgba(0, 0, 0, 0.30)"
      }
    >
      <Form>
        <Form.Group
          as={Col}
          controlId="companyEmail"
          className="single-input"
          style={{ margin: "0 43px 20px 43px" }}
        >
          <Form.Label className="input-title">Email</Form.Label>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Form.Control
              type="email"
              placeholder="Enter their email"
              value={invitationEmail}
              style={{
                borderRadius: "10px 0 0 10px",
                width: "80%",
              }}
              className="custom-input"
              onChange={(e) => setInvitationEmail(e.target.value)}
            />
            <button
              type="button"
              className="custom-input-permission"
              style={{
                backgroundImage: `url(${arrowDown})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right 10px center",
                paddingRight: "40px",
              }}
              onClick={handlePermissionDropdown}
            >
              {currentPermission || "can view"}
            </button>
          </div>
        </Form.Group>

        {isPermissionDropdownOpen && (
          <div
            ref={permissionDropdownRef}
            className="dots-menu"
            style={{
              position: "absolute",
              height: "135px",
              width: "144px",
              gap: "20px",
              paddingLeft: "20px",
            }}
          >
            <span
              onClick={() =>
                handleSelectPermissionOption(invitationEmail, "can view")
              }
              style={divFlexRow}
            >
              Can view
            </span>
            <span
              onClick={() =>
                handleSelectPermissionOption(invitationEmail, "can edit")
              }
              style={divFlexRow}
            >
              Can edit
            </span>
            <span
              onClick={() =>
                handleSelectPermissionOption(invitationEmail, "admin")
              }
              style={divFlexRow}
            >
              Admin
            </span>
          </div>
        )}

        <Form.Group
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "43px",
          }}
        >
          <button className="send-invite-btn" onClick={handleSendInvite}>
            Send Invite
          </button>
        </Form.Group>
        <Form.Group>
          <span
            style={{
              color: "#FFF",
              marginBottom: "55px",
              display: "flex",
              marginLeft: "43px",
            }}
          >
            Invited members
          </span>
          <div>
            {invitedMembers.length > 0 ? (
              invitedMembers.map((member, index) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "0 43px 20px 43px",
                    position: "relative",
                  }}
                  key={index}
                >
                  <span style={{ color: "#7E7E7E", fontWeight: "500" }}>
                    {member.emailAddress}
                  </span>
                  <span
                    style={{
                      backgroundImage: `url(${arrowDown})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "right center",
                      paddingRight: "40px",
                      color: "#7E7E7E",
                    }}
                    onClick={() => handleEditPermissionDropdown(index)}
                  >
                    {member.permission}
                  </span>

                  {menuVisibility[index] && isEditPermissionDropdownOpen && (
                    <div
                      // ref={permissionEditDropdownRef}
                      className="dots-menu"
                      style={{
                        position: "absolute",
                        height: "135px",
                        width: "144px",
                        gap: "20px",
                        paddingLeft: "20px",
                        marginTop: "30px",
                      }}
                    >
                      <span
                        onClick={() =>
                          handleEditPermissionOption(
                            member.emailAddress,
                            "can view",
                            index
                          )
                        }
                        style={divFlexRow}
                      >
                        Can view
                      </span>
                      <span
                        onClick={() =>
                          handleEditPermissionOption(
                            member.emailAddress,
                            "can edit",
                            index
                          )
                        }
                        style={divFlexRow}
                      >
                        Can edit
                      </span>
                      <span
                        onClick={() =>
                          handleEditPermissionOption(
                            member.emailAddress,
                            "admin",
                            index
                          )
                        }
                        style={divFlexRow}
                      >
                        Admin
                      </span>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <span style={{ color: "#FFF", fontWeight: "500" }}>
                No one has been invited yet
              </span>
            )}
          </div>
        </Form.Group>
      </Form>
    </SignUpContainer>
  );
};

export default InvitationPage;
