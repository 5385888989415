import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import mail from "../../../assets/mail.svg";
import "../SignUpContainer.css";
import { useNavigate } from "react-router-dom";
import { useNotification } from "../../Notifications/Notifications";

const ProfileConfirmation = ({
  profileSetup,
  title,
  subtitle,
  image,
  accountType,
  handleSubmit,
  currentSupplier,
  handleUpdateSupplier,
  formDataStep,
  sellerProfile
}) => {
  const navigate = useNavigate();
  const { addNotification } = useNotification();
  const currentSupplierId = JSON.parse(localStorage.getItem('currentSupplierID')) || "";

  console.log(currentSupplierId, "supplierID");

  const proceedToDashboard = () => {
    handleSubmit();
    
    navigate('/seller-dashboard');
    addNotification({title:"Profile created!"});
  };
  const proceedToprofileSetup = () => {
    navigate("/seller-onboarding/personal-profile", { state: { currentSupplier } });
  };
  return (
    <Container className="signup-main-container">
      <Row className="justify-content-center align-items-center">
        <Col md={6} className="d-flex flex-column align-items-center">
          <div className="w-100 mx-auto signup-email-container">
            <Col>
              {image !== "" && (
                <Image
                  src={mail}
                  alt=""
                  width="57.347px"
                  height="68px"
                  style={{ display: "inline" }}
                />
              )}
            </Col>
            <p className="title">{title}</p>
            <p className="subtitle">{subtitle || ""}</p>
            <Button
              variant="primary"
              type="submit"
              className="proceed-button"
              onClick={profileSetup ? proceedToprofileSetup : proceedToDashboard}
              style={{ background: "#fff", color: "#000" }}
            >
              {profileSetup || "Proceed to Dashboard"}
            </Button>
            <p
              style={{ color: "#fff", marginTop: "18px", fontWeight: "600" }}
              onClick={proceedToDashboard}
            >
              {profileSetup ? "Proceed to Dashboard" : ""}
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default ProfileConfirmation;
