import React, { useState } from "react";
import { Container, Row, Col, Image, Button, Card } from "react-bootstrap";
import "./SignUpComponent.css";
import cube from "../../assets/cube-mark.svg";
import userLogo from "../../assets/buyer-seller-icon.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const SignUpComponent = () => {
  const location = useLocation();
  const selected = location.state?.selected;
  const [selectedCard, setSelectedCard] = useState(selected || null);

  const handleCardSelection = (cardType) => {
    setSelectedCard(cardType);
  };

  return (
    <Container fluid className="signup-main-container">
      <Row>
        <Col>
          <div className="signup-container">
            <Col>
              <Image
                src={cube}
                alt=""
                width="57.347px"
                height="68px"
                style={{
                  display: "inline",
                  marginTop: "52px",
                  marginBottom: "30px",
                }}
              />
            </Col>
            <p className="container-title">Select an account type</p>
            <p className="container-subtitle">
              What do you want to use TalkDEI for?
            </p>
            <div className="cards-container">
              <Col>
                <Card
                  className={`card-container ${
                    selectedCard === "buyer" ? "selected" : ""
                  }`}
                  onClick={() => handleCardSelection("buyer")}
                >
                  <Card.Body>
                    <Col>
                      <Image
                        className="card-image"
                        src={userLogo}
                        alt=""
                        width="36px"
                        height="36px"
                      />
                    </Col>
                    <Card.Subtitle className="signup-card-subtitle">
                      Buyer
                    </Card.Subtitle>
                    <Card.Text className="select-card-text">
                      Identify, source and manage diverse suppliers for your
                      organization
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col>
                <Card
                  className={`card-container ${
                    selectedCard === "seller" ? "selected" : ""
                  }`}
                  onClick={() => handleCardSelection("seller")}
                >
                  <Card.Body>
                    <Col>
                      <Image
                        className="card-image"
                        src={userLogo}
                        alt=""
                        width="36px"
                        height="36px"
                      />
                    </Col>
                    <Card.Subtitle className="signup-card-subtitle">
                      Seller
                    </Card.Subtitle>
                    <Card.Text className="select-card-text">
                      Offer your services and engage with potential employers of
                      diverse suppliers
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </div>

            <Link
              to={selectedCard === "buyer" ? "/buyer-onboarding" : "/seller-onboarding"}
              className={`custom-button-select-account ${
                selectedCard === "buyer" ? "selected" : ""
              }`}
              style={{ textDecoration: "none", border: "none" }}
            >
              <Button
                variant="primary"
                type="submit"
                className={`custom-button-select-account ${
                  selectedCard === "buyer" || selectedCard=== "seller" ? "selected" : ""
                }`}
              >
                Continue
              </Button>
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUpComponent;
