import React from 'react';
import "../Pages.css";
import {  Image } from "react-bootstrap";
import ownershipContainerImg from "../../assets/LandingPage/pricingPageOwnership.png";
import { useNavigate } from 'react-router';

const SuppliersPricing = ({isMobile}) => {
  const navigate = useNavigate()


  const handlegetStarted = () => {
    navigate('/sign-up', {state: {selected: "seller"}})
  }


  return (

    <div className="terms-main-container">
    <div className="pricing-gradient-container">
      <h1 className="terms-of-use">TalkDEI helps diverse suppliers</h1>
      <p>
        At TalkDEI, we are committed to empowering diverse suppliers and
        leveling the playing field in the procurement landscape. Our
        platform offers a range of tools and resources designed to support
        diverse businesses in accessing contracting opportunities, expanding
        their networks, and thriving in competitive markets. From
        streamlined profile creation to real-time communication with
        enterprises, we provide the essential tools for diverse suppliers to
        succeed. 
      </p>
    </div>

    <div
      className="main-features-container-pricing"
      style={{
        background: "#fff",
      }}
    >
      <div className="features-container-pricing">
        <div className="feature-column-suppliers">
          <h2>Feature</h2>
          <div className="feature-category">
            <h4>Create Profile</h4>
          </div>
          <div className="feature-category">
            <h4>List Business and services</h4>
          </div>
          <div className="feature-category">
            <h4>Manage contracts and orders</h4>
          </div>
          <div className="feature-category">
            <h4>In-App messaging</h4>
          </div>
          <div className="feature-category">
            <h4>Access to opportunities</h4>
          </div>
          <div className="feature-category">
            <h4>Always free for suppliers</h4>
          </div>
          <div className="feature-category">
            <h5>Free</h5>
          </div>
        </div>
        <div className="feature-column">
          <h2>Description</h2>
          <div className="feature-category">
            <span>Suppliers can easily create a profile</span>
          </div>
          <div className="feature-category">
            <span>
              Suppliers can list their business details and services offered
            </span>
          </div>
          <div className="feature-category">
            <span>
              Suppliers can efficiently manage their contracts and orders
            </span>
          </div>
          <div className="feature-category">
            <span>
              Suppliers can communicate with enterprises within the platform
            </span>
          </div>
          <div className="feature-category">
            <span>
              Suppliers can have access to procurement opportunities and
              offers
            </span>
          </div>
          <div className="feature-category">
            <span>
              All features provided to suppliers are free of charge
            </span>
          </div>
          <div className="feature-category">
            <span> </span>
          </div>
        </div>
      </div>
      <div className="gradient-join-btn">
        <button onClick={handlegetStarted}>Join TalkDEI now</button>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            position: "relative",
            alignItems: isMobile ? "center" : ""
          }}
          className="diverse-suppliers-container-pricing"
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>We welcome all diverse suppliers</span>
          {!isMobile && (
            <Image
              src={ownershipContainerImg}
              width="287.56px"
              height="277.974px"
              fluid
              loading="lazy" 
            />
          )}
          </div>
          <div
            style={{ display: "flex", flexDirection: "column" }}
            className="list-of-diversity-pricing"
          >
            <p>Minority-Owned Business Enterprise (MBE)</p>
            <p>Women-Owned Business Enterprise (WBE)</p>
            <p>LGBT-Owned Business Enterprise (LGBTBE)</p>
            <p>Veteran-Owned Business Enterprise (VBE)</p>
            <p>Disability-Owned Business Enterprise (DOBE)</p>
            <p>Service-Disabled Veteran-Owned Small Business (SDVOSB)</p>
            <p>Small Disadvantaged Business (SDB)</p>
            <p>
              Historically Underutilized Business Zone (HUBZone) Certified
            </p>
            <p>Disadvantaged Business Enterprise (DBE)</p>
            <p>Native American-Owned Business Enterprise</p>
            <p>Asian American-Owned Business Enterprise</p>
            <p>Hispanic-Owned Business Enterprise</p>
            <p>Pacific Islander-Owned Business Enterprise</p>
            <p>Black-Owned Business Enterprise</p>
          </div>
          {isMobile && (
            <Image
              src={ownershipContainerImg}
              width="188px"
              height="182px"
              fluid
              loading="lazy" 
            />
          )}
          <div
          className='pink-circle'
            style={{
              position: "absolute",
              left: isMobile ? "" : "-160px",
              right: isMobile ? "-160px" : "",
              bottom: isMobile ? "-112px" : "-212px",
              zIndex: -1,
              borderRadius: isMobile ? "335px" : "571px",
              background:
                "var(--Gradient-2, linear-gradient(0deg, #B034DB 0%, #FF6B00 100%))",
              filter: "blur(64px)",
              width:  isMobile ? "335px" : "571px",
              height: isMobile ? "335px" : "571px",
              opacity: "0.9",
            }}
          ></div>
        </div>
      </div>

      
    </div>
  </div>
  );
};

export default SuppliersPricing;
