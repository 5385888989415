// File: sellerReducer.js
import { GET_SELLERS_START, GET_SELLERS_FAILURE, GET_SELLERS_SUCCESS } from "../constants/constants"

const initialState = {
  sellers: [],
  isLoading: false,
  error: null,
};

const sellerReducer = (state = initialState, action) => {
  console.log('ACTION FROM SELLER', action)
  switch (action.type) {
    case GET_SELLERS_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case GET_SELLERS_SUCCESS:
      return {
        ...state,
        sellers: action.payload,
        isLoading: false,
        error: null,
      };

    case GET_SELLERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default sellerReducer;
