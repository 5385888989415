import react from "react";
import { Container, Row, Col, Image, Form, Card } from "react-bootstrap";
import userprofile from "../../../../assets/userprofile.png";
import dot from "../../../../assets/dot.svg";
import star from "../../../../assets/star.svg";
import certifiedCheck from "../../../../assets/certifiedCheck.svg";
import "../../Dashboard.css";
import CompanyAvatar from "../../../CompanyAvatar";

const ListOfSuppliers = ({ suppliers, onCardClick }) => {
  const suppliersList = suppliers.map((supplier, i) => {
    return (
      <Container fluid>
        <Col
          key={`${supplier} + ${i}`}
          className="talents-list"
          onClick={() => onCardClick(supplier)}
        >
          <Card.Body>
            <div
              className="integration-list-content"
              style={{ marginBottom: "24px" }}
            >
              <div className="integration-list-info">
                {supplier.company.companyProfilePicture ? (
                  <Image
                    src={supplier.company.companyProfilePicture}
                    alt=""
                    width="41px"
                    height="41px"
                    style={{ borderRadius: "9px", marginRight: "10px" }}
                  />
                ) : (
                  <div style={{ marginRight: "10px" }}>
                    <CompanyAvatar companyName={supplier.company.name} size="58px" />
                  </div>
                )}

                <div className="talent-overview">
                  <Card.Subtitle
                    className="integration-list-subtitle"
                    style={{
                      color: "#1D2739",
                      fontSize: "19px",
                      fontWeight: 500,
                    }}
                  >
                    {supplier.company.name}
                  </Card.Subtitle>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <p className="integration-list-desc">{supplier.company.industry}</p>
                    <img
                      src={dot}
                      alt=""
                      width="10px"
                      height="10px"
                      style={{ margin: "5px 10px" }}
                    />
                    <p className="integration-list-desc">
                      {" "}
                      {supplier.address.city},&nbsp;
                      {supplier.address.state},&nbsp;
                      {supplier.address.country}
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  //   flexDirection:"row",
                  //   alignItems: "center",
                  //   justifyContent: "center",
                }}
              >
                <p className="mr-2" style={{ color: "#7B7B7B" }}>
                  Rating:
                </p>
                {/* <img src={star} alt="star" /> */}
                <p
                  className="ml-2"
                  style={{
                    borderRadius: "4.501px",
                    background: "rgba(216, 216, 216, 0.63)",
                    width: "37px",
                    marginLeft: "9px",
                  }}
                >
                  {supplier.supplierProfile.rating}
                </p>
                {supplier.supplierProfile.certificates.length > 0 && (
                  <span style={{ display: "flex" }}>
                    <img
                      src={certifiedCheck}
                      alt=""
                      style={{ marginLeft: "16px", marginRight: "5px" }}
                    />
                   <p
                  style={{
                    fontSize: "14px",
                    background:
                      "linear-gradient(180deg, #CC9866 9.29%, #792E8E 98.58%)",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                  }}
                >
                  Certified
                </p>
                  </span>
                )}
              </div>
            </div>
          </Card.Body>
          <div className="about-talent" style={{ marginBottom: "41px" }}>
            <Card.Subtitle
              className="integration-list-subtitle"
              style={{ marginBottom: "9px" }}
            >
              About
            </Card.Subtitle>
            <p className="integration-list-desc">{supplier.supplierProfile.bio}</p>
          </div>
          <div className="talent-certificates">
            <Card.Subtitle
              className="integration-list-subtitle"
              style={{ marginBottom: "8px" }}
            >
              Skills
            </Card.Subtitle>
            <div className="list-of-cert">
            {Object.keys(supplier.supplierProfile.skills).map((skillType) =>
                 supplier.supplierProfile.skills[skillType].map((skill, index) => (
                   <span key={index}>{skill}</span>
                 ))
               )}
            </div>
          </div>
        </Col>
      </Container>
    );
  });
  return (
    <Container fluid className="talents-main-container">
      {suppliersList}
    </Container>
  );
};

export default ListOfSuppliers;
