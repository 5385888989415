import React from "react";
import { Container} from "react-bootstrap";
import CompanyTable from "./CompanyTable";
import { useSavedSuppliers } from "./SavedSuppliersContext";


const SavedSuppliers = ({isSavedSuppliers}) => {
  const { savedSuppliers, updateSavedSuppliers } = useSavedSuppliers();


  return (
    <Container fluid style={{ background: "#F5F5F5", minHeight: "100vh" }}>
      <CompanyTable suppliers={savedSuppliers} isSavedSuppliers={isSavedSuppliers}/>
    </Container>
  );
};
export default SavedSuppliers;
