import React, { useEffect, useState } from "react";
import Navbar from "../components/LandingPage/Navbar";
import Footer from "../components/LandingPage/Footer";
import "./Pages.css";
import background from "../assets/LandingPage/Background (1).png";
import { Container, Col, Image } from "react-bootstrap";
import { useNavigate } from "react-router";

const TermsOfUse = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const navigate = useNavigate();

  const handleRedirectToPolicy = () => {
    navigate("/privacy-policy");
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Navbar isMobile={isMobile} />

      <div className="terms-main-container">
        <div className="terms-container">
          <h1 className="terms-of-use">Terms of Use</h1>
        </div>

        <div
          className="content-container-terms"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Image src={background} className="background-image-terms" fluid />
          <div className="text-content-terms">
            <span className="category" style={{ marginBottom: "20px" }}>
              Last updated: 01/24/2024
            </span>
            {/* <span className="category">  1. Introduction </span> */}
            <span className="content">
              The following terms (“Terms”) constitute a binding legal agreement
              between you and TalkDEI, Inc. (“TalkDEI.io,” “we” or “us”). These
              Terms govern your use of our website, TalkDEI.io (“Site”), any
              apps we offer (“Apps”) and any of our other services
              (collectively, the “Services”).
            </span>
            <span className="content">
              By visiting the Site, subscribing to our newsletter or using our
              Services in any other way, you agree to the Terms and our
              <span
                className="content"
                style={{ color: "black", fontWeight:"700",textDecoration:"underline", cursor: "pointer", marginLeft:"5px"}}
                onClick={handleRedirectToPolicy}
              >
                Privacy Policy.
              </span>
              {" "} If you do not agree with the Terms or the Privacy Policy, you
              may not use the Services.
            </span>
            <span className="content">
              We may post new versions of these Terms to the Site in the event
              of any future changes. We may also notify you via email or by
              posting a notice on the Site, and we may require you to consent to
              the updated Terms before you may continue using the Services. In
              all cases, your use of the Services is always subject to the then
              current version of the Terms.
            </span>
            <span className="category">Eligible Users</span>
            <span className="content">
              If you are under 18 years of age, you may not use the Services.
            </span>
            <span className="category">Changes</span>
            <span className="content">
              We reserve the right to change or discontinue the Services at any
              time and in any way.
            </span>
            <span className="category">Acceptable Use</span>
            <span className="content">
              You may not (yourself or through a third party):
            </span>
            <span className="content">
              · Use the Services and/or your experience using the Services to
              create similar or competitive services;
            </span>
            <span className="content">
              · Modify, alter, tamper with, reverse engineer, create derivative
              works of, repurpose or repackage any content or other aspect of
              the Services, except and only to the extent that the applicable
              law expressly requires us to permit you to do so;
            </span>
            <span className="content">
              · Use any automated process to access the Services, except for
              search engines compiling a free public search index;
            </span>
            <span className="content">
              · Circumvent any technical measures we use to restrict access to
              the Services;
            </span>
            <span className="content">
              · Use the Services for any purpose other than as expressly
              permitted by these Terms and any policies posted on the Site;
            </span>
            <span className="content">
              · Sell, lend, rent, lease, sublicense, assign or otherwise
              transfer any of the rights granted to you with respect to the
              Services to any third party;
            </span>
            <span className="content">
              · Remove, obscure or alter any proprietary rights notice
              pertaining to the Services;
            </span>
            <span className="content">
              · Use the Services in connection with anything misleading or
              illegal, unsolicited or spam email, bulk mailing or chain letters,
              contests, sweepstakes, pyramid or ponzi schemes, to advertise or
              promote a commercial product or service that is not available
              through TalkDEI, or post anything that is unlawful, defamatory,
              threatening, pornographic, abusive, libelous or otherwise
              objectionable or that encourages criminal conduct;
            </span>
            <span className="content">
              · Overload TalkDEI’s systems or attempt to disrupt the Services.
            </span>

            <span className="category">Ownership</span>
            <span className="content">
              TalkDEI.io retains all right, title and interest in the Services,
              trademarks, service marks and logos, including all underlying
              software, technology and processes and any enhancements or
              modifications. Except for rights expressly granted in the Terms,
              we do not grant you any other right, title or license.
            </span>

            <span className="category">Feedback</span>
            <span className="content">
              If you send us feedback, we may use your feedback for any purpose
              without attribution, accounting or compensation to you.
            </span>

            <span className="category">Contacting you</span>
            <span className="content">
              We may email you about the Services, including but not limited to
              service announcements and administrative messages. You may also
              receive promotional messages from us. If you no longer want to
              receive promotional emails from us, you may follow the
              instructions in the emails to unsubscribe.
            </span>

            <span className="category">Privacy</span>
            <span className="content">
              When you use the Services, you and we are subject to our 
              <span
                className="content"
                style={{ color: "black", fontWeight:"700", textDecoration:"underline", cursor: "pointer", marginLeft:"5px"}}
                onClick={handleRedirectToPolicy}
              >
                Privacy Policy.
              </span>
            </span>

            <span className="category">Indemnity</span>
            <span className="content">
              You will indemnify, defend and hold harmless TalkDEI, its
              affiliates, officers, directors, shareholders, employees,
              contractors, licensors, licensees, agents and representatives (the
              “Covered Entities”) against all liability, claims, costs, damages,
              settlements and expenses (including interest, penalties, attorney
              fees and expert witness fees) (“Liabilities”) incurred by any
              Covered Entity in any way arising out of or relating to your
              violation of the Terms, your use of the Services or your Content.
              TalkDEI reserves the right, at your expense, to assume the
              exclusive defense and control of any matter otherwise subject to
              indemnification by you, and in such case, you agree to cooperate
              with our defense of such claim.
            </span>
            <span className="category">Disclaimer of Warranties</span>
            <span className="content">
              To the maximum extent permitted by applicable law, the Services
              are provided “AS IS” and “AS AVAILABLE”, with all faults and
              without warranty of any kind. The Covered Entities disclaim all
              warranties and representations whether express, implied or
              statutory, including implied warranties of fitness for a
              particular purpose, accuracy and non-infringement. The Covered
              Entities do not promise that the Services will meet your
              requirements or be uninterrupted or error-free. The Covered
              Entities do not control, endorse, verify or accept responsibility
              for any third party content, materials, products, services or
              offers accessible through the Services (including via links to
              third party websites). We make no representations or warranties
              about such third parties and you engage with them at your own
              risk. Some jurisdictions do not allow exclusion of implied
              warranties or limitations on your statutory rights, so our
              disclaimer may not apply to you.
            </span>

            <span className="category">Limitation of Liability</span>
            <span className="content">
              To the extent not prohibited by law, the Covered Entities shall
              not be liable for any incidental, special, indirect, consequential
              or similar damages, including lost profits, damages arising from
              loss of information, business interruption, regardless of the
              theory of liability (contract, tort or otherwise) and even if we
              have been advised of the possibility of such damages. To the
              extent permitted by law, in no event shall the Covered Entities’
              total aggregate liability for all damages arising under or related
              to the Services exceed the amount you have paid to TalkDEI for the
              Services in the twelve months before the date the cause of action
              arose. These limits apply even if a stated remedy fails of its
              essential purpose. Some jurisdictions do not allow limits on
              certain liabilities so these limitations may not apply to you.
            </span>

            <span className="category">Miscellaneous</span>
            <span className="content">
              · These Terms incorporate all policies and requirements shown on
              the Site.
            </span>
            <span className="content">
              · You cannot assign your account to any third party without our
              prior written consent.
            </span>
            <span className="content">
              · TalkDEI will be entitled to recover any legal fees and other
              costs associated with enforcing the Terms.
            </span>
            <span className="content">
              · You can provide any notices to us by sending an email to
              nina.legal@talkdei.io.
            </span>
            <span className="content">
              · This Agreement is governed by the laws of the State of
              California, excluding its choice of law principles.
            </span>
            <span className="content">
              · You must bring any claims under the Terms within one year unless
              the law requires a longer timeframe. Claims not filed within those
              limits are permanently barred.
            </span>
            <span className="content">
              · The parties will resolve any disputes in the courts of Los
              Angeles County, California.
            </span>
            <span className="content">
              · The initiating party must bring all claims in its individual
              capacity and not as a plaintiff or class member in a class action
              or other similar proceeding.
            </span>
            <span className="content">
              · If any provision of the Terms is legally unenforceable, that
              provision shall be limited to the least extent necessary to render
              it enforceable (or eliminated if it cannot be made enforceable)
              and the validity and enforceability of the remaining provisions
              will not be affected. No waiver will be effective unless it is in
              an explicit writing and signed by the waiving party.
            </span>
          </div>
        </div>
      </div>
      <div className="terms-footer">
        <Footer button={true} height={true} isMobile={isMobile} />
      </div>
    </div>
  );
};

export default TermsOfUse;
