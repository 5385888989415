import React, { useState, useEffect } from "react";
import Navbar from "../../components/LandingPage/Navbar";
import Footer from "../../components/LandingPage/Footer";
import pricingImg from "../../assets/LandingPage/pricing.png";
import "../Pages.css";
import background from "../../assets/LandingPage/bacgroundPricing.png";
import { Image } from "react-bootstrap";
import EnterprisesPricing from "./Enterprises";
import SuppliersPricing from "./Suppliers";
import { useLocation } from "react-router";

const Pricing = () => {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const tab = location.state?.tab;
  console.log(tab, location.state)
  const [activeTab, setActiveTab] = useState(tab ? tab : 'suppliers'); 

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute("charset", "utf-8");
    script.setAttribute("type", "text/javascript");
    script.setAttribute("src", "//js.hsforms.net/forms/embed/v2.js");
    document.body.appendChild(script);

    script.onload = () => {
      console.log("on load");
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "45694015",
          formId: "bc18351f-71cb-4bad-a79c-9520feaad39a",
          target: "#hubspot-sign-up",
          onFormSubmit: () => {
            const emailInput = document.getElementById("email-input").value;
            window.hbspt.forms.submit({
              portalId: '45694015',
              formId: 'bc18351f-71cb-4bad-a79c-9520feaad39a',
              fields: {
                email: emailInput
              }
            });
          },
        });
      }
    };
  
  
}, []);

  const handleHomeClick = () => {
    navigate('/pricing');
  };

  const tabStyle = (tabName) => {
    if (tabName === activeTab) {
      return {
        background:
          "var(--DEI, linear-gradient(270deg, #CC9866 0%, #651498 79.79%))",
        backgroundClip: "text",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        fontSize: isMobile ? "32px" : "64px",
        fontStyle: "normal",
        fontWeight: "800",
        lineHeight: "normal",
        fontFamily: "Hanken Grotesk",
      };
    } else {
      return {
        color: "#8E8E8E",
        textAlign: "center",
        textLeadingTrim: "both",
        textEdge: "cap",
        fontFamily: "Hanken Grotesk",
        fontSize: isMobile ? "32px" : "64px",
        fontStyle: "normal",
        fontWeight: "800",
        lineHeight: "normal",
      };
    }
  };

  const handleClick = (tabName) => {
    setActiveTab(tabName);
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Navbar outsideOfLandingPage={true} onHomeClick={handleHomeClick} isMobile={isMobile}/>
      <div className="pricing-container">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <span style={{ marginBottom: isMobile ? "40px" :"95px", marginTop: isMobile ? "50px" : "160px" }} className="pricing-title">
            Pricing
          </span>
          <Image src={pricingImg} className="pricing-img" fluid />
        </div>
        <div style={{ position: "relative", height:isMobile ? "200px" : "350px" }}>
          <Image src={background} className="background-image-pricing" fluid />
          <div
            className="pricing-tab"
            style={{
              position: "absolute",
              top: "-100px",
              left: "50%",
              top: "70%",
              transform: "translate(-50%, -50%)",
              zIndex: "1",
            }}
          >
            <span
              style={tabStyle("suppliers")}
              onClick={() => handleClick("suppliers")}
            >
              Suppliers
            </span>
            <div className="divider"></div>
            <span
              style={tabStyle("enterprises")}
              onClick={() => handleClick("enterprises")}
            >
              Enterprises
            </span>
          </div>
        </div>
        {activeTab === "suppliers" ? (
          <SuppliersPricing renderfromPricing={true} isMobile={isMobile}/>
        ) : (
          <EnterprisesPricing renderfromPricing={true} isMobile={isMobile}/>
        )}
      </div>
      <div
        style={{
          marginBottom: isMobile ? "50px" : "150px",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <h1
        className="subscribe-to-mailing-list"
          style={{
            width: isMobile ? "267px" : "550px",
            color: "#000",
            textAlign: "center",
            fontFamily: "Hanken Grotesk, sans-serif",
            fontSize: isMobile ? "30px" : "",
            fontWeight: 800,
          }}
        >
          Subscribe to our mailing list
        </h1>
        <div className="subscribe-button-container">
        <div id='hubspot-sign-up' ></div>
          {/* <input
            type="email"
            placeholder="Enter your email"
            className="subscribe-input-field"
          />
          <button className="subscribe-footer-pricing-button">Subscribe</button> */}
        </div>
      </div>
      <div className="terms-footer">
        <Footer button={true} height={true}  setActiveTab={setActiveTab} isMobile={isMobile} />
      </div>
    </div>
  );
};

export default Pricing;
