import React, { useState } from "react";
import { Container, Row, Col, Button, Card, Image } from "react-bootstrap";
import emptyStarIcon from "../../assets/emptyStar.svg";
import starIcon from "../../assets/starIconBlack.svg";
import greyCircle from "../../assets/greyCircle.svg";

const Reviews = () => {
  const [textLength, setTextLength] = useState(0);
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState('');

  const [reviews, setReviews] = useState([
    {
      id: 1,
      reviewerName: "Apple",
      reviewerImage: "url_to_image",
      rating: 4,
      reviewText: "Great experience with this supplier. Highly recommended!",
    },
    // Add more reviews as needed
  ]);
  const handleTextChange = (e) => {
    setTextLength(e.target.value.length);
    setReviewText(e.target.value);
  };
  const handleStarClick = (index) => {
    setRating(index + 1);
  };

  const handleSubmit = () => {
    const newReview = {
      id: reviews.length + 1,
      reviewerName: "Global Tech Solutions",
      rating: rating,
      reviewText: reviewText, 
    };

    setReviews([...reviews, newReview]);
    setRating(0); 
    setTextLength(0);
    setReviewText('');
  };
  
  const handleCancel = () => {
    setRating(0); 
    setTextLength(0);
    setReviewText('');
  }
  const divFlexRow = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };
  const divFlexColumn = {
    display: "flex",
    flexDirection: "column",
  };
const renderReviews = (reviews) => {
    return reviews.map((review) => (
      <Card key={review.id} style={{ marginBottom: "20px" }}>
        <Card.Body>
          <Row>
            <Col xs={3}>
              <div style={{ ...divFlexRow, marginTop: "20px" }}>
                <img src={greyCircle} alt="" />
                <div style={{ ...divFlexColumn, marginLeft: "10px" }}>
                  <p style={{ fontSize: "16px", color: "#1D2739" }}>
                    {review.reviewerName}
                  </p>
                  {/* <a
                    style={{
                      fontSize: "12px",
                      textDecoration: "none",
                      color: "#7B7B7B",
                    }}
                    href={review.reviewerWebsite}
                  >
                    {review.reviewerWebsite}
                  </a> */}
                </div>
              </div>
            </Col>
            <Col xs={9}>
              <div style={divFlexRow}>
                {[...Array(5)].map((_, index) => (
                  <img
                    key={index}
                    src={index < review.rating ? starIcon : emptyStarIcon}
                    alt={index < review.rating ? "Filled Star" : "Empty Star"}
                    style={{
                      color: index < review.rating ? "#000000" : "inherit",
                    }}
                  />
                ))}
              </div>
              <p style={{color:"#1D2739", marginTop:"10px"}}>{review.reviewText}</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    ));
  };

  return (
    <Container>
      <Row>
        <Col>
          <div>
            <span>Rate your experience with this supplier</span>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "36px",
              }}
            >
              {[...Array(5)].map((_, index) => (
                <Image
                  key={index}
                  width="24px"
                  height="24px"
                  src={index < rating ? starIcon : emptyStarIcon}
                  alt={index < rating ? "Filled Star" : "Empty Star"}
                  style={{ cursor: "pointer" }}
                  onClick={() => handleStarClick(index)}
                />
              ))}
            </div>
          </div>
          <span style={{color:"#1D2739"}}>Leave a comment</span>
          <div
            style={{
              border: "1px solid #B2B2B2",
              borderRadius: "16px",
              padding: "15px",
              marginTop:"16px"
            }}
          >
            <textarea
              maxLength={400}
              rows={6}
              value={reviewText}
              style={{
                width: "100%",
                marginTop: "10px",
                resize: "none",
                outline: "none",
                color: "#7B7B7B",
              }}
              placeholder="Enter your feedback here"
              onChange={handleTextChange}
            />
            <span style={{ display: "flex", justifyContent: "end", color:"#B2B2B2" }}>
              {textLength}/400
            </span>
          </div>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              gap: "10px",
              marginBottom: "50px",
            }}
          >
            <Button className="review-btn" style={{ color: "#7B7B7B" }} onClick={() => handleCancel()}>
              Cancel
            </Button>{" "}
            <Button
              className="review-btn"
              style={{ background: "#0E8080", color: "fff", border: "none" }}
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>Previous Reviews</p>
          {renderReviews(reviews)}
        </Col>
      </Row>
    </Container>
  );
};

export default Reviews;
 