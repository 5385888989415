// authActions.js
import axios from 'axios';
import { ENDPOINTS } from '../URL';
import { LOGIN_START, LOGIN_SUCCESS, LOGIN_FAILURE, SIGNUP_START, SIGNUP_SUCCESS, SIGNUP_FAILURE } from '../constants/constants';
import { setSuppliers } from './suppliersActions';


export const loginStart = () => ({
  type: LOGIN_START,
});

export const loginSuccess = (token) => ({
  type: LOGIN_SUCCESS,
  payload: token,
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

export const login = (username, password) => async (dispatch) => {
  dispatch(loginStart());
  const url = ENDPOINTS.SIGN_IN

  try {
    // API endpoint
    const response = await axios.post(url, {
      username,
      password,
    });

    //API returns a token
    const token = response.data.token;

    // Store the token in local storage 
    localStorage.setItem('token', token);

    dispatch(loginSuccess(token));
  } catch (error) {
    dispatch(loginFailure(error.message));
  }
};

export const signupStart = () => ({
  type: SIGNUP_START,
});

export const signupSuccess = () => ({
  type: SIGNUP_SUCCESS,
});

export const signupFailure = (error) => ({
  type: SIGNUP_FAILURE,
  payload: error,
});


// New action creator for initiating SendGrid email verification
export const sendVerificationEmail = (email) => async (dispatch) => {
  const verificationUrl = ENDPOINTS.VERIFICATION;
  // NEED to Replace with backend verification endpoint

  try {
    await axios.post(verificationUrl, { email });
    console.log('Verification email sent successfully');
  } catch (error) {
    console.error('Error sending verification email:', error.message);
  }
};

// Updated signup action to include SendGrid email verification
// BUYER FLOW
// export const BuyerSignup = (userData) => async (dispatch) => {
//   dispatch(signupStart());

//   const url = "http://localhost:9000/users"
//   // const url = "http://localhost:9000/user/verify"

//   try {
//     const response = await axios.post(url, userData);
//     console.log('RESPONSE', response.data)

//     dispatch(signupSuccess());
//   } catch (error) {
//     dispatch(signupFailure(error.message));
//   }
// };
  export const SellerSignup = (userData) => async (dispatch) => {
    console.log('userDAta', userData)
    dispatch(signupStart());
  
    const url = ENDPOINTS.SELLER_SIGN_UP;
  
    try {
      const response = await axios.post(url, userData);
      
      dispatch(setSuppliers(response.data))
  
      dispatch(signupSuccess());
    } catch (error) {
      dispatch(signupFailure(error.message));
    }
};

export const BuyerSignup = (userData) => async (dispatch) => {
  console.log('userDAta', userData)
  dispatch(signupStart());

  const url = ENDPOINTS.BUYER_SIGN_UP;

  try {
    const response = await axios.post(url, userData);
    dispatch(setSuppliers(response.data))

    dispatch(signupSuccess());
  } catch (error) {
    dispatch(signupFailure(error.message));
  }
};