import React, { useState, useEffect } from "react";
import SignUpContainer from "../SignUpContainer";
import { Link } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import "./styling.css";
// import {SellerSignup} from "../../../actions/authActions";
// import { useDispatch } from "react-redux";

const CreatePassword = ({ formDataStep, setFormDataStep, handleNextStep, step, handleSignUp, getOtpCode}) => {
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  // const dispatch = useDispatch();
  const { password, confirmPassword } = formDataStep;

  useEffect(() => {
    validatePassword();
  }, [password, confirmPassword]);

  const validatePassword = () => {
    const hasMinimumLength = password.length >= 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialCharacter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password); // Regular expression for special characters
    const matchPasswords = password === confirmPassword;
  
    setIsPasswordValid(
      hasMinimumLength && hasUppercase && hasNumber && hasSpecialCharacter && matchPasswords
    );
  };
  

  const handleSubmit = (event) => {
    if(event) event.preventDefault();
    
    setSubmitted(true);

    validatePassword();

    const isPasswordNotEmpty = formDataStep.password.trim() !== "";
    const isConfirmPasswordNotEmpty =
      formDataStep.confirmPassword.trim() !== "";

    if (isPasswordValid && isPasswordNotEmpty && isConfirmPasswordNotEmpty) {
      // handleSignUp();
      getOtpCode();
      handleNextStep();
    }
  };

  return (
    <SignUpContainer
      title="Create Password"
      buttonBackground={isPasswordValid ? "#5C0E80" : "rgba(35, 35, 35, 0.30)"}
      step={step}
      subtitle={
        <>
          Already have an account?{" "}
          <Link
            to="/sign-in"
            style={{
              color: "#5C0E80",
              fontFamily: "Lato",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "144.523%",
              textDecorationLine: "underline",
            }}
          >
            Sign In
          </Link>
        </>
      }
      onContinueClick={handleSubmit}
    >
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Form.Group controlId="password" className="single-input">
              <Form.Label className="input-title">Password</Form.Label>
              <div className="password-input-wrapper">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder=""
                  required
                  className={`custom-input ${
                    submitted && !isPasswordValid ? "error" : ""
                  }`}
                  value={formDataStep.password}
                  onChange={(e) =>
                    setFormDataStep({
                      ...formDataStep,
                      password: e.target.value,
                    })
                  }
                />
                <div
                  className="show-password-button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? "Hide" : "Show"}
                </div>
              </div>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group controlId="confirmPassword" className="password-input">
              <Form.Label className="input-title">Confirm Password</Form.Label>
              <div className="password-input-wrapper">
                <Form.Control
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder=""
                  required
                  className={`custom-input ${
                    submitted && !isPasswordValid ? "error" : ""
                  }`}
                  value={formDataStep.confirmPassword}
                  onChange={(e) =>
                    setFormDataStep({
                      ...formDataStep,
                      confirmPassword: e.target.value,
                    })
                  }
                  style={{ width: "100%" }}
                />
                <div
                  className="show-password-button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? "Hide" : "Show"}
                </div>
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row className="error-statement">
          <Col md={6} className="d-flex flex-direction-column">
            <Form.Group
              controlId="lengthCheckbox"
              className="single-checkbox-input"
            >
              <div className="checkbox-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  className="custom-svg"
                  style={{
                    stroke:
                      password.length >= 8
                        ? "#5C0E80"
                        : !submitted
                        ? "#FFF"
                        : "#FF1616",
                  }}
                >
                  <path d="M4.26758 8.49967L7.46758 11.1663L11.7342 5.83301" />
                </svg>
                <span
                  className={`checkbox-label ${
                    password.length >= 8
                      ? "text-green"
                      : !submitted
                      ? "initial-color"
                      : "text-red"
                  }`}
                >
                  At least 8 characters long
                </span>
              </div>
            </Form.Group>

            <Form.Group
              controlId="uppercaseCheckbox"
              className="single-checkbox-input"
            >
              <div className="checkbox-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  className="custom-svg"
                  style={{
                    stroke:
                      isPasswordValid || /[A-Z]/.test(password)
                      ? "#5C0E80"
                      : !submitted
                      ? "#FFF"
                      : "#FF1616",
                  }}
                >
                  <path d="M4.26758 8.49967L7.46758 11.1663L11.7342 5.83301" />
                </svg>
                <span
                  className={`checkbox-label ${
                    isPasswordValid || /[A-Z]/.test(password)
                      ? "text-green"
                      : !submitted
                      ? "initial-color"
                      : "text-red"
                  }`}
                >
                  At least one uppercase
                </span>
              </div>
            </Form.Group>
            <Form.Group
              controlId="specialCharacterCheckbox"
              className="single-checkbox-input"
            >
              <div className="checkbox-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  className="custom-svg"
                  style={{
                    stroke:
                      isPasswordValid || /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)
                      ? "#5C0E80"
                      : !submitted
                      ? "#FFF"
                      : "#FF1616",
                  }}
                >
                  <path d="M4.26758 8.49967L7.46758 11.1663L11.7342 5.83301" />
                </svg>
                <span
                  className={`checkbox-label ${
                    isPasswordValid || /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)
                      ? "text-green"
                      : !submitted
                      ? "initial-color"
                      : "text-red"
                  }`}
                >
                  At least one special character
                </span>
              </div>
            </Form.Group>
          </Col>

          <Col md={6} className="d-flex flex-direction-column">
            <Form.Group
              controlId="numberCheckbox"
              className="single-checkbox-input"
            >
              <div className="checkbox-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  className="custom-svg"
                  style={{
                    stroke:
                      isPasswordValid || /\d/.test(password)
                      ? "#5C0E80"
                      : !submitted
                      ? "#FFF"
                      : "#FF1616",
                  }}
                >
                  <path d="M4.26758 8.49967L7.46758 11.1663L11.7342 5.83301" />
                </svg>
                <span
                  className={`checkbox-label ${
                    isPasswordValid || /\d/.test(password)
                      ? "text-green"
                      : !submitted
                      ? "initial-color"
                      : "text-red"
                  }`}
                >
                  At least one number
                </span>
              </div>
            </Form.Group>

            <Form.Group
              controlId="matchCheckbox"
              className="single-checkbox-input"
            >
              <div className="checkbox-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  className="custom-svg"
                  style={{
                    stroke: isPasswordValid
                    ? "#5C0E80"
                    : !submitted
                    ? "#FFF"
                    : "#FF1616",
                  }}
                >
                  <path d="M4.26758 8.49967L7.46758 11.1663L11.7342 5.83301" />
                </svg>
                <span
                  className={`checkbox-label ${
                    isPasswordValid
                      ? "text-green"
                      : !submitted
                      ? "initial-color"
                      : "text-red"
                  }`}
                >
                  Passwords must match
                </span>
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="marketingEmailsCheckbox">
          <Form.Check
            className="custom-checkbox"
            type="checkbox"
            label="I agree to receiving marketing emails from TalkDEI"
            value="checked"
            defaultChecked={true}
            onChange={(e) =>
              setFormDataStep({
                ...formDataStep,
                agreeToMarketingEmails: e.target.checked,
              })
            }
          />
        </Form.Group>
      </Form>
    </SignUpContainer>
  );
};

export default CreatePassword;
