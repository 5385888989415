import { combineReducers } from 'redux';
import authReducer from './authReducers';
import sellerReducer from './sellerReducer';
import buyerReducer from './buyerReducer';
import suppliersReducer from './suppliersReducer';
import otpReducer from './OTPReducer';
import verificationReducer from './OTPVerificationReducer';


const rootReducer = combineReducers({
  auth:authReducer,
  seller: sellerReducer,
  buyers: buyerReducer,
  suppliers: suppliersReducer,
  otp:otpReducer,
  verification: verificationReducer,

 
});

export default rootReducer;
