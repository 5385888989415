import React, { useState, useEffect } from "react";
import { Nav, Image } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import "./Dashboard.css";
import cube from "../../assets/cube-mark.svg";
import talk from "../../assets/talk.svg";
import d from "../../assets/d.svg";
import e from "../../assets/e.svg";
import i from "../../assets/i.svg";
const DashboardSidebar = ({ links, url, link }) => {
  const [selectedLink, setSelectedLink] = useState(link);

  const handleLinkClick = (link) => {
    console.log(link);
    setSelectedLink(link.label);
  };
  return (
    <Nav className="dashboard-sidebar">
      <div className="svg-line">
        <Image
          src={cube}
          alt="Cube"
          className="svg-icon"
          style={{ width: "20px", height: "24px", marginRight: "3.2px" }}
        />
        <Image
          src={talk}
          alt="Talk"
          className="svg-icon"
          style={{ marginRight: "1.3px" }}
        />
        <Image src={d} alt="D" className="svg-icon" />
        <Image
          src={e}
          alt="E"
          className="svg-icon"
          style={{ marginRight: "1.3px" }}
        />
        <Image src={i} alt="I" className="svg-icon" />
        </div>
        {links.map((link) => (
  <React.Fragment key={link.label}>
    {(link.label === "Orders" || link.label === "Jobs & Offers" ) ? (

      <div
        style={{ display: "flex", flexDirection: "row", gap: "5px" }}
        className="nav-link" 
      >
        <Image src={link.icon} alt={link.label} className="icon" />
        <span
          style={{
            display: "flex",
            color: "#fff",
            justifyContent: "center",
            alignItems: "center",
            whiteSpace:"nowrap",
          }}
        >
          {link.label}
        </span>
        <span
          style={{
            borderRadius: "3.415px",
            background: "rgba(255, 255, 255, 0.10)",
            whiteSpace:"nowrap",
            display: "flex",
            padding: "5px",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            fontSize: "10px",
          }}
        >
         {link.label === "Orders" ? '' : 'coming soon'} 
         {link.label === "Jobs & Offers" ? '' : 'coming soon'} 
        </span>
      </div>
    ) : (
      <Nav.Item>
        <NavLink
          to={`${url}${link.to.toLowerCase()}`}
          className={`nav-link ${
            selectedLink === link.label ? "active-link" : ""
          }`}
          onClick={() => handleLinkClick(link)}
          disabled={(link.label === "Orders" || link.label === "Jobs & Offers")} // Disable the link based on condition
        >
          <Image src={link.icon} alt={link.label} className="icon" />
          <span>{link.label}</span>
        </NavLink>
      </Nav.Item>
    )}
  </React.Fragment>
  
))}

      {/* </div>
      {links.map((link) => (
        <Nav.Item key={link.label}>
          <NavLink
            to={`${url}${link.to.toLowerCase()}`}
            className={`nav-link ${
              selectedLink === link.label ? "active-link" : ""
            }`}
            onClick={() => handleLinkClick(link)}
            disabled={(link.label === "Orders" || link.label === "Jobs & Offers")} 
          >
            <Image src={link.icon} alt={link.label} className="icon" />
            {link.label === "Orders" || link.label === "Jobs & Offers" ? (
              <div
                style={{ display: "flex", flexDirection: "row", gap: "5px" }}
              >
                <span
                  style={{
                    display: "flex",
                    color: "#9A9A9A",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {link.label}
                </span>
                <span
                  style={{
                    borderRadius: "3.415px",
                    background: "#F6F6F6",
                    display: "flex",
                    // height: "19.634px",
                    padding: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#9A9A9A",
                    fontSize: "10px",
                  }}
                >
                  coming soon
                </span>
              </div>
            ) : (
              <span>{link.label}</span>
            )}
          </NavLink>
        </Nav.Item>
      ))} */}
    </Nav>
  );
};

export default DashboardSidebar;
