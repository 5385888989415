import React, { createContext, useContext, useState } from "react";

const SavedSuppliersContext = createContext();

export const useSavedSuppliers = () => {
  return useContext(SavedSuppliersContext);
};

export const SavedSuppliersProvider = ({ children }) => {
  const [savedSuppliers, setSavedSuppliers] = useState([]);

  const updateSavedSuppliers = (newSuppliers) => {
    setSavedSuppliers(newSuppliers);
  };

  return (
    <SavedSuppliersContext.Provider
      value={{ savedSuppliers, updateSavedSuppliers }}
    >
      {children}
    </SavedSuppliersContext.Provider>
  );
};
