import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PersonalDetails from "./PersonalDetails";
import DiversityInformation from "./DiversityInformation";
import UserCertificates from "./UserCertificates";
import ProfileConfirmation from "../../SharedComponents/ProfileConfirmation";
import { useDispatch, useSelector } from "react-redux";
import { updateSupplierByIdAsync, updateSupplierByIdSignUp, updateSupplierByIdSignUpThunk } from "../../../../actions/suppliersActions";
const SellerProfile = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const location = useLocation();
  const dispatch = useDispatch();
  const currentSupplierId = useSelector((state) => state.suppliers.currentSupplierID);
  const sellerProfile = useSelector((state) => state.suppliers.suppliers.find(s => s._id === currentSupplierId));
  const currentSupplier = useSelector((state) => state.suppliers.currentSupplier);
  // const currentSupplierId = JSON.parse(localStorage.getItem('currentSupplierID')) || "";

  const [formDataStep, setFormDataStep] = useState({
      name: "",
      profilePicture: "",
      email: "",
      primaryRole: "",
      rating: 0,
      bio: "",
      yearFounded: 0,
      linkedInUrl: "",
      diversity: {
        gender: "",
        pronouns: "",
        race: "",
        diversityStatus: "",
      },

      certificates: [""],
      businessLicenses: "",
      services: [
        {
          serviceImage: "",
          title: "",
          description: "",
          tag: "",
        },
      ],

      skills: {
        technical: [""],

        industry: [""],

        company: [""],
      },

      tier: "",
      mainContacts: [{ name: "", primaryRole: "", email: "" }],
      otherContacts: [
        {
          name: "",
          primaryRole: "",
          email: "",
        },
      ],
  });


  const navigate = useNavigate();

  const handleSubmit = (event) => {
    if(event) event.preventDefault();
      handleNextStep();
  };

  const handleUpdateSupplier = () => {
    const supplierProfile = formDataStep;
    dispatch(updateSupplierByIdSignUpThunk({ supplierId: currentSupplierId, supplierProfile }));
  };
  

  const handleSkip = () => {
    handleNextStep();
  };
  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
    window.history.pushState(null, null, `#${currentStep + 1}`);
  };
  useEffect(() => {
    if (currentStep === 1)
      window.history.pushState(null, null, `#${currentStep}`);
  });
  useEffect(() => {
    const handleHashChange = () => {
      const hashStep = parseInt(window.location.hash.slice(1), 10);

      if (!isNaN(hashStep) && hashStep !== currentStep) {
        setCurrentStep(hashStep);
      }
    };

    const handleBackClick = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };
  

    window.addEventListener("hashchange", handleHashChange);
    window.addEventListener("popstate", handleBackClick);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
      window.removeEventListener("popstate", handleBackClick);
    };
  }, [currentStep, navigate, setCurrentStep]);

  useEffect(() => {
    const hashStep = parseInt(window.location.hash.slice(1), 10);
    if (!isNaN(hashStep) && hashStep !== currentStep) {
      setCurrentStep(hashStep);
    }
  }, [currentStep]);

  const renderStep = () => {
    switch (currentStep) {
      // Step 1 
      case 1:
        return (
          <PersonalDetails
            formDataStep={formDataStep}
            setFormDataStep={setFormDataStep}
            handleNextStep={handleNextStep}
            handleSubmit={handleSubmit}
            step={currentStep}
            sellerProfile="true"
          />
        );
      // Step 2
      case 2:
        return (
          <DiversityInformation
            formDataStep={formDataStep}
            setFormDataStep={setFormDataStep}
            handleNextStep={handleNextStep}
            handleSkip={handleSkip}
            skip="true"
            step={currentStep}
            accountType="seller"
            sellerProfile="true"
          />
        );
 // Step 3
 case 3:
        return (
          <UserCertificates
            formDataStep={formDataStep}
            setFormDataStep={setFormDataStep}
            handleNextStep={handleNextStep}
            handleUpdateSupplier={handleUpdateSupplier}
            step={currentStep}
            handleSkip={handleSkip}
            skip="true"
            accountType="seller"
            sellerProfile="true"
          />
        );
        case 4:
          return (
            <ProfileConfirmation
            handleSubmit={handleSubmit}
            title="Details Confirmed"
            subtitle="Your details have been confirmed and 
            your account has been updated"
            image=""
            // handleUpdateSupplier={handleUpdateSupplier}
            formDataStep={formDataStep}
            sellerProfile={sellerProfile}

            />
          );
     
      default:
        return null;
    }
  };
  return <>{renderStep()}</>;
};

export default SellerProfile;
