import React, { useState, useEffect } from "react";
import { useNavigate, Outlet, useLocation} from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
import "../Dashboard.css";
import DashboardSidebar from "../DashboardSideBar";
import settings from '../../../assets/settings.svg';
import userIcon from '../../../assets/sellerProfileIconWhite.svg'
import userTag from '../../../assets/user-tag.svg'
import integration from '../../../assets/integration.svg';
import ordersIcon from '../../../assets/ordersIcon.svg';
import users from '../../../assets/users-three.svg';


const SellerDashboard = () => {
  const navigate= useNavigate();

  const SupplierLinks = [
    { label: "Profile", to: "user-profile", icon: userIcon },
    { label: "Collaborations", to: "collaborations", icon: users },
    { label: "Product/Services", to: "product-and-services", icon: integration },
    { label: "Orders", to: "orders", icon: ordersIcon },
    { label: "Jobs & Offers", to: "jobs-and-offers", icon: userTag},
    { label: "Settings", to: "settings", icon: settings },
  ];
  useEffect(() => {
    navigate('/seller-dashboard/user-profile')
  }, [])
  return (
    <Container fluid>
      <Row>
        <Col xs={2} id="sidebar-wrapper">
          <DashboardSidebar
            links={SupplierLinks}
            url='/seller-dashboard/'
            link="Profile"
          />
        </Col>
        <Col xs={10} id="page-content-wrapper">
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
};

export default SellerDashboard;
