import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { useHistory } from 'react-router-dom';
import { Image } from "react-bootstrap";
import "./landingPage.css";
import EIcon from "../../assets/LandingPage/ELetterGreen.svg";
import cubeBlack from "../../assets/LandingPage/blackCube.svg";
import talkDeiLogo from "../../assets/LandingPage/Tdei logo.png";


const Footer = ({button, height, isMobile}) => {
  const [isRunning, setIsRunning] = useState(false);
  const footerRef = useRef(null);
  const navigate = useNavigate();
  const [hubSpotCodeAdded, setHubSpotCodeAdded] = useState(false);
  
  useEffect(() => {
      const script = document.createElement("script");
      script.setAttribute("charset", "utf-8");
      script.setAttribute("type", "text/javascript");
      script.setAttribute("src", "//js.hsforms.net/forms/embed/v2.js");
      document.body.appendChild(script);

      script.onload = () => {
        console.log("on load");
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "45694015",
            formId: "bc18351f-71cb-4bad-a79c-9520feaad39a",
            target: "#hubspot-sign-up",
            onFormSubmit: () => {
              const emailInput = document.getElementById("email-input").value;
              window.hbspt.forms.submit({
                portalId: '45694015',
                formId: 'bc18351f-71cb-4bad-a79c-9520feaad39a',
                fields: {
                  email: emailInput
                }
              });
            },
          });
        }
      };
    
    
  }, []);

 
console.log(isMobile, 'isMobile')
  const handleRedirectToPage = (page) => {
    switch (page) {
      case 'terms':
        window.open("/terms-of-use", "_blank");
        break;
      case 'privacy':
        window.open("/privacy-policy", "_blank");
        break;
        case 'ccpapolicy':
          window.open("/ccpa-policy", "_blank");
          break;
      case 'linkedin':
        window.open("https://www.linkedin.com/company/talkdei/", "_blank");
        break;
      case 'twitter':
        window.open("https://twitter.com/Talkdei", "_blank");
        break;
      case 'instagram':
        window.open("https://www.instagram.com/talkdei/", "_blank");
        break;
        case 'facebook':
          window.open("https://www.facebook.com/TalkDEI/", "_blank");
          break;
          case 'blog':
            window.open("https://medium.com/@Talkdei", "_blank");
            break;
            case 'producthunt':
              window.open("https://www.producthunt.com/posts/talkdei?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-talkdei", "_blank");
              break;
        case 'careers':
          window.open("https://wellfound.com/company/talkdei/jobs", "_blank");
          break;
          case 'company':
            navigate('/about')
            break;
          case 'suppliers-pricing':
            
            navigate('/pricing', {state: {tab: 'suppliers'}})
            break;
            case 'enterprise-pricing':
              navigate('/pricing', {state: {tab: 'enterprises'}})
              break;
      default:
        break;
    }
  };
  

  useEffect(() => {
    const handleScroll = () => {
      if (footerRef.current) {
        const footerPosition = footerRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (footerPosition < windowHeight && footerPosition > 0) {
          setIsRunning(true);
          setTimeout(() => setIsRunning(false), 100);
          setTimeout(() => setIsRunning(true), 200);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (

    <div className="footer" ref={footerRef} style={{ height: height ? "" : "1006px"}}>
      <div
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "center",
          gap: isMobile ? "50px" : "110px"
        }}
      >
        <div  style={{
          display: "flex",
          flexDirection: isMobile ? "row" : "",
          justifyContent: "center",
          gap:"110px"
        }}>
        <div className="footer-column">
          <h4>PRODUCT</h4>
          <ul>
            <li onClick={() => handleRedirectToPage('enterprise-pricing')}>Enterprise</li>
            <li onClick={() => handleRedirectToPage('suppliers-pricing')}>Supplier</li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>COMPANY</h4>
          <ul>
            <li onClick={() => handleRedirectToPage('company')}>About</li>
            <li onClick={() => handleRedirectToPage('blog')}>Blog</li>
            {/* <li onClick={() => handleRedirectToPage('producthunt')}>Product Hunt</li> */}
            <li onClick={() => handleRedirectToPage('careers')}>Careers</li>
          </ul>
        </div>
        </div>
        <div  style={{
          display: "flex",
          flexDirection: isMobile ? "row" : "",
          justifyContent: "center",
          gap:"110px"
        }}>
        <div className="footer-column">
          <h4>CONTACT</h4>
          <ul>
            <li onClick={() => handleRedirectToPage('linkedin')}>Linkedin</li>
            <li onClick={() => handleRedirectToPage('twitter')}>Twitter</li>
            <li onClick={() => handleRedirectToPage('instagram')}>Instagram</li>
            <li onClick={() => handleRedirectToPage('facebook')}>Facebook</li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>LEGAL</h4>
          <ul>
          <li onClick={() => handleRedirectToPage('terms')}>Terms & Conditions</li>
            <li onClick={() => handleRedirectToPage('privacy')}>Privacy Policy</li>
            <li onClick={() => handleRedirectToPage('ccpapolicy')}>CCPA Policy</li>
          </ul>
        </div>
        </div>
      </div>
      {!button && (
           <div className="subscribe-button-container">
        <div id='hubspot-sign-up' style={{height:'200px'}}></div>
        </div>
  
      )}

          <div className="running-text-container">
        <span className={`running-text ${isRunning ? "start-animation" : ""}`}  style={{ display: 'flex', alignItems: 'center' }}>
          Let’s TALK D <Image src={EIcon} alt="EIcon" className="eicon" /> I
        </span>
      </div>
    </div>


  );
};

export default Footer;
