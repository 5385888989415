import React, { useState, useEffect, useRef } from "react";
import AIDiscoveryJson from "../../assets/LandingPage/Desktop anim.json";
import Lottie from "react-lottie";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const AIReccomendationsSection = ({isMobile}) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [showStaticImage, setShowStaticImage] = useState(false);
  const animationRef = useRef(null);
  const spanRef = useRef(null);
  const isFirstRender = useRef(true);
  const [isSmallLaptop, setSmallLaptop] = useState(window.innerWidth > 768 && window.innerWidth <= 1280 );


  useEffect(() => {
    const handleResize = () => {
      setSmallLaptop(window.innerWidth > 768 && window.innerWidth <= 1280 );
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (isFirstRender.current) {
          isFirstRender.current = false;
          return;
        }
        if (entry.isIntersecting && !showStaticImage && spanRef.current) {
          setIsAnimating(true);
          spanRef.current.classList.add("animate");
        } else if (spanRef.current) {
          setIsAnimating(false);
        }
      });
    }, {});

    if (spanRef.current) {
      observer.observe(spanRef.current);
    }

    return () => {
      if (spanRef.current) {
        observer.unobserve(spanRef.current);
      }
    };
  }, [showStaticImage]);

  const handleAnimationComplete = () => {
    setIsAnimating(false);
  };

  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: AIDiscoveryJson,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="supplier-discovery-container" ref={animationRef}>
      <span ref={spanRef} className="text">
        Seamless Recommended Supplier Discovery
      </span>
      <Lottie
        options={defaultOptions}
        // height={ isMobile ? 226 : 558}
        width={isMobile ? 287 : (isSmallLaptop ? 537 : 659)}
        height={isMobile ? 226 : (isSmallLaptop ? 422 : 558)}
        // width={isMobile ? 287 : 659}
        speed={0.4}
        isPaused={!isAnimating}
        eventListeners={[
          {
            eventName: "complete",
            callback: handleAnimationComplete,
          },
        ]}
      />
      <p>
        Embrace supplier diversity sourcing efficiency with TalkDEI AI-powered
        search. Our algorithms prioritize diverse suppliers, delivering
        precision and industry alignment.
      </p>
    </div>
  );
};

export default AIReccomendationsSection;
