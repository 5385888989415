import React, { useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import "../../Dashboard.css";
import DashboardNavbar from "../../DashboardNavbar";
import search from "../../../../assets/searchWhiteIcon.svg";
import searchGrey from "../../../../assets/search.svg";
import galleryicon from "../../../../assets/galleryicon.svg";
import listicon from "../../../../assets/listicon.svg";
import PaginationBar from "../../../PaginationBar/PaginationBar";
import ListOfSuppliers from "./ListOfSuppliers";
import GalleryOfSuppliers from "./GalleryOfSuppliers";
import UserProfileComponent from "../../../SellerProfile/UserProfileComponent";
import Overlay from "../../../Overlay/Overlay";
import PdfImportButton from "./PdfImportButton";
import axios from "axios";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { SupplierCompanyData } from "../../../../DummyData";

const Marketplace = () => {
  const [selectedCard, setSelectedCard] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [viewType, setViewType] = useState("gallery");
  const [currentPage, setCurrentPage] = useState(1);
  const [isUserProfileOpen, setIsUserProfileOpen] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  // const suppliers = useSelector((state) => state.suppliers.suppliers);

  const suppliers = SupplierCompanyData;

  const listItemsPerPage = 3;
  const galleryItemsPerPage = 6;
  const totalItems = suppliers.length;
  const navigate = useNavigate();

  const handleSearch = async () => {
    try {
      // API endpoint for searching suppliers
      const apiEndpoint = "YOUR_API";

      // Make a GET request to the API with the search query
      const response = await axios.get(apiEndpoint, {
        params: { query: searchQuery },
      });

      // Assuming the API returns JSON data
      const data = response.data;

      // Handle the data, update state, or perform any other actions
      console.log("Search results:", data);
    } catch (error) {
      console.error("Error during search:", error);
    }
  };

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
    console.log(e.target.value);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const startIndex =
    viewType === "list"
      ? (currentPage - 1) * listItemsPerPage
      : (currentPage - 1) * galleryItemsPerPage;
  const visibleListSuppliers = suppliers.slice(
    startIndex,
    startIndex + listItemsPerPage
  );
  const visibleGallerySuppliers = suppliers.slice(
    startIndex,
    startIndex + galleryItemsPerPage
  );

  const handleCardSelection = (user) => {
    if (isClosed) {
      setSelectedCard(false);
      setIsUserProfileOpen(false);
      setIsClosed(false);
      return;
    }
    setSelectedCard(true);
    setSelectedSupplier(user);
    setIsUserProfileOpen(true);
  };

  const handleCloseUserProfile = () => {
    setSelectedCard(false);
    setIsUserProfileOpen(false);
    setIsClosed(true);
  };

  const toggleView = () => {
    setViewType((prevView) => (prevView === "gallery" ? "list" : "gallery"));
  };
  const handleGoBack = () => {
    navigate("/buyer-dashboard/overview");
  };

  return (
    <>
      <DashboardNavbar
        title={"Marketplace"}
        subtitle={"Source the best suppliers"}
        handleBack={handleGoBack}
      />
      <Container fluid style={{ position: "relative" }}>
        <div>
          <Row>
            <Col>
              <div className="filter-container">
                <div style={{ marginLeft: "31px", display: "flex" }}>
                  <input
                    type="text"
                    placeholder="Input your prompt to match your search"
                    className="supplier-search-input"
                    aria-label="Search"
                    onChange={handleChange}
                    value={searchQuery}
                    style={{
                      backgroundImage: `url(${searchGrey})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "left 10px center",
                      color: "#A8A8A8",
                      fontSize: "16px",
                      fontStyle: "italic",
                      fontWeight: "400",
                    }}
                  />

                  <button
                    type="submit"
                    className="filter-search-button"
                    style={{
                      backgroundImage: `url(${search})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "left 5px center",
                      paddingRight: 0,
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                    // onClick={handleSearch}
                  >
                    Search
                  </button>

                  <PdfImportButton />
                </div>
                <div>
                  <Row className="toggle-views justify-content-end">
                    <Col className="view-icons">
                      <Image
                        src={galleryicon}
                        alt="Gallery View"
                        className={`icon ${
                          viewType === "gallery" ? "active" : ""
                        }`}
                        onClick={toggleView}
                      />
                      <Image
                        src={listicon}
                        alt="List View"
                        className={`icon ${
                          viewType === "list" ? "active" : ""
                        }`}
                        onClick={toggleView}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      {viewType === "list" ? (
        <ListOfSuppliers
          suppliers={visibleListSuppliers}
          onCardClick={handleCardSelection}
        />
      ) : (
        <GalleryOfSuppliers
          suppliers={visibleGallerySuppliers}
          onCardClick={handleCardSelection}
        />
      )}
      {isUserProfileOpen && <Overlay />}
      {selectedCard && (
        <div
          className="additional-bar"
          style={{
            width: "500px",
            position: "fixed",
            right: 0,
            top: 0,
            height: "100vh",
            background: "white",
            zIndex: 999,
          }}
        >
          <UserProfileComponent
            supplier={selectedSupplier}
            onClose={handleCloseUserProfile}
            backUrl="/buyer-dashboard/marketplace"
          />
        </div>
      )}
      <PaginationBar
        currentPage={currentPage}
        totalPages={
          viewType === "list"
            ? Math.ceil(totalItems / listItemsPerPage)
            : Math.ceil(totalItems / galleryItemsPerPage)
        }
        handlePageChange={handlePageChange}
      />
    </>
  );
};

export default Marketplace;
