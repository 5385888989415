
// actions/supplierActions.js
import { SET_TIER_ONE_SUPPLIERS, SET_TIER_TWO_SUPPLIERS, SET_PENDING_SUPPLIERS, SET_SUPPLIERS, UPDATE_SUPPLIER_BY_ID, UPDATE_SUPPLIER_TIER_ONE_BY_ID, UPDATE_SUPPLIER_TIER_TWO_BY_ID, UPDATE_COMPANY_BY_SUPPLIER_ID, UPDATE_SUPPLIER_PROFILE_PROPERTY, FETCH_ALL_SUPPLIERS, FETCH_SUPPLIER_BY_ID, UPDATE_COMPANY_BY_ID } from '../constants/constants';
import { ENDPOINTS } from '../URL';
import axios from 'axios';

export const fetchAllSuppliers = () => {
  const url = ENDPOINTS.GET_SELLERS

  console.log(url, 'url')
  return async dispatch => {
    try {
      const response = await axios.get(url); 
      console.log('response', response)
      dispatch({
        type: FETCH_ALL_SUPPLIERS,
        payload: response.data
      });
    } catch (error) {
      console.error('Error fetching suppliers:', error);
    }
  };
};
export const fetchSupplierById = (supplierId) => {
  const url = ENDPOINTS.FETCH_SUPPLIER_BY_ID(supplierId);
  return async dispatch => {
    try {
      const response = await axios.get(url);
      console.log('response', response);
      dispatch({
        type: FETCH_SUPPLIER_BY_ID,
        payload: response.data
      });
    } catch (error) {
      console.error('Error fetching supplier by ID:', error);
    }
  };
};

export const setTierOneSuppliers = (suppliers) => ({
  type: SET_TIER_ONE_SUPPLIERS,
  payload: suppliers,
});

export const setTierTwoSuppliers = (suppliers) => ({
  type: SET_TIER_TWO_SUPPLIERS,
  payload: suppliers,
});

export const setPendingSuppliers = (suppliers) => ({
  type: SET_PENDING_SUPPLIERS,
  payload: suppliers,
});

// export const updateSupplierById = (supplierId, supplierProfile ) => ({
//   type: "EDIT_SUPPLIER_BY_ID",
//   payload: {
//     supplierId,
//     supplierProfile
//   }
// });

export const updateSupplierById = (supplierId, supplierProfile) => (
  {
  type: UPDATE_SUPPLIER_BY_ID,
  payload: {
    supplierId,
    supplierProfile
  }
});


// export const updateSupplierByIdSignUpp = ({ supplierId, supplierProfile }) => ({
//   type: UPDATE_SUPPLIER_BY_ID,
//     payload: {
//       supplierId,
//       supplierProfile
//     }
  
// });

// Action Creator
export const updateSupplierByIdSignUp = ({ supplierId, supplierProfile }) => ({
  type: UPDATE_SUPPLIER_BY_ID,
  payload: {
    supplierId,
    supplierProfile,
  },
});

export const updateSupplierProfileProperty = ({ supplierId, property, value }) => ({
  type: UPDATE_SUPPLIER_PROFILE_PROPERTY,
  payload: {
    supplierId,
    property,
    value
  }
});

export const updateCompanyBySupplierId = (supplierId, updatedCompany) => {
  console.log('in action Update Company', supplierId, updatedCompany);

  const url = ENDPOINTS.UPDATE_COMPANY_BY_ID(supplierId);
  return async (dispatch) => {
    try {
      const response = await axios.post(url, updatedCompany); // Send updatedCompany directly
      dispatch({
        type: UPDATE_COMPANY_BY_ID,
        payload: {
          supplierId, 
          updatedCompany,
        },
      });
      console.log('Update successful', response.data);
    } catch (error) {
      console.error('Update failed', error);
    }
  };
};

export const updateSupplierTierOneById = (id, newData) => ({
  type: UPDATE_SUPPLIER_TIER_ONE_BY_ID,
  payload: { id, newData },
});

export const updateSupplierTierTwoById = (id, newData) => ({
  type: UPDATE_SUPPLIER_TIER_TWO_BY_ID,
  payload: { id, newData },
});

export const removeSupplierFromTierOne = (id) => ({
  type: 'REMOVE_SUPPLIER_FROM_TIER_ONE',
  payload: { id }
});

export const removeSupplierFromTierTwo = (id) => ({
  type: 'REMOVE_SUPPLIER_FROM_TIER_TWO',
  payload: { id }
});

export const addSupplierToTierOne = (newData) => ({
  type: 'ADD_SUPPLIER_TO_TIER_ONE',
  payload: { newData }
});

export const addSupplierToTierTwo = (newData) => ({
  type: 'ADD_SUPPLIER_TO_TIER_TWO',
  payload: { newData }
});

export const setSuppliers = (suppliers) => {
  console.log('New suppliers data:', suppliers); 
  return {
    type: SET_SUPPLIERS,
    payload: suppliers,
  };

  
};


export const updateSupplierByIdAsync = ({ supplierId, supplierProfile }) => {
  const url = ENDPOINTS.UPDATE_PROFILE_BY_ID(supplierId);
  return async dispatch => {
    try {
      const response = await axios.post(url, supplierProfile );
      dispatch(updateSupplierById(supplierId, supplierProfile));
    } catch (error) {
      console.error('Error updating supplier:', error);
    }
  };
};

// Thunk Action Creator
export const updateSupplierByIdSignUpThunk = ({ supplierId, supplierProfile }) => {
  const url = ENDPOINTS.UPDATE_PROFILE_BY_ID(supplierId);
  return async dispatch => {
    try {
      const response = await axios.post(url, supplierProfile);
      console.log("RESPONSE FROM UPDATE", response);

      // Dispatch the action with the correct payload structure
      dispatch(updateSupplierByIdSignUp({ supplierId, supplierProfile }));
    } catch (error) {
      console.error('Error updating supplier:', error);
    }
  };
};


// export const updateSupplierByIdSignUp = ({ supplierId, supplierProfile }) => {
//   const url = ENDPOINTS.UPDATE_PROFILE_BY_ID(supplierId);
//   return async dispatch => {
//     try {
//       const response = await axios.post(url, supplierProfile );
//       console.log("RESPONSE FROM UPDATE", response)
//       dispatch(updateSupplierByIdSignUpp(supplierId, supplierProfile));
//     } catch (error) {
//       console.error('Error updating supplier:', error);
//     }
//   };
// };