import React, { useState, useRef } from "react";
import { Container, Row, Col, Image, Form, FormGroup } from "react-bootstrap";
import DashboardNavbar from "../../DashboardNavbar";
import "../../Dashboard.css";
import moneyBagIcon from "../../../../assets/moneyBagIcon.svg";
import penIcon from "../../../../assets/penIcon.svg";
import penIconWhite from "../../../../assets/penIconWhite.svg"
import moneySpendIcon from "../../../../assets/moneySpend.svg";
import PieChart from "./PieChart";
import LineChart from "./LineChart";
import EngagementTable from "./EngagementTable";
import Overlay from "../../../Overlay/Overlay";
import redDot from "../../../../assets/redDot.svg";
import DiversityPieChart from "./DiversityPieChart";

const initialMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const initialMonthlySpent = initialMonths.reduce((acc, month) => {
  acc[month] = 0;
  return acc;
}, {});

const Overview = () => {
  const monthSpendsDropdownRef = useRef();
  const [isMonthSpendsDropdownOpen, setMonthSpendsDropdownOpen] =
    useState(false);
  const [isEditBudgetClicked, setEditBudgetClicked] = useState(false);
  const [isEditMonthlySpentClicked, setEditMonhlySpentClicked] =
    useState(false);
  const [budget, setBudget] = useState("200,000");
  const [currentAmount, setCurrentAmount] = useState("");
  const [monthlySpends, setMonthlySpends] = useState(initialMonthlySpent);
  const [currentSpendsMonth, setCurrentSpendsMonth] = useState(0);
  const [currentSpendsAmount, setCurrentSpendsAmount] = useState(0);
  const [lastUpdatedSpendAmount, setLastUpdatedSpendAmount] = useState(0);
  const [isExceedingBudget, setExceedsBudget] = useState(true);
  // const [isExceedingBudget, setExceedsBudget] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isSpendsHovered, setSpendsIsHovered] = useState(false);

  const spendsDummyData =  {
  January: "20,000",
  February: "50,000",
  March: "50,000",
  April: "40,000",
  May: "40,000",
  June: "50,000",
  July: "30,000",
  August: "50,000",
  September: "60,000",
  October: "70,000",
  November: "60,000",
  December: "80,000"
  }


  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleSpendsMouseEnter = () => {
    setSpendsIsHovered(true);
  };

  const handleSpendsMouseLeave = () => {
    setSpendsIsHovered(false);
  };

  const updateBudget = (updatedBudget) => {
    setBudget(updatedBudget);
    setCurrentAmount("");
    if (lastUpdatedSpendAmount !== 0) {
      const sanitizedValue = lastUpdatedSpendAmount.replace(/,/g, "");
      const numericValue = parseInt(sanitizedValue, 10);
      handleExceedingBudget(numericValue, updatedBudget);
    }
  };

  const handleEditBudgetButton = () => {
    setEditBudgetClicked(true);
  };
  const handleEditMonthlySpentButton = () => {
    setEditMonhlySpentClicked(true);
    setCurrentSpendsAmount("");
  };

  const handleSave = () => {
    setEditBudgetClicked(false);
    updateBudget(currentAmount);
  };

  const handleCancel = () => {
    setEditBudgetClicked(false);
    setEditMonhlySpentClicked(false);
  };

  const handleToggleMonthSpends = () => {
    setMonthSpendsDropdownOpen(!isMonthSpendsDropdownOpen);
  };

  const handleExceedingBudget = (amount, updateBudget) => {
    const sanitizedValue = updateBudget.replace(/,/g, "");
    const numericValue = parseInt(sanitizedValue, 10);

    const isExceedingBudget = amount > (numericValue || 0);

    setExceedsBudget(isExceedingBudget);
  };

  const updateMonthlySpend = (month, monthlySpend) => {
    setMonthlySpends((prevState) => ({
      ...prevState,
      [month]: monthlySpend,
    }));
    let amount;
    if (lastUpdatedSpendAmount !== 0) {
      amount =
        Number(lastUpdatedSpendAmount.replace(",", ".")) +
        Number(currentSpendsAmount.replace(",", "."));
    } else {
      amount =
        lastUpdatedSpendAmount + Number(currentSpendsAmount.replace(",", "."));
    }

    setLastUpdatedSpendAmount((amount * 1000).toLocaleString());
    handleExceedingBudget(amount * 1000, budget);
    setCurrentSpendsMonth("");
  };

  const handleSaveMonthlySpend = () => {
    setEditMonhlySpentClicked(false);
    updateMonthlySpend(currentSpendsMonth, currentSpendsAmount);
  };

  const divFlexRow = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };
  const divFlexColumn = {
    display: "flex",
    flexDirection: "column",
  };

  return (
    <div style={{ position: "relative" }}>
      <DashboardNavbar title={"Overview"} arrow={false} />
      <Container fluid>
        <Row style={divFlexRow}>
          <div style={divFlexColumn}>
            <div style={divFlexRow}>
              <Col className="overview-budget-cards monthly-spends-card">
                <div style={divFlexRow}>
                  <Image
                    src={moneyBagIcon}
                    alt=""
                    style={{ marginRight: "9px" }}
                  />
                  <div
                    style={{
                      ...divFlexRow,
                      justifyContent: "space-between",
                      flex: 2,
                    }}
                  >
                    <div style={divFlexColumn}>
                      <span style={{ fontWeight: 600, textAlign: "left" }}>
                        Budget
                      </span>
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: 400,
                          color: "#666",
                          textAlign: "left",
                        }}
                      >
                        Last updated{" "}
                        {budget
                          ? new Date().toLocaleDateString("en-US", {
                              day: "numeric",
                              month: "short",
                            })
                          : ""}
                      </p>
                    </div>
                    <button
                      className="overview-edit-btn"
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      style={{
                        backgroundImage: `url(${isHovered ? penIconWhite : penIcon})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "right 5px center",
                      }}
                      onClick={() => handleEditBudgetButton()}
                    >
                      Edit
                    </button>
                  </div>
                </div>
                <p
                  style={{
                    display: "flex",
                    marginTop: "45px",
                    color: "#666",
                    fontSize: "34px",
                    color: "#1D2739",
                  }}
                >
                  {`$${budget || 0}`}
                </p>
              </Col>
              <Col className="overview-budget-cards monthly-spends-card">
                <div style={divFlexRow}>
                  <Image
                    src={moneySpendIcon}
                    alt=""
                    style={{ marginRight: "9px" }}
                  />
                  <div
                    style={{
                      ...divFlexRow,
                      justifyContent: "space-between",
                      flex: 2,
                    }}
                  >
                    <div style={divFlexColumn}>
                      <span style={{ fontWeight: 600, textAlign: "left" }}>
                        Monthly Spend
                      </span>
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: 400,
                          color: "#666",
                          textAlign: "left",
                        }}
                      >
                        Last updated{" "}
                        {currentSpendsAmount
                          ? new Date().toLocaleDateString("en-US", {
                              day: "numeric",
                              month: "short",
                            })
                          : ""}
                      </p>
                    </div>
                    <button
                      className="overview-edit-btn"
                      onMouseEnter={handleSpendsMouseEnter}
                      onMouseLeave={handleSpendsMouseLeave}
                      style={{
                        backgroundImage: `url(${isSpendsHovered ? penIconWhite : penIcon})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "right 5px center",
                        transition: "filter 0.3s background 0.3s",
                      }}
                      onClick={() => handleEditMonthlySpentButton()}
                    >
                      Edit
                    </button>
                  </div>
                </div>
                <div
                  style={{
                    ...divFlexRow,
                    marginTop: "45px",
                    justifyContent: "space-between",
                  }}
                >
                  <p
                    style={{
                      color: "#666",
                      fontSize: "34px",
                      color: "#1D2739",
                    }}
                  >
                    {`$${currentSpendsAmount || '250,000'}`}
                  </p>
                  {isExceedingBudget && (
                    <div style={divFlexRow}>
                      <Image
                        src={redDot}
                        alt=""
                        style={{ marginRight: "4px" }}
                      />
                      <p style={{ color: "#E10000", fontSize: "14px" }}>
                        Exceeding budget
                      </p>
                    </div>
                  )}
                </div>
              </Col>
            </div>
            <Col>
              <div className="line-chart-container">
                <LineChart 
                // monthlySpends={monthlySpends}
                monthlySpends={spendsDummyData}
                 />
              </div>
            </Col>
          </div>
          <Col>
            <div className="engagement-metrics-container">
              <span style={{ fontWeight: 600 }}>Engagement Metrics</span>
              <div>
                <PieChart title={"Total number of Actions"} />
              </div>
              <div>
                <DiversityPieChart
                  title="No of Diverse Suppliers Onboarded"
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <EngagementTable />
          </Col>
        </Row>
      </Container>

      {isEditBudgetClicked && (
        <>
          <Overlay />
          <div className="edit-budget-container">
            <Form>
              <div style={divFlexRow}>
                <Image
                  src={moneyBagIcon}
                  alt=""
                  style={{ marginRight: "9px" }}
                />
                <span
                  style={{
                    fontWeight: 600,
                    textAlign: "left",
                    fontSize: "20px",
                  }}
                >
                  Budget
                </span>
              </div>
              <Form.Group
                as={Col}
                controlId="amount"
                className="single-input"
                style={{ margin: "40px 0 20px 0" }}
              >
                <Form.Label className="input-title-edit-profile">Amount</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="$50,000"
                  className="custom-edit-profile-input"
                  value={currentAmount}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue === "") {
                      setCurrentAmount("");
                    } else {
                      const sanitizedValue = inputValue.replace(/[,.]/g, "");
                      const numericValue = parseFloat(sanitizedValue);
                      const formattedValue = numericValue.toLocaleString();
                      setCurrentAmount(formattedValue);
                    }
                  }}
                />
              </Form.Group>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "20px",
                  position: "absolute",
                  bottom: "20px",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <button
                  variant="primary"
                  type="submit"
                  className="custom-edit-profile-button"
                  onClick={() => handleSave()}
                >
                  Save
                </button>
                <button
                  onClick={() => handleCancel()}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#7E7E7E",
                    border: "none",
                    background: "none",
                    marginTop: "10px",
                  }}
                >
                  Cancel
                </button>
              </div>
            </Form>
          </div>
        </>
      )}

      {isEditMonthlySpentClicked && (
        <>
          <Overlay />
          <div className="edit-budget-container">
            <Form>
              <div style={divFlexRow}>
                <Image
                  src={moneySpendIcon}
                  alt=""
                  style={{ marginRight: "9px" }}
                />
                <span
                  style={{
                    fontWeight: 600,
                    textAlign: "left",
                    fontSize: "20px",
                  }}
                >
                  Monthly Spend
                </span>
              </div>
              <Form.Group
                as={Col}
                controlId="companyState"
                className="single-input"
                style={{ margin: "40px 0 0 0" }}
              >
                <Form.Label className="input-title-edit-profile">Month</Form.Label>
                <div
                  ref={monthSpendsDropdownRef}
                  className={`custom-edit-profile-input custom-dropdown-container ${
                    isMonthSpendsDropdownOpen ? "open" : ""
                  }
      `}
                >
                  <div
                    className="custom-dropdown-edit-profile"
                    onClick={handleToggleMonthSpends}
                  >
                    <span className="selected-option">
                      {currentSpendsMonth || "Select month"}
                    </span>
                    <ul
                      className={`custom-dropdown-list ${
                        isMonthSpendsDropdownOpen ? "show" : ""
                      }`}
                    >
                      <li
                        className={`custom-dropdown-item ${
                          currentSpendsMonth ? "" : "disabled"
                        }`}
                        onClick={() => setCurrentSpendsMonth("")}
                      ></li>
                      {initialMonths.map((month, index) => (
                        <li
                          key={index}
                          className="custom-dropdown-item"
                          onMouseDown={(e) => {
                            e.preventDefault();
                            setCurrentSpendsMonth(month);
                          }}
                        >
                          {month}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Form.Group>
              <Form.Group
                as={Col}
                controlId="amount"
                className="single-input"
                style={{ margin: "40px 0 20px 0" }}
              >
                <Form.Label className="input-title-edit-profile">Amount</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="$50,000"
                  className="custom-edit-profile-input"
                  value={currentSpendsAmount}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (inputValue === "") {
                      setCurrentSpendsAmount("");
                    } else {
                      const sanitizedValue = inputValue.replace(/[,.]/g, "");
                      const numericValue = parseFloat(sanitizedValue);
                      const formattedValue = numericValue.toLocaleString();
                      setCurrentSpendsAmount(formattedValue);
                    }
                  }}
                />
              </Form.Group>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "20px",
                  position: "absolute",
                  bottom: "20px",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              >
                <button
                  variant="primary"
                  type="submit"
                  className="custom-edit-profile-button"
                  onClick={() => handleSaveMonthlySpend()}
                >
                  Save
                </button>
                <button
                  onClick={() => handleCancel()}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#7E7E7E",
                    border: "none",
                    background: "none",
                    marginTop: "10px",
                  }}
                >
                  Cancel
                </button>
              </div>
            </Form>
          </div>
        </>
      )}
    </div>
  );
};

export default Overview;
