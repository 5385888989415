import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Button,
  Card,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import DashboardNavbar from "../../DashboardNavbar";
import "../../Dashboard.css";
import ariba from "../../../../assets/ariba.svg";
import oracle from "../../../../assets/oracle.svg";
import microsoft from "../../../../assets/microsoft.png";
import csvfile from "../../../../assets/csvfile.svg";
import galleryicon from "../../../../assets/galleryicon.svg";
import listicon from "../../../../assets/listicon.svg";
import { useNavigate } from "react-router";

const Integration = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const [viewType, setViewType] = useState("gallery");
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/dashboard");
  };

  const handleCardSelection = (cardType) => {
    setSelectedCard(cardType);
  };

  const toggleView = () => {
    setViewType((prevView) => (prevView === "gallery" ? "list" : "gallery"));
  };

  const renderCards = () => {
    switch (viewType) {
      case "gallery":
        return (
          <div className="integration-cards-container">
            <Col
              className="integration-card"
              onClick={() => handleCardSelection("ariba")}
            >
              <Card.Body>
                <Row className="integration-card-title">
                  <Col>
                    <Image
                      className="integration-card-image"
                      src={ariba}
                      alt=""
                      width="45px"
                      height="46px"
                    />
                  </Col>
                  <Col>
                    <Card.Subtitle className="integration-card-subtitle">
                      SAP Ariba
                    </Card.Subtitle>
                  </Col>
                </Row>
                <Card.Text className="integration-card-desc">
                  Integrate SAP Ariba into your dashboard to help with spend
                  management
                </Card.Text>
                <Button
                  type="submit"
                  className="integration-card-button"
                  style={{ marginTop: "28px" }}
                >
                  Coming soon
                </Button>
              </Card.Body>
            </Col>
            <Col>
              <Card
                className="integration-card"
                onClick={() => handleCardSelection("buyer")}
              >
                <Card.Body>
                  <Row className="integration-card-title">
                    <Col>
                      <Image
                        className="integration-card-image"
                        src={oracle}
                        alt=""
                        width="45px"
                        height="45px"
                        style={{ background: "#E32124", borderRadius: "10px" }}
                      />
                    </Col>
                    <Col>
                      <Card.Subtitle className="integration-card-subtitle">
                        Oracle SCM
                      </Card.Subtitle>
                    </Col>
                  </Row>
                  <Card.Text className="integartion-card-desc" style={{color:"#7b7b7b"}}>
                    Seamlessly connect your supply chain to create a resilient
                    network
                  </Card.Text>
                  <Button
                    type="submit"
                    className="integration-card-button"
                    style={{ marginTop: "29px" }}
                  >
                    Coming soon
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card
                className="integration-card"
                onClick={() => handleCardSelection("buyer")}
              >
                <Card.Body>
                  <Row className="integration-card-title">
                    <Col>
                      <Image
                        className="integration-card-image"
                        src={microsoft}
                        alt=""
                        width="45px"
                        height="45px"
                      />
                    </Col>
                    <Col>
                      <Card.Subtitle className="integration-card-subtitle">
                        Microsoft Dynamics 365
                      </Card.Subtitle>
                    </Col>
                  </Row>
                  <Card.Text className="integration-card-desc">
                    Connect your teams, processes, and data across your entire
                    organisation
                  </Card.Text>
                  <Button
                    type="submit"
                    className="integration-card-button"
                    style={{ marginTop: "28px" }}
                  >
                    Coming soon
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card
                className="integration-card"
                onClick={() => handleCardSelection("seller")}
              >
                <Card.Body>
                  <Row className="integration-card-title">
                    <Col>
                      <Image
                        className="integration-card-image"
                        src={csvfile}
                        alt=""
                        width="45px"
                        height="45px"
                      />
                    </Col>
                    <Col>
                      <Card.Subtitle className="integration-card-subtitle">
                        CSV File
                      </Card.Subtitle>
                    </Col>
                  </Row>
                  <Card.Text className="integration-card-desc">
                    Import data using CSV
                  </Card.Text>
                  <Button
                    type="submit"
                    className="integration-card-button"
                    style={{ marginTop: "78px" }}
                  >
                    Coming soon
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </div>
        );
      case "list":
        return (
          <div className="integration-list-container">
            <Row>
              <Col
                className="integration-list"
                onClick={() => handleCardSelection("ariba")}
              >
                <Card.Body>
                  <div className="integration-list-content">
                    <div className="integration-list-info">
                      <Image
                        className="integration-card-image"
                        src={ariba}
                        alt=""
                        width="45px"
                        height="46px"
                      />
                      <div>
                        <Card.Subtitle className="integration-list-subtitle">
                          SAP Ariba
                        </Card.Subtitle>
                        <p className="integration-list-desc">
                          Integrate SAP Ariba into your dashboard to help with
                          spend management
                        </p>
                      </div>
                    </div>
                    <Button type="submit" className="integration-list-button">
                      Coming soon
                    </Button>
                  </div>
                </Card.Body>
              </Col>
              <Col
                className="integration-list"
                onClick={() => handleCardSelection("oracle")}
              >
                <Card.Body>
                  <div className="integration-list-content">
                    <div className="integration-list-info">
                      <Image
                        className="integration-card-image"
                        src={oracle}
                        alt=""
                        width="45px"
                        height="46px"
                        style={{ background: "#E32124" }}
                      />
                      <div>
                        <Card.Subtitle className="integration-list-subtitle">
                          Oracle SCM
                        </Card.Subtitle>
                        <p className="integration-list-desc">
                          Seamlessly connect your supply chain to create a
                          resilient network
                        </p>
                      </div>
                    </div>
                    <Button type="submit" className="integration-list-button">
                      Coming soon
                    </Button>
                  </div>
                </Card.Body>
              </Col>
              <Col
                className="integration-list"
                onClick={() => handleCardSelection("microsoft")}
              >
                <Card.Body>
                  <div className="integration-list-content">
                    <div className="integration-list-info">
                      <Image
                        className="integration-card-image"
                        src={microsoft}
                        alt=""
                        width="45px"
                        height="46px"
                      />
                      <div>
                        <Card.Subtitle className="integration-list-subtitle">
                          Microsoft Dynamics 365
                        </Card.Subtitle>
                        <p className="integration-list-desc">
                          Connect your teams, processes, and data across your
                          entire organisation
                        </p>
                      </div>
                    </div>
                    <Button type="submit" className="integration-list-button">
                      Coming soon
                    </Button>
                  </div>
                </Card.Body>
              </Col>
              <Col
                className="integration-list"
                onClick={() => handleCardSelection("csv")}
              >
                <Card.Body>
                  <div className="integration-list-content">
                    <div className="integration-list-info">
                      <Image
                        className="integration-card-image"
                        src={csvfile}
                        alt=""
                        width="45px"
                        height="46px"
                      />
                      <div>
                        <Card.Subtitle className="integration-list-subtitle">
                          CSV File
                        </Card.Subtitle>
                        <p className="integration-list-desc">
                          Import data using CSV
                        </p>
                      </div>
                    </div>
                    <Button type="submit" className="integration-list-button">
                      Coming soon
                    </Button>
                  </div>
                </Card.Body>
              </Col>
            </Row>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <DashboardNavbar title={"Integrations"} handleBack={handleGoBack} />
      <Container fluid className="integration-main-container">
        <Row className="integration-tab-toggle-views">
          <Col>
            <p className="container-label">Integrations</p>
          </Col>
          <Col className="integration-tab-view-icons">
            <Image
              src={galleryicon}
              alt="Gallery View"
              className={`icon ${viewType === "gallery" ? "active" : ""}`}
              onClick={toggleView}
            />
            <Image
              src={listicon}
              alt="List View"
              className={`icon ${viewType === "list" ? "active" : ""}`}
              onClick={toggleView}
            />
          </Col>
        </Row>
        <Row>{renderCards()}</Row>
      </Container>
    </>
  );
};

export default Integration;
