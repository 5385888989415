// const APIEndpoint = process.env.REACT_APP_APIENDPOINT || 'https://www.talkdei.io/';
const APIEndpoint = process.env.REACT_APP_APIENDPOINT || 'https://talkdei-api.azurewebsites.net/api/v1/';
export const ENDPOINTS = {
APIEndpoint: () => APIEndpoint,
SIGN_IN: `${APIEndpoint}/sign-in`,
SIGN_UP: `${APIEndpoint}/sign-up`,
GET_SELLERS: `${APIEndpoint}seller/profile`,
GET_BUYERS: `${APIEndpoint}/get-buyers`,
VERIFICATION: `${APIEndpoint}/verification`,
SELLER_SIGN_UP: `${APIEndpoint}seller`,
BUYER_SIGN_UP: `${APIEndpoint}buyer`,
GET_OTP: `${APIEndpoint}auth/otp`,
VERIFY_OTP: `${APIEndpoint}auth/otp/verification`,
UPDATE_PROFILE_BY_ID: (supplierId) =>  `${APIEndpoint}seller/${supplierId}/profile/supplier`,
UPDATE_COMPANY_BY_ID: (supplierId) =>  `${APIEndpoint}seller/${supplierId}/profile/company`,
FETCH_SUPPLIER_BY_ID: (supplierId) =>  `${APIEndpoint}seller/${supplierId}/profile`
}
export const ApiEndpoint = APIEndpoint;