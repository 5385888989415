import React, { useEffect, useState } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import "../Dashboard.css";
import DashboardSidebar from "../DashboardSideBar";
import settings from "../../../assets/settings.svg";
import management from "../../../assets/management.svg";
import userTag from "../../../assets/user-tag.svg";
import integration from "../../../assets/integration.svg";
import material from "../../../assets/material.svg";
import users from "../../../assets/users-three.svg";
import Overlay from "../../Overlay/Overlay";
import checkmark from "../../../assets/LandingPage/checkmarkWhite.svg";

const BuyerDashboard = () => {
  const navigate = useNavigate();
  const [popUp, setPopUp] = useState(true);

  const overlayStyle = {
    background: "rgba(0, 0, 0, 0.55)",
  };

  const handleMeetingSchedule = () => {
    window.open(
      "https://outlook.office365.com/book/EnterpriseTalkDEI@talkdei.io/",
      "_blank"
    );
  };

  const BuyerLinks = [
    { label: "Overview", to: "overview", icon: material },
    { label: "Collaborations", to: "collaborations", icon: users },
    { label: "Integrations", to: "integrations", icon: integration },
    { label: "Marketplace", to: "marketplace", icon: userTag },
    { label: "Management", to: "management", icon: management },
    { label: "Settings", to: "settings", icon: settings },
  ];

  useEffect(() => {
    navigate("/buyer-dashboard/overview");
  }, []);

  return (
    <div style={{ position: "relative" }}>
      {popUp && (
        <>
          <Overlay overlayStyle={overlayStyle} />
          <div
            style={{
              position: "absolute",
              top: "20%",
              left: "50%",
              transform: "translateX(-50%)",
              background: "#fff",
              borderRadius: "8px",
              zIndex: 1000,
            }}
          >
            <div
              style={{
                display: "flex",
                width: "658px",
                paddingBottom: "58px",
                flexDirection: "column",
                alignItems: "center",
                gap: "85px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  background:
                    "linear-gradient(270deg, #CC9866 0%, #722592 102.7%)",
                  height: "78px",
                  width: "100%",
                  padding: "27px 428px 27px 19px",
                  alignItems: "center",
                  borderRadius: "8px 8px 0 0",
                }}
              >
                <img src={checkmark} alt="" />
                <span
                  style={{
                    color: "#FFF",
                    marginLeft: "10px",
                    fontFamily: "Lato",
                    fontSize: "20px",
                    fontWeight: "600",
                    whiteSpace: "nowrap",
                  }}
                >
                  Welcome to TalkDEI
                </span>
              </div>
              <span
                style={{
                  color: "#1D2739",
                  width: "308px",
                  fontFamily: "Lato",
                  fontSize: "16px",
                  fontWeight: "400",
                  textAlign: "center",
                }}
              >
                Thank you for signing up on TalkDEI. To proceed, kindly book a
                meeting with our founder with the button below
              </span>
              <button
                style={{
                  display: "flex",
                  background:
                    "linear-gradient(270deg, #CC9866 0%, #722592 102.7%)",
                  alignItems: "center",
                  borderRadius: "8px",
                  border: "none",
                  width: "264px",
                  height: "50px",
                  padding: "22px 38px",
                  justifyContent: "center",
                  gap: "10px",
                  color: "#fff",
                }}
                onClick={handleMeetingSchedule}
              >
                Book a meeting
              </button>
            </div>
          </div>
        </>
      )}
      <div style={{ pointerEvents: popUp ? "none" : "auto" }}>
        <Container fluid>
          <Row>
            <Col xs={2} id="sidebar-wrapper">
              <DashboardSidebar
                links={BuyerLinks}
                url="/buyer-dashboard/"
                link="Overview"
              />
            </Col>
            <Col xs={10} id="page-content-wrapper">
              <Outlet />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default BuyerDashboard;
