import React, { useEffect, useState } from "react";
import Navbar from "../components/LandingPage/Navbar";
import Footer from "../components/LandingPage/Footer";
import "./Pages.css";
import background from "../assets/LandingPage/Background (1).png";
import { Image } from "react-bootstrap";

const CCPAPolicy = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Navbar isMobile={isMobile} outsideOfLandingPage={true}/>

      <div className="terms-main-container">
        <div className="terms-container">
          <h1 className="terms-of-use">CCPA Policy</h1>
        </div>

        <div
          className="content-container-terms"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Image src={background} className="background-image-terms" fluid />
          <div className="text-content-terms">
            <span className="category">Effective date: 02/28/2024</span>
            <span className="category">CCPA Policy</span>
            <span className="content">
              This California Consumer Privacy Act Policy (the “CCPA Policy”) is
              incorporated by reference into the TalkDEI Privacy Policy, found
              <span style={{ color: "black", fontWeight:"700",textDecoration:"underline", cursor: "pointer", marginLeft:"5px"}} onClick={() => window.open("/privacy-policy", "_blank")}>here</span>
              , and applies only to the Personal Information of California
              consumers, unless specified otherwise in the Privacy Policy. Any
              capitalized terms not defined in this CCPA Policy have the same
              meaning given to them in our Privacy Policy and/or the California
              Consumer Privacy Act (“CCPA”).
            </span>

            <span className="category">California Privacy</span>
            <span className="category">
              Information We Collect, Use, and Disclose
            </span>
            <span className="content">
              This section of the CCPA Policy provides information to California
              (“CA”) residents who have certain rights under the California
              Consumer Privacy Act of 2018 (“CCPA”) with respect to the personal
              information that we collect about you. California law requires us
              to disclose information about the categories of personal
              information we collect and how we use them, the categories of
              sources from which we collect personal information, and the third
              parties with whom we share personal information. We have provided
              detailed descriptions above in the “Information We Collect” “How
              We Use the Information We Collect and Receive” “Sharing
              Information” sections of the TalkDEI Privacy Policy.
            </span>
            <span className="content">
              We don’t sell nor share the personal information of California
              residents to any third parties as defined by applicable California
              law.
            </span>
            <span className="category">Individual Rights</span>
            <span className="content">
              The CCPA provides California consumers with several individual
              rights with respect to Personal Information. Note that these
              rights apply to individual consumers, not to Companies.
            </span>
            <span className="content">
              We will not discriminate against you for exercising any of these
              rights.
            </span>
            <span className="category">Exercising Your Rights</span>
            <span className="content">
              To exercise any of the rights described in this section, please
              contact us at nina.legal@talkdei.io with (i) a complete
              description of your request, including the specific right(s) you
              wish to exercise and (ii) sufficient information about you so we
              can confirm that your request is a verifiable consumer request,
              including at a minimum your name and email address. Once we
              consistent with applicable law. Please note that you may also
              designate an authorized agent to make a request on your behalf. To
              do so, you must provide us with written authorization or a power
              of attorney, signed by you, for the agent to act on your behalf.
              You will still need to verify your identity directly with us.
            </span>

            <span className="category">Access and Data Portability Rights</span>
            <span className="content">
              You have the right to request that we disclose certain information
              to you about our collection and use of Personal Information: (1)
              categories of Personal Information we collected about you;
              categories of sources of Personal Information we have collected
              about you; our business or commercial purpose for collecting that
              Personal Information (if applicable); categories of Personal
              Information we have made available to our customers or partners,
              if any; and categories of Personal Information we disclosed to
              service providers or third parties, and the categories of those
              parties, for a business purpose, if any; and (2) the specific
              pieces of Personal Information we have collected about you in a
              readily useable format that allows you to transmit it to another
              entity
            </span>

            <span className="category">Deletion Rights</span>
            <span className="content">
              You have the right to request that we delete Personal Information
              about you that we have collected, subject to certain exceptions.
              We may retain a record of the request for the purpose of ensuring
              that the consumer’s personal information remains deleted from our
              database. The CCPA includes multiple exceptions to this deletion
              right. For example, we are not required to delete information if
              we need to use it for the following:
            </span>
            <span className="content">
              · Completing a transaction or contract with you.
            </span>
            <span className="content">
              · Detecting security interests, protecting against malicious,
              deceptive, fraudulent, or illegal activity, or prosecuting people
              responsible for that activity.
            </span>
            <span className="content">
              · Debugging our products to identify and repair errors to our
              Services
            </span>
            <span className="content">
              · Exercising, and allowing others to exercise, free speech, or
              other legal rights.
            </span>
            <span className="content">
              · Internal uses that align with your expectations.
            </span>
            <span className="content">
              · Complying with a legal requirement
            </span>
            <span className="content">
              · Other internal and lawful uses consistent with the reasons you
              provided the information.
            </span>
            <span className="category">Correction Rights</span>
            <span className="content">
              You have the right to request that we correct Personal Information
              we maintain about you, subject to certain exceptions.
            </span>
            <span className="category">
              Right to Opt-Out of Sharing or Sales of Personal Informatio
            </span>
            <span className="content">
              You have the right to direct us not to “share” or “sell” your
              Personal Information on a going-forward basis, as those terms are
              defined in the CCPA. We do not knowingly “sell” or “share” the
              personal information of children under 16, nor of any individual.
              Please email nina.legal@talkdei.io to exercise this right.
            </span>

            <span className="category">Non-Discrimination</span>
            <span className="content">
              You have the right not to receive discriminatory treatment for the
              exercise of your rights under the CCPA, subject to certain
              limitations. If applicable, any CCPA-permitted financial incentive
              we offer will reasonably relate to your personal information’s
              value and contain written terms that describe the program’s
              material aspects. Participation in a financial incentive program
              requires your prior opt-in consent, which you may revoke at any
              time by contacting us.
            </span>
            <span className="category">Sensitive Personal Information</span>
            <span className="content">
              The CCPA also allows you to limit the use or disclosure of your
              “sensitive personal information” (as defined in the CCPA) if your
              sensitive personal information is used for certain purposes.
              Please note that we do not use or disclose sensitive personal
              information.
            </span>

            <span className="category">Shine the Light Law</span>
            <span className="content">
              Under California Civil Code sections 1798.83-1798.84, also known
              as the “Shine the Light Law,” any of our users who are California
              residents may request and receive once a year, free of charge, a
              notice from us describing what categories of personal customer
              information we share with third parties for their direct marketing
              purposes. That notice will identify the categories of information
              shared, the third parties and affiliates with which it was shared,
              and their names and addresses. If you are a California resident
              and would like a copy of the notice that the law requires, please
              submit a written request to the following address:
              nina.legal@talkdei.io. In your request, please specify that you
              want a “California Privacy Rights Notice.” Please allow at least
              thirty days for a response.
            </span>
            <span className="category">Changes to this CCPA Policy</span>
            <span className="content">
              We reserve the right to amend this CCPA Policy at our discretion
              and at any time. When we make changes to this CCPA Policy, we will
              post the updated notice on the Site and update the CCPA Policy
              effective date at the top of the page. By accessing or using the
              Site and/or the Services, you acknowledge that you have read this
              CCPA Policy and, to the extent it applies to you, that you agree
              to the practices described in this CCPA Policy.
            </span>
          </div>
        </div>
      </div>
      <div className="terms-footer">
        <Footer button={true} height={true} isMobile={isMobile} />
      </div>
    </div>
  );
};

export default CCPAPolicy;
