import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import cube from "../../assets/cube-mark.svg";
import linkedinIcon from "../../assets/linkedinIcon.svg";
import "./SignUpContainer.css";
import ProgressBarComponent from "./SharedComponents/ProgressBarComponent";

const SignUpContainer = ({
  title,
  subtitle,
  children,
  onContinueClick,
  handleSkip,
  buttonText,
  buttonBackground,
  accountType,
  height,
  step,
  skip, 
  sellerProfile
}) => (
  <Container fluid className="signup-main-container" >
    <Row>
      <Col md={6} className="d-flex align-items-center">
        <div
          className="w-100 mx-auto signup-form-container"
          style={{ height: height || "767px" }}
        >
          <Col>
            <Image
              src={cube}
              alt=""
              width="57.347px"
              height="68px"
              className="cube-img"
            />
          </Col>
          {sellerProfile && (
          <ProgressBarComponent selectedStep={step}/>
          )}
          <p className="title">{title}</p>
          <p className="subtitle">{subtitle}</p>
          {children}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "20px",
              position: "absolute",
              bottom: "20px",
              left: "50%",
              transform: "translateX(-50%)"
            }}
          >
            <Button
              variant="primary"
              type="submit"
              className={accountType === "seller" ? "custom-continue-button" : "custom-button"}
              style={{
                backgroundColor: buttonBackground || "#782D8F",
              }}
              onClick={onContinueClick}
            >
              {buttonText || "Continue"}
            </Button>

            {/* {accountType === "seller" && step == 1 ? (
              <>
                <p style={{color:"#FFF", marginTop:"22px", marginBottom:"22px"}}>OR</p>
                <Button
                  variant="primary"
                  type="submit"
                  className="custom-linkedin-button"
                >
                  <Image src={linkedinIcon} alt="" className="linkedin-icon" />
                  Create account with Linkedin
                </Button>
              </>
            ) : null} */}
            {skip ? (
              <button onClick={() => handleSkip()} style={{display:"flex", alignItems:"center", color:"#FFF", border:"none", background:"none",
           }}>Skip</button>
            ) : null}
            
          </div>
        </div>
      </Col>
    </Row>
  </Container>
);

export default SignUpContainer;
