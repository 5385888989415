import React, { useState, useRef, useEffect } from "react";
import SignUpContainer from "../SignUpContainer";
import { Form, InputGroup, FormControl, Row, Col } from "react-bootstrap";
import "./styling.css";
import { useSelector } from "react-redux";

const OTPConfirmation = ({
  handleNextStep,
  handleOTPVerification,
  formDataStep,
  handleSignUp,
}) => {
  const [otp, setOtp] = useState(["", "", "", "", ""]);
  const inputRefs = [useRef(), useRef(), useRef(), useRef(), useRef()];
  const [isFilled, setFilled] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const otpId = useSelector((state) => state.otp.otpId?.otpRefenceId);
  const [isValidatingOtp, setIsValidatingOtp] = useState(false);

  const otpVerified = useSelector(
    (state) => state.verification.verificationResult
  );
  const otpStateError = useSelector(state => state.verification.error);
  const handleSubmit = async (event) => {
    if (event) event.preventDefault();
    if (isFilled) {
      setIsValidatingOtp(true);
    } else {
      setOtpError(true);
    }
  };

  useEffect(() => {
    const validateOtp = async () => {
      const userOtp = otp.join("");
      const email = formDataStep.email;

      try {
      await handleOTPVerification(email, otpId, userOtp);
      } catch (error) {
        console.error("Error verifying OTP:", error.message);
      } finally {
        setIsValidatingOtp(false);
      }
    };

    if (isValidatingOtp) {
      validateOtp();
    }
  }, [isValidatingOtp]);

  useEffect(() => {
    if (otpVerified === "VERIFIED") {
      setOtpError(false);
      handleSignUp();
      handleNextStep();
    } if (otpStateError) {
      setOtpError(true);
    }
  }, [otpVerified, otpStateError]);

  useEffect(() => {
    if (otp.every((digit) => digit !== "")) {
      setFilled(true);
    } else {
      setFilled(false);
    }
  }, [otp]);

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (index < inputRefs.length - 1 && value !== "") {
      inputRefs[index + 1].current.focus();
    }

    if (index > 0 && value === "") {
      inputRefs[index - 1].current.focus();
    }
    setOtpError(false);
  };

  const handlePaste = (event, index) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData("text");
    const pastedDigits = pastedText
      .split("")
      .filter((digit) => !isNaN(parseInt(digit)));

    // Update OTP with pasted digits
    const newOtp = [...otp];
    let currentIndex = index;
    for (const digit of pastedDigits) {
      if (currentIndex >= newOtp.length) break;
      newOtp[currentIndex] = digit;
      currentIndex++;
    }
    setOtp(newOtp);
  };

  // Add event listener for paste on each input field
  useEffect(() => {
    const handleInputPaste = (event, index) => {
      handlePaste(event, index);
    };

    inputRefs.forEach((ref, index) => {
      ref.current.addEventListener("paste", (event) =>
        handleInputPaste(event, index)
      );
    });

    return () => {
      inputRefs.forEach((ref, index) => {
        if(ref.current) {
        ref.current.removeEventListener("paste", (event) =>
          handleInputPaste(event, index)
        
        );
        }
      });
    };
  }, [otp]);

  return (
    <SignUpContainer
      title="Please enter OTP"
      subtitle="Enter the 5 digit code sent to your email"
      buttonText="Verify"
      buttonBackground={isFilled ? "#5C0E80" : "rgba(35, 35, 35, 0.30)"}
      onContinueClick={() => handleSubmit()}
      isValid={isFilled && !otpError}
    >
      <Form>
        <Row className="otp-container">
          {otp.map((digit, index) => (
            <Col key={index} xs="auto" className="mx-2">
              <InputGroup>
                <FormControl
                  key={index}
                  type="text"
                  maxLength="1"
                  value={digit}
                  onChange={(e) => handleOtpChange(index, e.target.value)}
                  className={`otp-input ${otpError ? "error" : ""}`}
                  style={{
                    borderBottom:
                      digit !== ""
                        ? otpError
                          ? "3px solid red"
                          : "3px solid #782D8F"
                        : "1px solid rgba(0, 0, 0, 0.20)",
                    borderRadius: digit !== "" ? "8px 8px 0px 0px" : "8px",
                  }}
                  ref={inputRefs[index]}
                />
              </InputGroup>
            </Col>
          ))}
        </Row>
        {otpError && (
          <div className="error-message">Incorrect OTP. Please try again.</div>
        )}
      </Form>
    </SignUpContainer>
  );
};

export default OTPConfirmation;
