import React, { useState, useEffect, useRef } from "react";
import { Image } from "react-bootstrap";
import "./landingPage.css";
import signupIcon from "../../assets/LandingPage/signupIcon.svg";
import signUpJson from "../../assets/LandingPage/Field.json";
import searchIcon from "../../assets/LandingPage/findSuppliers.svg";
import chatIcon from "../../assets/LandingPage/communicateIcon.svg";
import chatJson from "../../assets/LandingPage/Comms.json";
import searchJson from "../../assets/LandingPage/Search_.json";
import Lottie from "react-lottie";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Header = React.memo(() => {
  return (
    <div className="signup-content">
      <span>Get started with 3 easy steps</span>
    </div>
  );
});

const AnimatedImageJson = React.memo(({
  animationData,
  alt,
  index,
  visibleIndex,
  isPaused,
  isMobile
}) => {
  const visibility = index === visibleIndex ? "visible" : "hidden";

  return (
    <Lottie
      options={{
        loop: false,
        autoplay: false,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      }}
      speed={0.5}
      height={ isMobile ? 157 : 450}
      width={isMobile ? 242 : 450}
      isPaused={isPaused} 
      style={{
        opacity: index === visibleIndex ? 1 : 0,
        visibility: visibility,
        transition: "opacity 0.5s ease",
      }}
    />
  );
});

const AnimatedIcon = React.memo(({ src, alt, index, visibleIndex, isMobile }) => {
  return (
    <Image
      src={src}
      alt={alt}
      className="animated-icon"
      style={{
        display: index === visibleIndex ? "block" : "none",
      }}
      width="32px"
      height="32px"
    />
  );
});

const SignupSection = ({isMobile}) => {
  const signUpSectionRef = useRef(null);
  const triggerContainerRef = useRef(null);
  const [index, setIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(true); 
  const icons = [signupIcon, searchIcon, chatIcon];
  const jsonAnimations = [signUpJson, searchJson, chatJson];
  const texts = ["Signup", "Search", "Chat"];

  useEffect(() => {
    const container = triggerContainerRef.current;
    const signUpSection = signUpSectionRef.current;

    const icons = signUpSection.querySelectorAll(
      ".icon-text-container .animated-icon"
    );
    const iconTexts = signUpSection.querySelectorAll(
      ".icon-text-container .text-content"
    );
    const textContent = signUpSection.querySelectorAll(
      ".text-content"
    );

      ScrollTrigger.create({
        trigger: ".signup-triger-container",
        start: "top top",
        end: isMobile ? "+=1500" : "+=2000",
        pin: ".signup-triger-container",

        onUpdate: (self) => {
          const newIndex = Math.floor(self.progress * icons.length);
          if (newIndex >= index) {
            setIndex(newIndex);
          }
          setIsPaused(false);
            gsap.from(icons, {
              opacity: 1,
              stagger: 0.2,
              duration: 0.3,
              ease: "power1.inOut",
            });
            gsap.from(iconTexts, {
              opacity: 1,
              duration: 1,
              ease: "power1.inOut",
            });
          }
      });

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => {
        trigger.kill();
      });
    };
  }, []);

  return (
    <div className="signup-triger-container" ref={triggerContainerRef}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Header />
      </div>

      <div className="signup-section" ref={signUpSectionRef}>
        <div className="icon-text-container">
          {icons.map((icon, idx) => (
            <AnimatedIcon
              key={idx}
              src={icon}
              alt=""
              index={idx}
              visibleIndex={index}
              isMobile={isMobile}
            />
          ))}
          <span className={`text-content`}>{texts[index]}</span>
        </div>
        <div className="image-section">
          <AnimatedImageJson
            animationData={jsonAnimations[index]}
            alt={`${texts[index]} Image`}
            index={index}
            visibleIndex={index}
            isPaused={isPaused}
            isMobile={isMobile}
          />
        </div>
      </div>
    </div>
  );
};

export default SignupSection;
