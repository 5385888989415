import { v4 as uuidv4 } from "uuid";
import pictureSymbol from "./assets/pictureSymbol.svg";

// export const SupplierCompanyData = [
//   {
//     id: uuidv4(),
//     firstName: "",
//     lastName: "",
//     email: "",
//     address: {
//       country: "USA",
//       state: "CA",
//       city: "San Francisco",
//     },
//     company: {
//       name: "Apple",
//       size: "Small (11-50 employees)",
//       industry: "Manufacturing",
//       companyProfilePicture: null,
//       companyWebsite: "apple.com",
//       annualRevenue: "$2.0m",
//       registrationStatus: "Current",
//       yearFounded: 1994,
//     },
//     password: "",
//     confirmPassword: "",

//     supplierProfile: {
//       name: "Sarah Jenkins",
//       profilePicture: null,
//       email: "sarahjenkins@gmail.com",
//       primaryRole: "HR Personnel",
//       rating: 4.9,
//       bio: `I’m a HR personnel with over 4 years of experience. I’ve had the opportunity to lead different projects across diverse teams. My main area of expertise is working on projects for real estate companies but I also have skills transferrable to other disciplines.`,
//       yearFounded: 0,
//       linkedInUrl: "",

//       diversity: {
//         gender: "",
//         pronouns: "",
//         race: "",
//         diversityStatus:"Small Business",
//       },

//       certificates: ["NMSDC"],
//       reviews: [
//         {
//           id: 1,
//           reviewerName: "John Doe",
//           reviewerImage: "url_to_image",
//           reviewerWebsite: "www.example.com",
//           rating: 4,
//           reviewText: "Great experience with this supplier. Highly recommended!",
//         },
//       ],
//       businessLicenses: "",
//       services: [
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//       ],

//       skills: {
//         technical: ["Assistant"],

//         industry: ["Real Estate"],

//         company: ["People"],
//       },

//       tier: "Tier 1",
//       mainContacts: [{
//         id: uuidv4(),
//         name: "Michael Scott",
//         primaryrole: "HR Personnel",
//         email: "michaelscott@gmail.com",
//       }],
//       otherContacts: [
//         {
//           id: uuidv4(),
//           name: "Michael Scott",
//           primaryrole: "HR Personnel",
//           email: "michaelscott@gmail.com",
//           permission: "can view",
//         },
//         {
//           id: uuidv4(),
//           name: "Sarah Jenkins",
//           primaryrole: "HR Personnel",
//           email: "sarah@gmail.com",
//           permission: "admin",
//         },
//       ],
//     },
//   },
//   {
//     id: uuidv4(),
//     firstName: "",
//     lastName: "",
//     email: "",
//     address: {
//       country: "USA",
//       state: "CA",
//       city: "San Francisco",
//     },
//     company: {
//       name: "Global Tech Solutions",
//       size: "Small (11-50 employees)",
//       industry: "Manufacturing",
//       companyProfilePicture: null,
//       companyWebsite: "globaltechsolutions.com",
//       annualRevenue: "$2.0m",
//       registrationStatus: "Current",
//       yearFounded: 1994,
//     },
//     password: "",
//     confirmPassword: "",

//     supplierProfile: {
//       name: "Sarah Jenkins",
//       profilePicture: null,
//       email: "sarahjenkins@gmail.com",
//       primaryRole: "HR Personnel",
//       rating: 4.9,
//       bio: `I’m a HR personnel with over 4 years of experience. I’ve had the
//       opportunity to lead different projects across diverse teams. My
//       main area of expertise is working on projects for real estate
//       companies but I also have skills transferrable to other
//       disciplines.`,
//       yearFounded: 0,
//       linkedInUrl: "",

//       diversity: {
//         gender: "",
//         pronouns: "",
//         race: "",
//         diversityStatus:"Small Business",
//       },

//       certificates: ["NMSDC"],
//       reviews: [
//         {
//           id: 1,
//           reviewerName: "John Doe",
//           reviewerImage: "url_to_image",
//           reviewerWebsite: "www.example.com",
//           rating: 4,
//           reviewText: "Great experience with this supplier. Highly recommended!",
//         },
//       ],
//       businessLicenses: "",
//       services: [
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//         {
//           serviceImage:null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//       ],

//       skills: {
//         technical: ["Assistant"],

//         industry: ["Real Estate"],

//         company: ["People"],
//       },

//       tier: "Tier 1",
//       mainContacts: [{
//         id: uuidv4(),
//         name: "Michael Scott",
//         primaryrole: "HR Personnel",
//         email: "michaelscott@gmail.com",
//       }],
//       otherContacts: [
//         {
//           id: uuidv4(),
//           name: "Michael Scott",
//           primaryrole: "HR Personnel",
//           email: "michaelscott@gmail.com",
//           permission: "can view",
//         },
//         {
//           id: uuidv4(),
//           name: "Sarah Jenkins",
//           primaryrole: "HR Personnel",
//           email: "sarah@gmail.com",
//           permission: "admin",
//         },
//       ],
//     },
//   },
//   {
//     id: uuidv4(),
//     firstName: "",
//     lastName: "",
//     email: "",
//     address: {
//       country: "USA",
//       state: "CA",
//       city: "San Francisco",
//     },
//     company: {
//       name: "Global Tech Solutions",
//       size: "Small (11-50 employees)",
//       industry: "Manufacturing",
//       companyProfilePicture: null,
//       companyWebsite: "globaltechsolutions.com",
//       annualRevenue: "$2.0m",
//       registrationStatus: "Current",
//       yearFounded: 1994,
//     },
//     password: "",
//     confirmPassword: "",

//     supplierProfile: {
//       name: "Sarah Jenkins",
//       profilePicture: null,
//       email: "sarahjenkins@gmail.com",
//       primaryRole: "HR Personnel",
//       rating: 4.9,
//       bio: `I’m a HR personnel with over 4 years of experience. I’ve had the
//       opportunity to lead different projects across diverse teams. My
//       main area of expertise is working on projects for real estate
//       companies but I also have skills transferrable to other
//       disciplines.`,
//       yearFounded: 0,
//       linkedInUrl: "",

//       diversity: {
//         gender: "",
//         pronouns: "",
//         race: "",
//         diversityStatus:"Small Business",
//       },

//       certificates: ["NMSDC"],
//       reviews: [
//         {
//           id: 1,
//           reviewerName: "John Doe",
//           reviewerImage: "url_to_image",
//           reviewerWebsite: "www.example.com",
//           rating: 4,
//           reviewText: "Great experience with this supplier. Highly recommended!",
//         },
//       ],
//       businessLicenses: "",
//       services: [
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//       ],

//       skills: {
//         technical: ["Assistant"],

//         industry: ["Real Estate"],

//         company: ["People"],
//       },

//       tier: "Tier 1",
//       mainContacts: [{
//         id: uuidv4(),
//         name: "Michael Scott",
//         primaryrole: "HR Personnel",
//         email: "michaelscott@gmail.com",
//       }],
//       otherContacts: [
//         {
//           id: uuidv4(),
//           name: "Michael Scott",
//           primaryrole: "HR Personnel",
//           email: "michaelscott@gmail.com",
//           permission: "can view",
//         },
//         {
//           id: uuidv4(),
//           name: "Sarah Jenkins",
//           primaryrole: "HR Personnel",
//           email: "sarah@gmail.com",
//           permission: "admin",
//         },
//       ],
//     },
//   },
//   {
//     id: uuidv4(),
//     firstName: "",
//     lastName: "",
//     email: "",
//     address: {
//       country: "USA",
//       state: "CA",
//       city: "San Francisco",
//     },
//     company: {
//       name: "Global Tech Solutions",
//       size: "Small (11-50 employees)",
//       industry: "Manufacturing",
//       companyProfilePicture: null,
//       companyWebsite: "globaltechsolutions.com",
//       annualRevenue: "$2.0m",
//       registrationStatus: "Current",
//       yearFounded: 1994,
//     },
//     password: "",
//     confirmPassword: "",

//     supplierProfile: {
//       name: "Sarah Jenkins",
//       profilePicture: null,
//       email: "sarahjenkins@gmail.com",
//       primaryRole: "HR Personnel",
//       rating: 4.9,
//       bio: `I’m a HR personnel with over 4 years of experience. I’ve had the
//       opportunity to lead different projects across diverse teams. My
//       main area of expertise is working on projects for real estate
//       companies but I also have skills transferrable to other
//       disciplines.`,
//       yearFounded: 0,
//       linkedInUrl: "",

//       diversity: {
//         gender: "",
//         pronouns: "",
//         race: "",
//         diversityStatus:"Small Business",
//       },

//       certificates: ["NMSDC"],
//       reviews: [
//         {
//           id: 1,
//           reviewerName: "John Doe",
//           reviewerImage: "url_to_image",
//           reviewerWebsite: "www.example.com",
//           rating: 4,
//           reviewText: "Great experience with this supplier. Highly recommended!",
//         },
//       ],
//       businessLicenses: "",
//       services: [
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//       ],

//       skills: {
//         technical: ["Assistant"],

//         industry: ["Real Estate"],

//         company: ["People"],
//       },

//       tier: "Tier 1",
//       mainContacts: [{
//         id: uuidv4(),
//         name: "Michael Scott",
//         primaryrole: "HR Personnel",
//         email: "michaelscott@gmail.com",
//       }],
//       otherContacts: [
//         {
//           id: uuidv4(),
//           name: "Michael Scott",
//           primaryrole: "HR Personnel",
//           email: "michaelscott@gmail.com",
//           permission: "can view",
//         },
//         {
//           id: uuidv4(),
//           name: "Sarah Jenkins",
//           primaryrole: "HR Personnel",
//           email: "sarah@gmail.com",
//           permission: "admin",
//         },
//       ],
//     },
//   },
//   {
//     id: uuidv4(),
//     firstName: "",
//     lastName: "",
//     email: "",
//     address: {
//       country: "USA",
//       state: "CA",
//       city: "San Francisco",
//     },
//     company: {
//       name: "Global Tech Solutions",
//       size: "Small (11-50 employees)",
//       industry: "Manufacturing",
//       companyProfilePicture: null,
//       companyWebsite: "globaltechsolutions.com",
//       annualRevenue: "$2.0m",
//       registrationStatus: "Current",
//       yearFounded: 1994,
//     },
//     password: "",
//     confirmPassword: "",

//     supplierProfile: {
//       name: "Sarah Jenkins",
//       profilePicture: null,
//       email: "sarahjenkins@gmail.com",
//       primaryRole: "HR Personnel",
//       rating: 4.9,
//       bio: `I’m a HR personnel with over 4 years of experience. I’ve had the
//       opportunity to lead different projects across diverse teams. My
//       main area of expertise is working on projects for real estate
//       companies but I also have skills transferrable to other
//       disciplines.`,
//       yearFounded: 0,
//       linkedInUrl: "",

//       diversity: {
//         gender: "",
//         pronouns: "",
//         race: "",
//         diversityStatus:"Small Business",
//       },

//       certificates: ["NMSDC"],
//       reviews: [
//         {
//           id: 1,
//           reviewerName: "John Doe",
//           reviewerImage: "url_to_image",
//           reviewerWebsite: "www.example.com",
//           rating: 4,
//           reviewText: "Great experience with this supplier. Highly recommended!",
//         },
//       ],
//       businessLicenses: "",
//       services: [
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//       ],

//       skills: {
//         technical: ["Assistant"],

//         industry: ["Real Estate"],

//         company: ["People"],
//       },

//       tier: "Tier 1",
//       mainContacts: [{
//         id: uuidv4(),
//         name: "Michael Scott",
//         primaryrole: "HR Personnel",
//         email: "michaelscott@gmail.com",
//       }],
//       otherContacts: [
//         {
//           id: uuidv4(),
//           name: "Michael Scott",
//           primaryrole: "HR Personnel",
//           email: "michaelscott@gmail.com",
//           permission: "can view",
//         },
//         {
//           id: uuidv4(),
//           name: "Sarah Jenkins",
//           primaryrole: "HR Personnel",
//           email: "sarah@gmail.com",
//           permission: "admin",
//         },
//       ],
//     },
//   },
//   {
//     id: uuidv4(),
//     firstName: "",
//     lastName: "",
//     email: "",
//     address: {
//       country: "USA",
//       state: "CA",
//       city: "San Francisco",
//     },
//     company: {
//       name: "Global Tech Solutions",
//       size: "Small (11-50 employees)",
//       industry: "Manufacturing",
//       companyProfilePicture: null,
//       companyWebsite: "globaltechsolutions.com",
//       annualRevenue: "$2.0m",
//       registrationStatus: "Current",
//       yearFounded: 1994,
//     },
//     password: "",
//     confirmPassword: "",

//     supplierProfile: {
//       name: "Sarah Jenkins",
//       profilePicture: null,
//       email: "sarahjenkins@gmail.com",
//       primaryRole: "HR Personnel",
//       rating: 4.9,
//       bio: `I’m a HR personnel with over 4 years of experience. I’ve had the
//       opportunity to lead different projects across diverse teams. My
//       main area of expertise is working on projects for real estate
//       companies but I also have skills transferrable to other
//       disciplines.`,
//       yearFounded: 0,
//       linkedInUrl: "",

//       diversity: {
//         gender: "",
//         pronouns: "",
//         race: "",
//         diversityStatus:"Small Business",
//       },

//       certificates: ["NMSDC"],
//       reviews: [
//         {
//           id: 1,
//           reviewerName: "John Doe",
//           reviewerImage: "url_to_image",
//           reviewerWebsite: "www.example.com",
//           rating: 4,
//           reviewText: "Great experience with this supplier. Highly recommended!",
//         },
//       ],
//       businessLicenses: "",
//       services: [
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//       ],

//       skills: {
//         technical: ["Assistant"],

//         industry: ["Real Estate"],

//         company: ["People"],
//       },

//       tier: "Tier 1",
//       mainContacts: [{
//         id: uuidv4(),
//         name: "Michael Scott",
//         primaryrole: "HR Personnel",
//         email: "michaelscott@gmail.com",
//       }],
//       otherContacts: [
//         {
//           id: uuidv4(),
//           name: "Michael Scott",
//           primaryrole: "HR Personnel",
//           email: "michaelscott@gmail.com",
//           permission: "can view",
//         },
//         {
//           id: uuidv4(),
//           name: "Sarah Jenkins",
//           primaryrole: "HR Personnel",
//           email: "sarah@gmail.com",
//           permission: "admin",
//         },
//       ],
//     },
//   },
//   {
//     id: uuidv4(),
//     firstName: "",
//     lastName: "",
//     email: "",
//     address: {
//       country: "USA",
//       state: "CA",
//       city: "San Francisco",
//     },
//     company: {
//       name: "Global Tech Solutions",
//       size: "Small (11-50 employees)",
//       industry: "Manufacturing",
//       companyProfilePicture: null,
//       companyWebsite: "globaltechsolutions.com",
//       annualRevenue: "$2.0m",
//       registrationStatus: "Current",
//       yearFounded: 1994,
//     },
//     password: "",
//     confirmPassword: "",

//     supplierProfile: {
//       name: "Sarah Jenkins",
//       profilePicture: null,
//       email: "sarahjenkins@gmail.com",
//       primaryRole: "HR Personnel",
//       rating: 4.9,
//       bio: `I’m a HR personnel with over 4 years of experience. I’ve had the
//       opportunity to lead different projects across diverse teams. My
//       main area of expertise is working on projects for real estate
//       companies but I also have skills transferrable to other
//       disciplines.`,
//       yearFounded: 0,
//       linkedInUrl: "",

//       diversity: {
//         gender: "",
//         pronouns: "",
//         race: "",
//         diversityStatus:"Small Business",
//       },

//       certificates: ["NMSDC"],
//       reviews: [
//         {
//           id: 1,
//           reviewerName: "John Doe",
//           reviewerImage: "url_to_image",
//           reviewerWebsite: "www.example.com",
//           rating: 4,
//           reviewText: "Great experience with this supplier. Highly recommended!",
//         },
//       ],
//       businessLicenses: "",
//       services: [
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//       ],

//       skills: {
//         technical: ["Assistant"],

//         industry: ["Real Estate"],

//         company: ["People"],
//       },

//       tier: "Tier 1",
//       mainContacts: [{
//         id: uuidv4(),
//         name: "Michael Scott",
//         primaryrole: "HR Personnel",
//         email: "michaelscott@gmail.com",
//       }],
//       otherContacts: [
//         {
//           id: uuidv4(),
//           name: "Michael Scott",
//           primaryrole: "HR Personnel",
//           email: "michaelscott@gmail.com",
//           permission: "can view",
//         },
//         {
//           id: uuidv4(),
//           name: "Sarah Jenkins",
//           primaryrole: "HR Personnel",
//           email: "sarah@gmail.com",
//           permission: "admin",
//         },
//       ],
//     },
//   },
//   {
//     id: uuidv4(),
//     firstName: "",
//     lastName: "",
//     email: "",
//     address: {
//       country: "USA",
//       state: "CA",
//       city: "San Francisco",
//     },
//     company: {
//       name: "Global Tech Solutions",
//       size: "Small (11-50 employees)",
//       industry: "Manufacturing",
//       companyProfilePicture: null,
//       companyWebsite: "globaltechsolutions.com",
//       annualRevenue: "$2.0m",
//       registrationStatus: "Current",
//       yearFounded: 1994,
//     },
//     password: "",
//     confirmPassword: "",

//     supplierProfile: {
//       name: "Sarah Jenkins",
//       profilePicture: null,
//       email: "sarahjenkins@gmail.com",
//       primaryRole: "HR Personnel",
//       rating: 4.9,
//       bio: `I’m a HR personnel with over 4 years of experience. I’ve had the
//       opportunity to lead different projects across diverse teams. My
//       main area of expertise is working on projects for real estate
//       companies but I also have skills transferrable to other
//       disciplines.`,
//       yearFounded: 0,
//       linkedInUrl: "",

//       diversity: {
//         gender: "",
//         pronouns: "",
//         race: "",
//         diversityStatus:"Small Business",
//       },

//       certificates: ["NMSDC"],
//       reviews: [
//         {
//           id: 1,
//           reviewerName: "John Doe",
//           reviewerImage: "url_to_image",
//           reviewerWebsite: "www.example.com",
//           rating: 4,
//           reviewText: "Great experience with this supplier. Highly recommended!",
//         },
//       ],
//       businessLicenses: "",
//       services: [
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//         {
//           serviceImage: null,
//           title: "IP Monitoring",
//           description: "IP monitoring services to identify and address potential infringements",
//           tag: "IP Management",
//         },
//       ],

//       skills: {
//         technical: ["Assistant"],

//         industry: ["Real Estate"],

//         company: ["People"],
//       },

//       tier: "Tier 1",
//       mainContacts: [{
//         id: uuidv4(),
//         name: "Michael Scott",
//         primaryrole: "HR Personnel",
//         email: "michaelscott@gmail.com",
//       }],
//       otherContacts: [
//         {
//           id: uuidv4(),
//           name: "Michael Scott",
//           primaryrole: "HR Personnel",
//           email: "michaelscott@gmail.com",
//           permission: "can view",
//         },
//         {
//           id: uuidv4(),
//           name: "Sarah Jenkins",
//           primaryrole: "HR Personnel",
//           email: "sarah@gmail.com",
//           permission: "admin",
//         },
//       ],
//     },
//   },
// ]


export const SupplierCompanyData =
[
  {
    "id": uuidv4(),
    "firstName": "",
    "lastName": "",
    "email": "",
    "address": {
      "country": "USA",
      "state": "CO",
      "city": "Denver"
    },
    "company": {
      "name": "Polaris Environmental Solutions, Inc.",
      "size": "Small (11-50 employees)",
      "industry": "Environmental Services",
      "companyProfilePicture": null,
      "companyWebsite": "polaris.com",
      "annualRevenue": "$2.2m",
      "registrationStatus": "Current",
      "yearFounded": 2003
    },
    "password": "",
    "confirmPassword": "",
    "supplierProfile": {
      "name": "Laura Martinez",
      "profilePicture": null,
      "email": "lauramartinez@gmail.com",
      "primaryRole": "CEO",
      "rating": 4.2,
      "bio": "Polaris Environmental Solutions is a premier provider of sustainable environmental services and consulting. Founded in 2003 by Laura Martinez, the company has grown into a leader in environmental compliance, remediation, and green building practices. With a strong commitment to sustainability and community engagement, Polaris Environmental Solutions partners with businesses to create safer and more eco-friendly operations.",
      "yearFounded": 0,
      "linkedInUrl": "",
      "diversity": {
        "gender": "",
        "pronouns": "",
        "race": "",
        "diversityStatus": "Minority Business Enterprise (MBE)"
      },
      "certificates": ["WBE", "MBE", "SBE"],
      "reviews": [],
      "businessLicenses": "",
      "services": [
        {
          "serviceImage": null,
          "title": "Environmental Impact Assessment (EIA) Reports",
          "description": "Comprehensive Environmental Impact Assessment (EIA) reports for businesses and developers. Our team of experienced environmental consultants conducts thorough evaluations to identify potential environmental effects of proposed projects. We provide detailed reports that include data collection, analysis, risk assessment, and mitigation strategies, ensuring compliance with local and federal environmental regulations. These assessments help clients make informed decisions and facilitate smooth project approvals.",
          "tag": "Environmental Services"
        },
        {
          "serviceImage": null,
          "title": "Hazardous Waste Management Consulting",
          "description": "Hazardous waste management consulting services designed to help businesses handle and dispose of hazardous materials safely and legally. Our experts provide on-site assessments, waste characterization, and customized management plans tailored to meet the specific needs of each client. We ensure compliance with all relevant environmental laws and regulations, minimizing liability and protecting both the environment and public health.",
          "tag": "Environmental Services"
        },
        {
          "serviceImage": null,
          "title": "Agricultural Automation Solutions",
          "description": "Cutting-edge agricultural automation solutions designed to enhance farming efficiency and productivity. Our services include the design, installation, and maintenance of automated systems for irrigation, fertilization, and pest control. By integrating advanced technologies such as IoT sensors, drones, and AI-driven analytics, we help farmers optimize resource use, reduce labor costs, and increase crop yields. Our tailored solutions ensure that agricultural operations are both sustainable and profitable for an evolving world.",
          "tag": "Environmental Services"
        },
        {
          "serviceImage": null,
          "title": "Air Quality Monitoring and Assessment",
          "description": "Real-time air quality monitoring and assessment services for urban and industrial environments. Our team uses state-of-the-art monitoring equipment and advanced analytical techniques to measure pollutants and assess air quality levels. We offer both short-term monitoring for specific projects and long-term solutions for continuous air quality management. Our detailed reports and recommendations help clients address air quality issues, ensure regulatory compliance, and protect public health.",
          "tag": "Environmental Services"
        }
      ],
      "skills": {
        "technical": ["Hazardous Waste Management"],
        "industry": ["Environmental Impact Assessments", "Sustainability Consulting"],
        "company": ["Remediation Services"]
      },
      "tier": "Tier 1",
      "mainContacts": [
        {
          "id": uuidv4(),
          "name": "Laura Martinez",
          "primaryrole": "CEO",
          "email": "lauramartinez@gmail.com"
        }
      ],
      "otherContacts": [
        {
          "id": uuidv4(),
          "name": "Rob Stark",
          "primaryrole": "CFO",
          "email": "robstark@gmail.com"
        },
        {
          "id": uuidv4(),
          "name": "Tina Faira",
          "primaryrole": "HR Personnel",
          "email": "tinafaira@gmail.com"
        }
      ]
    }
  },
  {
    "id": uuidv4(),
    "firstName": "",
    "lastName": "",
    "email": "",
    "address": {
      "country": "USA",
      "state": "AL",
      "city": "Huntsville"
    },
    "company": {
      "name": "RT Aerospace, LLC",
      "size": "Medium (51-250 employees)",
      "industry": "Aerospace and Defense",
      "companyProfilePicture": null,
      "companyWebsite": "rtaero.io",
      "annualRevenue": "$3.8m",
      "registrationStatus": "Pending",
      "yearFounded": 2010
    },
    "password": "",
    "confirmPassword": "",
    "supplierProfile": {
      "name": "Robert Carter",
      "profilePicture": null,
      "email": "robert332@rtaero.com",
      "primaryRole": "Founder & CTO",
      "rating": 4.8,
      "bio": "RT Aerospace LLC is an innovative provider of high-precision components and assemblies for the aerospace and defense sectors. Established in 2010 by aerospace engineer Robert Carter, the company specializes in manufacturing and engineering services tailored to the rigorous demands of the industry. Known for their precision engineering and sustainability initiatives, RT Aerospace LLC has become a trusted supplier for leading aerospace companies looking to lead the future of aerospace.",
      "yearFounded": 0,
      "linkedInUrl": "",
      "diversity": {
        "gender": "",
        "pronouns": "",
        "race": "",
        "diversityStatus": "Veteran-Owned Small Business (VOSB)"
      },
      "certificates": ["VOSB", "SDVOSB", "HUBZone Certified"],
      "reviews": [],
      "businessLicenses": "",
      "services": [
        {
          "serviceImage": null,
          "title": "Aerospace Component Manufacturing",
          "description": "Our Aerospace Component Manufacturing service specializes in the production of precision-engineered components for the aerospace industry. Leveraging state-of-the-art technology and expertise, we deliver high-quality parts tailored to meet the stringent requirements of aerospace applications. From structural components to intricate assemblies, we ensure exceptional accuracy, durability, and reliability to support the most demanding aerospace projects.",
          "tag": "Aerospace and Defense"
        },
        {
          "serviceImage": null,
          "title": "Custom Engineering Solutions",
          "description": "Our Custom Engineering Solutions offer tailored design and development services to address unique challenges across various industries. With a team of experienced engineers and advanced tools, we provide innovative solutions that meet specific project requirements. Whether it's product design, system optimization, or process improvement, our customizable engineering services aim to enhance efficiency, performance, and overall project success.",
          "tag": "Aerospace and Defense"
        },
        {
          "serviceImage": null,
          "title": "Quality Control Testing",
          "description": "Our Quality Control Testing service ensures the integrity and reliability of products through comprehensive testing protocols. Utilizing cutting-edge equipment and rigorous methodologies, we conduct thorough inspections and analyses to verify product quality and adherence to industry standards. From material analysis to performance testing, we provide accurate and actionable insights to optimize product quality and reliability.",
          "tag": "Aerospace and Defense"
        },
        {
          "serviceImage": null,
          "title": "Rapid Prototyping",
          "description": "Our Rapid Prototyping service offers fast and cost-effective solutions for product development and validation. Using advanced prototyping technologies such as 3D printing and CNC machining, we quickly transform design concepts into physical prototypes. With rapid turnaround times and iterative prototyping processes, we enable clients to accelerate innovation, reduce time to market, and make informed design decisions.",
          "tag": "Aerospace and Defense"
        },
        {
          "serviceImage": null,
          "title": "Supply Chain Logistics",
          "description": "Our Supply Chain Logistics service optimizes the end-to-end supply chain process to enhance efficiency, reduce costs, and improve overall performance. From procurement and inventory management to warehousing and distribution, we offer comprehensive logistics solutions tailored to meet specific business needs. Leveraging advanced analytics and strategic partnerships, we streamline logistics operations and ensure seamless flow of goods from suppliers to customers.",
          "tag": "Aerospace and Defense"
        }
      ],
      "skills": {
        "technical": ["CNC Machining", "CAD/CAM Design"],
        "industry": ["Component Testing and Quality Assurance", "Supply Chain Management"],
        "company": ["Additive Manufacturing"]
      },
      "tier": "Tier 2",
      "mainContacts": [
        {
          "id": uuidv4(),
          "name": "Robert Carter",
          "primaryrole": "Founder & CTO",
          "email": "robert332@rtaero.com"
        }
      ],
      "otherContacts": [
        {
          "id": uuidv4(),
          "name": "Max Miller",
          "primaryrole": "CFO",
          "email": "maxmiller@rtaero.com"
        },
        {
          "id": uuidv4(),
          "name": "Martin Gonzales",
          "primaryrole": "HR Personnel",
          "email": "m.gonzales@rtaero.com"
        }
      ]
    }
  },
  {
    "id": uuidv4(),
    "firstName": "",
    "lastName": "",
    "email": "",
    "address": {
      "country": "USA",
      "state": "MN",
      "city": "Minneapolis"
    },
    "company": {
      "name": "Nugenos Medical Systems, LLC",
      "size": "Medium (51-250 employees)",
      "industry": "Medical Devices Manufacturing",
      "companyProfilePicture": null,
      "companyWebsite": "nugenosmedical.com",
      "annualRevenue": "$4.1m",
      "registrationStatus": "Inactive",
      "yearFounded": 2011
    },
    "password": "",
    "confirmPassword": "",
    "supplierProfile": {
      "name": "Dr. Rajesh Patel",
      "profilePicture": null,
      "email": "rajeshpatel@nugenos.com",
      "primaryRole": "Founder & CTO",
      "rating": 4.0,
      "bio": "Nugenos Medical Systems, Inc is a pioneering medical devices company that focuses on the development and manufacturing of advanced medical technologies. Established by Dr. Rajesh Patel in 2011, Nugenos Medical Systems is at the forefront of creating innovative solutions that improve patient outcomes. The company specializes in diagnostic equipment, surgical instruments, and patient monitoring systems.",
      "yearFounded": 0,
      "linkedInUrl": "",
      "diversity": {
        "gender": "",
        "pronouns": "",
        "race": "",
        "diversityStatus": "Minority Business Enterprise (MBE)"
      },
      "certificates": ["MBE", "SBE", "ISO 13485"],
      "reviews": [],
      "businessLicenses": "",
      "services": [
        {
          "serviceImage": null,
          "title": "Diagnostic Imaging Equipment",
          "description": "",
          "tag": "Medical Devices Manufacturing"
        },
        {
          "serviceImage": null,
          "title": "Surgical Instruments",
          "description": "Our Diagnostic Imaging Equipment encompasses a range of advanced medical devices designed to visualize internal body structures for diagnostic purposes. From X-ray machines to MRI and CT scanners, our cutting-edge imaging solutions offer high-resolution images with exceptional clarity and detail.",
          "tag": "Medical Devices Manufacturing"
        },
        {
          "serviceImage": null,
          "title": "Patient Monitoring Systems",
          "description": "Our Surgical Instruments are meticulously crafted tools designed to assist healthcare professionals during surgical procedures. Made from high-quality materials and precision-engineered for optimal performance, our instruments ensure surgical precision, efficiency, and safety. From scalpels and forceps to retractors and clamps, our comprehensive range of surgical instruments caters to diverse surgical specialties and procedures, enabling surgeons to achieve superior surgical outcomes.",
          "tag": "Medical Devices Manufacturing"
        },
        {
          "serviceImage": null,
          "title": "Medical Device Prototyping",
          "description": "Patient Monitoring Systems provide real-time monitoring and analysis of vital signs and physiological parameters to support patient care and clinical decision-making. Featuring advanced sensors and intuitive interfaces, our monitoring systems offer continuous monitoring of parameters such as heart rate, blood pressure, and oxygen saturation. With customizable alarm settings and remote monitoring capabilities, our systems enhance patient safety and clinical efficiency in various healthcare settings.",
          "tag": "Medical Devices Manufacturing"
        },
        {
          "serviceImage": null,
          "title": "Regulatory Consulting Services",
          "description": "With the Medical Device Prototyping service, we offer rapid and iterative development of prototype medical devices to support your product innovation and validation. Utilizing state-of-the-art prototyping technologies and materials, we transform design concepts into functional prototypes for testing and evaluation. From concept sketches to 3D-printed models and functional prototypes, our prototyping services accelerate the product development process and enable iterative refinement based on user feedback and testing results.",
          "tag": "Medical Devices Manufacturing"
        }
      ],
      "skills": {
        "technical": ["Medical Device Engineering", "Product Development and Prototyping", "Healthcare-based AI Technology"],
        "industry": ["Quality Assurance and Testing"],
        "company": ["Regulatory Compliance"]
      },
      "tier": "Tier 1",
      "mainContacts": [
        {
          "id": uuidv4(),
          "name": "Dr. Rajesh Patel",
          "primaryrole": "Founder & CTO",
          "email": "rajeshpatel@nugenos.com"
        }
      ],
      "otherContacts": [
        {
          "id": uuidv4(),
          "name": "Trisha Nguyen",
          "primaryrole": "HR Director",
          "email": "trisha@nugenos.com"
        }
      ]
    }
  },
  {
    "id": uuidv4(),
    "firstName": "",
    "lastName": "",
    "email": "",
    "address": {
      "country": "USA",
      "state": "CA",
      "city": "San Francisco"
    },
    "company": {
      "name": "Pure Delight Foods, Inc.",
      "size": "Micro (1-10 employees)",
      "industry": "Food and Beverage",
      "companyProfilePicture": null,
      "companyWebsite": "puredelightfoods.com",
      "annualRevenue": "$1.8m",
      "registrationStatus": "Current",
      "yearFounded": 2015
    },
    "password": "",
    "confirmPassword": "",
    "supplierProfile": {
      "name": "Emily Tran",
      "profilePicture": null,
      "email": "emily@puredelightfoods.com",
      "primaryRole": "Founder & CEO",
      "rating": 4.0,
      "bio": "Pure Delight Foods, Inc. is a leading producer of organic and healthy food products. Founded by Emily Tran in 2015, Pure Delight Foods is dedicated to providing delicious, nutritious, and sustainably sourced food options. The Pure Delight Foods product line includes organic snacks, meal kits, and beverages, all crafted with the highest quality ingredients. Pure Delight Foods is committed to promoting health and wellness through nutritional food choices.",
      "yearFounded": 0,
      "linkedInUrl": "",
      "diversity": {
        "gender": "",
        "pronouns": "",
        "race": "",
        "diversityStatus": "Woman-Owned Business Enterprise (WBE)"
      },
      "certificates": ["WBE", "MBE", "Certified Organic by USDA"],
      "reviews": [],
      "businessLicenses": "",
      "services": [
        {
          "serviceImage": null,
          "title": "Organic Snacks and Beverages",
          "description": "Indulge in guilt-free snacking with our Organic Snacks and Beverages. Made from the finest organic ingredients, our snacks and beverages offer delicious flavors without compromising on quality or nutrition. From crispy kale chips to refreshing fruit juices, each product is thoughtfully crafted to satisfy your cravings while supporting a healthy lifestyle. Enjoy the goodness of nature with every bite and sip, knowing that you're nourishing your body with wholesome, organic goodness.",
          "tag": "Food and Beverage"
        },
        {
          "serviceImage": null,
          "title": "Healthy Meal Kits",
          "description": "Make healthy eating convenient and delicious with our Healthy Meal Kits. Packed with nutritious ingredients and easy-to-follow recipes, our meal kits take the guesswork out of meal planning and preparation. Whether you're looking to eat clean, lose weight, or simply enjoy wholesome meals, our curated meal kits offer a variety of options to suit your dietary preferences and lifestyle. Say goodbye to takeout and hello to home-cooked meals that nourish your body and delight your taste buds.",
          "tag": "Food and Beverage"
        },
        {
          "serviceImage": null,
          "title": "Private Label Food Production",
          "description": "Elevate your brand with our Private Label Food Production services. With our expertise in food manufacturing and packaging, we help you bring your culinary creations to life under your own brand name. From concept development to production and packaging, our turnkey solutions ensure that your private label products meet the highest standards of quality and consistency.",
          "tag": "Food and Beverage"
        },
        {
          "serviceImage": null,
          "title": "Nutritional Consulting",
          "description": "Achieve your health and wellness goals with our Nutritional Consulting services. Our team of registered dietitians and nutrition experts provide personalized guidance and support to help you optimize your diet and lifestyle. Whether you're looking to improve your eating habits, manage a medical condition, or enhance athletic performance, our customized nutrition plans and counseling sessions empower you to make informed choices and achieve lasting results.",
          "tag": "Food and Beverage"
        },
        {
          "serviceImage": null,
          "title": "Food Safety Audits",
          "description": "Ensure the safety and quality of your food products with our Food Safety Audits. Our experienced auditors conduct comprehensive assessments of your food production facilities and processes to identify potential hazards and ensure compliance with food safety regulations and standards. From HACCP implementation to sanitation practices and quality control measures, our audits help you mitigate risks, protect your brand reputation, and uphold the highest standards of food safety and quality assurance.",
          "tag": "Food and Beverage"
        }
      ],
      "skills": {
        "technical": ["Organic Food Production", "Marketing and Brand Development"],
        "industry": ["Nutritional Product Development", "Food Safety and Quality Control"],
        "company": ["Sustainable Sourcing"]
      },
      "tier": "Tier 1",
      "mainContacts": [
        {
          "id": uuidv4(),
          "name": "Emily Tran",
          "primaryrole": "Founder & CEO",
          "email": "emilytran@puredelightfoods.com"
        }
      ],
      "otherContacts": [
        {
          "id": uuidv4(),
          "name": "Tim White",
          "primaryrole": "CFO",
          "email": "timwhite@puredelightfoods.com"
        },
        {
          "id": uuidv4(),
          "name": "Jackson Flores",
          "primaryrole": "HR Director",
          "email": "jacksonflores@puredelightfoods.com"
        }
      ]
    }
  },
  {
    "id": uuidv4(),
    "firstName": "",
    "lastName": "",
    "email": "",
    "address": {
      "country": "USA",
      "state": "GA",
      "city": "Atlanta"
    },
    "company": {
      "name": "NewStart, LLC",
      "size": "Small (11-50 employees)",
      "industry": "Human Resources and Staffing",
      "companyProfilePicture": null,
      "companyWebsite": "newstartstaffing.com",
      "annualRevenue": "$1.9m",
      "registrationStatus": "Pending",
      "yearFounded": 0
    },
    "password": "",
    "confirmPassword": "",
    "supplierProfile": {
      "name": "Kimberly Lee",
      "profilePicture": null,
      "email": "kimberly.lee@newstartstaffing.com",
      "primaryRole": "Managing Director",
      "rating": 4.9,
      "bio": "NewStart, Inc is a woman-owned staffing and human resources firm dedicated to connecting businesses with top diverse talent. When working with partners, NewStart focuses on providing tailored staffing solutions and HR consulting services. Known for its commitment to diversity and inclusion, NewStart helps organizations build strong, diverse teams for the modern world.",
      "yearFounded": 0,
      "linkedInUrl": "",
      "diversity": {
        "gender": "",
        "pronouns": "",
        "race": "",
        "diversityStatus": "Woman-Owned Business Enterprise (WBE)"
      },
      "certificates": ["WBE", "MBE", "Certified B Corporation (B Corp)"],
      "reviews": [],
      "businessLicenses": "",
      "services": [
        {
          "serviceImage": null,
          "title": "Temporary and Permanent Staffing",
          "description": "Streamline your workforce management with our Temporary and Permanent Staffing solutions. Whether you need short-term support or long-term talent acquisition, our comprehensive staffing services connect you with qualified candidates who meet your specific requirements and cultural fit. ",
          "tag": "Human Resources and Staffing"
        },
        {
          "serviceImage": null,
          "title": "Executive Search Services",
          "description": "Locate exceptional leadership talent with our Executive Search Services. Our experienced team specializes in identifying and recruiting top executives and senior-level professionals across various industries. Utilizing a strategic approach and extensive networks, we conduct targeted searches to match your organization's unique needs and culture with qualified candidates who possess the skills, experience, and leadership qualities to drive success.",
          "tag": "Human Resources and Staffing"
        },
        {
          "serviceImage": null,
          "title": "HR Consulting Services",
          "description": "Elevate your human resources function with our HR Consulting Services. From policy development to performance management and employee relations, our team of HR experts offers comprehensive consulting services to help you optimize your HR practices and align them with your business objectives. Whether you need assistance with HR strategy, compliance, or organizational development, we provide tailored solutions to support your HR initiatives and drive organizational growth.",
          "tag": "Human Resources and Staffing"
        },
        {
          "serviceImage": null,
          "title": "Employee Training Workshops",
          "description": "Invest in your team's professional development with our Employee Training Workshops. Designed to enhance skills, knowledge, and performance, our workshops cover a wide range of topics, including leadership development, communication skills, diversity and inclusion, and technical training. Led by experienced trainers and subject matter experts, our interactive workshops provide practical insights, actionable strategies, and hands-on exercises to empower your employees and foster continuous learning and growth.",
          "tag": "Human Resources and Staffing"
        },
        {
          "serviceImage": null,
          "title": "Compliance and Regulatory Consulting",
          "description": "Navigate complex regulatory requirements with our Compliance and Regulatory Consulting services. Our team of compliance experts helps you stay ahead of changing regulations and industry standards by providing guidance and support across various compliance areas, including labor laws, safety regulations, data privacy, and industry-specific mandates. Everything you need in one package.",
          "tag": "Human Resources and Staffing"
        }
      ],
      "skills": {
        "technical": ["Talent Acquisition"],
        "industry": ["HR Compliance", "Employee Training and Development"],
        "company": ["Diversity and Inclusion Consulting, "]
      },
      "tier": "Tier 2",
      "mainContacts": [
        {
          "id": uuidv4(),
          "name": "Kimberly Lee",
          "primaryrole": "Managing Director",
          "email": "kimberly.lee@newstartstaffing.com"
        }
      ],
      "otherContacts": [
        {
          "id": uuidv4(),
          "name": "Robin Micchini",
          "primaryrole": "HR Manager",
          "email": "robin.micchini@newstartstaffing.com"
        }
      ]
    }
  },
  {
    "id": uuidv4(),
    "firstName": "",
    "lastName": "",
    "email": "",
    "address": {
      "country": "USA",
      "state": "PA",
      "city": "Philadelphia"
    },
    "company": {
      "name": "Red Ribbon Printing, Inc.",
      "size": "Small (11-50 employees)",
      "industry": "Printing and Promotional Products",
      "companyProfilePicture": null,
      "companyWebsite": "redribbonprinting.net",
      "annualRevenue": "$2.4m",
      "registrationStatus": "Current",
      "yearFounded": 2016
    },
    "password": "",
    "confirmPassword": "",
    "supplierProfile": {
      "name": "Tomiko Yamashita",
      "profilePicture": null,
      "email": "tomiko@redribbonsupport.com",
      "primaryRole": "President",
      "rating": 3.8,
      "bio": "Red Ribbon Printing, Inc. is a top-tier printing company that offers a wide range of printing and promotional products. Established by Tomiko Yamashita in 2016, Red Ribbon Printing is known for combining high-quality products with eco-friendly solutions backed by excellent customer service. Red Ribbon Printing provides custom printing solutions to meet the needs of businesses, schools, and non-profits from around the world.",
      "yearFounded": 0,
      "linkedInUrl": "",
      "diversity": {
        "gender": "",
        "pronouns": "",
        "race": "",
        "diversityStatus": "Woman-Owned Business Enterprise (WBE)"
      },
      "certificates": ["WBE", "MBE", "SBE"],
      "reviews": [],
      "businessLicenses": "",
      "services": [
        {
          "serviceImage": null,
          "title": "Business Cards and Stationery",
          "description": "Make a lasting impression with our Business Cards and Stationery. Crafted with precision and attention to detail, our business cards and stationery products reflect the professionalism and quality of your brand. Elevate your professional image and leave a memorable impression with our premium business cards and stationery.",
          "tag": "Printing and Promotional Products"
        },
        {
          "serviceImage": null,
          "title": "Marketing Materials",
          "description": "Accelerate your marketing efforts with our Marketing Materials. From brochures and flyers to posters and banners, our high-quality marketing materials are designed to capture attention and communicate your brand message effectively. With eye-catching designs and compelling content, our marketing materials help you stand out from the competition and attract your target audience",
          "tag": "Printing and Promotional Products"
        },
        {
          "serviceImage": null,
          "title": "Custom Promotional Items",
          "description": "Leave a lasting impression with our Custom Promotional Items. Whether you're looking for branded giveaways, corporate gifts, or promotional merchandise for events, our custom items are designed to showcase your brand in a memorable and impactful way. From custom-printed apparel and accessories to tech gadgets and office essentials, we offer a wide range of promotional items that can be customized with your logo, messaging, and branding. Build brand awareness, foster customer loyalty, and drive engagement with our high-quality custom promotional items.",
          "tag": "Printing and Promotional Products"
        },
        {
          "serviceImage": null,
          "title": "Large Format Printing",
          "description": "Make a big statement with our Large Format Printing services. Whether you need signage, banners, posters, or vehicle wraps, our large format printing capabilities allow you to create stunning visuals that command attention and leave a lasting impression.",
          "tag": "Printing and Promotional Products"
        },
        {
          "serviceImage": null,
          "title": "Branded Apparel",
          "description": "Showcase your brand with our Branded Apparel. From custom t-shirts and polo shirts to hoodies and jackets, our branded apparel options allow you to outfit your team and promote your brand in style.",
          "tag": "Printing and Promotional Products"
        }
      ],
      "skills": {
        "technical": ["Digital and Offset Printing", "Graphic Design"],
        "industry": ["Promotional Product Sourcing", "Brand Management"],
        "company": ["Custom Packaging Solutions"]
      },
      "tier": "",
      "mainContacts": [
        {
          "id": uuidv4(),
          "name": "Tomiko Yamashita",
          "primaryrole": "President",
          "email": "tomiko@redribbonsupport.com"
        }
      ],
      "otherContacts": [
        {
          "id": uuidv4(),
          "name": "Argus Smith",
          "primaryrole": "Marketing Manager",
          "email": "argus.smith@redribbonsupport.com"
        },
        {
          "id": uuidv4(),
          "name": "Octa Ruhjo",
          "primaryrole": "Customer Success Manager",
          "email": "octa.ruhjo@redribbonsupport.com"
        }
      ]
    }
  },
  {
    "id": uuidv4(),
    "firstName": "",
    "lastName": "",
    "email": "",
    "address": {
      "country": "USA",
      "state": "MI",
      "city": "Detroit"
    },
    "company": {
      "name": "Titan Automotive Components, Inc.",
      "size": "Large (251-500 employees)",
      "industry": "Automotive Manufacturing",
      "companyProfilePicture": null,
      "companyWebsite": "titanauto.com",
      "annualRevenue": "$8.4m",
      "registrationStatus": "Inactive",
      "yearFounded": 0
    },
    "password": "",
    "confirmPassword": "",
    "supplierProfile": {
      "name": "Jason Yang",
      "profilePicture": null,
      "email": "jasonyang@titanauto.com",
      "primaryRole": "President & CEO",
      "rating": 3.5,
      "bio": "Titan Automotive Components, Inc. is a leading manufacturer of high-quality components for the automotive industry. We specialize in manufacturing parts for commercial vehicles and electric aircraft. Titan Automotive Components is dedicated to supporting our partners with cutting-edge technology and superior sustainability initiatives.",
      "yearFounded": 0,
      "linkedInUrl": "",
      "diversity": {
        "gender": "",
        "pronouns": "",
        "race": "",
        "diversityStatus": "Woman-Owned Business Enterprise (WBE)"
      },
      "certificates": ["WBE", "MBE", "ISO/TS 16949 Certified"],
      "reviews": [],
      "businessLicenses": "",
      "services": [
        {
          "serviceImage": null,
          "title": "Engine and Transmission Components",
          "description": "Power up your vehicle with our Engine and Transmission Components. Engineered for performance and durability, our components are designed to optimize power delivery, fuel efficiency, and reliability. Lead the way with precision-engineered engine parts to robust transmission components, our products meet the highest quality standards and OEM specifications. Whether you're upgrading your vehicle or performing routine maintenance, our engine and transmission components ensure smooth operation and peak performance on the road.",
          "tag": "Automotive Manufacturing"
        },
        {
          "serviceImage": null,
          "title": "Electric Vehicle Parts",
          "description": "Drive the future with our Electric Vehicle Parts. Designed to support the growing demand for electric vehicles, our parts and components are engineered for efficiency, reliability, and sustainability. Whether you need battery systems and electric motors or charging infrastructure and power management solutions, our electric vehicle parts enable you to build or upgrade electric vehicles with confidence. Experience the next generation of mobility with our high-quality electric vehicle parts.",
          "tag": "Automotive Manufacturing"
        },
        {
          "serviceImage": null,
          "title": "Advanced Driver-Assistance Systems (ADAS)",
          "description": "Stay safe and connected on the road with our Advanced Driver-Assistance Systems (ADAS). Utilizing new-age sensors, cameras, and software algorithms, our ADAS solutions enhance driver awareness, improve vehicle control, and prevent accidents. Experience the future of automotive safety with our advanced ADAS technologies.",
          "tag": "Automotive Manufacturing"
        },
        {
          "serviceImage": null,
          "title": "Custom Automotive Solutions",
          "description": "Unlock the full potential of your vehicle with our Custom Automotive Solutions. Whether you're looking to enhance performance, improve aesthetics, or add personalized features, our custom solutions are tailored to meet your unique needs and preferences. Transform your vehicle into a one-of-a-kind masterpiece with our custom automotive solutions.",
          "tag": "Automotive Manufacturing"
        },
        {
          "serviceImage": null,
          "title": "Quality Assurance Services",
          "description": "Ensure the highest standards of quality and reliability with our Quality Assurance Services. Our service includes product testing and validation to process audits and supplier assessments, and our comprehensive quality assurance services help you identify and mitigate risks at every stage of the production lifecycle. Trust our quality assurance services to safeguard your reputation and deliver exceptional products to your customers.",
          "tag": "Automotive Manufacturing"
        }
      ],
      "skills": {
        "technical": ["Precision Machining", "Quality Control and Testing","Advanced Materials Engineering"],
        "industry": ["Supply Chain Management"],
        "company": ["Automotive Electronics"]
      },
      "tier": "Tier 2",
      "mainContacts": [
        {
          "id": uuidv4(),
          "name": "Jason Yang",
          "primaryrole": "President & CEO",
          "email": "jasonyang@titanauto.com"
        }
      ],
      "otherContacts": [
        {
          "id": uuidv4(),
          "name": "Kota Subaru",
          "primaryrole": "HR Director",
          "email": "kotasubaru@titanauto.com"
        },
        {
          "id": uuidv4(),
          "name": "Fran Rellis",
          "primaryrole": "CFO",
          "email": "franrellis@titanauto.com"
        }
      ]
    }
  },
  {
    "id": uuidv4(),
    "firstName": "",
    "lastName": "",
    "email": "",
    "address": {
      "country": "USA",
      "state": "CA",
      "city": "Sacramento"
    },
    "company": {
      "name": "Bulldog Vehicle Interiors, LLC",
      "size": "Small (11-50 employees)",
      "industry": "Automotive Manufacturing",
      "companyProfilePicture": null,
      "companyWebsite": "bulldoginteriors.com",
      "annualRevenue": "$1.8m",
      "registrationStatus": "Pending",
      "yearFounded": 2020
    },
    "password": "",
    "confirmPassword": "",
    "supplierProfile": {
      "name": "Alex Rodriguez",
      "profilePicture": null,
      "email": "alex@bulldoginteriors.com",
      "primaryRole": "Founder & CTO",
      "rating": 4.3,
      "bio": "Bulldog Vehicle Interiors, founded in 2020 by automotive specialist Alex Rodriguez, is a leading provider of high-end interiors for luxury and electric vehicles. Headquartered in Sacramento, CA, Bulldog Vehicle Interiors works with leading automotive brands to deliver bespoke interior solutions that combine comfort, aesthetics, and cutting-edge technology. Our mission is to help partners develop superb automotive interiors through innovative, efficient, and cost-effective solutions. Bulldog Vehicle Interiors prides itself on its technical expertise, exceptional customer service, and dedication to quality assurance.",
      "yearFounded": 0,
      "linkedInUrl": "",
      "diversity": {
        "gender": "",
        "pronouns": "",
        "race": "",
        "diversityStatus": "Hispanic-Owned Business (HOB)"
      },
      "certificates": ["HOB", "MBE", "ISO 9001 Certified"],
      "reviews": [],
      "businessLicenses": "",
      "services": [
        {
          "serviceImage": null,
          "title": "Luxury Seating Solutions",
          "description": "Lead with unparalleled comfort and style with our Luxury Seating Solutions. Crafted with precision and attention to detail, our seating solutions redefine luxury in automotive interiors. From plush leather upholstery to ergonomic designs and customizable features, our seats offer the ultimate in comfort, support, and sophistication. Whether you're cruising on the highway or navigating city streets, our luxury seating solutions elevate your driving experience to new heights of luxury and refinement.",
          "tag": "Automotive Manufacturing"
        },
        {
          "serviceImage": null,
          "title": "High-Quality Upholstery",
          "description": "Experience the epitome of craftsmanship with our High-Quality Upholstery services. Using the finest materials and meticulous attention to detail, we transform your vehicle's interior into a luxurious sanctuary of comfort and elegance. From premium leather and suede to exotic materials and custom embroidery, our upholstery services offer endless possibilities for personalization and customization. Enhance the beauty and sophistication of your vehicle with our high-quality upholstery craftsmanship.",
          "tag": "Automotive Manufacturing"
        },
        {
          "serviceImage": null,
          "title": "Interior Trim Components",
          "description": "Elevate your vehicle's interior aesthetics with our Interior Trim Components. From door panels and center consoles to dashboard accents and trim details, our trim components add a touch of sophistication and refinement to every corner of your vehicle's interior. Crafted from premium materials and precision-engineered for a perfect fit, our trim components enhance the visual appeal and tactile experience of your vehicle's interior.",
          "tag": "Automotive Manufacturing"
        },
        {
          "serviceImage": null,
          "title": "Custom Dashboard Design",
          "description": "Make a statement with our Custom Dashboard Design services. From sleek and modern layouts to classic and timeless designs, our custom dashboards are tailored to reflect your unique style and preferences. Advanced technology and innovative features offer intuitive controls, seamless connectivity, and personalized interfaces to enhance your driving experience. Whether you prefer minimalist elegance or high-tech sophistication, our custom dashboard designs combine form and function to create a dashboard that's as unique as you are.",
          "tag": "Automotive Manufacturing"
        },
        {
          "serviceImage": null,
          "title": "Soundproofing and Insulation",
          "description": "Enjoy a quiet and peaceful ride with our Soundproofing and Insulation services. Designed to reduce noise, vibration, and harshness (NVH) levels in your vehicle's cabin, our soundproofing and insulation solutions create a serene environment for driving and relaxation. Using advanced materials and expert installation techniques, we minimize road noise, engine vibrations, and outside disturbances to ensure a comfortable and enjoyable driving experience. Escape the noise and distractions of the outside world with our soundproofing and insulation services.",
          "tag": "Automotive Manufacturing"
        }
      ],
      "skills": {
        "technical": ["Upholstery and Trim", "Ergonomic Engineering", "Quality Assurance and Testing"],
        "industry": ["Advanced Material Sourcing"],
        "company": ["Custom Interior Design"]
      },
      "tier": "Tier 2",
      "mainContacts": [
        {
          "id": uuidv4(),
          "name": "Alex Rodriguez",
          "primaryrole": "Founder & CTO",
          "email": "alex@bulldoginteriors.com"
        }
      ],
      "otherContacts": [
        {
          "id": uuidv4(),
          "name": "John Harris",
          "primaryrole": "Managing Director",
          "email": "john@bulldoginteriors.com"
        }
      ]
    }
  }
]