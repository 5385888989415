import React, { useState, useRef, useEffect } from "react";
import SignUpContainer from "../../SignUpContainer";
import { Col, Form, Image } from "react-bootstrap";
import "../../SharedComponents/styling.css"

const PersonalDetails = ({ formDataStep, setFormDataStep, handleNextStep, accountType, step, sellerProfile }) => {
  // const [isCountryDropdownOpen, setCountryDropdownOpen] = useState(false);
  // const [isStateDropdownOpen, setStateDropdownOpen] = useState(false);
  // const [isIndustryDropdownOpen, setIndustryDropdownOpen] = useState(false);
  // const industryDropdownRef = useRef();
  // const stateDropdownRef = useRef();
  // const countryDropdownRef = useRef();

  
  const handleSubmit = (event) => {
    event.preventDefault();

      handleNextStep();
  };
  const handleSkip = () => {
    handleNextStep();
  };

  // const industryOptions = [
  //   "",
  //   "Technology",
  //   "Healthcare",
  //   "Finance",
  //   "Education",
  //   "Retail",
  //   "Manufacturing",
  //   "Non-profit",
  //   "Energy",
  //   "Transportation",
  //   "Real Estate",
  //   "Agriculture",
  //   "Hospitality",
  //   "Entertainment",
  //   "Telecommunications",
  //   "Government",
  //   "Consulting",
  //   "Consumer Goods",
  //   "Environmental Services",
  //   "Marketing/Advertising",
  //   "Legal",
  //   "Other",
  // ];
  // const companyLocations = [
  //   "Alabama", "Alaska", "American Samoa", "Arizona", "Arkansas", "California",
  //   "Colorado", "Connecticut", "Delaware", "District Of Columbia", "Florida", "Georgia",
  //   "Guam", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky",
  //   "Louisiana", "Maine", "Marshall Islands", "Maryland", "Massachusetts", "Michigan",
  //   "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada",
  //   "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina",
  //   "North Dakota", "Northern Marianas", "Ohio", "Oklahoma", "Oregon", "Palau",
  //   "Pennsylvania", "Puerto Rico", "Rhode Island", "South Carolina", "South Dakota",
  //   "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington",
  //   "West Virginia", "Wisconsin", "Wyoming"
  // ];
  // const countries = [
  //   "United States",
  //   "Canada",
  //   "United Kingdom",
  //   "Australia",
  //   "Germany",
  //   "France",
  //   "Japan",
  //   "China",
  //   "India",
  //   "Brazil",
  //   "Mexico",
  //   "Italy",
  //   "Spain",
  //   "South Korea",
  //   "Russia",
  //   "Indonesia",
  //   "Netherlands",
  //   "Switzerland",
  //   "Sweden",
  //   "Belgium",
  // ];


 

  // const handleToggleIndustryDropdown = () => {
  //   setIndustryDropdownOpen(!isIndustryDropdownOpen);
  // };


  // const handleSelectIndustryOption = (value) => {
  //   setFormDataStep({
  //     ...formDataStep,
  //     supplierProfile: {
  //       ...formDataStep.supplierProfile,
  //         industry: value 
        
  //     }
  //   });
  //   setIndustryDropdownOpen(false);
  // };

  // const handleToggleCountry = () => {
  //   setCountryDropdownOpen(!isCountryDropdownOpen);
  // };
  // const handleToggleState = () => {
  //   setStateDropdownOpen(!isStateDropdownOpen);
  // };


  // const handleSelectCountryOption = (value) => {
  //   setFormDataStep({
  //     ...formDataStep,
  //     supplierProfile: {
  //       ...formDataStep.supplierProfile,
  //       address: {
  //         ...formDataStep.supplierProfile.address,
  //         country: value,
  //       },
  //     },
  //   });
  //   setCountryDropdownOpen(false);
  // };

  // const handleSelectStateOption = (value) => {
  //   setFormDataStep({
  //     ...formDataStep,
  //     supplierProfile: {
  //       ...formDataStep.supplierProfile,
  //       address: {
  //         ...formDataStep.supplierProfile.address,
  //         state: value,
  //       },
  //     },
  //   });
  //   setStateDropdownOpen(false);
  // };
  // const handleSelectCityOption = (value) => {
  //   setFormDataStep({
  //     ...formDataStep,
  //     supplierProfile: {
  //       ...formDataStep.supplierProfile,
  //       address: {
  //         ...formDataStep.supplierProfile.address,
  //         city: value,
  //       },
  //     },
  //   });
  // };


  // const handleClickOutside = (event) => {
  //   if (
  //     (countryDropdownRef.current &&
  //       !countryDropdownRef.current.contains(event.target)) ||
  //     (stateDropdownRef.current &&
  //       !stateDropdownRef.current.contains(event.target)) ||
  //     (industryDropdownRef.current &&
  //       !industryDropdownRef.current.contains(event.target))
  //   ) {
  //     setCountryDropdownOpen(false);
  //     setStateDropdownOpen(false);
  //     setIndustryDropdownOpen(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  return (
    <SignUpContainer
      title="Personal Details"
      subtitle={<>Tell us about yourself</>}
      // height="1626px"
      height="1152px"
      onContinueClick={handleSubmit}
      handleSkip={handleSkip}
      step={step}
      sellerProfile={sellerProfile}
    >
      <Form onSubmit={handleSubmit}>
        <div style={{display:"flex", flexDirection:"row", margin: "0 43px 40px 43px",
    gap: "24px"}}>
        <Form.Group as={Col} >
          <Form.Label className="input-title" style={{marginBottom:"7px"}}>Your Primary role</Form.Label>
          <Form.Control
            type="text"
            style={{width:"275px"}}
            placeholder="Technical Recruiter"
            required
            className="custom-input"
            value={formDataStep.primaryRole}
            onChange={(e) => {
              setFormDataStep({
                ...formDataStep,
                primaryRole: e.target.value,
              })
            }}
          />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label className="input-title" style={{marginBottom:"7px"}}>Year founded</Form.Label>
          <Form.Control
            type="text"
            placeholder="2024"
            style={{width:"275px"}}
            required
            className="custom-input"
            value={formDataStep.yearFounded}
            onChange={(e) => {
              setFormDataStep({
                ...formDataStep,
                yearFounded: e.target.value,
              })
            }}
          />
        </Form.Group>
        </div>

        {/* <Form.Group className="single-input">
          <Form.Label className="input-title" >Industry</Form.Label>
          <div
            ref={industryDropdownRef}
            className={`custom-input custom-dropdown-container ${
              isIndustryDropdownOpen ? "open" : ""
            }
            `}
          >
            <div
              className="custom-dropdown"
              onClick={handleToggleIndustryDropdown}
            >
              <span className="selected-option">
                {formDataStep.supplierProfile.industry || "Select Industry"}
              </span>
              <ul
                className={`custom-dropdown-industry-list ${
                  isIndustryDropdownOpen ? "show" : ""
                }`}
              >
                {industryOptions.map((option, index) => (
                  <li
                    key={option}
                    className={`custom-dropdown-item ${
                      index === 0 
                        ? "disabled"
                        : ""
                    }`}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    handleSelectIndustryOption(option);
                  }}
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Form.Group> */}

        {/* <Form.Group
          as={Col}
          controlId="companyCountry"
          className="single-input"
        >
          <Form.Label className="input-title">Country</Form.Label>
          <div
            ref={countryDropdownRef}
            className={`custom-input custom-dropdown-container ${
              isCountryDropdownOpen ? "open" : ""
            }
          `}
          >
            <div className="custom-dropdown" onClick={handleToggleCountry}>
              <span className="selected-option">
                {formDataStep.supplierProfile.address.country || "Select Country"}
              </span>
              <ul
                className={`custom-dropdown-list ${
                  isCountryDropdownOpen ? "show" : ""
                }`}
              >
                <li
                  className={`custom-dropdown-item ${
                    formDataStep.supplierProfile.address.country ? "" : "disabled"
                  }`}
                  onClick={() => handleSelectCountryOption("")}
                >
                  Select Location
                </li>
                {countries.map((country, index) => (
                  <li
                    key={index}
                    className="custom-dropdown-item"
                    onMouseDown={(e) => {
                      e.preventDefault();
                      handleSelectCountryOption(country);
                    }}
                  >
                    {country}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Form.Group> */}

        {/* <Form.Group as={Col} controlId="companyState" className="single-input">
          <Form.Label className="input-title">State</Form.Label>
          <div
            ref={stateDropdownRef}
            className={`custom-input custom-dropdown-container ${
              isStateDropdownOpen ? "open" : ""
            }
           `}
          >
            <div className="custom-dropdown" onClick={handleToggleState}>
              <span className="selected-option">
                {formDataStep.supplierProfile.address.state || "Select State"}
              </span>
              <ul
                className={`custom-dropdown-list ${
                  isStateDropdownOpen ? "show" : ""
                }`}
              >
                <li
                  className={`custom-dropdown-item ${
                    formDataStep.supplierProfile.address.state ? "" : "disabled"
                  }`}
                  onClick={() => handleSelectStateOption("")}
                >
                  Select State
                </li>
                {companyLocations.map((location, index) => (
                  <li
                    key={index}
                    className="custom-dropdown-item"
                    onMouseDown={(e) => {
                      e.preventDefault();
                      handleSelectStateOption(location);
    
                    }}
                  >
                    {location}
                  </li>
                ))}
              </ul>
            </div>
          </div>

        </Form.Group> */}
        {/* <Form.Group as={Col} controlId="cityLocation" className="single-input">
          <Form.Label className="input-title">City</Form.Label>
          <Form.Control
            type="text"
            placeholder="City"
            required
            className="custom-input"
            value={formDataStep.supplierProfile.address.city}
            onChange={(e) => {
              e.preventDefault();
              handleSelectCityOption(e.target.value);
            }}
          />
        </Form.Group> */}
  
        <Form.Group as={Col} style={{margin: "0 43px 40px 43px"}} >
          <Form.Label className="input-title" style={{marginBottom:"7px"}}>Your bio</Form.Label>
          <Form.Control
             as="textarea"
            style={{height:"196px", padding:"20px", overflow:"hidden", resize:"none"}}
            required
            className="custom-input"
            value={formDataStep.bio}
            onChange={(e) => {
              setFormDataStep({
                ...formDataStep,
                bio: e.target.value,
              })
            }}
          />
        </Form.Group>
        <Form.Group as={Col}  className="single-input">
          <Form.Label className="input-title">Website</Form.Label>
          <Form.Control
            type="text"
            placeholder="https://"
            required
            className="custom-input"
            value={formDataStep.website}
            onChange={(e) => {
              setFormDataStep({
                ...formDataStep,
                website: e.target.value,
              })
            }}
          />
        </Form.Group>
        <Form.Group as={Col}  className="single-input">
          <Form.Label className="input-title">LinkedIn</Form.Label>
          <Form.Control
            type="text"
            placeholder="linkedin.com/in/"
            required
            className="custom-input"
            value={formDataStep.linkedin}
            onChange={(e) => {
              setFormDataStep({
                ...formDataStep,
                linkedin: e.target.value,
              })
            }}
          />
        </Form.Group>
        
      </Form>
    </SignUpContainer>
  );
};

export default PersonalDetails;

