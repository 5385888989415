import React, { useState, useRef, useEffect } from "react";
import SignUpContainer from "../../SignUpContainer";
import { Form } from "react-bootstrap";
import "../../SharedComponents/styling.css"

const DiversityInformation = ({
  formDataStep,
  setFormDataStep,
  handleNextStep,
  handleSkip,
  accountType,
  skip,
  step,
  sellerProfile
}) => {
  const [isGenderDropdownOpen, setGenderDropdownOpen] = useState(false);
  const [isPronounsDropdownOpen, setPronounsDropdownOpen] = useState(false);
  const [isRaceDropdownOpen, setRaceDropdownOpen] = useState(false);
  const [isOwnershipDropdownOpen, setOwnershipDropdownOpen] = useState(false);
  const genderDropdownRef = useRef();
  const pronounsDropdownRef = useRef();
  const raceDropdownRef = useRef();
  const ownershipDropdownRef = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();

    handleNextStep();
  };

  const genderOptions = [
    "",
    "Male",
    "Female",
    "Non-binary",
    "Genderqueer",
    "Transgender",
    "Cisgender ",
    "Androgynous",
    "Intersex",
    "Other",
  ];
  const pronounsOptions = [
    "",
    "He/Him/His",
    "She/Her/Hers",
    "They/Them/Theirs",
    "Ze/Hir/Hirs",
    "Ze/Zir/Zirs",
    "Xe/Xem/Xyrs",
    "Ey/Em/Eirs",
    "Ve/Ver/Vers",
    "Per/Per/Per's",
    "Emself/Emself/Emself",
  ];

  const raceOptions = [
    "",
    "White",
    "Black or African American",
    "Asian",
    "Native American or Alaska Native",
    "Native Hawaiian or Other Pacific Islander",
    "Multiracial",
    "Middle Eastern or North African (MENA)",
    "Indigenous or Aboriginal",
    "Other (with self-identification option)",
  ];
  const ownershipOptions = [
    "",
    "Women-Owned Business (WOB)",
    "Minority-Owned Business (MBE)",
    "Veteran-Owned Business (VOSB)",
    "Service-Disabled Veteran-Owned Business (SDVOSB)",
    "LGBTQ+-Owned Business",
    "Disabled-Owned Business (DOBE)",
    "Small Business (based on relevant criteria such as revenue, number of employees, etc.)",
    "Historically Underutilized Business (HUB)",
    "Disadvantaged Business Enterprise (DBE)",
    "Socially or Economically Disadvantaged Business",
    "Native American-Owned Business",
    "Alaskan Native-Owned Business",
    "Native Hawaiian-Owned Business",
    "Asian-Owned Business",
    "Hispanic-Owned Business",
    "Black-Owned Business",
    "Pacific Islander-Owned Business",
    "Middle Eastern-Owned Business",
    "Nonprofit or Social Enterprise"
  ];
  

  const handleToggleGenderDropdown = () => {
    setGenderDropdownOpen(!isGenderDropdownOpen);
  };

  const handleTogglePronounsDropdown = () => {
    setPronounsDropdownOpen(!isPronounsDropdownOpen);
  };
  const handleToggleRaceDropdown = () => {
    setRaceDropdownOpen(!isRaceDropdownOpen);
  };
  const handleToggleOwnershipDropdown = () => {
    setOwnershipDropdownOpen(!isOwnershipDropdownOpen);
  };

  const handleSelectGenderOption = (value) => {
    setFormDataStep({
      ...formDataStep,
      diversity: {
        ...formDataStep.diversity,
        gender: value
      }
    });
    setGenderDropdownOpen(false);
  };

  const handleSelectPronounsOption = (value) => {
    setFormDataStep({
      ...formDataStep,
      diversity: {
        ...formDataStep.diversity,
        pronouns: value
      }
    });
    setPronounsDropdownOpen(false);
  };
  const handleSelectRaceOption = (value) => {
    setFormDataStep({
      ...formDataStep,
      diversity: {
        ...formDataStep.diversity,
        race: value
      }
    });
    setRaceDropdownOpen(false);
  };
  const handleSelectOwnershipOption = (value) => {
    setFormDataStep({
      ...formDataStep,
      diversity: {
        ...formDataStep.diversity,
        diversityStatus: value
      }
    });
    setOwnershipDropdownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (
      (genderDropdownRef.current &&
        !genderDropdownRef.current.contains(event.target)) ||
      (pronounsDropdownRef.current &&
        !pronounsDropdownRef.current.contains(event.target))
        ||
        (raceDropdownRef.current &&
          !raceDropdownRef.current.contains(event.target))
          ||
          (ownershipDropdownRef.current &&
            !ownershipDropdownRef.current.contains(event.target))
        ) {

      setGenderDropdownOpen(false);
      setPronounsDropdownOpen(false);
      setRaceDropdownOpen(false);
      setOwnershipDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <SignUpContainer
      title="Diversity group"
      subtitle={<>Tell us about diversity group</>}
      height="1052px"
      step={step}
      skip={skip}
      // containerHeight="100%"
      onContinueClick={handleSubmit}
      handleSkip={handleSkip}
      accountType={accountType}
      sellerProfile={sellerProfile}
    >
      <Form onSubmit={handleSubmit}>
        <Form.Group className="single-input">
          <Form.Label className="input-title">Gender</Form.Label>
          <div
            ref={genderDropdownRef}
            className={`custom-input custom-dropdown-container ${
              isGenderDropdownOpen ? "open" : ""
            }
            `}
          >
            <div
              className="custom-dropdown"
              onClick={handleToggleGenderDropdown}
              
            >
              <span className="selected-option">
                {formDataStep.diversity.gender || "Male"}
              </span>
              <ul
                className={`custom-dropdown-list ${
                  isGenderDropdownOpen ? "show" : ""
                }`}
              >
                {genderOptions.map((option, index) => (
                  <li
                    key={option}
                    className={`custom-dropdown-item ${
                      index === 0 ? "disabled" : ""
                    }`}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      handleSelectGenderOption(option);
                      
                    }}
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Form.Group>
        <Form.Group className="single-input">
          <Form.Label className="input-title">Pronouns</Form.Label>
          <div
            ref={pronounsDropdownRef}
            className={`custom-input custom-dropdown-container ${
              isPronounsDropdownOpen ? "open" : ""
            }
            `}
          >
            <div
              className="custom-dropdown"
              onClick={handleTogglePronounsDropdown}
            >
              <span className="selected-option">
                {formDataStep.diversity.pronouns || "He/Him"}
              </span>
              <ul
                className={`custom-dropdown-list ${
                  isPronounsDropdownOpen ? "show" : ""
                }`}
              >
                {pronounsOptions.map((option, index) => (
                  <li
                    key={option}
                    className={`custom-dropdown-item ${
                      index === 0 ? "disabled" : ""
                    }`}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      handleSelectPronounsOption(option);
                    
                    }}
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Form.Group>
        <Form.Group className="single-input">
          <Form.Label className="input-title">Race/Ethnicity</Form.Label>
          <div
            ref={raceDropdownRef}
            className={`custom-input custom-dropdown-container ${
              isRaceDropdownOpen ? "open" : ""
            }
            `}
          >
            <div className="custom-dropdown" onClick={handleToggleRaceDropdown}>
              <span className="selected-option">
                {formDataStep.diversity.race || "Choose race"}
              </span>
              <ul
                className={`custom-dropdown-list ${
                  isRaceDropdownOpen ? "show" : ""
                }`}
              >
                {raceOptions.map((option, index) => (
                  <li
                    key={option}
                    className={`custom-dropdown-item ${
                      index === 0 ? "disabled" : ""
                    }`}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      handleSelectRaceOption(option);
                     
                    }}
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Form.Group>
        <Form.Group className="single-input">
          <Form.Label className="input-title">Business Ownership</Form.Label>
          <div
            ref={ownershipDropdownRef}
            className={`custom-input custom-dropdown-container ${
              isOwnershipDropdownOpen ? "open" : ""
            }
            `}
          >
            <div
              className="custom-dropdown"
              onClick={handleToggleOwnershipDropdown}
            >
              <span className="selected-option">
                {formDataStep.diversity.diversityStatus || "Women-owned"}
              </span>
              <ul
                className={`custom-dropdown-ownership-list ${
                  isOwnershipDropdownOpen ? "show" : ""
                }`}
              >
                {ownershipOptions.map((option, index) => (
                  <li
                    key={option}
                    className={`custom-dropdown-item ${
                      index === 0 ? "disabled" : ""
                    }`}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      handleSelectOwnershipOption(option);
                    }}
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Form.Group>
      </Form>
    </SignUpContainer>
  );
};

export default DiversityInformation;
