import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BasicInformation from "../SharedComponents/BasicInformation";
import CompanyDetails from "../SharedComponents/CompanyDetails";
import CompanyLocation from "../SharedComponents/CompanyLocation";
import CreatePassword from "../SharedComponents/CreatePassword";
import OTPConfirmation from "../SharedComponents/OTPConfirmation";
import ProfileConfirmation from "../SharedComponents/ProfileConfirmation";
import CompanyDetailsPartTwo from "../SharedComponents/CompanyDetailsPartTwo";
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { BuyerSignup } from "../../../actions/authActions";
import { getSellerOTP, verifySellerOTP } from "../../../actions/OTPAction";


const BuyerOnboarding = () => {
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const [formDataStep, setFormDataStep] = useState({
    firstName: "",
    lastName: "",
    email: "",

    company: {
      name: "",
      size: "",
      industry: "",
    },
    address: {
      country: "",
      state: "",
      city: "",
    },
    password: "",
    confirmPassword: ""
  });
    

  const navigate = useNavigate();
console.log('form data step', formDataStep)


const handleSignUp = () => {
  dispatch(BuyerSignup(formDataStep));
}

const getOtpCode = () => {
  dispatch(getSellerOTP(formDataStep.email, 5))
}
const handleOTPVerification = (email, otpId, otp) => {
  dispatch(verifySellerOTP(email,otpId, otp));
}

const handleSubmit = (event) => {
  if (event) event.preventDefault();
  handleNextStep();

};



  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
    window.history.pushState(null, null, `#${currentStep + 1}`);
  };

  useEffect(() => {
    if (currentStep === 1)
      window.history.pushState(null, null, `#${currentStep}`);
  }, []);
  useEffect(() => {
    const handleHashChange = () => {
      const hashStep = parseInt(window.location.hash.slice(1), 10);

      if (!isNaN(hashStep) && hashStep !== currentStep) {
        setCurrentStep(hashStep);
      }
    };

    const handleBackClick = () => {
      if (currentStep === 1) {
        navigate("/sign-up");
      } else {
        setCurrentStep((prevStep) => prevStep - 1);
      }
    };

    window.addEventListener("hashchange", handleHashChange);
    window.addEventListener("popstate", handleBackClick);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
      window.removeEventListener("popstate", handleBackClick);
    };
  }, [currentStep, navigate, setCurrentStep]);

  useEffect(() => {
    const hashStep = parseInt(window.location.hash.slice(1), 10);
    if (!isNaN(hashStep) && hashStep !== currentStep) {
      setCurrentStep(hashStep);
    }
  }, [currentStep]);

  const renderStep = () => {
    switch (currentStep) {
      // Step 1 
      case 1:
        return (
          <BasicInformation
            formDataStep={formDataStep}
            setFormDataStep={setFormDataStep}
            handleNextStep={handleNextStep}
            handleSubmit={handleSubmit}
          />
        );
      // Step 2
      case 2:
        return (
          <CompanyDetails
            formDataStep={formDataStep}
            setFormDataStep={setFormDataStep}
            handleNextStep={handleNextStep}
          />
        );
 // Step 3
      case 3:
        return (
          <CompanyLocation
            formDataStep={formDataStep}
            setFormDataStep={setFormDataStep}
            handleNextStep={handleNextStep}
          />
        );
        case 4:
        return (
          <CompanyDetailsPartTwo
            handleNextStep={handleNextStep}
          />
        );
         // Step 4
        case 5:
          return (
            <CreatePassword
              formDataStep={formDataStep}
              setFormDataStep={setFormDataStep}
              handleNextStep={handleNextStep}
              getOtpCode={getOtpCode}
            />
          );
           // Step 5
          case 6:
          return (
            <OTPConfirmation
              formDataStep={formDataStep}
              setFormDataStep={setFormDataStep}
              handleNextStep={handleNextStep}
              handleOTPVerification={handleOTPVerification}
              handleSignUp={handleSignUp}
            />
          );
           // Step 6
          case 7:
            return (
              <ProfileConfirmation
              title="Email Confirmed"
                formDataStep={formDataStep}
                setFormDataStep={setFormDataStep}
                handleNextStep={handleNextStep}
                accountType="buyer"
                handleSubmit={handleSubmit}
              />
            );
      default:
        return null;
    }
  };
  return <>{renderStep()}</>;
};

export default BuyerOnboarding;
