import React, { useState, useRef, useEffect } from "react";
import DragAndDropComponent from "../../../DragAndDrop/DragAndDropComponent";
import { Container } from "react-bootstrap";
import Overlay from "../../../Overlay/Overlay";
import PreviewPopup from "../../../FilePreviewPopup/PreviewPopup";
import { Col, Form, Image } from "react-bootstrap";
import questionMark from "../../../../assets/questionMark.svg";
import MappingTable from "./MappingTable";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  setTierOneSuppliers,
  setTierTwoSuppliers,
  setPendingSuppliers,
  updateSupplierById,
  updateSupplierTierOneById,
  updateSupplierTierTwoById,
} from "../../../../actions/suppliersActions";

const CreateNewSupplierForm = ({
  setNewSupplierClicked,
  newSupplier,
  editableCompany,
  isEditableSupplier,
  setEditableSupplier,
  setSupplierCreated,
  setEditPopup,
  tierOneSuppliers,
  tierTwoSuppliers,
  pendingSuppliers,
}) => {
  const [pdfFile, setPdfFile] = useState(null);
  const [importedFiles, setImportedFiles] = useState([]);
  const [showPreviewPopup, setShowPreviewPopup] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [isSizeDropdownOpen, setSizeDropdownOpen] = useState(false);
  const [isIndustryDropdownOpen, setIndustryDropdownOpen] = useState(false);
  const [isCountryDropdownOpen, setCountryDropdownOpen] = useState(false);
  const [isStateDropdownOpen, setStateDropdownOpen] = useState(false);
  const [isTierDropdownOpen, setTierDropdownOpen] = useState(false);
  const tierDropdownRef = useRef();
  const sizeDropdownRef = useRef();
  const industryDropdownRef = useRef();
  const stateDropdownRef = useRef();
  const countryDropdownRef = useRef();
  const [autoFilledData, setAutoFilledData] = useState([]);
  const [isManuallyCreated, setManuallyCreated] = useState(false);
  const [newSupplierData, setNewSupplierData] = useState(newSupplier);
  const [showMessage, setShowMessage] = useState(false);
  const [newImportedSupplierTier, setnewImportedSupplierTier] = useState("");
  const navigate = useNavigate();
  const [isProceed, setProceed] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleCreateByFile = (event) => {
    if (event) event.preventDefault();
    setIsProcessing(true);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (isProcessing) {
      const newDataArray = autoFilledData.map((data) => {
        return {
          id: uuidv4(),
          firstName: "",
          lastName: "",
          email: "",
          address: {
            country: "",
            state: "",
            city: "",
          },
          company: {
            name: data.companyname,
            size: "",
            industry: data.companytype,
            companyProfilePicture: null,
            registrationStatus: "",
            yearFounded: "",
            companyWebsite: data.url,
            annualRevenue: "",
          },
          password: "",
          confirmPassword: "",
          supplierProfile: {
            name: "",
            profilePicture: null,
            email: "",
            primaryRole: "",
            rating: 0,
            bio: "",
            yearFounded: 0,
            linkedInUrl: "",
            diversity: {
              gender: "",
              pronouns: "",
              race: "",
              diversityStatus: data.ownership,
            },
            certificates: [data.certification],
            reviews: [],
            businessLicenses: "",
            services: [
              {
                serviceImage: null,
                title: "",
                description: "",
                tag: "",
              },
              {
                serviceImage: null,
                title: "",
                description: "",
                tag: "",
              },
              {
                serviceImage: null,
                title: "",
                description: "",
                tag: "",
              },
              {
                serviceImage: null,
                title: "",
                description: "",
                tag: "",
              },
              {
                serviceImage: null,
                title: "",
                description: "",
                tag: "",
              },
            ],
            skills: {
              technical: [],
              industry: [],
              company: [],
            },
            tier: "",
            mainContacts: [
              {
                id: uuidv4(),
                name: "",
                primaryrole: "",
                email: "",
              },
            ],
            otherContacts: [
              {
                id: uuidv4(),
                name: "",
                primaryrole: "",
                email: "",
              },
            ],
          },
        };
      });
      if (newImportedSupplierTier === "Tier 1") {
        dispatch(setTierOneSuppliers([...tierOneSuppliers, ...newDataArray]));
      } else if (newImportedSupplierTier === "Tier 2") {
        dispatch(setTierTwoSuppliers([...tierTwoSuppliers, ...newDataArray]));
      }

      navigate(
        "/buyer-dashboard/management/create-new-supplier/mapping-table",
        {
          state: { suppliers: autoFilledData },
        }
      );

      setIsProcessing(false);
    }
  }, [isProcessing]);

  useEffect(() => {
    if (editableCompany) {
      setEditableSupplier(true);
      setCurrentStep(2);
      setNewSupplierData(editableCompany);
    }
  }, [editableCompany]);


  const handleEditConfirmation = () => {
    const tierChanged = editableCompany.supplierProfile.tier !== newSupplierData.supplierProfile.tier;
    if (tierChanged) {
      if (editableCompany.supplierProfile.tier === "Tier 1") {
        dispatch(
          setTierOneSuppliers((prevTierOneSuppliers) => [
            ...prevTierOneSuppliers.filter(
              (supplier) => supplier.id !== newSupplierData.id
            ),
            newSupplierData,
          ])
        );
      } else if (editableCompany.supplierProfile.tier === "Tier 2") {
        dispatch(
          setTierTwoSuppliers((prevTierTwoSuppliers) => [
            ...prevTierTwoSuppliers.filter(
              (supplier) => supplier.id !== newSupplierData.id
            ),
            newSupplierData,
          ])
        );
      }
    } else {
      if (editableCompany.supplierProfile.tier === "Tier 1") {
        dispatch(
          updateSupplierTierOneById(editableCompany.id, newSupplierData)
        );
      } else if (editableCompany.supplierProfile.tier === "Tier 2") {
        dispatch(
          updateSupplierTierTwoById(editableCompany.id, newSupplierData)
        );
      }
    }
    dispatch(updateSupplierById(editableCompany.id, newSupplierData));
    setNewSupplierClicked(false);
    setEditPopup(true);
  };

  const handleCancel = () => {
    setNewSupplierClicked(false);
  };
  const handleFileChange = (files) => {
    console.log("files", files);
  };
  const handlePreview = (file) => {
    setShowPreviewPopup(true);
  };

  const closePreview = () => {
    setShowPreviewPopup(false);
  };

  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleDelete = (fileName) => {
    setImportedFiles((prevImportedFiles) =>
      prevImportedFiles.filter((file) => file.name !== fileName)
    );
  };

  const sizeOptions = [
    "",
    "Micro (1-10 employees)",
    "Small (11-50 employees)",
    "Medium (51-250 employees)",
    "Large (251-500 employees)",
    "Enterprise (501-1000 employees)",
    "Corporate (1001-5000 employees)",
    "Global (5001+ employees)",
  ];

  const industryOptions = [
    "",
    "Technology",
    "Healthcare",
    "Finance",
    "Education",
    "Retail",
    "Manufacturing",
    "Non-profit",
    "Energy",
    "Transportation",
    "Real Estate",
    "Agriculture",
    "Hospitality",
    "Entertainment",
    "Telecommunications",
    "Government",
    "Consulting",
    "Consumer Goods",
    "Environmental Services",
    "Marketing/Advertising",
    "Legal",
    "Other",
  ];
  const companyLocations = [
    "Alabama",
    "Alaska",
    "American Samoa",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District Of Columbia",
    "Florida",
    "Georgia",
    "Guam",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Marshall Islands",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Northern Marianas",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Palau",
    "Pennsylvania",
    "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const countries = [
    "United States",
    "Canada",
    "United Kingdom",
    "Australia",
    "Germany",
    "France",
    "Japan",
    "China",
    "India",
    "Brazil",
    "Mexico",
    "Italy",
    "Spain",
    "South Korea",
    "Russia",
    "Indonesia",
    "Netherlands",
    "Switzerland",
    "Sweden",
    "Belgium",
  ];
  const tiers = ["Tier 1", "Tier 2"];

  const handleToggleCountry = () => {
    setCountryDropdownOpen(!isCountryDropdownOpen);
  };
  const handleToggleState = () => {
    setStateDropdownOpen(!isStateDropdownOpen);
  };

  const handleSelectCountryOption = (value) => {
    setNewSupplierData({
      ...newSupplierData,
      address: {
        ...newSupplierData.address,
        country: value,
      },
    });
    setCountryDropdownOpen(false);
  };

  const handleSelectStateOption = (value) => {
    setNewSupplierData({
      ...newSupplierData,
      address: {
        ...newSupplierData.address,
        state: value,
      },
    });
    setCountryDropdownOpen(false);
  };

  const handleToggleSizeDropdown = () => {
    setSizeDropdownOpen(!isSizeDropdownOpen);
  };

  const handleToggleIndustryDropdown = () => {
    setIndustryDropdownOpen(!isIndustryDropdownOpen);
  };

  const handleSelectSizeOption = (value) => {
    setNewSupplierData((prevData) => ({
      ...prevData,
      company: {
        ...prevData.company,
        size: value,
      },
    }));
    setSizeDropdownOpen(false);
  };

  const handleSelectIndustryOption = (value) => {
    setNewSupplierData((prevData) => ({
      ...prevData,
      company: {
        ...prevData.company,
        industry: value,
      },
    }));
    setIndustryDropdownOpen(false);
  };

  const handleSelectTierOption = (value) => {
    setNewSupplierData((prevData) => ({
      ...prevData,
      supplierProfile: {
        ...prevData.supplierProfile,
        tier: value,
      },
    }));
    setTierDropdownOpen(false);
  };

  const handleToggleTier = () => {
    setTierDropdownOpen(!isTierDropdownOpen);
  };

  const handleClickOutside = (event) => {
    if (
      (sizeDropdownRef.current &&
        !sizeDropdownRef.current.contains(event.target)) ||
      (industryDropdownRef.current &&
        !industryDropdownRef.current.contains(event.target))
    ) {
      setSizeDropdownOpen(false);
      setIndustryDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const handleCreateManually = () => {
    setManuallyCreated(true);
    handleNextStep();
  };

  useEffect(() => {
    if (!isManuallyCreated && autoFilledData.length !== 0) {
      const newDataArray = autoFilledData.map((data) => ({
        id: uuidv4(),
        firstName: "",
        lastName: "",
        email: "",
        address: {
          country: "",
          state: "",
          city: "",
        },
        company: {
          name: data.companyname,
          size: "",
          industry: data.companytype,
          companyProfilePicture: null,
          registrationStatus: "",
          yearFounded: "",
          companyWebsite: data.url,
          annualRevenue: "",
        },
        password: "",
        confirmPassword: "",
        supplierProfile: {
          name: "",
          profilePicture: null,
          email: "",
          primaryRole: "",
          rating: 0,
          bio: "",
          yearFounded: 0,
          linkedInUrl: "",
          diversity: {
            gender: "",
            pronouns: "",
            race: "",
            diversityStatus: data.ownership,
          },
          certificates: [data.certification],
          reviews: [],
          businessLicenses: "",
          services: [
            {
              serviceImage: null,
              title: "",
              description: "",
              tag: "",
            },
            {
              serviceImage: null,
              title: "",
              description: "",
              tag: "",
            },
            {
              serviceImage: null,
              title: "",
              description: "",
              tag: "",
            },
            {
              serviceImage: null,
              title: "",
              description: "",
              tag: "",
            },
            {
              serviceImage: null,
              title: "",
              description: "",
              tag: "",
            },
          ],
          skills: {
            technical: [],
            industry: [],
            company: [],
          },
          tier: "",
          mainContacts: [
            {
              id: uuidv4(),
              name: "",
              primaryrole: "",
              email: "",
            },
          ],
          otherContacts: [
            {
              id: uuidv4(),
              name: "",
              primaryrole: "",
              email: "",
            },
          ],
        },
      }));
  
      const flattenedPendingSuppliers = pendingSuppliers.flat();
      dispatch(setPendingSuppliers([...flattenedPendingSuppliers, ...newDataArray]));
    }
  }, [autoFilledData, isManuallyCreated]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    if (currentStep === 3) {
      setSupplierCreated(true);
      setNewSupplierClicked(false);
      console.log("NEW SUPPLIER DATA", newSupplierData);
  
      const newSupplierDataArray = Array.isArray(newSupplierData) ? newSupplierData : [newSupplierData];
  
  
      const flattenedPendingSuppliers = pendingSuppliers.flat();
      dispatch(setPendingSuppliers([...flattenedPendingSuppliers, ...newSupplierDataArray]));
  
      if (newSupplierData.supplierProfile.tier === "Tier 1") {
        dispatch(setTierOneSuppliers([...tierOneSuppliers, newSupplierData]));
      } else if (newSupplierData.supplierProfile.tier === "Tier 2") {
        dispatch(setTierTwoSuppliers([...tierTwoSuppliers, newSupplierData]));
      }
    }
    handleNextStep();
  };
  


  

  return (
    <Container fluid>
      <div className="pdf-import-container">
        <>
          <Overlay />
          <div className="popup-import-container">
            <div
              className="popup-import-content"
              style={{ position: "relative", padding: 0, height: "790px" }}
            >
              <p
                style={{
                  marginTop: "53px",
                  display: "flex",
                  justifyContent: "center",
                  color: "#1D2739",
                  fontSize: "24px",
                  fontWeight: 600,
                  marginBottom: "35px",
                }}
              >
                {isEditableSupplier ? "Edit supplier" : "Create a new supplier"}
              </p>
              {currentStep === 1 && (
                <div style={{ padding: "10px 40px" }}>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "#1D2739",
                      fontSize: "16px",
                      fontWeight: 500,
                    }}
                  >
                    Upload Supplier CSV file
                  </p>
                  <DragAndDropComponent
                    onFileChange={handleFileChange}
                    onPreview={handlePreview}
                    onDelete={handleDelete}
                    importedFiles={importedFiles}
                    setImportedFiles={setImportedFiles}
                    setPdfFile={setPdfFile}
                    setAutoFilledData={setAutoFilledData}
                    subtitle="CSV"
                  />
                  {importedFiles.length === 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "20px",
                          marginTop: "20px",
                        }}
                      >
                        <hr
                          style={{
                            flex: "1",
                            borderTop: "1px solid #8F8F8F",
                            margin: "0 10px",
                          }}
                        />
                        <p style={{ color: "#8F8F8F", margin: "0" }}>OR</p>
                        <hr
                          style={{
                            flex: "1",
                            borderTop: "1px solid #8F8F8F",
                            margin: "0 10px",
                          }}
                        />
                      </div>
                      <p
                        onClick={handleCreateManually}
                        style={{
                          color: "#0E8080",
                          fontWeight: 600,
                        }}
                      >
                        Create manually
                      </p>
                    </div>
                  ) : (
                    <Form.Group
                      as={Col}
                      controlId="companyState"
                      className="single-input"
                      style={{ margin: "40px 0 0 0" }}
                    >
                      <Form.Label className="edit-profile-input-title">
                        Tier
                      </Form.Label>
                      <div
                        ref={tierDropdownRef}
                        className={`custom-edit-profile-input custom-dropdown-container ${
                          isTierDropdownOpen ? "open" : ""
                        }
      `}
                      >
                        <div
                          className="custom-dropdown-edit-profile"
                          onClick={handleToggleTier}
                        >
                          <span className="selected-option">
                            {newImportedSupplierTier || "Select Tier"}
                          </span>
                          <ul
                            className={`custom-dropdown-list ${
                              isTierDropdownOpen ? "show" : ""
                            }`}
                          >
                            <li
                              className={`custom-dropdown-item ${
                                newSupplierData.tier ? "" : "disabled"
                              }`}
                              onClick={() => setnewImportedSupplierTier("")}
                            ></li>
                            {tiers.map((tier, index) => (
                              <li
                                key={index}
                                className="custom-dropdown-item"
                                onMouseDown={(e) => {
                                  e.preventDefault();
                                  setnewImportedSupplierTier(tier);
                                }}
                              >
                                {tier}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </Form.Group>
                  )}
                </div>
              )}

              {currentStep === 2 && (
                <Form onSubmit={handleSubmit}>
                  <Form.Group
                    as={Col}
                    controlId="firstName"
                    className="single-input"
                  >
                    <Form.Label className="edit-profile-input-title">
                      Supplier Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      required
                      className="custom-edit-profile-input"
                      value={newSupplierData.supplierProfile.name}
                      onChange={(e) => {
                        setNewSupplierData((prevData) => ({
                          ...prevData,
                          supplierProfile: {
                            ...prevData.supplierProfile,
                            name: e.target.value,
                          },
                        }));
                      }}
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    controlId="companyEmail"
                    className="single-input"
                    s
                  >
                    <Form.Label className="edit-profile-input-title">
                      Email
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter your company email"
                      required
                      className="custom-edit-profile-input"
                      value={newSupplierData.supplierProfile.email}
                      onChange={(e) => {
                        setNewSupplierData((prevData) => ({
                          ...prevData,
                          supplierProfile: {
                            ...prevData.supplierProfile,
                            email: e.target.value,
                          },
                        }));
                      }}
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    controlId="firstName"
                    className="single-input"
                  >
                    <Form.Label className="edit-profile-input-title">
                      Company Name
                      <span className="question-mark-container">
                        <Image
                          src={questionMark}
                          alt=""
                          className="question-mark-icon"
                          onClick={() => setShowMessage(!showMessage)}
                        />
                        {showMessage && (
                          <div className="info-message">
                            If you’re representing a company, providing the
                            company name is preferred.
                          </div>
                        )}
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter company name"
                      required
                      className="custom-edit-profile-input"
                      value={newSupplierData.company.name}
                      onChange={(e) => {
                        setNewSupplierData((prevData) => ({
                          ...prevData,
                          company: {
                            ...prevData.company,
                            name: e.target.value,
                          },
                        }));
                        // setFirstNameError(false);
                        // setSubmitted(false);
                      }}
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    controlId="companySize"
                    className="single-input"
                  >
                    <Form.Label className="edit-profile-input-title">
                      Company Size
                    </Form.Label>
                    <div
                      ref={sizeDropdownRef}
                      className={`custom-edit-profile-input custom-dropdown-container ${
                        isSizeDropdownOpen ? "open" : ""
                      }
                        `}
                    >
                      <div
                        className="custom-dropdown-edit-profile"
                        onClick={handleToggleSizeDropdown}
                      >
                        <span className="selected-option">
                          {newSupplierData.company.size || "50-100"}
                        </span>
                        <ul
                          className={`custom-dropdown-list ${
                            isSizeDropdownOpen ? "show" : ""
                          }`}
                        >
                          {sizeOptions.map((option, index) => (
                            <li
                              key={option}
                              className={`custom-dropdown-item ${
                                index === 0 ? "disabled" : ""
                              }`}
                              onMouseDown={(e) => {
                                e.preventDefault();
                                handleSelectSizeOption(option);
                              }}
                            >
                              {option}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Form.Group>

                  <Form.Group
                    controlId="companyIndustry"
                    className="single-input"
                  >
                    <Form.Label className="edit-profile-input-title">
                      Industry
                    </Form.Label>
                    <div
                      ref={industryDropdownRef}
                      className={`custom-edit-profile-input custom-dropdown-container ${
                        isIndustryDropdownOpen ? "open" : ""
                      }
                      `}
                    >
                      <div
                        className="custom-dropdown-edit-profile"
                        onClick={handleToggleIndustryDropdown}
                      >
                        <span className="selected-option">
                          {newSupplierData.company.industry ||
                            "Select Industry"}
                        </span>
                        <ul
                          className={`custom-dropdown-list ${
                            isIndustryDropdownOpen ? "show" : ""
                          }`}
                        >
                          {industryOptions.map((option, index) => (
                            <li
                              key={option}
                              className={`custom-dropdown-item ${
                                index === 0 ? "disabled" : ""
                              }`}
                              onMouseDown={(e) => {
                                e.preventDefault();
                                handleSelectIndustryOption(option);
                              }}
                            >
                              {option}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Form.Group>
                </Form>
              )}
              {currentStep === 3 && (
                <Form onSubmit={handleSubmit}>
                  <Form.Group
                    as={Col}
                    controlId="companyState"
                    className="single-input"
                  >
                    <Form.Label className="edit-profile-input-title">
                      Tier
                    </Form.Label>
                    <div
                      ref={tierDropdownRef}
                      className={`custom-edit-profile-input custom-dropdown-container ${
                        isTierDropdownOpen ? "open" : ""
                      }
        `}
                    >
                      <div
                        className="custom-dropdown-edit-profile"
                        onClick={handleToggleTier}
                      >
                        <span className="selected-option">
                          {newSupplierData.supplierProfile.tier ||
                            "Select Tier"}
                        </span>
                        <ul
                          className={`custom-dropdown-list ${
                            isTierDropdownOpen ? "show" : ""
                          }`}
                        >
                          <li
                            className={`custom-dropdown-item ${
                              newSupplierData.tier ? "" : "disabled"
                            }`}
                            onClick={() => handleSelectTierOption("")}
                          >
                            Tier
                          </li>
                          {tiers.map((tier, index) => (
                            <li
                              key={index}
                              className="custom-dropdown-item"
                              onMouseDown={(e) => {
                                e.preventDefault();
                                handleSelectTierOption(tier);
                              }}
                            >
                              {tier}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    controlId="companyCountry"
                    className="single-input"
                  >
                    <Form.Label className="edit-profile-input-title">
                      Country
                    </Form.Label>
                    <div
                      ref={countryDropdownRef}
                      className={`custom-edit-profile-input custom-dropdown-container ${
                        isCountryDropdownOpen ? "open" : ""
                      }
           `}
                    >
                      <div
                        className="custom-dropdown-edit-profile"
                        onClick={handleToggleCountry}
                      >
                        <span className="selected-option">
                          {newSupplierData.address.country || "Select Location"}
                        </span>
                        <ul
                          className={`custom-dropdown-list ${
                            isCountryDropdownOpen ? "show" : ""
                          }`}
                        >
                          <li
                            className={`custom-dropdown-item ${
                              newSupplierData.address.country ? "" : "disabled"
                            }`}
                            onClick={() => handleSelectCountryOption("")}
                          >
                            Select Location
                          </li>
                          {countries.map((country, index) => (
                            <li
                              key={index}
                              className="custom-dropdown-item"
                              onMouseDown={(e) => {
                                e.preventDefault();
                                handleSelectCountryOption(country);
                              }}
                            >
                              {country}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    controlId="companyState"
                    className="single-input"
                  >
                    <Form.Label className="edit-profile-input-title">
                      State
                    </Form.Label>
                    <div
                      ref={stateDropdownRef}
                      className={`custom-edit-profile-input custom-dropdown-container ${
                        isStateDropdownOpen ? "open" : ""
                      }
        `}
                    >
                      <div
                        className="custom-dropdown-edit-profile"
                        onClick={handleToggleState}
                      >
                        <span className="selected-option">
                          {newSupplierData.address.state || "Select Location"}
                        </span>
                        <ul
                          className={`custom-dropdown-list ${
                            isStateDropdownOpen ? "show" : ""
                          }`}
                        >
                          <li
                            className={`custom-dropdown-item ${
                              newSupplierData.address.state ? "" : "disabled"
                            }`}
                            onClick={() => handleSelectStateOption("")}
                          >
                            Select State
                          </li>
                          {companyLocations.map((location, index) => (
                            <li
                              key={index}
                              className="custom-dropdown-item"
                              onMouseDown={(e) => {
                                e.preventDefault();
                                handleSelectStateOption(location);
                              }}
                            >
                              {location}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    controlId="cityLocation"
                    className="single-input"
                  >
                    <Form.Label className="edit-profile-input-title">
                      City
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="City"
                      required
                      className="custom-edit-profile-input"
                      value={newSupplierData.address.city}
                      onChange={(e) => {
                        setNewSupplierData({
                          ...newSupplierData,
                          address: {
                            ...newSupplierData.address,
                            city: e.target.value,
                          },
                        });
                      }}
                    />
                  </Form.Group>
                </Form>
              )}
              <div>
                <button
                  className="custom-continue-button"
                  onClick={() => {
                    if (currentStep === 1 && importedFiles.length > 0) {
                      handleCreateByFile();
                    } else if (currentStep === 3 && isEditableSupplier) {
                      handleEditConfirmation();
                    } else {
                      handleSubmit();
                    }
                  }}
                  style={{
                    position: "absolute",
                    bottom: "40px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    background:
                      "linear-gradient(270deg, #CC9866 0%, #722592 102.7%)",
                    borderRadius: "8px",
                    color: "#fff",
                  }}
                >
                  {currentStep === 1
                    ? "Proceed"
                    : currentStep === 3 && isEditableSupplier
                    ? "Save"
                    : currentStep !== 1 && currentStep !== 3
                    ? "Continue"
                    : "Create"}
                </button>
                <span
                  onClick={() => handleCancel()}
                  style={{
                    color: "#7E7E7E",
                    display: "flex",
                    justifyContent: "center",
                    position: "absolute",
                    bottom: 0,
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                >
                  Cancel
                </span>
              </div>
            </div>
          </div>
        </>
        {isProceed && <MappingTable suppliers={autoFilledData} />}
        {showPreviewPopup && (
          <>
            <Overlay />
            <PreviewPopup file={pdfFile} onClose={closePreview} />
          </>
        )}
      </div>
    </Container>
  );
};

export default CreateNewSupplierForm;
