import React, { useEffect, useState } from "react";
import Navbar from "../components/LandingPage/Navbar";
import Footer from "../components/LandingPage/Footer";
import "./Pages.css";
import background from "../assets/LandingPage/Background (1).png";
import { Image } from "react-bootstrap";

const Policy = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Navbar isMobile={isMobile} outsideOfLandingPage={true} />

      <div className="terms-main-container">
        <div className="terms-container">
          <h1 className="terms-of-use">Privacy Policy</h1>
        </div>

        <div
          className="content-container-terms"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Image src={background} className="background-image-terms" fluid />
          <div className="text-content-terms">
            <span className="category">Effective date: 02/28/2024</span>
            {/* <span className="category">1. Introduction</span> */}
            <span className="content">
              This Privacy Policy describes how we process and handle data
              collected or otherwise maintained by TalkDEI, Inc. (“TalkDEI”,
              “we”, “us”, or “our”), including in connection with your use of
              our products, services, apps, and websites that link to this
              policy (we refer to these collectively as our “Services”).
            </span>
            <span className="content">
              TalkDEI is committed to protecting and processing your personal
              information responsibly. We value the data you share with us and
              treat it with respect. Our Services are designed to help our
              customers and partners in a wide variety of ways, including which
              suppliers fulfill specific needs as they relate to diversity,
              equity, and inclusion.
            </span>

            <span className="category">Information We Collect</span>
            <span className="content">
              • Personal Information. We collect the information you volunteer
              to us. When you subscribe to our newsletter, comment on an
              article, send us an email, or share personal information with us
              in any other way, we will collect the information you provide.
              This information may include your name, email address or other
              contact information (“Personal Information”).
            </span>
            <span className="content">
              • Usage. We will record information about your use of our
              Services, such as what Content you post or respond to and other
              information about your interaction with the tools and content we
              offer.
            </span>
            <span className="content">
              interaction with the tools and content we offer. • Subscribing to
              Newsletters. You may subscribe to our recurring email newsletter
              by providing your email address. You can unsubscribe at any time
              by following the directions in the newsletter.
            </span>
            <span className="content">
              • Content. We may offer a user the ability to post content
              (“Content”). TalkDEI may use your Content as described in the
              Terms of Service. Any Content you post on our Site is by design
              open to the public and is not private. This includes any comments
              you post and any Personal Information included in connection with
              those comments. Your Content can be seen, collected and disclosed
              by third parties, including third parties who may contact you for
              unauthorized purposes. Please think carefully before posting any
              Personal Information or any information you wish to keep private.
            </span>
            <span className="content">
              • Automatic Collection. Like most web businesses, we may use
              cookies, pixels, web beacons and other automated mechanisms to
              keep track of user interactions with our Site and other Services
              and with any ads we place on third party properties. We may also
              use third party services such as Google Analytics to gather and
              analyze user information. We may collect information such as your
              computer’s IP address, the type of browser you are using, the
              websites you visit and the type of content and ads you click on.
              We refer to all of the data gathered for our analysis as
              “Analytics Data.” Analytics Data does not include personal
              information such as your name, email address, physical address or
              phone number. We use Analytics Data to help us evaluate and
              improve the Site and other Services. Google gives users the
              ability to opt out of its data gathering. If you are interested in
              finding out more, please see the information provided at
              https://support.google.com/analytics/answer/181881?hl=en and
              https://tools.google.com/dlpage/gaoptout. Another way to prevent
              the collection of Analytics Data is to disable the use of cookies
              in your browser but the Site and Services may not function as well
              if you do that.
            </span>
            <span className="content">
              • If you use our Apps or otherwise access our Services through a
              mobile device, we may collect information such as your mobile
              device’s UDID and/or other persistent identifiers and different
              types of information about your location, including specific
              location information (e.g., GPS data), and your interaction with
              the App and our content (e.g., events). If you do not want your
              device to provide us with location-tracking information, you can
              disable the GPS or other location-tracking functions on your
              device, if your device gives you that option.
            </span>

            <span className="category">
              How We Use the Information We Collect and Receive
            </span>
            <span className="content">We may use your information to:</span>
            <span className="content">• Provide you with Services.</span>
            <span className="content">
              • Operate, maintain, protect, develop and improve the Services.
            </span>
            <span className="content">• Conduct and publish research.</span>
            <span className="content">• Contact and communicate with you.</span>
            <span className="content">
              • Provide you with additional information we think may be of
              interest to you.
            </span>
            <span className="content">
              • Customize the content and any advertising you see
            </span>

            <span className="category">Sharing Information</span>
            <span className="content">
              • Personal Information and Usage. In limited circumstances, we may
              give third parties your Personal Information and/or usage
              information. For example:
            </span>
            <span className="content">
              • We may disclose this information as part of due diligence or in
              connection with a sale, merger, consolidation, change in control,
              transfer of substantial assets, reorganization or liquidation.
            </span>
            <span className="content">
              • We may transfer this information to third parties that are
              helping us provide our Services, such as hosting companies,
              payment processors, subcontractors, and our affiliates,
              consultants and representatives.
            </span>
            <span className="content">
              • We may disclose this information if permitted or required by
              law, or where we believe such action is necessary in order to
              protect or defend TalkDEI or the interests of our users or
              business partners
            </span>
            <span className="content">
              • Content and Automated Information. We may freely share any
              Content and any information that is collected by automated means
              with third parties such as clients, advertisers, sponsors and
              business partners, for our benefit and/or for their own use.
            </span>
            <span className="content">
              • Related Companies. We may share your information with our
              related companies. Those companies will only use your information
              in the same manner that we are allowed to use it.
            </span>
            <span className="content">
              • Network Advertisers. We may share information relating to your
              usage of the Site and Services collected on an aggregated basis
              with network advertisers. Such network advertisers are third
              parties that serve advertisements on the Site for products and
              services that you may be interested in. These advertisers, as well
              as third party ad network providers, sponsors and traffic
              measurement services may use technologies such as cookies, web
              beacons or JavaScript to analyze the effectiveness of their ad
              targeting. Such third parties may also gather user data
              themselves, separate from any data that we provide to them. Third
              party gathering and use of data is subject to their own privacy
              practices and privacy policies, which may be different than ours
            </span>
            <span className="content">
              • Opting Out of Network Advertising. You may opt out of many
              third-party ad networks. Please visit
              http://www.networkadvertising.org/optout_nonppii.asp for
              information on opting out of third-party ad networks operated by
              Network Advertising Initiative (“NAI”) members, the Digital
              Advertising Alliance at http://www.aboutads.info/choices/ for
              information on opting out of third-party ad networks operated by
              About Ads members, and the IAB opt-out platform at
              http://youronlinechoices.eu for an on-line guide to pan-European
              ad networks. Please make sure that cookies are enabled when you
              opt-out from any third-party ad networks; if your browser is not
              set to accept cookies or you later erase your cookies, your
              opt-out may no longer be effective. Please note that if you
              disable the display of personalized ads from advertising partners,
              you will continue to receive ads that are less relevant to your
              interests/browsing behavior. Finally, be aware that even if you
              opt out of third-party ad networks operated by these members, you
              may continue to receive targeted advertising on our Site and other
              websites.
            </span>
            <span className="category">Do Not Track</span>
            <span className="content">
              At this time, TalkDEI does not respond to Do Not Track signals
              from Web browsers.
            </span>
            <span className="category">Children Under 18</span>
            <span className="content">
              Our Services are not intended for children under the age of 18. If
              you are under the age of 18, please do not use our Services or
              provide your contact information or any other personally
              identifiable information to TalkDEI. Our Services may include
              unmonitored content posted by users or sourced from other third
              parties and such content may be unsuitable for children. We do not
              knowingly collect or distribute information from or about children
              under 13 and will delete any such information if we become aware
              of it. If you are a parent or guardian and you are aware that your
              child has provided us with personal information, please contact
              nina.legal@talkdei.io to have your child’s personal information
              removed.
            </span>

            <span className="category">Security</span>
            <span className="content">
              Any information collected by TalkDEI is stored using security
              procedures and practices that we believe are appropriate to the
              nature of the information. However, no online storage or data
              transmission over the Internet can be 100% secure. As a result,
              TalkDEI cannot guarantee the security of any information you
              transmit using our Services.
            </span>

            <span className="category">Foreign Countries</span>
            <span className="content">
              Our Services are provided from facilities based in the United
              States and may also be provided from servers located outside the
              United States. We may store and process your information both in
              and out of the United States. Your use of our Services constitutes
              your consent to and understanding of processing both in and out of
              the United States. For example, if you use our Services from
              outside the United States, your Personal Information will be
              transferred to and maintained on servers or databases located
              outside your country. The privacy and data protection laws and
              rules of the United States may vary from those in the country
              where you live.
            </span>
            <span className="category">
              Accessing or Rectifying Your Information
            </span>
            <span className="content">
              You have the right to obtain from us free information about your
              personal data stored at any time and a copy of this information.
              If the information we hold about you is inaccurate or not
              complete, you have the right to ask us to rectify it. If that data
              has been passed to a third party with your consent or for legal
              reasons, then we must also ask them to rectify the data.
            </span>
            <span className="category">Erasing Your Information</span>
            <span className="content">
              We shall process and store your personal data only for the period
              necessary to achieve the purpose of storage, or as far as this is
              granted by applicable laws or regulations to which we are subject
              to. You have the right to request the erasure of your information.
              This is sometimes called ‘the right to be forgotten’. You have the
              right to the erasure of personal data concerning you without undue
              delay, and we shall have the obligation to erase personal data
              without undue delay when:
            </span>
            <span className="content">
              · The original purpose for the personal data is no longer
              necessary or the data itself is no longer necessary for the
              purpose
            </span>
            <span className="content">· When you no longer consent.</span>
            <span className="content">
              · If you object to your data being processed and there is no
              legitimate reason to override this objection.
            </span>
            <span className="content">
              · If the data being processed relates to the offer of information
              society services to a child.
            </span>
            <span className="content">
              · If the data is ever processed in breach of data protection
              regulations (i.e. GDPR).
            </span>
            <span className="content">
              · There is a legal obligation to erase the data.
            </span>
            <span className="content">
              If one of the aforementioned reasons applies, and you wish to
              request the erasure of personal data stored by us, you may at any
              time contact our Data Protection Officer or the controller who
              shall promptly ensure that the erasure request is complied with
              immediately.
            </span>
            <span className="content">
              Where the controller has made personal data public and is obliged
              to erase the personal data, the controller, taking account of
              available technology and the cost of implementation, shall take
              reasonable steps, including technical measures, to inform other
              controllers processing the personal data that you have requested
              erasure by such controllers of any links to, or copy or
              replication of, those personal data, as far as processing is not
              required.
            </span>

            <span className="category">
              Object, Restrict, or Withdraw Consent
            </span>
            <span className="content">
              You have the right to ask us to restrict how we process your data.
              This means we are permitted to store the data but not further
              process it. We keep just enough data to make sure we respect your
              request in the future.
            </span>
            <span className="content">
              It is your right to lodge an objection to the processing of your
              personal data on grounds relating to your particular situation, at
              any time, to the processing of personal data concerning you. This
              also applies to profiling.
            </span>
            <span className="content">
              We shall no longer process the personal data in the event of the
              objection unless we can demonstrate compelling legitimate grounds
              for the processing which override your interests, rights, and
              freedoms, or for the establishment, exercise or defense of legal
              claims.
            </span>
            <span className="content">
              If we process personal data for direct marketing purposes, you
              shall have the right to object at any time to the processing of
              personal data concerning you for such marketing. This applies to
              profiling to the extent that it is related to such direct
              marketing. If you object to us to the processing for direct
              marketing purposes, we will no longer process the personal data
              for these purposes.
            </span>
            <span className="content">
              In addition, you have the right, on grounds relating to your
              particular situation, to object to the processing of personal data
              concerning you by us for scientific or historical research
              purposes, or for statistical purposes, unless the processing is
              necessary for the performance of a task carried out for reasons of
              public interest.
            </span>
            <span className="content">
              If you have given us your consent to process your data but change
              your mind later, you have the right to withdraw your consent at
              any time, and we must stop processing your data.
            </span>

            <span className="category">Data Portability</span>
            <span className="content">
              We must allow you to obtain and reuse your personal data for your
              own purposes across services in a safe and secure way without this
              effecting the usability of your data. In exercising your right to
              data portability, you shall have the right to have personal data
              transmitted directly from one controller to another, where
              technically feasible and when doing so does not adversely affect
              the rights and freedoms of others.
            </span>

            <span className="category">
              Automated Individual Decision-Making
            </span>
            <span className="content">
              You shall have the right not to be subject to a decision based
              solely on automated processing, including profiling, which
              produces legal effects concerning you, or similarly significantly
              affects you, as long as the decision (1) is not necessary for
              entering into, or the performance of, a contract between you and a
              data controller, or (2) is not authorized by law to which the
              controller is subject and which also lays down suitable measures
              to safeguard your rights and freedoms and legitimate interests, or
              (3) is not based on your explicit consent.
            </span>
            <span className="content">
              If the decision (1) is necessary for entering into, or the
              performance of, a contract between you and a data controller, or
              (2) it is based on your explicit consent, we shall implement
              suitable measures to safeguard your rights and freedoms and
              legitimate interests, at least the right to obtain human
              intervention on the part of the controller, to express your point
              of view and contest the decision.
            </span>
            <span className="category">Privacy</span>
            <span className="content">
              California We have prepared additional disclosures and notices for
              California consumers. Those additional disclosures can be found
              here.
            </span>
            <p>https://newarena.com/new-arena-ccpa-policy/</p>
            <span className="content">
              Nevada We do not sell your Covered Information (as defined under
              Nevada law).
            </span>
            <span className="content">
              Notice of Privacy Practices for Other U.S. States
            </span>
            <span className="content">
              For disclosures required by the Colorado Privacy Act (CPA)
              regarding the categories of personal information, processing
              purposes, and information regarding personal information that we
              collect, please see the Information We Collect section of the
              TalkDEI CCPA Policy, which can be found 
              <span style={{ color: "black", fontWeight:"700",textDecoration:"underline", cursor: "pointer", marginLeft:"5px"}} onClick={() => window.open("/ccpa-policy", "_blank")}>here</span>.

            </span>
            <p></p>
            <span className="content">
              Please note that the term “share” is defined differently in the
              CCPA and the CPA; we do not share personal information with third
              parties, as those terms are used in the CPA.
            </span>

            <span className="content">
              Applicable state law (such as the Virginia Consumer Data
              Protection Act, Colorado Privacy Act, and the Connecticut Data
              Privacy Act) may give you certain rights. The law of your
              jurisdiction may permit you to request that we:
            </span>
            <span className="content">
              · Confirm whether or not we are processing your personal
              information and provide you with access to such personal
              information
            </span>
            <span className="content">
              · Correct inaccuracies in your personal information, taking into
              account the nature of the personal information and the purposes of
              the processing of the personal information;
            </span>
            <span className="content">· Delete your personal information;</span>
            <span className="content">
              · Provide you a copy of personal information that you previously
              provided to us in a portable and, to the extent technically
              feasible, readily usable format that allows you to transmit the
              data to another business, where our processing is carried out by
              automated means; and
            </span>
            <span className="content">
              · Opt you out of the processing of the personal information for
              purposes of targeted advertising, the sale of personal
              information, or profiling in furtherance of decisions that produce
              legal or similarly significant effects concerning you.
            </span>
            <span className="content">
              Certain information may be exempt from such requests under
              applicable law. We need certain types of information so that we
              can provide our Services. If you ask us to delete it, you may no
              longer be able to access or use the Services.
            </span>
            <span className="content">
              If you would like to exercise any of these rights, please submit a
              request at nina.legal@talkdei.io. You will be required to verify
              your identity before we fulfill your request. To do so, you will
              need to provide at a minimum your name and email address or as
              otherwise directed by us.
            </span>
            <span className="content">
              If we deny your request to exercise any of the rights above, you
              may appeal that denial by contacting us in the manner described
              herein and describing in detail why you believe the denial was in
              error. Your description must include your full name and email
              address, along with a copy of the denial notice you received from
              us.
            </span>

            <span className="category">Data Retention</span>
            <span className="content">
              We will only retain your information for as long as necessary to
              fulfill the purposes we collected it for, including for the
              purposes of satisfying any legal, accounting, or reporting
              requirements. To determine the appropriate retention period for
              your information, we consider the amount, nature, and sensitivity
              of the information, the potential risk of harm from unauthorized
              use or disclosure of your information, the purposes for which we
              process your information and whether we can achieve those purposes
              through other means, and the applicable legal requirements.
            </span>
            <span className="content">
              In some circumstances we may anonymize information about you (so
              that it can no longer be associated with you) for research or
              statistical purposes in which case we may use and retain this
              information indefinitely without further notice to you.
            </span>

            <span className="category">Third Party Sites and Services</span>
            <span className="content">
              We may provide links to third party websites, apps and other
              services. We are not responsible for those third parties and
              cannot control their use of any information that they collect from
              you. You should contact such third-party websites directly to
              determine their privacy policies.
            </span>

            <span className="category">Changes to Privacy Policy</span>
            <span className="content">
              We reserve the right to change this Privacy Policy at any time and
              will do so by posting the revised Privacy Policy on the Sites. The
              new Privacy Policy will apply to all current and past users of the
              Sites, Products, and Services and will replace any prior Privacy
              Policies that are inconsistent. Your continued use of the Sites
              constitutes your agreement to the Privacy Policy as revised.
            </span>

            <span className="category">Contact Us</span>
            <span className="content">
              If you would like to opt-out of receiving marketing communications
              from us, please follow the instructions in the messages or emails
              to unsubscribe.
            </span>
            <span className="content">
              If you have any questions about our privacy practices, please
              email us at nina.legal@talkdei.io.
            </span>
          </div>
        </div>
      </div>
      <div className="terms-footer">
        <Footer button={true} height={true} isMobile={isMobile} />
      </div>
    </div>
  );
};

export default Policy;
