
import {
  SET_TIER_ONE_SUPPLIERS,
  SET_TIER_TWO_SUPPLIERS,
  SET_PENDING_SUPPLIERS,
  SET_SUPPLIERS,
  UPDATE_SUPPLIER_BY_ID,
  UPDATE_SUPPLIER_TIER_ONE_BY_ID,
  UPDATE_SUPPLIER_TIER_TWO_BY_ID,
  UPDATE_COMPANY_BY_ID,
  UPDATE_SUPPLIER_PROFILE_PROPERTY,
  FETCH_ALL_SUPPLIERS,
  FETCH_SUPPLIER_BY_ID
} from "../constants/constants";
import { SupplierCompanyData } from "../DummyData";

const initialState = {
  suppliers:  SupplierCompanyData, // Initialize suppliers array
  tierOneSuppliers: [],
  tierTwoSuppliers: [],
  pendingSuppliers: [],
  loading: false,
  currentSupplier: null,  // Initialize currentSupplier as null
  currentSupplierID: JSON.parse(localStorage.getItem('currentSupplierID')) || "",
};

console.log("State suppliers", initialState.suppliers)

const suppliersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_SUPPLIERS:
      return {
        ...state,
        loading: false,
        suppliers: action.payload,
      };

    case FETCH_SUPPLIER_BY_ID:
      return {
        ...state,
        currentSupplier: action.payload,
      };

    case SET_SUPPLIERS:
      // const updatedSuppliers = [...state.suppliers, action.payload];
      const newSupplier = action.payload; // Assuming action.payload contains the new supplier data
      const updatedSuppliers = [...state.suppliers, newSupplier];

      localStorage.setItem('currentSupplierID', JSON.stringify(action.payload._id));
      return {
        ...state,
        suppliers: updatedSuppliers,
        currentSupplier: newSupplier,
        currentSupplierID: action.payload._id,
        // suppliers: updatedSuppliers,
        // currentSupplierID: action.payload._id,
      };

          case SET_TIER_ONE_SUPPLIERS:
      return {
        ...state,
        tierOneSuppliers: [...state.tierOneSuppliers, ...action.payload],
      };
    case SET_TIER_TWO_SUPPLIERS:
      return {
        ...state,
        tierTwoSuppliers: [...state.tierTwoSuppliers, ...action.payload],
      };
    case SET_PENDING_SUPPLIERS:
      return {
        ...state,
        pendingSuppliers: [...state.pendingSuppliers, action.payload]
      };

      case UPDATE_SUPPLIER_BY_ID: {
        const { supplierId, supplierProfile } = action.payload;
      
        // Update the suppliers list with the new supplierProfile
        const updatedSuppliersList = state.suppliers.map(supplier =>
          supplier._id === supplierId
            ? {
                ...supplier,
                supplierProfile: supplier.supplierProfile
                  ? { ...supplier.supplierProfile, ...supplierProfile }
                  : { ...supplierProfile },
              }
            : supplier
        );
      
        // Update currentSupplier if it matches the supplierId
        const updatedCurrentSupplier = state.currentSupplier && state.currentSupplier._id === supplierId
          ? {
              ...state.currentSupplier,
              supplierProfile: state.currentSupplier.supplierProfile
                ? { ...state.currentSupplier.supplierProfile, ...supplierProfile }
                : { ...supplierProfile },
            }
          : state.currentSupplier;
      
        return {
          ...state,
          suppliers: updatedSuppliersList,
          currentSupplier: updatedCurrentSupplier,
        };
      }
      
    case UPDATE_SUPPLIER_PROFILE_PROPERTY:
      return {
        ...state,
        suppliers: state.suppliers.map(supplier =>
          supplier._id === action.payload.supplierId
            ? {
                ...supplier,
                supplierProfile: {
                  ...supplier.supplierProfile,
                  [action.payload.property]: action.payload.value,
                },
              }
            : supplier
        ),
      };

    case UPDATE_COMPANY_BY_ID:
      return {
        ...state,
        suppliers: state.suppliers.map(supplier =>
          supplier._id === action.payload.supplierId
            ? {
                ...supplier,
                company: action.payload.updatedCompany,
              }
            : supplier
        ),
        currentSupplier:
          state.currentSupplier && state.currentSupplier._id === action.payload.supplierId
            ? {
                ...state.currentSupplier,
                company: action.payload.updatedCompany,
              }
            : state.currentSupplier,
      };

    case 'UPDATE_SUPPLIER_TIER_ONE_BY_ID':
      return {
        ...state,
        tierOneSuppliers: state.tierOneSuppliers.map(supplier =>
          supplier.id === action.payload.id
            ? { ...supplier, ...action.payload.newData }
            : supplier
        ),
        suppliers: state.suppliers.map(supplier =>
          supplier.id === action.payload.id
            ? { ...supplier, ...action.payload.newData }
            : supplier
        ),
      };
    case 'UPDATE_SUPPLIER_TIER_TWO_BY_ID':
      return {
        ...state,
        tierTwoSuppliers: state.tierTwoSuppliers.map(supplier =>
          supplier.id === action.payload.id
            ? { ...supplier, ...action.payload.newData }
            : supplier
        ),
        suppliers: state.suppliers.map(supplier =>
          supplier.id === action.payload.id
            ? { ...supplier, ...action.payload.newData }
            : supplier
        ),
      };
    case 'EDIT_SUPPLIER_BY_ID':
      return {
        ...state,
        suppliers: state.suppliers.map(supplier =>
          supplier._id === action.payload.id
            ? { ...supplier, ...action.payload.supplierProfile}
            : supplier
        ),
      };

  //   case 'EDIT_SUPPLIER_BY_ID':
  // return {
  //   ...state,
  //   suppliers: state.suppliers.map(supplier =>
  //     supplier._id === action.payload.supplierId
  //       ? { ...supplier, ...action.payload.supplierProfile }
  //       : supplier
  //   ),
  // };

  // case 'EDIT_SUPPLIER_BY_ID':
  // return {
  //   ...state,
  //   suppliers: state.suppliers.map(supplier =>
  //     supplier._id === action.payload.supplierId
  //       ? { ...supplier, supplierProfile: action.payload.supplierProfile }
  //       : supplier
  //   ),
  // };
    case 'SET_TIER_ONE_SUPPLIERS':
      return {
        ...state,
        tierOneSuppliers: action.payload,
      };
    case 'SET_TIER_TWO_SUPPLIERS':
      return {
        ...state,
        tierTwoSuppliers: action.payload,
      };


      case 'REMOVE_SUPPLIER_FROM_TIER_ONE':
        return {
          ...state,
          tierOneSuppliers: state.tierOneSuppliers.filter(supplier => supplier.id !== action.payload.id)
        };
      case 'REMOVE_SUPPLIER_FROM_TIER_TWO':
        return {
          ...state,
          tierTwoSuppliers: state.tierTwoSuppliers.filter(supplier => supplier.id !== action.payload.id)
        };
      case 'ADD_SUPPLIER_TO_TIER_ONE':
        return {
          ...state,
          tierOneSuppliers: [...state.tierOneSuppliers, action.payload.newData]
        };
      case 'ADD_SUPPLIER_TO_TIER_TWO':
        return {
          ...state,
          tierTwoSuppliers: [...state.tierTwoSuppliers, action.payload.newData]
        };

    default:
      return state;
  }
};

export default suppliersReducer;

