import React, { useState, useRef, useEffect } from "react";
import { Image, Card, Form, Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "../../SellerProfile/SellerProfile.css";
import message from "../../../assets/message.svg";
import certificate from "../../../assets/certificate.svg";
import "../../Dashboard/Dashboard.css";
import DashboardNavbar from "../../Dashboard/DashboardNavbar";
import certifiedCheck from "../../../assets/certifiedCheck.svg";
import CompanyAvatar from "../../CompanyAvatar";
import DragAndDropComponent from "../../DragAndDrop/DragAndDropComponent.jsx";
import Overlay from "../../Overlay/Overlay.jsx";
import PreviewPopup from "../../FilePreviewPopup/PreviewPopup.jsx";
import {
  updateCompanyBySupplierId,
  updateSupplierProfileProperty,
  fetchSupplierById,
  updateSupplierByIdAsync,
  fetchAllSuppliers,
} from "../../../actions/suppliersActions";
import { useDispatch, useSelector } from "react-redux";
import arrowDownGrey from "../../../assets/arrowDowngrey.svg";
import greyCircle from "../../../assets/greyCircle.svg";
import emptyStarIcon from "../../../assets/emptyStar.svg";
import starIcon from "../../../assets/starIconBlack.svg";
import EditProfile from "./EditProfile.jsx";
import penIcon from "../../../assets/penIcon.svg";
import penIconWhite from "../../../assets/penIconWhite.svg";
import suppliersReducer from "../../../reducers/suppliersReducer.jsx";

const Profile = () => {
  const [selectedTab, setSelectedTab] = useState("about");
  const location = useLocation();
  const dispatch = useDispatch();
  const [isMouseOver, setMouseOver] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [addCertificate, setAddCertificate] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [importedCertificates, setImportedCertificates] = useState([]);
  const [showPreviewPopup, setShowPreviewPopup] = useState(false);
  const [isPermissionDropdownOpen, setPermissionDropdownOpen] = useState(false);
  const permissionDropdownRef = useRef();
  const [isEditPermissionDropdownOpen, setEditPermissionDropdownOpen] =
    useState(false);
  const [invitationEmail, setInvitationEmail] = useState("");
  const [currentPermission, setCurrentPermission] = useState("can view");
  const [menuVisibility, setMenuVisibility] = useState({});
  const [invitedMembers, setInvitedMembers] = useState([]);
  const [inviteButtonClicked, setInviteButtonClicked] = useState(false);
  const [isSizeDropdownOpen, setSizeDropdownOpen] = useState(false);
  const [isIndustryDropdownOpen, setIndustryDropdownOpen] = useState(false);
  const [isOwnershipDropdownOpen, setOwnershipDropdownOpen] = useState(false);
  const [showCertificates, setShowCertificates] = useState(true);
  const sizeDropdownRef = useRef();
  const industryDropdownRef = useRef();
  const ownershipDropdownRef = useRef();
  const [editCompanyInfo, setEditCompanyInfo] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isCompanyHovered, setCompanyIsHovered] = useState(false);

  const currentSupplierId = useSelector(
    (state) => state.suppliers.currentSupplierID
  );
  // const supplier = useSelector((state) => state.suppliers.suppliers.find(s => s._id === currentSupplierId));

  const supplier = useSelector((state) => state.suppliers.currentSupplier);
  // const supplier = useSelector((state) => state.suppliers.suppliers.find(s => s._id === currentSupplierId));




  const [formDataStep, setFormDataStep] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address: {
      country: "",
      state: "",
      city: "",
    },
    company: {
      name: "",
      size: "",
      industry: "",
      companyProfilePicture: "",
      companyWebsite: "",
      annualRevenue: "",
      registrationStatus: "",
      yearFounded: "",
    },
    supplierProfile: {
      name: "",
      profilePicture: "",
      email: "",
      primaryRole: "",
      rating: "",
      reviews: [],
      bio: "",
      yearFounded: "",
      linkedInUrl: "",
      diversity: {
        gender: "",
        pronouns: "",
        race: "",
        diversityStatus: "",
      },
      certificates: [],
      businessLicenses: [],
      services: [],
      skills: {
        technical: [],
        industry: [],
        company: [],
      },
      tier: "",
      mainContacts: [],
      otherContacts: [],
    },
  });

  const divFlexRow = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };
  const divFlexColumn = {
    display: "flex",
    flexDirection: "column",
  };
  const tabStyle = {
    padding: "10px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const activeTabStyle = {
    color: "#1D2739",
    borderRadius: "8px",
    background: "#F0F2F4",
    height: "43px",
    width: "102px",
  };

  const inactiveTabStyle = {
    color: "grey",
  };

  useEffect(() => {
    if (currentSupplierId) {
      dispatch(fetchSupplierById(currentSupplierId));
    }
  }, [currentSupplierId, dispatch]);

  
  
  const handleSaveProfileInfo = () => {
    setIsHovered(false);
    const supplierProfile = formDataStep.supplierProfile;
    const updatedCompany = formDataStep.company;
  
    if (currentSupplierId) {
      dispatch(updateSupplierByIdAsync({ supplierId: currentSupplierId, supplierProfile }));
      dispatch(updateCompanyBySupplierId(currentSupplierId, updatedCompany));
    }
  
    setEditProfile(false);
    // dispatch(fetchSupplierById(currentSupplierId));
  };

  useEffect(() => {
    if (supplier) {
      setFormDataStep({
        firstName: supplier.firstName || "",
        lastName: supplier.lastName || "",
        email: supplier.email || "",
        address: {
          country: supplier.address?.country || "",
          state: supplier.address?.state || "",
          city: supplier.address?.city || "",
        },
        company: {
          name: supplier.company?.name || "",
          size: supplier.company?.size || "",
          industry: supplier.company?.industry || "",
          companyProfilePicture: supplier.company?.companyProfilePicture || "",
          companyWebsite: supplier.company?.companyWebsite || "",
          annualRevenue: supplier.company?.annualRevenue || "",
          registrationStatus: supplier.company?.registrationStatus || "",
          yearFounded: supplier.company?.yearFounded || "",
        },
        supplierProfile: {
          name: supplier.supplierProfile?.name || "",
          profilePicture: supplier.supplierProfile?.profilePicture || null,
          email: supplier.supplierProfile?.email || "",
          primaryRole: supplier.supplierProfile?.primaryRole || "",
          rating: supplier.supplierProfile?.rating || "",
          reviews: supplier.supplierProfile?.reviews || [],
          bio: supplier.supplierProfile?.bio || "",
          yearFounded: supplier.supplierProfile?.yearFounded || "",
          linkedInUrl: supplier.supplierProfile?.linkedInUrl || "",
          diversity: {
            gender: supplier.supplierProfile?.diversity?.gender || "",
            pronouns: supplier.supplierProfile?.diversity?.pronouns || "",
            race: supplier.supplierProfile?.diversity?.race || "",
            diversityStatus:
              supplier.supplierProfile?.diversity?.diversityStatus || "",
          },
          certificates: supplier.supplierProfile?.certificates || [],
          businessLicenses: supplier.supplierProfile?.businessLicenses || [],
          services: supplier.supplierProfile?.services || [],
          skills: {
            technical: supplier.supplierProfile?.skills?.technical || [],
            industry: supplier.supplierProfile?.skills?.industry || [],
            company: supplier.supplierProfile?.skills?.company || [],
          },
          tier: supplier.supplierProfile?.tier || "",
          mainContacts: supplier.supplierProfile?.mainContacts || [],
          otherContacts: supplier.supplierProfile?.otherContacts || [],
        },
      });
    }
  }, [supplier]);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const handleCompanyMouseEnter = () => setCompanyIsHovered(true);
  const handleCompanyMouseLeave = () => setCompanyIsHovered(false);

  const handleEditCompanyInfo = () => {
    setAddCertificate(false);
    setEditCompanyInfo(true);
  };

  const handleSaveCompanyInfo = () => {
    const updatedCompany = formDataStep.company;
    const supplierProfile = formDataStep.supplierProfile;
    const supplierId = currentSupplierId;
    dispatch(updateCompanyBySupplierId(supplierId, updatedCompany));
    dispatch(updateSupplierByIdAsync({ supplierId, supplierProfile }));
    setEditCompanyInfo(false);
  };

  const sizeOptions = [
    " ",
    "Micro (1-10 employees)",
    "Small (11-50 employees)",
    "Medium (51-250 employees)",
    "Large (251-500 employees)",
    "Enterprise (501-1000 employees)",
    "Corporate (1001-5000 employees)",
    "Global (5001+ employees)",
  ];

  const industryOptions = [
    " ",
    "Technology",
    "Healthcare",
    "Finance",
    "Education",
    "Retail",
    "Manufacturing",
    "Non-profit",
    "Energy",
    "Transportation",
    "Real Estate",
    "Agriculture",
    "Hospitality",
    "Entertainment",
    "Telecommunications",
    "Government",
    "Consulting",
    "Consumer Goods",
    "Environmental Services",
    "Marketing/Advertising",
    "Legal",
    "Other",
  ];

  const ownershipOptions = [
    " ",
    "Women-Owned Business (WOB)",
    "Minority-Owned Business (MBE)",
    "Veteran-Owned Business (VOSB)",
    "Service-Disabled Veteran-Owned Business (SDVOSB)",
    "LGBTQ+-Owned Business",
    "Disabled-Owned Business (DOBE)",
    "Small Business",
    "Historically Underutilized Business (HUB)",
    "Disadvantaged Business Enterprise (DBE)",
    "Socially or Economically Disadvantaged Business",
    "Native American-Owned Business",
    "Alaskan Native-Owned Business",
    "Native Hawaiian-Owned Business",
    "Asian-Owned Business",
    "Hispanic-Owned Business",
    "Black-Owned Business",
    "Pacific Islander-Owned Business",
    "Middle Eastern-Owned Business",
    "Nonprofit or Social Enterprise",
  ];

  const handleToggleSizeDropdown = () =>
    setSizeDropdownOpen(!isSizeDropdownOpen);
  const handleToggleIndustryDropdown = () =>
    setIndustryDropdownOpen(!isIndustryDropdownOpen);
  const handleSelectSizeOption = (value) => {
    setFormDataStep({
      ...formDataStep,
      company: {
        ...formDataStep.company,
        size: value,
      },
    });
    setSizeDropdownOpen(false);
  };

  const handleSelectIndustryOption = (value) => {
    setFormDataStep({
      ...formDataStep,
      company: {
        ...formDataStep.company,
        industry: value,
      },
    });
    setIndustryDropdownOpen(false);
  };

  const handleToggleOwnershipDropdown = () =>
    setOwnershipDropdownOpen(!isOwnershipDropdownOpen);
  const handlePermissionDropdown = () =>
    setPermissionDropdownOpen(!isPermissionDropdownOpen);
  const handleEditPermissionDropdown = (memberId) => {
    setEditPermissionDropdownOpen(!isEditPermissionDropdownOpen);
    setMenuVisibility((prevVisibility) => ({
      ...prevVisibility,
      [memberId]: !prevVisibility[memberId],
    }));
  };

  const handleSelectOwnershipOption = (value) => {
    setFormDataStep({
      ...formDataStep,
      supplierProfile: {
        ...formDataStep.supplierProfile,
        diversity: {
          ...formDataStep.supplierProfile.diversity,
          diversityStatus: value,
        },
      },
    });
    setOwnershipDropdownOpen(false);
  };

  const handleSelectPermissionOption = (email, permissionType) => {
    setCurrentPermission(permissionType);
    setPermissionDropdownOpen(false);
  };
  const handleEditPermissionOption = (email, permissionType, memberId) => {
    setEditPermissionDropdownOpen(false);
    setMenuVisibility((prevVisibility) => ({
      ...prevVisibility,
      [memberId]: !prevVisibility[memberId],
    }));
    const updatedInvitedMembers = supplier.supplierProfile.otherContacts.map(
      (member) => {
        if (member.email === email) {
          return { ...member, permission: permissionType };
        }
        return member;
      }
    );
  };

  const handleSendInvite = () => {
    setInvitedMembers([
      ...invitedMembers,
      { emailAddress: invitationEmail, permission: currentPermission },
    ]);
    setCurrentPermission("");
    setInvitationEmail("");
    setInviteButtonClicked(false);
  };

  const handleInviteClick = () => setInviteButtonClicked(true);
  const handleFileChange = (files) => console.log("Files", files, selectedTab);
  const handlePreview = (file) => setShowPreviewPopup(true);
  const closePreview = () => setShowPreviewPopup(false);
  const handleDelete = (fileName) => {
    setImportedCertificates((prevImportedFiles) =>
      prevImportedFiles.filter((file) => file.name !== fileName)
    );
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    if (tab !== "about") {
      setShowCertificates(false);
    } else {
      setShowCertificates(true);
    }
  };

  const extractTextBetweenParentheses = (text) => {
    const startIndex = text.indexOf("(") + 1;
    const endIndex = text.indexOf(")");

    const lastSpaceIndex = text.lastIndexOf(" ", endIndex);
    return text.substring(startIndex, lastSpaceIndex);
  };

  const generateInfoBox = (label, value) => {
    return (
      <div className="info-box">
        <p style={{ color: "grey", display: "flex", flexDirection: "column" }}>
          {label} <span style={{ color: "black" }}>{value}</span>
        </p>
      </div>
    );
  };

  const handleMessage = () => navigate("/seller-dashboard/collaborations");
  const handleEdit = () => setEditProfile(true);
  const handleMouseOver = () => setMouseOver(true);
  const handleMouseOut = () => setMouseOver(false);
  const handleAddNewCertificate = () => setAddCertificate(true);
  const handleSaveCertificate = () => {
    setAddCertificate(false);
    const certificateNames = importedCertificates.map((file) => file.name);
    const updatedCertificates = [
      ...supplier.supplierProfile.certificates,
      ...certificateNames,
    ];
    const supplierId = supplier._id;
    setFormDataStep((prevFormDataStep) => ({
      ...prevFormDataStep,
      supplierProfile: {
        ...prevFormDataStep.supplierProfile,
        certificates: updatedCertificates,
      },
    }));
    const updatedSupplierProfile = {
      ...formDataStep.supplierProfile,
      certificates: updatedCertificates,
    };
    dispatch(
      updateSupplierByIdAsync({
        supplierId,
        supplierProfile: updatedSupplierProfile,
      })
    );
    setImportedCertificates([]);
  };

  const handleClickOutside = (event) => {
    if (
      (sizeDropdownRef.current &&
        !sizeDropdownRef.current.contains(event.target)) ||
      (industryDropdownRef.current &&
        !industryDropdownRef.current.contains(event.target)) ||
      (ownershipDropdownRef.current &&
        !ownershipDropdownRef.current.contains(event.target)) ||
      (permissionDropdownRef.current &&
        !permissionDropdownRef.current.contains(event.target))
    ) {
      setSizeDropdownOpen(false);
      setIndustryDropdownOpen(false);
      setOwnershipDropdownOpen(false);
      setPermissionDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!supplier) {
    return null;
  }

  return (
    <>
      <DashboardNavbar arrow={false} title="Profile" />

      <Card.Body className="full-user-profile-card-body">
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, marginRight: "38px", marginTop: "50px" }}>
            {!editProfile ? (
              <div style={divFlexColumn}>
                <div
                  style={{
                    ...divFlexRow,
                    justifyContent: "space-between",
                  }}
                >
                  <div style={divFlexRow}>
                    {supplier.supplierProfile.profilePicture ? (
                      <Image
                        src={supplier.supplierProfile.profilePicture}
                        alt=""
                        width="106px"
                        height="106px"
                        style={{ borderRadius: "9px", marginRight: "10px" }}
                      />
                    ) : (
                      <div style={{ marginRight: "10px" }}>
                        <CompanyAvatar
                          companyName={supplier.company.name}
                          size="106px"
                        />
                      </div>
                      // <div
                      //   style={{
                      //     width: "106px",
                      //     height: "106px",
                      //     marginRight: "10px",
                      //     borderRadius: "9px",
                      //     backgroundColor: "#D1D1D1",
                      //   }}
                      // />
                    )}

                    <div style={{ ...divFlexColumn, gap: "8px" }}>
                      <Card.Subtitle className="user-profile-subtitle">
                        {supplier.company.name}
                      </Card.Subtitle>
                      <Card.Subtitle
                        className="user-profile-subtitle"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "#7B7B7B",
                        }}
                      >
                        {supplier.supplierProfile.primaryRole}
                      </Card.Subtitle>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            flexDirection: "column",
                          }}
                        >
                          <p style={{ color: "#7B7B7B", marginRight: "10px" }}>
                            {supplier.address.city},&nbsp;
                            {supplier.address.state},&nbsp;
                            {supplier.address.country}
                          </p>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <p
                              style={{ color: "#7B7B7B"}}
                            >
                              {supplier.supplierProfile.rating === 0 ||
                              supplier.supplierProfile.rating === ""
                                ? null
                                : "Rating:"}
                            </p>

                            {supplier.supplierProfile.rating !== 0 &&
                              supplier.supplierProfile.rating !== "" && (
                                <p>{supplier.supplierProfile.rating}</p>
                              )}
                            {formDataStep.supplierProfile.certificates.length >
                              0 && (
                              <>
                                <span style={{ display: "flex" }}>
                                  <img
                                    src={certifiedCheck}
                                    alt=""
                                    style={{
                                      marginLeft: supplier.supplierProfile.rating !== '' ? "16px" : "0",
                                      marginRight: "5px",
                                    }}
                                  />
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      background:
                                        "linear-gradient(180deg, #CC9866 9.29%, #792E8E 98.58%)",
                                      WebkitBackgroundClip: "text",
                                      color: "transparent",
                                    }}
                                  >
                                    Certified
                                  </p>
                                </span>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={divFlexColumn}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <button
                        className="overview-edit-btn"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        style={{
                          backgroundImage: `url(${
                            isHovered ? penIconWhite : penIcon
                          })`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "right 5px center",
                        }}
                        onClick={() => handleEdit()}
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="tabs"
                  style={{ ...divFlexRow, marginTop: "87px", gap: "44px" }}
                >
                  <div
                    className="tab"
                    style={{
                      ...tabStyle,
                      ...(selectedTab === "about"
                        ? activeTabStyle
                        : inactiveTabStyle),
                    }}
                    onClick={() => handleTabClick("about")}
                  >
                    About
                  </div>
                  <div
                    className="tab"
                    style={{
                      ...tabStyle,
                      ...(selectedTab === "skills"
                        ? activeTabStyle
                        : inactiveTabStyle),
                    }}
                    onClick={() => handleTabClick("skills")}
                  >
                    Skills
                  </div>
                  <div
                    className="tab"
                    style={{
                      ...tabStyle,
                      ...(selectedTab === "reviews"
                        ? activeTabStyle
                        : inactiveTabStyle),
                    }}
                    onClick={() => handleTabClick("reviews")}
                  >
                    Reviews
                  </div>
                </div>

                <div className="tab-content" style={{ marginBottom: "76px" }}>
                  {selectedTab === "about" && (
                    <p>{formDataStep.supplierProfile.bio}</p>
                  )}
                  {selectedTab === "skills" && (
                    <div className="list-of-skills-pers-profile">
                      {Object.keys(formDataStep.supplierProfile.skills).map(
                        (skillType, index) => (
                          <div key={index}>
                            <h3>
                              {skillType === "technical"
                                ? "Technical Skills"
                                : skillType === "industry"
                                ? "Industry Skills"
                                : skillType === "company"
                                ? "Company Skills"
                                : skillType}
                            </h3>
                            <div className="skills-row">
                              {formDataStep.supplierProfile.skills[skillType] &&
                                formDataStep.supplierProfile.skills[skillType]
                                  .filter((skill) => skill !== "")
                                  .map((skill, skillIndex) => (
                                    <span key={skillIndex}>{skill}</span>
                                  ))}
                            </div>
                          </div>
                        )
                      )}

                      <div>
                        <h3>Certificates</h3>
                        <div className="skills-row">
                          {formDataStep.supplierProfile.certificates &&
                            formDataStep.supplierProfile.certificates
                              .filter((skill) => skill !== "")
                              .map((skill, skillIndex) => (
                                <span key={skillIndex}>{skill}</span>
                              ))}
                        </div>
                      </div>
                    </div>
                  )}
                  {selectedTab === "reviews" &&
                    (supplier.supplierProfile.reviews &&
                    supplier.supplierProfile.reviews.length > 0 ? (
                      supplier.supplierProfile.reviews.map((review) => (
                        <Card key={review.id} style={{ marginBottom: "20px" }}>
                          <Card.Body>
                            <Row>
                              <Col xs={3}>
                                <div
                                  style={{ ...divFlexRow, marginTop: "20px" }}
                                >
                                  <img src={greyCircle} alt="" />
                                  <div
                                    style={{
                                      ...divFlexColumn,
                                      marginLeft: "10px",
                                    }}
                                  >
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        color: "#1D2739",
                                      }}
                                    >
                                      {review.reviewerName}
                                    </p>
                                    <a
                                      style={{
                                        fontSize: "12px",
                                        textDecoration: "none",
                                        color: "#7B7B7B",
                                      }}
                                      href={review.reviewerWebsite}
                                    >
                                      {review.reviewerWebsite}
                                    </a>
                                  </div>
                                </div>
                              </Col>
                              <Col xs={9}>
                                <div style={divFlexRow}>
                                  {[...Array(5)].map((_, index) => (
                                    <img
                                      key={index}
                                      src={
                                        index < review.rating
                                          ? starIcon
                                          : emptyStarIcon
                                      }
                                      alt={
                                        index < review.rating
                                          ? "Filled Star"
                                          : "Empty Star"
                                      }
                                      style={{
                                        color:
                                          index < review.rating
                                            ? "#000000"
                                            : "inherit",
                                      }}
                                    />
                                  ))}
                                </div>
                                <p style={{ color: "#1D2739" }}>
                                  {review.reviewText}
                                </p>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      ))
                    ) : (
                      <p>No reviews yet</p>
                    ))}
                </div>
                {showCertificates && (
                  <div
                    style={{
                      ...divFlexRow,
                      justifyContent: "space-between",
                      marginBottom: "20px",
                    }}
                  >
                    <Card.Subtitle
                      className="integration-list-subtitle"
                      style={{ marginBottom: "8px" }}
                    >
                      Certificates
                    </Card.Subtitle>

                    {!addCertificate ? (
                      <button
                        style={{
                          display: "inline-flex",
                          height: "36px",
                          padding: "10px",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "8px",
                          border: "1px solid #8B8B8B",
                          color: "#8B8B8B",
                          background: "#FFF",
                        }}
                        onClick={() => handleAddNewCertificate()}
                      >
                        Add new certificates
                      </button>
                    ) : (
                      <button
                        style={{
                          display: "inline-flex",
                          height: "36px",
                          padding: "10px",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "8px",
                          border: "none",
                          color: "#fff",
                          background: "#0E8080",
                        }}
                        onClick={() => handleSaveCertificate()}
                      >
                        Save certificates
                      </button>
                    )}
                  </div>
                )}
                {formDataStep.supplierProfile.certificates.length > 0 &&
                  showCertificates && (
                    <div
                      style={{
                        ...divFlexColumn,
                        border: "1px solid #ddd",
                        padding: "15px 14px",
                        gap: "34px",
                        borderRadius: "8px",
                        marginBottom: "40px",
                      }}
                    >
                      {formDataStep.supplierProfile.certificates
                        .filter((cert) => cert !== "")
                        .map((cert, i) => (
                          <div key={`${i} + ${cert}`} style={divFlexRow}>
                            <Image
                              src={certificate}
                              alt=""
                              width="18px"
                              height="18px"
                              style={{
                                borderRadius: "9px",
                                marginRight: "10px",
                              }}
                            />
                            <span>{cert}</span>
                          </div>
                        ))}
                    </div>
                  )}
              </div>
            ) : (
              <EditProfile
                formDataStep={formDataStep}
                setFormDataStep={setFormDataStep}
                supplier={supplier}
                industryOptions={industryOptions}
                handleSaveProfileInfo={handleSaveProfileInfo}
              />
            )}

            {addCertificate && !editProfile && (
              <DragAndDropComponent
                width="100%"
                onFileChange={handleFileChange}
                onPreview={handlePreview}
                onDelete={handleDelete}
                importedFiles={importedCertificates}
                setImportedFiles={setImportedCertificates}
                setPdfFile={setPdfFile}
              />
            )}
          </div>

          <div
            style={{
              // height: "100vh",
              maxWidth: "40%",
              background: "#F9F9F9",
              padding: "20px",
            }}
          >
            <div>
              <div
                style={{
                  ...divFlexRow,
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Card.Subtitle
                  className="integration-list-subtitle"
                  style={{
                    marginTop: "24px",
                    marginBottom: "15px",
                    color: "#000",
                  }}
                >
                  Company info
                </Card.Subtitle>
                {!editCompanyInfo ? (
                  <button
                    className="overview-edit-btn"
                    onMouseEnter={handleCompanyMouseEnter}
                    onMouseLeave={handleCompanyMouseLeave}
                    style={{
                      backgroundImage: `url(${
                        isCompanyHovered ? penIconWhite : penIcon
                      })`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "right 5px center",
                    }}
                    onClick={() => handleEditCompanyInfo()}
                  >
                    Edit
                  </button>
                ) : (
                  <button
                    className="overview-edit-btn"
                    style={{
                      background: "#0E8080",
                      color: "#fff",
                      whiteSpace: "nowrap",
                      padding: "10px",
                    }}
                    onClick={() => handleSaveCompanyInfo()}
                  >
                    Save Edit
                  </button>
                )}
              </div>

              {editCompanyInfo ? (
                <div>
                  <Form.Group
                    as={Col}
                    controlId="companySize"
                    className="single-input"
                    style={{ margin: "30px 0" }}
                  >
                    <Form.Label className="edit-profile-input-title">
                      Employees
                    </Form.Label>
                    <div
                      ref={sizeDropdownRef}
                      className={`custom-edit-profile-input custom-dropdown-container ${
                        isSizeDropdownOpen ? "open" : ""
                      }`}
                      style={{
                        background: "#fff",
                        border: "border: 2px solid #EBEBEB",
                      }}
                    >
                      <div
                        className="custom-dropdown-edit-profile"
                        onClick={handleToggleSizeDropdown}
                      >
                        <span className="selected-option">
                          {formDataStep.company.size || "Select Company Size"}
                        </span>
                        <ul
                          className={`custom-dropdown-list ${
                            isSizeDropdownOpen ? "show" : ""
                          }`}
                        >
                          {sizeOptions.map((option, index) => (
                            <li
                              key={option}
                              className={`custom-dropdown-item ${
                                index === 0 ? "disabled" : ""
                              }`}
                              onMouseDown={(e) => {
                                e.preventDefault();
                                handleSelectSizeOption(option);
                              }}
                            >
                              {option}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Form.Group>

                  <Form.Group
                    controlId="companyIndustry"
                    className="single-input"
                    style={{ margin: "30px 0" }}
                  >
                    <Form.Label className="edit-profile-input-title">
                      Industry
                    </Form.Label>
                    <div
                      ref={industryDropdownRef}
                      className={`custom-edit-profile-input custom-dropdown-container ${
                        isIndustryDropdownOpen ? "open" : ""
                      }`}
                      style={{
                        background: "#fff",
                        border: "border: 2px solid #EBEBEB",
                      }}
                    >
                      <div
                        className="custom-dropdown-edit-profile"
                        onClick={handleToggleIndustryDropdown}
                      >
                        <span className="selected-option">
                          {formDataStep.company.industry || "Select Industry"}
                        </span>
                        <ul
                          className={`custom-dropdown-industry-list ${
                            isIndustryDropdownOpen ? "show" : ""
                          }`}
                        >
                          {industryOptions.map((option, index) => (
                            <li
                              key={option}
                              className={`custom-dropdown-item ${
                                index === 0 ? "disabled" : ""
                              }`}
                              onMouseDown={(e) => {
                                e.preventDefault();
                                handleSelectIndustryOption(option);
                              }}
                            >
                              {option}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="single-input"
                    style={{ margin: "30px 0" }}
                  >
                    <Form.Label className="edit-profile-input-title">
                      Diversity Group
                    </Form.Label>
                    <div
                      ref={ownershipDropdownRef}
                      className={`custom-edit-profile-input custom-dropdown-container ${
                        isOwnershipDropdownOpen ? "open" : ""
                      }
            `}
                      style={{
                        background: "#fff",
                        border: "border: 2px solid #EBEBEB",
                      }}
                    >
                      <div
                        className="custom-dropdown-edit-profile"
                        onClick={handleToggleOwnershipDropdown}
                      >
                        <span className="selected-option">
                          {formDataStep.supplierProfile.diversity
                            .diversityStatus || ""}
                        </span>
                        <ul
                          className={`custom-dropdown-ownership-list ${
                            isOwnershipDropdownOpen ? "show" : ""
                          }`}
                        >
                          {ownershipOptions.map((option, index) => (
                            <li
                              key={option}
                              className={`custom-dropdown-item ${
                                index === 0 ? "disabled" : ""
                              }`}
                              onMouseDown={(e) => {
                                e.preventDefault();
                                handleSelectOwnershipOption(option);
                              }}
                            >
                              {option}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Form.Group>
                </div>
              ) : (
                <div
                  style={{
                    background: "#fff",
                    padding: "20px",
                    border: "1px solid #EBEBEB",
                    borderRadius: "10px",
                  }}
                >
                  <div style={{ ...divFlexRow, marginTop: "16px" }}>
                    {supplier.company.companyProfilePicture ? (
                      <Image
                        src={supplier.company.companyProfilePicture}
                        alt=""
                        width="46px"
                        height="46px"
                        style={{
                          borderRadius: "9px",
                          marginRight: "10px",
                          marginBottom: "42px",
                        }}
                      />
                    ) : (
                      <div
                        style={{ marginRight: "10px", marginBottom: "42px" }}
                      >
                        {/* <CompanyAvatar
                          companyName={supplier.company.name}
                          size="46px"
                        /> */}
                      </div>
                    )}

                    <div
                      style={{
                        ...divFlexColumn,
                        alignItems: "flex-start",
                        marginBottom: "42px",
                      }}
                    >
                      <span>{supplier.company.name}</span>
                      <span style={{ color: "#7B7B7B" }}>
                        {supplier.company.website}
                      </span>
                    </div>
                  </div>

                  <div className="company-full-info-container">
                    <div className="info-boxes">
                      {generateInfoBox(
                        "Industry",
                        formDataStep.company.industry
                      )}
                      {generateInfoBox(
                        "Diversity Group",
                        formDataStep.supplierProfile.diversity.diversityStatus.replace(
                          /\s*\((?=.*[a-zA-Z0-9]).*?\)\s*/g,
                          ""
                        )
                      )}
                      {generateInfoBox(
                        "Employees",
                        extractTextBetweenParentheses(formDataStep.company.size)
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div
              style={{
                ...divFlexRow,
                justifyContent: "space-between",
                marginTop: "66px",
                marginBottom: "10px",
              }}
            >
              <Card.Subtitle
                className="integration-list-subtitle"
                style={{ color: "#1D2739" }}
              >
                Contacts
              </Card.Subtitle>
            </div>

            <div
              style={{
                background: "#fff",
                border: "1px solid #EBEBEB",
                borderRadius: "10px",
                paddingLeft: "20px",
              }}
            >
              <Form.Group
                as={Col}
                controlId="companyEmail"
                className="single-input"
                style={{ margin: "20px 0" }}
              >
                <Form.Label className="edit-profile-input-title">
                  Email
                </Form.Label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Form.Control
                    type="email"
                    placeholder="Enter their email"
                    value={invitationEmail}
                    style={{
                      borderRadius: "10px 0 0 10px",
                      width: "45%",
                    }}
                    className="custom-edit-profile-input"
                    onChange={(e) => setInvitationEmail(e.target.value)}
                  />
                  <button
                    type="button"
                    className="custom-input-permission-edit-profile"
                    style={{
                      backgroundImage: `url(${arrowDownGrey})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "right 2px center",
                      paddingRight: "20px",
                      width: "25%",
                      marginRight: "10px",
                    }}
                    onClick={handlePermissionDropdown}
                  >
                    {currentPermission || "can view"}
                  </button>
                  <button
                    className="send-invite-btn-edit-profile"
                    onClick={handleSendInvite}
                    style={{
                      height: "36px",
                      background:
                        invitationEmail !== "" ? "#0E8080" : "#C6C6C6",
                    }}
                  >
                    Send Invite
                  </button>
                </div>
              </Form.Group>

              {isPermissionDropdownOpen && (
                <div
                  ref={permissionDropdownRef}
                  className="dots-menu"
                  style={{
                    position: "absolute",
                    height: "135px",
                    width: "144px",
                    gap: "20px",
                    paddingLeft: "20px",
                  }}
                >
                  <span
                    onClick={() =>
                      handleSelectPermissionOption(invitationEmail, "can view")
                    }
                    style={divFlexRow}
                  >
                    Can view
                  </span>
                  <span
                    onClick={() =>
                      handleSelectPermissionOption(invitationEmail, "can edit")
                    }
                    style={divFlexRow}
                  >
                    Can edit
                  </span>
                  <span
                    onClick={() =>
                      handleSelectPermissionOption(invitationEmail, "admin")
                    }
                    style={divFlexRow}
                  >
                    Admin
                  </span>
                </div>
              )}

              <div style={{ marginBottom: "42px", padding: "20px" }}>
                <Card.Subtitle
                  className="integration-list-subtitle"
                  style={{
                    marginTop: "30px",
                    marginBottom: "15px",
                    color: "#7B7B7B",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Main Contact
                </Card.Subtitle>
                <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                  <div style={{ ...divFlexRow, marginTop: "16px" }}>
                    {formDataStep.supplierProfile.profilePicture ? (
                      <Image
                        src={formDataStep.supplierProfile.profilePicture}
                        alt=""
                        width="46px"
                        height="46px"
                        style={{
                          borderRadius: "9px",
                          marginRight: "10px",
                          // marginBottom: "42px",
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          width: "46px",
                          height: "46px",
                          marginRight: "10px",
                          borderRadius: "9px",
                          backgroundColor: "#D1D1D1",
                        }}
                      />
                      // <div style={{ marginRight: "10px" }}>
                      //   <CompanyAvatar
                      //     companyName={supplier.supplier.supplierProfile.mainContacts[0].name}
                      //     size="46px"
                      //   />
                      // </div>
                    )}

                    <div
                      style={{
                        ...divFlexRow,
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          ...divFlexColumn,
                          alignItems: "flex-start",
                          // marginBottom: "42px",
                          marginRight: "50px",
                        }}
                      >
                        <p
                          style={{
                            display: "flex",
                            color: "#7B7B7B",
                            fontSize: "16px",
                          }}
                        ></p>
                        <span style={{ color: "#313131" }}>
                          {supplier.supplierProfile.mainContacts[0].name}
                        </span>
                        <span style={{ color: "#313131" }}>
                          {supplier.supplierProfile.mainContacts[0].primaryrole}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Card.Subtitle
                className="integration-list-subtitle"
                style={{
                  marginTop: "30px",
                  marginBottom: "15px",
                  color: "#7B7B7B",
                  fontSize: "16px",
                  fontWeight: "500",
                  paddingLeft: "20px",
                }}
              >
                Other Contacts
              </Card.Subtitle>
              {supplier.supplierProfile.otherContacts.length > 0 &&
              supplier.supplierProfile.otherContacts[0].email !== ""
                ? supplier.supplierProfile.otherContacts.map(
                    (supplier, index) => (
                      <div
                        style={{
                          ...divFlexRow,
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          onMouseOver={handleMouseOver}
                          onMouseOut={handleMouseOut}
                        >
                          <div
                            style={{
                              ...divFlexRow,
                              padding: " 0 20px 20px 20px",
                            }}
                          >
                            {supplier.profilePicture ? (
                              <Image
                                src={supplier.profilePicture}
                                alt=""
                                width="46px"
                                height="46px"
                                style={{
                                  borderRadius: "9px",
                                  marginRight: "10px",
                                  // marginBottom: "42px",
                                }}
                              />
                            ) : (
                              <div
                                style={{
                                  width: "46px",
                                  height: "46px",
                                  marginRight: "10px",
                                  borderRadius: "9px",
                                  backgroundColor: "#D1D1D1",
                                }}
                              />
                              // <div style={{ marginRight: "10px" }}>
                              //   <CompanyAvatar
                              //     companyName={supplier.name}
                              //     size="46px"
                              //   />
                              // </div>
                            )}
                            <div
                              style={{
                                ...divFlexRow,
                                alignItems: "flex-start",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  ...divFlexColumn,
                                  alignItems: "flex-start",
                                  // marginBottom: "42px",
                                  marginRight: "50px",
                                }}
                              >
                                <p
                                  style={{
                                    display: "flex",
                                    color: "#7B7B7B",
                                    fontSize: "16px",
                                  }}
                                ></p>
                                <span style={{ color: "#313131" }}>
                                  {supplier.supplierProfile.name}
                                </span>
                                <span style={{ color: "#313131" }}>
                                  {supplier.supplierProfile.primaryRole}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ position: "relative" }}>
                          <button
                            type="button"
                            className="input-permission"
                            style={{
                              backgroundImage: `url(${arrowDownGrey})`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "right 10px center",
                              paddingRight: "40px",
                            }}
                            onClick={() => handleEditPermissionDropdown(index)}
                          >
                            {supplier.permission}
                          </button>
                        </div>

                        {menuVisibility[index] &&
                          isEditPermissionDropdownOpen && (
                            <div
                              // ref={permissionEditDropdownRef}
                              className="dots-menu"
                              style={{
                                position: "absolute",
                                height: "135px",
                                width: "144px",
                                gap: "20px",
                                paddingLeft: "20px",
                                marginTop: "30px",
                              }}
                            >
                              <span
                                onClick={() =>
                                  handleEditPermissionOption(
                                    supplier.email,
                                    "can view",
                                    index
                                  )
                                }
                                style={divFlexRow}
                              >
                                Can view
                              </span>
                              <span
                                onClick={() =>
                                  handleEditPermissionOption(
                                    supplier.email,
                                    "can edit",
                                    index
                                  )
                                }
                                style={divFlexRow}
                              >
                                Can edit
                              </span>
                              <span
                                onClick={() =>
                                  handleEditPermissionOption(
                                    supplier.email,
                                    "admin",
                                    index
                                  )
                                }
                                style={divFlexRow}
                              >
                                Admin
                              </span>
                            </div>
                          )}
                      </div>
                    )
                  )
                : " "}
            </div>
          </div>
        </div>
      </Card.Body>

      {showPreviewPopup && (
        <>
          <Overlay />
          <PreviewPopup file={pdfFile} onClose={closePreview} />
        </>
      )}
    </>
  );
};

export default Profile;
