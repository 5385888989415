import React, { useState } from "react";
import SignUpContainer from "../SignUpContainer";
import { Link } from "react-router-dom";
import { Col, Form } from "react-bootstrap";
import "./styling.css";

const BasicInformation = ({ formDataStep, setFormDataStep, handleNextStep, step, accountType}) => {
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [companyEmailError, setCompanyEmailError] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (event) => {
    if(event) event.preventDefault();
    setSubmitted(true);

    setFirstNameError(!formDataStep.firstName);
    setLastNameError(!formDataStep.lastName);

    const isEmailValid = validateEmail(formDataStep.email);
    setCompanyEmailError(!formDataStep.email || !isEmailValid);

    if (formDataStep.firstName && formDataStep.lastName && isEmailValid) {
      handleNextStep();
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  return (
    <SignUpContainer
      title="Create Account"
      accountType={accountType}
      className="basic-info-container"
      // height={accountType === "seller" ? "955px" : "768px"}
      height="768px"
      step={step}
      subtitle={
        <>
          Already have an account?{" "}
          <Link
            to="/sign-in"
            style={{
              color: "#fff",
              fontFamily: "Lato",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "144.523%",
              textDecorationLine: "underline",
            }}
          >
            Sign In
          </Link>
        </>
      }
      onContinueClick={handleSubmit}
    >
      <Form onSubmit={handleSubmit}>
        <Form.Group as={Col} controlId="firstName" className="single-input">
          <Form.Label className="input-title">First Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your first name"
            required
            className={`custom-input ${firstNameError ? "error" : ""}`}
            value={formDataStep.firstName}
            onChange={(e) => {
              setFormDataStep({
                ...formDataStep,
                firstName: e.target.value,
              });
              setFirstNameError(false);
              setSubmitted(false);
            }}
          />
          {firstNameError && (
            <div className="error-message">First Name is required</div>
          )}
        </Form.Group>

        <Form.Group as={Col} controlId="lastName" className="single-input">
          <Form.Label className="input-title">Last Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your last name"
            required
            className={`custom-input ${lastNameError ? "error" : ""}`}
            value={formDataStep.lastName}
            onChange={(e) => {
              setFormDataStep({
                ...formDataStep,
                lastName: e.target.value,
              });
              setLastNameError(false);
              setSubmitted(false);
            }}
          />
          {lastNameError && (
            <div className="error-message">Last Name is required</div>
          )}
        </Form.Group>
        <Form.Group as={Col} controlId="companyEmail" className="email-input">
          <Form.Label className="input-title">Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter your company email"
            required
            className={`custom-input ${companyEmailError ? "error" : ""}`}
            value={formDataStep.email}
            onChange={(e) => {
              const enteredEmail = e.target.value;
              setFormDataStep({
                ...formDataStep,
                email: enteredEmail,
              });
              setSubmitted(false);
              setCompanyEmailError(
                submitted && (!enteredEmail || !validateEmail(enteredEmail))
              );
            }}
          />
          {companyEmailError && (
            <div className="error-message">
              {formDataStep.email
                ? "Please enter a valid email address"
                : "Company Email is required"}
            </div>
          )}
        </Form.Group>
      </Form>
    </SignUpContainer>
  );
};

export default BasicInformation;
