import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

const BarChart = () => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    // Define your data
    const data = {
      labels: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      datasets: [
        {
          label: "Sales Data",
          data: [100, 120, 150, 180, 350, 170, 500, 140, 130, 110, 90, 80],
          backgroundColor: " #0E8080",
          borderWidth: 1,
        },
      ],
    };

    setChartData(data);
    return () => {
      setChartData(null);
    };
  }, []);

  return (
    <div style={{display:"flex", flexDirection:"column"}}>
    <h3 style={{ margin: '0', display:"flex", marginTop:"20px", marginLeft:"20px", fontSize:"15px"}}>Supplier growth</h3>
    <p style={{color:"#7B7B7B", display:"flex", marginBottom: '30px', marginLeft:"20px", fontSize:"14px"}}>No of Suppliers</p>
    <div style={{height:"437px"}}>
      {chartData && (
        <Bar
          data={chartData}
          options={{
            indexAxis: "x",
            maintainAspectRatio: false,
            responsive: true,
            scales: {
              x: {
                title: {
                  display: true,
                },
                grid: {
                  display: false,
                },
              },
              y: {
                beginAtZero: true,
                title: {
                  display: true,
                },
                ticks: {
                  stepSize: 500,
                  min: 0,
                  max: 500,
                },
                grid: {
                  display: false,
                },
              },
            },
            layout: {
              padding: {
                left: 10,
                right: 10,
                top: 10,
                bottom: 10,
              },
            },
            plugins: {
              legend: {
                display: false,
              },
            },
            barPercentage: 1,
            categoryPercentage: 0.5,
          }}
        />
      )}
    </div>
    </div>
  );
};

export default BarChart;
