// File: buyerReducer.js
import { GET_BUYERS_START, GET_BUYERS_SUCCESS, GET_BUYERS_FAILURE, SET_BUYERS } from "../constants/constants"

const initialState = {
  buyers: [],
  isLoading: false,
  error: null,
};


const buyerReducer = (state = initialState, action) => {
  console.log('ACTION FROM SELLER', action)
  switch (action.type) {
    case GET_BUYERS_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case GET_BUYERS_SUCCESS:
      return {
        ...state,
        sellers: action.payload,
        isLoading: false,
        error: null,
      };

    case GET_BUYERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default buyerReducer;

