import { LOGIN_START, LOGIN_SUCCESS, LOGIN_FAILURE, SIGNUP_START, SIGNUP_SUCCESS, SIGNUP_FAILURE } from '../constants/constants';

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  token: null,
  error: null,
  isSigningUp: false,
  signupError: null,
  isSignupSuccess: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_START:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        token: action.payload,
        error: null,
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        token: null,
        error: action.payload,
      };
      
      case SIGNUP_START:
        return {
          ...state,
          isSigningUp: true,
          signupError: null,
          isSignupSuccess: false,
        };
  
      case SIGNUP_SUCCESS:
        return {
          ...state,
          isSigningUp: false,
          signupError: null,
          isSignupSuccess: true,
        };
  
      case SIGNUP_FAILURE:
        return {
          ...state,
          isSigningUp: false,
          signupError: action.payload,
          isSignupSuccess: false,
        };

    default:
      return state;
  }
};

export default authReducer;
