
import React from "react";
import Avatar from "react-avatar";

const CompanyAvatar = ({ companyName, size }) => {

  return (
      <Avatar
        name={companyName}
        size={size}
        maxInitials={2}
        colors={['#0E8080','#654198', '#CC9866']} 
        round='8px'
      />
  );
};

export default CompanyAvatar;