import React, { useState, useEffect, useRef } from "react";
import { Container, Col, Image } from "react-bootstrap";
import background from "../../assets/LandingPage/Background (1).png";
import backgroundVideo from "../../assets/LandingPage/backgroundVideo-5.mov";
import Preloader from "../../assets/LandingPage/TalkDEI preloader.mp4";
import Overlay from "../Overlay/Overlay";
import { useNavigate } from "react-router";
// import Preloader from "../../assets/LandingPage/TalkDEI preloader.mp4";
import NavBar from "./Navbar";
import DynamicTextComponent from "./DynamicTextComponent";
import SignupSection from "./SignupSection";
import Footer from "./Footer";
import ChartSection from "./ChartSection";
import GradientSection from "./GradientSection";
import AIReccomendationsSection from "./AIReccomendationsSection";
import SuppliersDiscoverySection from "./SupplierDiscoverySection";

const words = [
  "Diverse",
  "IT",
  "Legal",
  "Engineering",
  "Architecture",
  "Corporate",
  "Real Estate",
];

const LandingPage = () => {
  const navigate = useNavigate();
  const [isFixed, setIsFixed] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const formInitialized = useRef(false);

  useEffect(() => {
    const videoElement = document.querySelector('.background-video');

    const playVideo = () => {
      videoElement.play();
    };

    const pauseVideo = () => {
      videoElement.pause();
    };

    if (videoElement) {
      // Desktop events
      videoElement.addEventListener('mouseover', playVideo);
      videoElement.addEventListener('mouseout', pauseVideo);

      // Mobile events
      videoElement.addEventListener('touchstart', playVideo);
      videoElement.addEventListener('touchend', pauseVideo);
    }

    // Clean up event listeners on component unmount
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('mouseover', playVideo);
        videoElement.removeEventListener('mouseout', pauseVideo);
        videoElement.removeEventListener('touchstart', playVideo);
        videoElement.removeEventListener('touchend', pauseVideo);
      }
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const handleMouseEnterTextArea = () => {
    setIsHovering(true);
  };

  const handleMouseLeaveTextArea = () => {
    setIsHovering(false);
  };

  const [isTextHovered, setIsTextHovered] = useState(false);
  const [showPreloader, setShowPreloader] = useState(true);

  useEffect(() => {
    const preloaderDuration = 8600;

    const preloaderShown = sessionStorage.getItem("preloaderShown");

    if (!preloaderShown) {
      setShowPreloader(true);
      const timer = setTimeout(() => {
        setShowPreloader(false);
        setSubmitted(true);
        sessionStorage.setItem("preloaderShown", "true");
      }, preloaderDuration);

      return () => clearTimeout(timer);
    } else {
      setShowPreloader(false);
      setSubmitted(false); 
    }
  }, []);



  useEffect(() => {
    const script = document.createElement("script");
    script.setAttribute("charset", "utf-8");
    script.setAttribute("type", "text/javascript");
    script.setAttribute("src", "//js.hsforms.net/forms/embed/v2.js");
    document.body.appendChild(script);

    script.onload = () => {
      if (window.hbspt) {
        hbspt.forms.create({
          region: "na1",
          portalId: "45694015",
          formId: "8be87e87-7294-41bb-a3f5-33bb995c6f32",
          target: "#hubspot-form",
          onFormSubmit: () => {
            setSubmitted(false);
          },
        });
      }
    };
  }, [submitted]);

  const handleClose = () => {
    setSubmitted(false);
  };


  // useEffect(() => {
  //   const handleMouseMove = (e) => {
  //     const cursor = document.querySelector(".custom-cursor");
  //     cursor.style.top = e.pageY + "px";
  //     cursor.style.left = e.pageX + "px";
  //   };

  //   document.addEventListener("mousemove", handleMouseMove);

  //   return () => {
  //     document.removeEventListener("mousemove", handleMouseMove);
  //   };
  // }, []);

  useEffect(() => {
    const handleHover = () => {
      setIsTextHovered(true);
    };

    const handleLeave = () => {
      setIsTextHovered(false);
    };

    document.querySelectorAll("a, button").forEach((el) => {
      el.addEventListener("mouseenter", handleHover);
      el.addEventListener("mouseleave", handleLeave);
    });

    return () => {
      document.querySelectorAll("a, button").forEach((el) => {
        el.removeEventListener("mouseenter", handleHover);
        el.removeEventListener("mouseleave", handleLeave);
      });
    };
  }, []);

  useEffect(() => {
    const container = document.querySelector(".beta-program-container");
    if (container) {
      const containerOffset = container.offsetTop;
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        setIsFixed(scrollPosition >= containerOffset);
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  });

  const handleSignUp = () => {
    navigate("/sign-up");
  };

  return (
    <div className="landing-page-container">
      {showPreloader && !isMobile ? (
        <video
          autoPlay="autoplay"
          playsInLine="playsinline"
          loop="true"
          muted="true"
          className="preloader-video"
        >
          <source src={Preloader} type="video/mp4" />
        </video>
      ) : (
        <>

          <NavBar isMobile={isMobile} />


        {/* {submitted && (
  <div className="overlay-landing">
    <Overlay />
    <div
      style={{
        position: "absolute",
        top: "20%",
        left: "50%",
        background: "#fff",
        borderRadius: "8px",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          display: "flex",
          width: "450px",
          paddingBottom: "58px",
          flexDirection: "column",
          alignItems: "center",
          gap: "30px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            background:
              "linear-gradient(270deg, #CC9866 0%, #722592 102.7%)",
            height: "78px",
            width: "100%",
            padding: "27px 27px 19px",
            alignItems: "center",
            borderRadius: "8px 8px 0 0",
          }}
        >
          <span className="close-button" onClick={handleClose}>X</span>
          <span
            style={{
              color: "#FFF",
              marginLeft: "10px",
              fontFamily: "Lato",
              fontSize: "20px",
              fontWeight: "600",
              whiteSpace: "nowrap",
            }}
          >
            Welcome to TalkDEI
          </span>
        </div>
        <span
          style={{
            color: "#1D2739",
            width: "308px",
            fontFamily: "Lato",
            fontSize: "16px",
            fontWeight: "400",
            textAlign: "center",
          }}
        >
          Subscribe for updates with your email
        </span>
        <div id="hubspot-form"></div>
      </div>
    </div>
  </div>
)} */}
          <Container fluid>
            <div className="background-container">
              <Col>
                <div
                  className="text-container"
                  onMouseEnter={handleMouseEnterTextArea}
                  onMouseLeave={handleMouseLeaveTextArea}
                >
                  <DynamicTextComponent words={words} isHovering={isHovering} isMobile={isMobile} />
                  <p>
                    Sign Up Now for Early Beta Access and Amplify Your Reach
                  </p>
                  <button onClick={handleSignUp}>Join now</button>

                  <video muted loop autoPlay playsInline controls={false} src={backgroundVideo} type='video/mp4' class="background-video"></video>
                </div>
              </Col>
              <Image src={background} className="background-image" fluid />
            </div>
          </Container>
          <div className={`beta-program-container ${isFixed && !isMobile ? "fixed" : ""}`}>

          <a href="https://www.producthunt.com/products/talkdei?utm_source=badge-follow&utm_medium=badge&utm_souce=badge-talkdei" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/follow.svg?product_id=593166&theme=dark" alt="TalkDEI&#0032; - AI&#0045;powered&#0044;&#0032;bias&#0045;free&#0032;supplier&#0032;sourcing&#0032;and&#0032;management&#0046; | Product Hunt" style= {{height: "54px", width: isMobile ? "" : "200px"}} /></a>
          {/* <a href="https://www.producthunt.com/products/talkdei?utm_source=badge-follow&utm_medium=badge&utm_souce=badge-talkdei" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/follow.svg?product_id=593166&theme=light" alt="TalkDEI&#0032; - AI&#0045;powered&#0044;&#0032;bias&#0045;free&#0032;supplier&#0032;sourcing&#0032;and&#0032;management&#0046; | Product Hunt" style= {{height: "54px", width:"200px", height:"54px"}} /></a> */}
          {/* <a href="https://www.producthunt.com/products/talkdei?utm_source=badge-follow&utm_medium=badge&utm_souce=badge-talkdei" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/follow.svg?product_id=593166&theme=light&size=small" alt="TalkDEI&#0032; - AI&#0045;powered&#0044;&#0032;bias&#0045;free&#0032;supplier&#0032;sourcing&#0032;and&#0032;management&#0046; | Product Hunt" style={{width: "86px", height: "32px", width:"86px", height:"32"}} /></a> */}
            <span>
              Try out our beta program to find the best fit for your business
            </span>
            <button onClick={() => handleSignUp()}>Get Started</button>
          </div>
          <AIReccomendationsSection isMobile={isMobile} />
          <SuppliersDiscoverySection isMobile={isMobile} />
          <GradientSection handleSignUp={handleSignUp} isMobile={isMobile} />
          <ChartSection isMobile={isMobile} />
          <SignupSection
            handleSignUp={handleSignUp}
            isMobile={isMobile}
          />
          <Footer isMobile={isMobile}/>
        </>
        
      )}
      {/* <div className="custom-cursor"></div> */}
           {/* { !showPreloader && submitted && (
            <div className="overlay-landing">
              <Overlay />
              <div
                style={{
                  position: "absolute",
                  top: "20%",
                  left: "50%",
                  background: "#fff",
                  borderRadius: "8px",
                  zIndex: 1000,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "450px",
                    paddingBottom: "58px",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "30px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      background:
                        "linear-gradient(270deg, #CC9866 0%, #722592 102.7%)",
                      height: "78px",
                      width: "100%",
                      padding: "27px 27px 19px",
                      alignItems: "center",
                      borderRadius: "8px 8px 0 0",
                    }}
                  >
                    <span className="close-button" onClick={handleClose}>
                      X
                    </span>
                    <span
                      style={{
                        color: "#FFF",
                        marginLeft: "10px",
                        fontFamily: "Lato",
                        fontSize: "20px",
                        fontWeight: "600",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Welcome to TalkDEI
                    </span>
                  </div>
                  <span
                    style={{
                      color: "#1D2739",
                      width: "308px",
                      fontFamily: "Lato",
                      fontSize: "16px",
                      fontWeight: "400",
                      textAlign: "center",
                    }}
                  >
                    Subscribe for updates with your email
                  </span>
                  <div id="hubspot-form"></div>
                </div>
              </div>
            </div> 
           )} */}
          
    </div>
  );
};

export default LandingPage;