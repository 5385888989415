import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart, ArcElement } from "chart.js";
Chart.register(ArcElement);

const DiversityPieChart = ({ title }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const data = {
      labels: [
        "Minority owned business",
        "Women owned business",
        "Veteran owned business",
        "SMBs",
        "Others",
      ],
      datasets: [
        {
          label: "Diversity Metrics",
          data: [20, 10, 12, 6, 5],
          backgroundColor: [
            "#FFD399",
            "#D37CCF",
            "#B3F6FA",
            "#7045AF",
            "#3EA77B",
          ],
          borderWidth: 2,
          borderRadius: 9,
        },
      ],
    };

    setChartData(data);

    return () => {
      setChartData(null);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        border: "0.959px solid #EBEBEB",
        borderRadius: "15.341px",
        width: "413px",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", justifyContent:"space-between" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3
            style={{
              margin: "0",
              display: "flex",
              padding: "20px 20px 0 20px",
              fontSize: "14px",
              color: "#7B7B7B",
              fontFamily: "Lato",
            }}
          >
            {title}
          </h3>
          {chartData && (
            <p style={{ alignSelf: "flex-start", paddingLeft: "20px" }}>
              {chartData.datasets[0].data.reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0
              )}
            </p>
          )}
        </div>
        <p
          style={{
            display: "flex",
            margin: "10px",
            padding: "9.356px 10.025px",
            fontSize: "15px",
            color: "#1D2739",
            borderRadius: "4.01px",
            border: "0.668px solid #EBEBEB",
            background: "#FFF",
            width: "90px",
            height: "36px",
            alignSelf: "flex-end",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Monthly
        </p>
      </div>
      <div
        style={{ display: "flex", alignItems: "center", flexDirection: "row" }}
      >
        <div className="diversity-pie-chart-position">
          {chartData && (
            <Doughnut
              data={chartData}
              options={{
                // maintainAspectRatio: false,
                // responsive: true,
                cutout: "50%",
                plugins: {
                  legend: {
                    display: false,
                  },
                  datalabels: {
                    color: "#000000",
                    font: {
                      size: 15,
                    },
                    formatter: (value, context) => {
                      return `${value}`;
                    },
                  },
                },
                layout: {
                  padding: {
                    left: 10,
                    right: 10,
                    top: 10,
                    bottom: 10,
                  },
                },
              }}
              plugins={[ChartDataLabels]}
            />
          )}
        </div>
        <div className="chart-labels" style={{ marginRight: "10px" }}>
          {chartData &&
            chartData.labels.map((label, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "5px",
                  color: "#7B7B7B",
                }}
              >
                <div
                  style={{
                    width: "14px",
                    height: "14px",
                    backgroundColor:
                      chartData.datasets[0].backgroundColor[index],
                    marginRight: "5px",
                    borderRadius: "3px",
                  }}
                ></div>
                <span>{label}</span>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default DiversityPieChart;
