import React, { useState, useEffect, useRef } from "react";
import { Image } from "react-bootstrap";
import landingPageChart from "../../assets/LandingPage/landingPageChart.png";
import landingPageChartJson from "../../assets/LandingPage/Charts.json";
import vectorPurple from "../../assets/LandingPage/vectorPurple.svg";
import vectorGreen from "../../assets/LandingPage/vectorGreen.svg";
import DEI from "../../assets/LandingPage/DEI.svg";
import Lottie from "react-lottie";

const ChartSection = ({isMobile}) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const chartRef = useRef();
  const chartHeaderRef = useRef();
  const [isFirstRender, setFirstRender] = useState(true);

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       entries.forEach((entry) => {
  //         if (isFirstRender.current) {
  //           isFirstRender.current = false;
  //           return;
  //         }
  //         if (entry.isIntersecting && chartHeaderRef) {
  //           setIsAnimating(true);
  //           // spanRef.current.classList.add("animate");
  //           chartHeaderRef.current.classList.add("animate");
  //         } else if (chartHeaderRef.current) {
  //           setIsAnimating(false);
  //         }
  //       });
  //     },
  //     {
  //       threshold: 0.1,
  //     }
  //   );

  //   if (chartHeaderRef.current) {
  //     observer.observe(chartHeaderRef.current);
  //   }

  //   return () => {
  //     if (chartHeaderRef.current) {
  //       observer.unobserve(chartHeaderRef.current);
  //     }
  //   };
  // }, [isAnimating]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
          }
          if (entry.isIntersecting && chartHeaderRef) {
            setIsAnimating(true);
            // spanRef.current.classList.add("animate");
            chartHeaderRef.current.classList.add("animate");
            document.querySelectorAll(".text-box").forEach((element) => {
              element.classList.add("animate");
            });
          } else if (chartHeaderRef.current) {
            setIsAnimating(false);
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    if (chartHeaderRef.current) {
      observer.observe(chartHeaderRef.current);
    }

    return () => {
      if (chartHeaderRef.current) {
        observer.unobserve(chartHeaderRef.current);
      }
    };
  }, []); // Removed isAnimating from dependency array

  // const handleAnimationComplete = () => {
  //   setIsAnimating(false);
  // };

  // const defaultOptions = {
  //   loop: false,
  //   autoplay: false,
  //   animationData: landingPageChartJson,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  //   speed: 1,
  // };
  return (
    <>
      <div className="chart-container">
        <div className="chart-content" ref={chartHeaderRef}>
          <span>Why should you be</span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span>a</span>{" "}
            <Image
              src={DEI}
              alt=""
              width= { isMobile ? "42px" : "71px"}
              height={isMobile ? "20px" : "34px"}
              style={{
                marginRight: "10px",
                marginLeft: "10px",
                marginTop: "10px",
              }}
            />
            <span>champion</span>
          </div>
        </div>

        <div className="image-container" style={{ position: "relative", 
    top: isMobile ? "60px" : '' }}>
          <Image src={landingPageChart} alt="Background" fluid     width= { isMobile ? "230px" : ""}
              height={isMobile ? "196px" : ""} />
          {/* <Lottie
        options={defaultOptions}
        height={573}
        width={672}
        isPaused={!isAnimating}
        eventListeners={[
          {
            eventName: "complete",
            callback: handleAnimationComplete,
          },
        ]}
      /> */}
          <div style={{ position: "absolute", top: isMobile ? -35 : -100 , left: isMobile ? 60 : 220 }}>
            <Image src={vectorPurple} alt="Left Line" height={isMobile ? "62px" : ""} />

            <div
              className="text-box"
              style={{ position: "absolute", top: isMobile ? -18 : -30 , left: isMobile ? -110 : -240 }}
              ref={chartRef}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  className="dot"
                  style={{
                    width: 9,
                    height: 9,
                    backgroundColor: "#654198",
                    borderRadius: "50%",
                    marginRight: 12,
                  }}
                ></div>
                <span>Economic Impact</span>
              </div>
              <div className="expanded-content">
                <span
                  style={{
                    display: "block",
                    marginTop: "10px",
                    fontSize: isMobile ? "15px" : "32px",
                  }}
                >
                  $16 trillion
                </span>
                <p style={{ marginTop: "5px", fontSize: isMobile ? "10px" : "13px" }}>
                  GDP loss due to systemic discrimination against Black people,
                  emphasizing the urgency for change. 
                </p>
              </div>
            </div>
          </div>

          <div style={{ position: "absolute", top: isMobile ? -20 : -50, right: 50 }}>
            <Image src={vectorGreen} alt="Right Line" height={isMobile ? "62px" : ""} width={isMobile ? "13px" : ""}/>
            <div
              className="text-box"
              style={{ position: "absolute", top: isMobile ? -13 : -30, left: isMobile ? -5 : 30 }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  className="dot"
                  style={{
                    width: 9,
                    height: 9,
                    backgroundColor: "#4AA0A1",
                    borderRadius: "50%",
                    marginRight: 12,
                  }}
                ></div>
                <span>Revenue generation</span>
              </div>
              <div className="expanded-content">
                <span
                  style={{
                    display: "block",
                    marginTop: "10px",
                    fontSize: isMobile ? "15px" : "32px",
                  }}
                >
                  $400 billion
                </span>
                <p style={{ marginTop: "5px", fontSize: isMobile ? "10px" : "13px" }}>
                  annual revenue generated by certified minority-owned
                  businesses, along with the creation of 2.2 million jobs and
                  $49 billion in annual tax revenue. 
                </p>
              </div>
            </div>
          </div>

          <div
            className="text-box"
            style={{ position: "absolute", bottom: isMobile ? 80 : 220, left: isMobile ? -70 : -225 }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                className="dot"
                style={{
                  width: 9,
                  height: 9,
                  backgroundColor: "#CC9866",
                  borderRadius: "50%",
                  marginRight: 12,
                }}
              ></div>
              <span>Procurement ROI</span>
            </div>
            <div className="expanded-content">
              <span
                style={{
                  display: "block",
                  marginTop: "10px",
                  fontSize: isMobile ? "15px" : "32px",
                }}
              >
                133%
              </span>
              <p style={{ marginTop: "5px", fontSize: isMobile ? "10px" : "13px" }}>
                greater return on procurement investments for companies with
                diverse supplier bases, illustrating the business case for
                supplier diversity.
              </p>
            </div>
          </div>

          <div
            className="text-box"
            style={{
              position: "absolute",
              bottom: "40%",
              width: isMobile ? "130px" : "",
              right: isMobile ? -80 : -220,
              transform: "translateY(50%)",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                className="dot"
                style={{
                  width: 9,
                  height: 9,
                  backgroundColor: "#A4BBEF",
                  borderRadius: "50%",
                  marginRight: 12,
                }}
              ></div>
              <span>Efficiency and innovation</span>
            </div>
            <div className="expanded-content">
              <span
                style={{
                  display: "block",
                  marginTop: "10px",
                  fontSize: isMobile ? "15px" : "32px",
                }}
              >
                94%
              </span>
              <p style={{ marginTop: "5px", fontSize: isMobile ? "10px" : "13px" }}>
                of organizations with supplier diversity programs report
                increased client attraction or retention.
              </p>
            </div>
          </div>

          <div
            className="text-box"
            style={{
              position: "absolute",
              bottom: isMobile ? 0 : -20,
              right: 50,
              transform: "translateY(50%)",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                className="dot"
                style={{
                  width: 9,
                  height: 9,
                  backgroundColor: "#FF6B00",
                  borderRadius: "50%",
                  marginRight: 12,
                }}
              ></div>
              <span className="text">Talent recruitment</span>
            </div>
            <div className="expanded-content">
              <span
                style={{
                  display: "block",
                  marginTop: "10px",
                  fontSize: isMobile ? "15px" : "32px",
                }}
              >
                52%
              </span>
              <p style={{ marginTop: "5px", fontSize: isMobile ? "10px" : "13px" }}>
                of job seekers considering a company's supplier diversity and
                inclusion programs, emphasizing the importance of supplier
                diversity in talent attraction. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChartSection;
