// actions.js

import axios from 'axios'; 
import {
  SEND_OTP,
  VERIFY_OTP,
  OTP_SENT_SUCCESS,
  OTP_SENT_FAILURE,
  OTP_VERIFIED_SUCCESS,
  OTP_VERIFIED_FAILURE,
} from '../constants/constants';
import { ENDPOINTS } from '../URL';


export const sendOTP = (email) => ({
  type: SEND_OTP,
  payload: email,
});

export const verifyOTP = (otp) => ({
  type: VERIFY_OTP,
  payload: otp,
});

export const otpSentSuccess = (otpReferenceId) => ({
  type: OTP_SENT_SUCCESS,
  payload: otpReferenceId,
});


export const otpSentFailure = (error) => ({
  type: OTP_SENT_FAILURE,
  payload: error,
});

export const otpVerifiedSuccess = (status) => ({
  type: OTP_VERIFIED_SUCCESS,
  payload: status,
});

export const otpVerifiedFailure = (error) => ({
  type: OTP_VERIFIED_FAILURE,
  payload: error,
});


// export const sendAndVerifyOTP = (email, otp) => {
//   return async (dispatch) => {
//     try {
//       // Send POST request to the endpoint with email and OTP code
//       const response = await axios.post('http://localhost:9000/users/verify', { email, otp });

//       // Log the response received from the server
//       console.log('Response from server:', response.data);

//       // Check if the response contains an auth token
//       const authToken = response.data.accessToken;
//       if (authToken) {
//         // Dispatch success action for OTP sent and OTP verified
//         dispatch(otpSentSuccess());
//         dispatch(otpVerifiedSuccess(authToken));
//       } else {
//         // If auth token is not present in the response, dispatch failure action
//         dispatch(otpVerifiedFailure('Invalid OTP'));
//       }
//     } catch (error) {
//       // Log the error message
//       console.error('Error:', error.message);

//       // Dispatch failure action with error message
//       dispatch(otpSentFailure(error.message));
//       dispatch(otpVerifiedFailure(error.message));
//     }
//   };
// };
export const getSellerOTP = (userEmail, otpLength) => {
  const url = ENDPOINTS.GET_OTP; // Assuming ENDPOINTS.GET_OTP is defined somewhere
  return async (dispatch) => {
    try {
      const response = await axios.post(url, { userEmail, otpLength });

      console.log('Response from server OTP reference id:', response.data);

      // Dispatch success action with OTP reference id
      dispatch(otpSentSuccess(response.data));

    } catch (error) {
      console.error('Error:', error);

      dispatch(otpSentFailure(error.response ? error.response.data : error.message));
    }
  };
};


// export const verifySellerOTP = (userEmail, userOtpReferenceId, userOtp) => {
//   const url = ENDPOINTS.VERIFY_OTP; 
//   return async (dispatch) => {
//     try {
//       const response = await axios.post(url, { userEmail, userOtpReferenceId, userOtp });
//       console.log('Response from server after OTP verification:', response.data);

//       // Check if status is 'VERIFIED', then dispatch success action
//       if (response.data.status === 'VERIFIED') {
//         dispatch(otpVerifiedSuccess(response.data.status));
//       } else {
//         // If status is not 'VERIFIED', dispatch failure action
//         dispatch(otpVerifiedFailure(response.data.status));
//       }
//     } catch (error) {
//       console.error('Error:', error);
//       dispatch(otpVerifiedFailure(error.response ? error.response.data : error.message));
//     }
//   };
// };

export const verifySellerOTP = (userEmail, userOtpReferenceId, userOtp) => {
  const url = ENDPOINTS.VERIFY_OTP; 
  return async (dispatch) => {
    try {
      const response = await axios.post(url, { userEmail, userOtpReferenceId, userOtp });
      console.log('Response from server after OTP verification:', response.data);

      if (response.data.status === 'VERIFIED') {
        dispatch({ type: OTP_VERIFIED_SUCCESS, status: response.data.status });
      } else {
        dispatch({ type: OTP_VERIFIED_FAILURE, status: response.data.status });
      }
    } catch (error) {
      console.error('Error:', error);
      dispatch({ type: OTP_VERIFIED_FAILURE, status: error.response ? error.response.data : error.message });
    }
  };
};



